/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cancelTripMutation
// ====================================================

export interface cancelTripMutation_cancelTrip_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  profilePictureUrl: string | null;
}

export interface cancelTripMutation_cancelTrip_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  generatedAt: CORE_IsoDateTime | null;
}

export interface cancelTripMutation_cancelTrip_vehicle {
  __typename: "Vehicle";
  id: string;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  availability: cancelTripMutation_cancelTrip_vehicle_availability | null;
}

export interface cancelTripMutation_cancelTrip {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  cancelledBy: string | null;
  cancelledByAdmin: cancelTripMutation_cancelTrip_cancelledByAdmin | null;
  cancelledReason: string | null;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
  vehicle: cancelTripMutation_cancelTrip_vehicle | null;
}

export interface cancelTripMutation {
  cancelTrip: cancelTripMutation_cancelTrip | null;
}

export interface cancelTripMutationVariables {
  id: string;
  reason?: string | null;
  givePenalty?: boolean | null;
  charge?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEntityTagMutation
// ====================================================

export interface createEntityTagMutation_createEntityTag {
  __typename: "EntityTag";
  id: string;
}

export interface createEntityTagMutation {
  createEntityTag: createEntityTagMutation_createEntityTag | null;
}

export interface createEntityTagMutationVariables {
  input?: EntityTagInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteEntityTagMutation
// ====================================================

export interface deleteEntityTagMutation_deleteEntityTag {
  __typename: "EntityTag";
  id: string;
}

export interface deleteEntityTagMutation {
  deleteEntityTag: deleteEntityTagMutation_deleteEntityTag | null;
}

export interface deleteEntityTagMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tagsQuery
// ====================================================

export interface tagsQuery_tags {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface tagsQuery {
  tags: tagsQuery_tags[];
}

export interface tagsQueryVariables {
  systemId: string;
  entityKind: Entity;
  includeSystemTags?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: mutationLog
// ====================================================

export interface mutationLog_mutationLog_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface mutationLog_mutationLog_edges_node_mutations_item {
  __typename: "TypedMutation";
  lhs: CORE_JSON | null;
  rhs: CORE_JSON | null;
  kind: MutationKind | null;
  index: string | null;
  path: (string | null)[] | null;
}

export interface mutationLog_mutationLog_edges_node_mutations {
  __typename: "TypedMutation";
  lhs: CORE_JSON | null;
  rhs: CORE_JSON | null;
  kind: MutationKind | null;
  index: string | null;
  path: (string | null)[] | null;
  item: mutationLog_mutationLog_edges_node_mutations_item | null;
}

export interface mutationLog_mutationLog_edges_node {
  __typename: "TypedMutationLogNode";
  id: string;
  createdAt: CORE_IsoDateTime;
  mutations: mutationLog_mutationLog_edges_node_mutations[];
}

export interface mutationLog_mutationLog_edges {
  __typename: "TypedMutationLogEdge";
  node: mutationLog_mutationLog_edges_node;
}

export interface mutationLog_mutationLog {
  __typename: "TypedMutationLog";
  pageInfo: mutationLog_mutationLog_pageInfo;
  edges: mutationLog_mutationLog_edges[];
}

export interface mutationLog {
  mutationLog: mutationLog_mutationLog;
}

export interface mutationLogVariables {
  entityId: string;
  entityKind: Entity;
  cursor?: Cursor | null;
  options?: FilterAndOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: controllerAssemblyExistingController
// ====================================================

export interface controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_displayName = controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_displayName_VehicleDisplayNamed | controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_displayName_VehicleDisplayNumbered | controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_displayName_VehicleDisplayAnonymous;

export interface controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle {
  __typename: "Vehicle";
  id: string;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_displayName;
  model: controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle_model | null;
}

export interface controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_DockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
  name: string | null;
}

export type controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity = controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_Vehicle | controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity_DockGroup;

export interface controllerAssemblyExistingController_controllersConnection_edges_node_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
  type: AssetType;
}

export interface controllerAssemblyExistingController_controllersConnection_edges_node {
  __typename: "Controller";
  id: string;
  pairedEntity: controllerAssemblyExistingController_controllersConnection_edges_node_pairedEntity | null;
  imei: string | null;
  physicalLockId: string | null;
  iccid: string | null;
  latestResponse: CORE_IsoDateTime | null;
  model: controllerAssemblyExistingController_controllersConnection_edges_node_model | null;
}

export interface controllerAssemblyExistingController_controllersConnection_edges {
  __typename: "ControllerEdge";
  node: controllerAssemblyExistingController_controllersConnection_edges_node;
}

export interface controllerAssemblyExistingController_controllersConnection {
  __typename: "ControllersConnection";
  edges: controllerAssemblyExistingController_controllersConnection_edges[];
}

export interface controllerAssemblyExistingController {
  controllersConnection: controllerAssemblyExistingController_controllersConnection | null;
}

export interface controllerAssemblyExistingControllerVariables {
  systemId: string;
  filters?: (Filter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: globalControllerSearch
// ====================================================

export interface globalControllerSearch_globalControllerSearch_pairedEntity_Vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
}

export interface globalControllerSearch_globalControllerSearch_pairedEntity_DockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
  name: string | null;
}

export type globalControllerSearch_globalControllerSearch_pairedEntity = globalControllerSearch_globalControllerSearch_pairedEntity_Vehicle | globalControllerSearch_globalControllerSearch_pairedEntity_DockGroup;

export interface globalControllerSearch_globalControllerSearch {
  __typename: "Controller";
  id: string;
  imei: string | null;
  physicalLockId: string | null;
  systemId: string | null;
  pairedEntity: globalControllerSearch_globalControllerSearch_pairedEntity | null;
}

export interface globalControllerSearch {
  globalControllerSearch: (globalControllerSearch_globalControllerSearch | null)[] | null;
}

export interface globalControllerSearchVariables {
  physicalLockId?: string | null;
  imei?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleControllerAssemblyPaginated
// ====================================================

export interface vehicleControllerAssemblyPaginated_vehiclesPaginated_rows_controller {
  __typename: "Controller";
  id: string;
  serialNumber: string | null;
  physicalLockId: string | null;
}

export interface vehicleControllerAssemblyPaginated_vehiclesPaginated_rows {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  controller: vehicleControllerAssemblyPaginated_vehiclesPaginated_rows_controller | null;
}

export interface vehicleControllerAssemblyPaginated_vehiclesPaginated {
  __typename: "VehiclesPaginated";
  rows: vehicleControllerAssemblyPaginated_vehiclesPaginated_rows[];
}

export interface vehicleControllerAssemblyPaginated {
  vehiclesPaginated: vehicleControllerAssemblyPaginated_vehiclesPaginated | null;
}

export interface vehicleControllerAssemblyPaginatedVariables {
  systemId: string;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleRFIDSearch
// ====================================================

export interface vehicleRFIDSearch_vehiclesPaginated_rows_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface vehicleRFIDSearch_vehiclesPaginated_rows_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface vehicleRFIDSearch_vehiclesPaginated_rows_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type vehicleRFIDSearch_vehiclesPaginated_rows_displayName = vehicleRFIDSearch_vehiclesPaginated_rows_displayName_VehicleDisplayNamed | vehicleRFIDSearch_vehiclesPaginated_rows_displayName_VehicleDisplayNumbered | vehicleRFIDSearch_vehiclesPaginated_rows_displayName_VehicleDisplayAnonymous;

export interface vehicleRFIDSearch_vehiclesPaginated_rows_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface vehicleRFIDSearch_vehiclesPaginated_rows_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
}

export interface vehicleRFIDSearch_vehiclesPaginated_rows_entityTags {
  __typename: "EntityTag";
  id: string;
  tag: vehicleRFIDSearch_vehiclesPaginated_rows_entityTags_tag;
}

export interface vehicleRFIDSearch_vehiclesPaginated_rows {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: vehicleRFIDSearch_vehiclesPaginated_rows_displayName;
  model: vehicleRFIDSearch_vehiclesPaginated_rows_model | null;
  rfid: string | null;
  frameNumber: string | null;
  entityTags: vehicleRFIDSearch_vehiclesPaginated_rows_entityTags[] | null;
}

export interface vehicleRFIDSearch_vehiclesPaginated {
  __typename: "VehiclesPaginated";
  rows: vehicleRFIDSearch_vehiclesPaginated_rows[];
}

export interface vehicleRFIDSearch {
  vehiclesPaginated: vehicleRFIDSearch_vehiclesPaginated | null;
}

export interface vehicleRFIDSearchVariables {
  systemId: string;
  limit: number;
  filters?: (Filter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: businessCompaniesQuery
// ====================================================

export interface businessCompaniesQuery_businessCompanies_approvals {
  __typename: "BusinessCompanyApprovals";
  sendInvoice: boolean;
}

export interface businessCompaniesQuery_businessCompanies {
  __typename: "BusinessCompanyListItem";
  id: string;
  name: string;
  organizationNumber: string;
  customerNumber: CORE_PositiveInt | null;
  address: string;
  zipCode: string;
  city: string;
  receiptEmail: string;
  collectionMethod: CollectionMethod;
  approvals: businessCompaniesQuery_businessCompanies_approvals;
}

export interface businessCompaniesQuery {
  businessCompanies: businessCompaniesQuery_businessCompanies[];
}

export interface businessCompaniesQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteBusinessCompanyMutation
// ====================================================

export interface deleteBusinessCompanyMutation_deleteBusinessCompany {
  __typename: "BusinessCompany";
  id: string;
}

export interface deleteBusinessCompanyMutation {
  deleteBusinessCompany: deleteBusinessCompanyMutation_deleteBusinessCompany;
}

export interface deleteBusinessCompanyMutationVariables {
  id: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: businessCompanyQuery
// ====================================================

export interface businessCompanyQuery_businessCompany_approvals {
  __typename: "BusinessCompanyApprovals";
  sendInvoice: boolean;
}

export interface businessCompanyQuery_businessCompany_companyAdministrators {
  __typename: "CompanyAdministrator";
  id: string;
  name: string | null;
  email: string | null;
  createdAt: CORE_IsoDateTime;
}

export interface businessCompanyQuery_businessCompany {
  __typename: "BusinessCompany";
  id: string;
  systemId: string;
  name: string;
  organizationNumber: string;
  customerNumber: CORE_PositiveInt | null;
  address: string;
  zipCode: string;
  city: string;
  receiptEmail: string;
  collectionMethod: CollectionMethod;
  approvals: businessCompanyQuery_businessCompany_approvals;
  tripChargeConsolidationEnabled: boolean | null;
  companyAdministrators: businessCompanyQuery_businessCompany_companyAdministrators[];
}

export interface businessCompanyQuery {
  businessCompany: businessCompanyQuery_businessCompany | null;
}

export interface businessCompanyQueryVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemCollectionMethodsQuery
// ====================================================

export interface systemCollectionMethodsQuery_system_collectionMethods {
  __typename: "CollectionMethods";
  chargeAutomatically: boolean;
  sendInvoice: boolean;
}

export interface systemCollectionMethodsQuery_system {
  __typename: "System";
  id: string;
  collectionMethods: systemCollectionMethodsQuery_system_collectionMethods;
}

export interface systemCollectionMethodsQuery {
  system: systemCollectionMethodsQuery_system;
}

export interface systemCollectionMethodsQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBusinessCompanyMutation
// ====================================================

export interface updateBusinessCompanyMutation_updateBusinessCompany {
  __typename: "BusinessCompany";
  id: string;
}

export interface updateBusinessCompanyMutation {
  updateBusinessCompany: updateBusinessCompanyMutation_updateBusinessCompany;
}

export interface updateBusinessCompanyMutationVariables {
  id: string;
  systemId: string;
  input: BusinessCompanyInput;
  revokeValueCodes?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: companyAdministratorQuery
// ====================================================

export interface companyAdministratorQuery_companyAdministrator {
  __typename: "CompanyAdministrator";
  id: string;
  name: string | null;
  email: string | null;
  createdAt: CORE_IsoDateTime;
}

export interface companyAdministratorQuery {
  companyAdministrator: companyAdministratorQuery_companyAdministrator | null;
}

export interface companyAdministratorQueryVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCompanyAdministratorMutation
// ====================================================

export interface updateCompanyAdministratorMutation_updateCompanyAdministrator {
  __typename: "CompanyAdministrator";
  id: string;
}

export interface updateCompanyAdministratorMutation {
  updateCompanyAdministrator: updateCompanyAdministratorMutation_updateCompanyAdministrator;
}

export interface updateCompanyAdministratorMutationVariables {
  id: string;
  systemId: string;
  input: CompanyAdministratorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteCompanyAdministratorMutation
// ====================================================

export interface deleteCompanyAdministratorMutation_deleteCompanyAdministrator {
  __typename: "CompanyAdministrator";
  id: string;
}

export interface deleteCompanyAdministratorMutation {
  deleteCompanyAdministrator: deleteCompanyAdministratorMutation_deleteCompanyAdministrator;
}

export interface deleteCompanyAdministratorMutationVariables {
  id: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: controllerEventLogsConnection
// ====================================================

export interface controllerEventLogsConnection_controllerEventLogsConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface controllerEventLogsConnection_controllerEventLogsConnection_edges_node {
  __typename: "ControllerEventLog";
  id: string;
  topic: string | null;
  payload: string | null;
  controllerId: string | null;
  generatedAt: CORE_IsoDateTime | null;
  publishedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  cellularNetworkLatency: number | null;
  pubSubLatency: number | null;
  direction: ControllerEventLogDirection | null;
  bridgeId: string | null;
}

export interface controllerEventLogsConnection_controllerEventLogsConnection_edges {
  __typename: "ControllerEventLogEdge";
  cursor: string;
  node: controllerEventLogsConnection_controllerEventLogsConnection_edges_node;
}

export interface controllerEventLogsConnection_controllerEventLogsConnection {
  __typename: "ControllerEventLogConnection";
  pageInfo: controllerEventLogsConnection_controllerEventLogsConnection_pageInfo;
  edges: controllerEventLogsConnection_controllerEventLogsConnection_edges[];
}

export interface controllerEventLogsConnection {
  controllerEventLogsConnection: controllerEventLogsConnection_controllerEventLogsConnection | null;
}

export interface controllerEventLogsConnectionVariables {
  systemId: string;
  controllerId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  complexFilters?: ComplexFilter[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: pairWithVehicleForm
// ====================================================

export interface pairWithVehicleForm_vehiclesPaginated_rows_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface pairWithVehicleForm_vehiclesPaginated_rows_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface pairWithVehicleForm_vehiclesPaginated_rows_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type pairWithVehicleForm_vehiclesPaginated_rows_displayName = pairWithVehicleForm_vehiclesPaginated_rows_displayName_VehicleDisplayNamed | pairWithVehicleForm_vehiclesPaginated_rows_displayName_VehicleDisplayNumbered | pairWithVehicleForm_vehiclesPaginated_rows_displayName_VehicleDisplayAnonymous;

export interface pairWithVehicleForm_vehiclesPaginated_rows_controller {
  __typename: "Controller";
  id: string;
  serialNumber: string | null;
}

export interface pairWithVehicleForm_vehiclesPaginated_rows {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: pairWithVehicleForm_vehiclesPaginated_rows_displayName;
  controller: pairWithVehicleForm_vehiclesPaginated_rows_controller | null;
}

export interface pairWithVehicleForm_vehiclesPaginated {
  __typename: "VehiclesPaginated";
  rows: pairWithVehicleForm_vehiclesPaginated_rows[];
}

export interface pairWithVehicleForm {
  vehiclesPaginated: pairWithVehicleForm_vehiclesPaginated | null;
}

export interface pairWithVehicleFormVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: pairControllerWithVehicle
// ====================================================

export interface pairControllerWithVehicle_pairControllerWithVehicle {
  __typename: "Controller";
  id: string;
}

export interface pairControllerWithVehicle {
  pairControllerWithVehicle: pairControllerWithVehicle_pairControllerWithVehicle | null;
}

export interface pairControllerWithVehicleVariables {
  controllerId: string;
  vehicleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: batteryChartQuery
// ====================================================

export interface batteryChartQuery_controller_batteryLogData {
  __typename: "BatteryLog";
  id: string;
  voltage: number | null;
  percentage: number | null;
  generatedAt: CORE_IsoDateTime | null;
}

export interface batteryChartQuery_controller {
  __typename: "Controller";
  id: string;
  batteryLogData: (batteryChartQuery_controller_batteryLogData | null)[] | null;
}

export interface batteryChartQuery {
  controller: batteryChartQuery_controller | null;
}

export interface batteryChartQueryVariables {
  controllerId: string;
  fromTime?: CORE_IsoDateTime | null;
  toTime?: CORE_IsoDateTime | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: controllerForm
// ====================================================

export interface controllerForm_assetModelsConnection_edges_node {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
  name: string | null;
  description: string | null;
}

export interface controllerForm_assetModelsConnection_edges {
  __typename: "AssetModelEdge";
  node: controllerForm_assetModelsConnection_edges_node;
}

export interface controllerForm_assetModelsConnection {
  __typename: "AssetModelsConnection";
  edges: controllerForm_assetModelsConnection_edges[];
}

export interface controllerForm {
  assetModelsConnection: controllerForm_assetModelsConnection | null;
}

export interface controllerFormVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paginatedHardwareControllersQuery
// ====================================================

export interface paginatedHardwareControllersQuery_paginatedHardwareControllers_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface paginatedHardwareControllersQuery_paginatedHardwareControllers_rows_pairedEntity_DockGroup {
  __typename: "DockGroup";
}

export interface paginatedHardwareControllersQuery_paginatedHardwareControllers_rows_pairedEntity_Vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
}

export type paginatedHardwareControllersQuery_paginatedHardwareControllers_rows_pairedEntity = paginatedHardwareControllersQuery_paginatedHardwareControllers_rows_pairedEntity_DockGroup | paginatedHardwareControllersQuery_paginatedHardwareControllers_rows_pairedEntity_Vehicle;

export interface paginatedHardwareControllersQuery_paginatedHardwareControllers_rows {
  __typename: "Controller";
  id: string;
  vehicleId: string | null;
  systemId: string | null;
  state: ControllerState | null;
  powerState: PowerState | null;
  firmwareVersion: string | null;
  lockMechanismState: LockMechanismState | null;
  pairedEntity: paginatedHardwareControllersQuery_paginatedHardwareControllers_rows_pairedEntity | null;
  serialNumber: string | null;
  ip: string | null;
  imei: string | null;
  physicalLockId: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface paginatedHardwareControllersQuery_paginatedHardwareControllers {
  __typename: "PaginatedHardwareControllers";
  paginationInfo: paginatedHardwareControllersQuery_paginatedHardwareControllers_paginationInfo;
  rows: paginatedHardwareControllersQuery_paginatedHardwareControllers_rows[];
}

export interface paginatedHardwareControllersQuery {
  paginatedHardwareControllers: paginatedHardwareControllersQuery_paginatedHardwareControllers;
}

export interface paginatedHardwareControllersQueryVariables {
  systemId: string;
  pagination?: OffsetPagination | null;
  order?: HardwareControllerOrder | null;
  filters?: HardwareControllerFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createHardwareControllerDataExportMutation
// ====================================================

export interface createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller_pairedEntity_DockGroup {
  __typename: "DockGroup";
}

export interface createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller_pairedEntity_Vehicle {
  __typename: "Vehicle";
  id: string;
  vehicleName: string | null;
  number: string | null;
  frameNumber: string | null;
}

export type createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller_pairedEntity = createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller_pairedEntity_DockGroup | createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller_pairedEntity_Vehicle;

export interface createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller_model {
  __typename: "AssetModel";
  name: string | null;
  description: string | null;
  type: AssetType;
}

export interface createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller {
  __typename: "Controller";
  controllerId: string;
  vehicleId: string | null;
  systemId: string | null;
  state: ControllerState | null;
  powerState: PowerState | null;
  firmwareVersion: string | null;
  lockMechanismState: LockMechanismState | null;
  pairedEntity: createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller_pairedEntity | null;
  serialNumber: string | null;
  ip: string | null;
  imei: string | null;
  physicalLockId: string | null;
  latestResponse: CORE_IsoDateTime | null;
  model: createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller_model | null;
  iccid: string | null;
}

export interface createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest {
  __typename: "CreateHardwareControllerDataExportRequestResponse";
  dataExportRequest: createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual controller.
   */
  controller: createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest_controller | null;
}

export interface createHardwareControllerDataExportMutation {
  createHardwareControllerDataExportRequest: createHardwareControllerDataExportMutation_createHardwareControllerDataExportRequest;
}

export interface createHardwareControllerDataExportMutationVariables {
  systemId: string;
  order?: HardwareControllerOrder | null;
  filters?: HardwareControllerFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardTripsThisYear
// ====================================================

export interface dashboardTripsThisYear {
  tripsThisYear: number | null;
}

export interface dashboardTripsThisYearVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardNumbers
// ====================================================

export interface dashboardNumbers_dashboard {
  __typename: "Dashboard";
  vehicles: number | null;
  controllers: number | null;
  users: number | null;
  vehiclesTaggedExceeding: number | null;
}

export interface dashboardNumbers {
  dashboard: dashboardNumbers_dashboard | null;
}

export interface dashboardNumbersVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardDockGroupStatistics
// ====================================================

export interface dashboardDockGroupStatistics_dockGroupStatistics {
  __typename: "DockGroupStatistics";
  totalActiveDocks: number | null;
  totalBrokenDocks: number | null;
  totalMaintenanceDockGroups: number | null;
  totalNotInServiceDockGroups: number | null;
  totalOnlineActiveDockGroups: number | null;
  totalUnresponsiveActiveDockGroups: number | null;
}

export interface dashboardDockGroupStatistics {
  dockGroupStatistics: dashboardDockGroupStatistics_dockGroupStatistics | null;
}

export interface dashboardDockGroupStatisticsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardSystemActiveTripCount
// ====================================================

export interface dashboardSystemActiveTripCount_systemActiveTripCount {
  __typename: "Count";
  count: number | null;
}

export interface dashboardSystemActiveTripCount {
  systemActiveTripCount: dashboardSystemActiveTripCount_systemActiveTripCount | null;
}

export interface dashboardSystemActiveTripCountVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardRebalancingStatistics
// ====================================================

export interface dashboardRebalancingStatistics_rebalancingStatistics {
  __typename: "DockGroupStatistics";
  totalBalancedDockGroups: number;
  totalDockGroupsWithoutAvailableDocks: number;
  totalDockGroupsWithoutAvailableVehicles: number;
}

export interface dashboardRebalancingStatistics {
  rebalancingStatistics: dashboardRebalancingStatistics_rebalancingStatistics | null;
}

export interface dashboardRebalancingStatisticsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardOperationLocationsStatistics
// ====================================================

export interface dashboardOperationLocationsStatistics_operationLocationsStatistics {
  __typename: "OperationLocationForStatistics";
  id: string;
  name: string;
  vehicleCount: number;
}

export interface dashboardOperationLocationsStatistics {
  operationLocationsStatistics: dashboardOperationLocationsStatistics_operationLocationsStatistics[];
}

export interface dashboardOperationLocationsStatisticsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardMisplacedVehicles
// ====================================================

export interface dashboardMisplacedVehicles_misplacedVehicles {
  __typename: "MisplacedVehicle";
  id: string;
}

export interface dashboardMisplacedVehicles {
  misplacedVehicles: dashboardMisplacedVehicles_misplacedVehicles[] | null;
}

export interface dashboardMisplacedVehiclesVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardActiveSystemAlerts
// ====================================================

export interface dashboardActiveSystemAlerts_activeSystemAlerts_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface dashboardActiveSystemAlerts_activeSystemAlerts_description {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface dashboardActiveSystemAlerts_activeSystemAlerts {
  __typename: "SystemAlert";
  id: string;
  incidentStartTime: CORE_IsoDateTime;
  title: dashboardActiveSystemAlerts_activeSystemAlerts_title[] | null;
  description: dashboardActiveSystemAlerts_activeSystemAlerts_description[] | null;
  visibility: SystemAlertVisibility;
}

export interface dashboardActiveSystemAlerts {
  activeSystemAlerts: dashboardActiveSystemAlerts_activeSystemAlerts[] | null;
}

export interface dashboardActiveSystemAlertsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardVehicleInventoryMas
// ====================================================

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesInService {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesOnStreet {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesInStation {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesOnTrip {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesWithOperations {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesInOperationLocation {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesInFreeFloatingLocation {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesAvailableInFreeFloatingLocation {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesAvailabilityUnknown {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesAvailableInStation {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory_vehiclesUnavailableInStation {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleInventoryMas_vehicleInventory {
  __typename: "VehicleInventory";
  vehiclesInService: dashboardVehicleInventoryMas_vehicleInventory_vehiclesInService;
  vehiclesOnStreet: dashboardVehicleInventoryMas_vehicleInventory_vehiclesOnStreet;
  vehiclesInStation: dashboardVehicleInventoryMas_vehicleInventory_vehiclesInStation;
  vehiclesOnTrip: dashboardVehicleInventoryMas_vehicleInventory_vehiclesOnTrip;
  vehiclesWithOperations: dashboardVehicleInventoryMas_vehicleInventory_vehiclesWithOperations;
  vehiclesInOperationLocation: dashboardVehicleInventoryMas_vehicleInventory_vehiclesInOperationLocation;
  vehiclesInFreeFloatingLocation: dashboardVehicleInventoryMas_vehicleInventory_vehiclesInFreeFloatingLocation;
  vehiclesAvailableInFreeFloatingLocation: dashboardVehicleInventoryMas_vehicleInventory_vehiclesAvailableInFreeFloatingLocation;
  vehiclesUnavailableInFreeFloatingLocation: dashboardVehicleInventoryMas_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation;
  vehiclesAvailabilityUnknown: dashboardVehicleInventoryMas_vehicleInventory_vehiclesAvailabilityUnknown;
  vehiclesAvailableInStation: dashboardVehicleInventoryMas_vehicleInventory_vehiclesAvailableInStation;
  vehiclesUnavailableInStation: dashboardVehicleInventoryMas_vehicleInventory_vehiclesUnavailableInStation;
}

export interface dashboardVehicleInventoryMas {
  vehicleInventory: dashboardVehicleInventoryMas_vehicleInventory;
}

export interface dashboardVehicleInventoryMasVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardVehiclesInventoryBattery
// ====================================================

export interface dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetBatteryOk {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetBatteryLow {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetBatteryDead {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetControllerBatteryOk {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetControllerBatteryLow {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetControllerBatteryDead {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehiclesInventoryBattery_vehicleInventory {
  __typename: "VehicleInventory";
  vehiclesOnStreetBatteryOk: dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetBatteryOk;
  vehiclesOnStreetBatteryLow: dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetBatteryLow;
  vehiclesOnStreetBatteryDead: dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetBatteryDead;
  vehiclesOnStreetControllerBatteryOk: dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetControllerBatteryOk;
  vehiclesOnStreetControllerBatteryLow: dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetControllerBatteryLow;
  vehiclesOnStreetControllerBatteryDead: dashboardVehiclesInventoryBattery_vehicleInventory_vehiclesOnStreetControllerBatteryDead;
}

export interface dashboardVehiclesInventoryBattery {
  vehicleInventory: dashboardVehiclesInventoryBattery_vehicleInventory;
}

export interface dashboardVehiclesInventoryBatteryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardVehicleMissingLost
// ====================================================

export interface dashboardVehicleMissingLost_vehicleInventory_vehiclesAvailabilityUnknown {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleMissingLost_vehicleInventory_vehiclesLost {
  __typename: "VehicleInventoryNode";
  count: number;
}

export interface dashboardVehicleMissingLost_vehicleInventory {
  __typename: "VehicleInventory";
  vehiclesAvailabilityUnknown: dashboardVehicleMissingLost_vehicleInventory_vehiclesAvailabilityUnknown;
  vehiclesLost: dashboardVehicleMissingLost_vehicleInventory_vehiclesLost;
}

export interface dashboardVehicleMissingLost {
  vehicleInventory: dashboardVehicleMissingLost_vehicleInventory;
}

export interface dashboardVehicleMissingLostVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dataExportRequestsQuery
// ====================================================

export interface dataExportRequestsQuery_dataExportRequests_DataExportRequestPending {
  __typename: "DataExportRequestPending";
  id: string;
  createdAt: CORE_IsoDateTime;
  entityKind: DataExportEntityKind;
}

export interface dataExportRequestsQuery_dataExportRequests_DataExportRequestCompleted {
  __typename: "DataExportRequestCompleted";
  id: string;
  createdAt: CORE_IsoDateTime;
  entityKind: DataExportEntityKind;
  url: string;
  completedAt: CORE_IsoDateTime;
  expiresAt: CORE_IsoDateTime;
}

export interface dataExportRequestsQuery_dataExportRequests_DataExportRequestRemoved {
  __typename: "DataExportRequestRemoved";
  id: string;
  createdAt: CORE_IsoDateTime;
  entityKind: DataExportEntityKind;
  deletedAt: CORE_IsoDateTime;
}

export interface dataExportRequestsQuery_dataExportRequests_DataExportRequestError {
  __typename: "DataExportRequestError";
  id: string;
  createdAt: CORE_IsoDateTime;
  entityKind: DataExportEntityKind;
}

export type dataExportRequestsQuery_dataExportRequests = dataExportRequestsQuery_dataExportRequests_DataExportRequestPending | dataExportRequestsQuery_dataExportRequests_DataExportRequestCompleted | dataExportRequestsQuery_dataExportRequests_DataExportRequestRemoved | dataExportRequestsQuery_dataExportRequests_DataExportRequestError;

export interface dataExportRequestsQuery {
  /**
   * Get all data export requests for an Aadministrator.
   */
  dataExportRequests: dataExportRequestsQuery_dataExportRequests[];
}

export interface dataExportRequestsQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dataExportRequestQuery
// ====================================================

export interface dataExportRequestQuery_dataExportRequest_DataExportRequestPending {
  __typename: "DataExportRequestPending";
  id: string;
  createdAt: CORE_IsoDateTime;
  entityKind: DataExportEntityKind;
}

export interface dataExportRequestQuery_dataExportRequest_DataExportRequestCompleted {
  __typename: "DataExportRequestCompleted";
  id: string;
  createdAt: CORE_IsoDateTime;
  entityKind: DataExportEntityKind;
  url: string;
  completedAt: CORE_IsoDateTime;
  expiresAt: CORE_IsoDateTime;
}

export interface dataExportRequestQuery_dataExportRequest_DataExportRequestRemoved {
  __typename: "DataExportRequestRemoved";
  id: string;
  createdAt: CORE_IsoDateTime;
  entityKind: DataExportEntityKind;
  deletedAt: CORE_IsoDateTime;
}

export interface dataExportRequestQuery_dataExportRequest_DataExportRequestError {
  __typename: "DataExportRequestError";
  id: string;
  createdAt: CORE_IsoDateTime;
  entityKind: DataExportEntityKind;
}

export type dataExportRequestQuery_dataExportRequest = dataExportRequestQuery_dataExportRequest_DataExportRequestPending | dataExportRequestQuery_dataExportRequest_DataExportRequestCompleted | dataExportRequestQuery_dataExportRequest_DataExportRequestRemoved | dataExportRequestQuery_dataExportRequest_DataExportRequestError;

export interface dataExportRequestQuery {
  /**
   * Get one dataExportRequests. Useful for checking if the state have changed from pending to processed.
   */
  dataExportRequest: dataExportRequestQuery_dataExportRequest | null;
}

export interface dataExportRequestQueryVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDiscountCampaign
// ====================================================

export interface createDiscountCampaign_createDiscountCampaign {
  __typename: "DiscountCampaign";
  /**
   * Id
   */
  id: string;
}

export interface createDiscountCampaign {
  createDiscountCampaign: createDiscountCampaign_createDiscountCampaign | null;
}

export interface createDiscountCampaignVariables {
  systemId: string;
  input: DiscountCampaignCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discountProducts
// ====================================================

export interface discountProducts_productsPaginated_rows {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface discountProducts_productsPaginated {
  __typename: "ProductsPaginated";
  rows: discountProducts_productsPaginated_rows[];
}

export interface discountProducts {
  productsPaginated: discountProducts_productsPaginated | null;
}

export interface discountProductsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discountsFormMeta
// ====================================================

export interface discountsFormMeta_discountCampaignType_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface discountsFormMeta_discountCampaignType {
  __typename: "__Type";
  name: string | null;
  enumValues: discountsFormMeta_discountCampaignType_enumValues[] | null;
}

export interface discountsFormMeta_discountType_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface discountsFormMeta_discountType {
  __typename: "__Type";
  name: string | null;
  enumValues: discountsFormMeta_discountType_enumValues[] | null;
}

export interface discountsFormMeta {
  discountCampaignType: discountsFormMeta_discountCampaignType | null;
  discountType: discountsFormMeta_discountType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discountCampaign
// ====================================================

export interface discountCampaign_discountCampaign_localizationData_name {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface discountCampaign_discountCampaign_localizationData {
  __typename: "DiscountCampaignLocalizationData";
  name: discountCampaign_discountCampaign_localizationData_name[] | null;
}

export interface discountCampaign_discountCampaign_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
}

export interface discountCampaign_discountCampaign_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type discountCampaign_discountCampaign_discountAmount = discountCampaign_discountCampaign_discountAmount_RelativeDiscountAmount | discountCampaign_discountCampaign_discountAmount_AbsoluteDiscountAmount;

export interface discountCampaign_discountCampaign_products {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface discountCampaign_discountCampaign {
  __typename: "DiscountCampaign";
  /**
   * Id
   */
  id: string;
  /**
   * Defines unique discount campaign name
   */
  name: string;
  /**
   * Defines discount campaign localization data
   */
  localizationData: discountCampaign_discountCampaign_localizationData | null;
  /**
   * System id
   */
  systemId: string;
  /**
   * Defines discount campaign strategy
   */
  type: DiscountCampaignType;
  /**
   * Defines how many time discount can be applied (0 - unlimited)
   */
  limit: number;
  /**
   * Current count of applied discounts
   */
  appliedCount: CORE_NonNegativeInt;
  /**
   * Allows or disallows apply discount
   */
  active: boolean;
  /**
   * Defines discount amount
   */
  discountAmount: discountCampaign_discountCampaign_discountAmount;
  /**
   * If active each user is able to apply discount only once
   */
  applicableOncePerUser: boolean;
  /**
   * If active discount can be applied for subscription order
   */
  applicableForSubscription: boolean;
  /**
   * If active discount can be applied for value code order
   */
  applicableForValueCode: boolean;
  /**
   * If active discount can be applied for any product among the system
   */
  applicableForAllProducts: boolean;
  code: string | null;
  /**
   * Defines start date of discount campaign
   */
  validFrom: CORE_IsoDateTime;
  /**
   * Defines end date of discount campaign
   */
  validTo: CORE_IsoDateTime | null;
  /**
   * Discount aplicable set of products
   */
  products: discountCampaign_discountCampaign_products[];
}

export interface discountCampaign {
  discountCampaign: discountCampaign_discountCampaign | null;
}

export interface discountCampaignVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discountCampaignPaginated
// ====================================================

export interface discountCampaignPaginated_discountCampaignPaginated_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  totalRowCount: number;
}

export interface discountCampaignPaginated_discountCampaignPaginated_rows_localizationData_name {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface discountCampaignPaginated_discountCampaignPaginated_rows_localizationData {
  __typename: "DiscountCampaignLocalizationData";
  name: discountCampaignPaginated_discountCampaignPaginated_rows_localizationData_name[] | null;
}

export interface discountCampaignPaginated_discountCampaignPaginated_rows_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
}

export interface discountCampaignPaginated_discountCampaignPaginated_rows_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type discountCampaignPaginated_discountCampaignPaginated_rows_discountAmount = discountCampaignPaginated_discountCampaignPaginated_rows_discountAmount_RelativeDiscountAmount | discountCampaignPaginated_discountCampaignPaginated_rows_discountAmount_AbsoluteDiscountAmount;

export interface discountCampaignPaginated_discountCampaignPaginated_rows_products {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface discountCampaignPaginated_discountCampaignPaginated_rows {
  __typename: "DiscountCampaign";
  /**
   * Id
   */
  id: string;
  /**
   * Defines unique discount campaign name
   */
  name: string;
  /**
   * Defines discount campaign localization data
   */
  localizationData: discountCampaignPaginated_discountCampaignPaginated_rows_localizationData | null;
  /**
   * System id
   */
  systemId: string;
  /**
   * Defines discount campaign strategy
   */
  type: DiscountCampaignType;
  /**
   * Defines how many time discount can be applied (0 - unlimited)
   */
  limit: number;
  /**
   * Current count of applied discounts
   */
  appliedCount: CORE_NonNegativeInt;
  /**
   * Allows or disallows apply discount
   */
  active: boolean;
  /**
   * Defines discount amount
   */
  discountAmount: discountCampaignPaginated_discountCampaignPaginated_rows_discountAmount;
  /**
   * If active each user is able to apply discount only once
   */
  applicableOncePerUser: boolean;
  /**
   * If active discount can be applied for subscription order
   */
  applicableForSubscription: boolean;
  /**
   * If active discount can be applied for value code order
   */
  applicableForValueCode: boolean;
  /**
   * If active discount can be applied for any product among the system
   */
  applicableForAllProducts: boolean;
  code: string | null;
  /**
   * Defines start date of discount campaign
   */
  validFrom: CORE_IsoDateTime;
  /**
   * Defines end date of discount campaign
   */
  validTo: CORE_IsoDateTime | null;
  /**
   * Discount aplicable set of products
   */
  products: discountCampaignPaginated_discountCampaignPaginated_rows_products[];
}

export interface discountCampaignPaginated_discountCampaignPaginated {
  __typename: "DiscountCampaignsPaginated";
  paginationInfo: discountCampaignPaginated_discountCampaignPaginated_paginationInfo;
  rows: discountCampaignPaginated_discountCampaignPaginated_rows[];
}

export interface discountCampaignPaginated {
  discountCampaignPaginated: discountCampaignPaginated_discountCampaignPaginated | null;
}

export interface discountCampaignPaginatedVariables {
  complexFilters?: (ComplexFilter | null)[] | null;
  filters?: (Filter | null)[] | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteDiscountCampaign
// ====================================================

export interface deleteDiscountCampaign_deleteDiscountCampaign {
  __typename: "ObjectDeleted";
  id: string;
}

export interface deleteDiscountCampaign {
  deleteDiscountCampaign: deleteDiscountCampaign_deleteDiscountCampaign | null;
}

export interface deleteDiscountCampaignVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDiscountCampaign
// ====================================================

export interface updateDiscountCampaign_updateDiscountCampaign {
  __typename: "DiscountCampaign";
  /**
   * Id
   */
  id: string;
}

export interface updateDiscountCampaign {
  updateDiscountCampaign: updateDiscountCampaign_updateDiscountCampaign | null;
}

export interface updateDiscountCampaignVariables {
  systemId: string;
  id: string;
  input: DiscountCampaignUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupDocksConnection
// ====================================================

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalRowCount: number;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows_coords {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_controller {
  __typename: "Controller";
  id: string;
  batteryStatus: BatteryStatus | null;
  batteryVoltage: number | null;
  batteryCharge: number | null;
  model: dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_controller_model | null;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
  vehicleCategory: VehicleCategory | null;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_displayName = dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayNamed | dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayNumbered | dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayAnonymous;

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  availabilityState: VehicleAvailabilityState;
  state: VehicleState | null;
  availability: dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_availability | null;
  batteryCharge: number | null;
  controller: dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_controller | null;
  model: dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_model | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle_displayName;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks_rows {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  state: DockState | null;
  rfid: string | null;
  coords: dockGroupDocksConnection_dockGroup_paginatedDocks_rows_coords | null;
  brokenAt: CORE_IsoDateTime | null;
  brokenReason: string | null;
  vehicle: dockGroupDocksConnection_dockGroup_paginatedDocks_rows_vehicle | null;
  availabilityState: DockAvailabilityState | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
}

export interface dockGroupDocksConnection_dockGroup_paginatedDocks {
  __typename: "PaginatedDocks";
  paginationInfo: dockGroupDocksConnection_dockGroup_paginatedDocks_paginationInfo;
  rows: dockGroupDocksConnection_dockGroup_paginatedDocks_rows[];
}

export interface dockGroupDocksConnection_dockGroup {
  __typename: "DockGroup";
  id: string;
  state: DockGroupState | null;
  paginatedDocks: dockGroupDocksConnection_dockGroup_paginatedDocks;
}

export interface dockGroupDocksConnection {
  dockGroup: dockGroupDocksConnection_dockGroup | null;
}

export interface dockGroupDocksConnectionVariables {
  systemId: string;
  dockGroupId: string;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: docksAvailability
// ====================================================

export interface docksAvailability_dockGroup_docksConnection_edges_node_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface docksAvailability_dockGroup_docksConnection_edges_node_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface docksAvailability_dockGroup_docksConnection_edges_node_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type docksAvailability_dockGroup_docksConnection_edges_node_vehicle_displayName = docksAvailability_dockGroup_docksConnection_edges_node_vehicle_displayName_VehicleDisplayNamed | docksAvailability_dockGroup_docksConnection_edges_node_vehicle_displayName_VehicleDisplayNumbered | docksAvailability_dockGroup_docksConnection_edges_node_vehicle_displayName_VehicleDisplayAnonymous;

export interface docksAvailability_dockGroup_docksConnection_edges_node_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface docksAvailability_dockGroup_docksConnection_edges_node_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: docksAvailability_dockGroup_docksConnection_edges_node_vehicle_displayName;
  model: docksAvailability_dockGroup_docksConnection_edges_node_vehicle_model | null;
}

export interface docksAvailability_dockGroup_docksConnection_edges_node {
  __typename: "Dock";
  id: string;
  availabilityState: DockAvailabilityState | null;
  vehicle: docksAvailability_dockGroup_docksConnection_edges_node_vehicle | null;
}

export interface docksAvailability_dockGroup_docksConnection_edges {
  __typename: "DockEdge";
  cursor: string;
  node: docksAvailability_dockGroup_docksConnection_edges_node;
}

export interface docksAvailability_dockGroup_docksConnection {
  __typename: "DocksConnection";
  edges: docksAvailability_dockGroup_docksConnection_edges[];
}

export interface docksAvailability_dockGroup {
  __typename: "DockGroup";
  id: string;
  docksConnection: docksAvailability_dockGroup_docksConnection | null;
}

export interface docksAvailability {
  dockGroup: docksAvailability_dockGroup | null;
}

export interface docksAvailabilityVariables {
  systemId: string;
  dockGroupId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendDockCommand
// ====================================================

export interface sendDockCommand_sendDockCommand {
  __typename: "DockCommandResponse";
  response: string;
}

export interface sendDockCommand {
  sendDockCommand: sendDockCommand_sendDockCommand | null;
}

export interface sendDockCommandVariables {
  id: string;
  command: DockCommand;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupProperties
// ====================================================

export interface dockGroupProperties_dockGroupProperties_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface dockGroupProperties_dockGroupProperties_sleepTimetable {
  __typename: "SleepTimetable";
  id: string;
  name: string | null;
  startTime: CORE_Time;
  endTime: CORE_Time;
}

export interface dockGroupProperties_dockGroupProperties {
  __typename: "DockGroup";
  id: string;
  coord: dockGroupProperties_dockGroupProperties_coord | null;
  sleepTimetable: dockGroupProperties_dockGroupProperties_sleepTimetable | null;
}

export interface dockGroupProperties {
  dockGroupProperties: dockGroupProperties_dockGroupProperties | null;
}

export interface dockGroupPropertiesVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendDockGroupCommand
// ====================================================

export interface sendDockGroupCommand_sendDockGroupCommand {
  __typename: "DockGroupCommandResponse";
  response: string;
}

export interface sendDockGroupCommand {
  sendDockGroupCommand: sendDockGroupCommand_sendDockGroupCommand | null;
}

export interface sendDockGroupCommandVariables {
  id: string;
  command: DockGroupCommand;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: provisionStationClientByQRMutation
// ====================================================

export interface provisionStationClientByQRMutation {
  provisionStationClientByQR: boolean | null;
}

export interface provisionStationClientByQRMutationVariables {
  dockGroupId: string;
  wireguardPubkey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createStationClientMutation
// ====================================================

export interface createStationClientMutation_createStationClient {
  __typename: "StationClient";
  id: string;
  description: string | null;
  verificationCode: string | null;
  state: StationClientState | null;
}

export interface createStationClientMutation {
  createStationClient: createStationClientMutation_createStationClient | null;
}

export interface createStationClientMutationVariables {
  dockGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteStationClientMutation
// ====================================================

export interface deleteStationClientMutation_deleteStationClient {
  __typename: "StationClient";
  id: string;
}

export interface deleteStationClientMutation {
  deleteStationClient: deleteStationClientMutation_deleteStationClient | null;
}

export interface deleteStationClientMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupTimeline2
// ====================================================

export interface dockGroupTimeline2_eventTypes_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface dockGroupTimeline2_eventTypes {
  __typename: "__Type";
  name: string | null;
  enumValues: dockGroupTimeline2_eventTypes_enumValues[] | null;
}

export interface dockGroupTimeline2_dockGroup_docksConnection_edges_node {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
}

export interface dockGroupTimeline2_dockGroup_docksConnection_edges {
  __typename: "DockEdge";
  node: dockGroupTimeline2_dockGroup_docksConnection_edges_node;
}

export interface dockGroupTimeline2_dockGroup_docksConnection {
  __typename: "DocksConnection";
  edges: dockGroupTimeline2_dockGroup_docksConnection_edges[];
}

export interface dockGroupTimeline2_dockGroup_timeline_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertUpdated {
  __typename: "SystemAlertUpdated";
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockMutationEvent_dock {
  __typename: "Dock";
  number: number | null;
  id: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockMutationEvent_diffs {
  __typename: "MutationDiff";
  field: string | null;
  lhs: CORE_JSON | null;
  rhs: CORE_JSON | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockMutationEvent_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockMutationEvent {
  __typename: "DockMutationEvent";
  id: string;
  dock: dockGroupTimeline2_dockGroup_timeline_edges_node_DockMutationEvent_dock;
  diffs: dockGroupTimeline2_dockGroup_timeline_edges_node_DockMutationEvent_diffs[] | null;
  administrator: dockGroupTimeline2_dockGroup_timeline_edges_node_DockMutationEvent_administrator | null;
  createdAt: CORE_IsoDateTime;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupMutationEvent_dockGroup {
  __typename: "DockGroup";
  name: string | null;
  id: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupMutationEvent_diffs {
  __typename: "MutationDiff";
  lhs: CORE_JSON | null;
  rhs: CORE_JSON | null;
  field: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupMutationEvent_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupMutationEvent {
  __typename: "DockGroupMutationEvent";
  id: string;
  dockGroup: dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupMutationEvent_dockGroup;
  diffs: dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupMutationEvent_diffs[] | null;
  administrator: dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupMutationEvent_administrator | null;
  createdAt: CORE_IsoDateTime;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_displayName = dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_displayName_VehicleDisplayNamed | dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_displayName_VehicleDisplayNumbered | dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_displayName_VehicleDisplayAnonymous;

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_displayName;
  model: dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle_model | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_dock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_user {
  __typename: "User";
  id: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent {
  __typename: "TripEvent";
  id: string;
  eventType: DockGroupTimelineEventType;
  vehicle: dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_vehicle;
  dock: dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_dock;
  trip: dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_trip | null;
  administrator: dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_administrator | null;
  user: dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent_user | null;
  createdAt: CORE_IsoDateTime;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_displayName = dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_displayName_VehicleDisplayNamed | dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_displayName_VehicleDisplayNumbered | dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_displayName_VehicleDisplayAnonymous;

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_displayName;
  model: dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle_model | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_dock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_user {
  __typename: "User";
  id: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent {
  __typename: "VehicleDockingEvent";
  id: string;
  eventType: DockGroupTimelineEventType;
  vehicle: dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_vehicle;
  dock: dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_dock;
  trip: dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_trip | null;
  administrator: dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_administrator | null;
  user: dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent_user | null;
  createdAt: CORE_IsoDateTime;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupCreationEvent_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupCreationEvent {
  __typename: "DockGroupCreationEvent";
  id: string;
  dockGroup: dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupCreationEvent_dockGroup;
  createdAt: CORE_IsoDateTime;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockCreationEvent_dock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_DockCreationEvent {
  __typename: "DockCreationEvent";
  id: string;
  dock: dockGroupTimeline2_dockGroup_timeline_edges_node_DockCreationEvent_dock;
  createdAt: CORE_IsoDateTime;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertEnded_systemAlert_resolvedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertEnded_systemAlert_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertEnded_systemAlert {
  __typename: "SystemAlert";
  id: string;
  incidentEndTime: CORE_IsoDateTime | null;
  systemAlertType: SystemAlertType;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertEnded_systemAlert_resolvedBy | null;
  title: dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertEnded_systemAlert_title[] | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertEnded {
  __typename: "SystemAlertEnded";
  id: string;
  createdAt: CORE_IsoDateTime;
  systemAlert: dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertEnded_systemAlert;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_description {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_updates_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  title: dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_updates_title[] | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert {
  __typename: "SystemAlert";
  id: string;
  createdAt: CORE_IsoDateTime;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  systemAlertType: SystemAlertType;
  title: dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_title[] | null;
  description: dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_description[] | null;
  administrator: dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_administrator;
  dockGroups: (dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_dockGroups | null)[] | null;
  updates: (dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert_updates | null)[] | null;
}

export interface dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted {
  __typename: "SystemAlertStarted";
  id: string;
  createdAt: CORE_IsoDateTime;
  systemAlert: dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted_systemAlert;
}

export type dockGroupTimeline2_dockGroup_timeline_edges_node = dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertUpdated | dockGroupTimeline2_dockGroup_timeline_edges_node_DockMutationEvent | dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupMutationEvent | dockGroupTimeline2_dockGroup_timeline_edges_node_TripEvent | dockGroupTimeline2_dockGroup_timeline_edges_node_VehicleDockingEvent | dockGroupTimeline2_dockGroup_timeline_edges_node_DockGroupCreationEvent | dockGroupTimeline2_dockGroup_timeline_edges_node_DockCreationEvent | dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertEnded | dockGroupTimeline2_dockGroup_timeline_edges_node_SystemAlertStarted;

export interface dockGroupTimeline2_dockGroup_timeline_edges {
  __typename: "DockGroupTimelineConnectionEdge";
  cursor: string;
  node: dockGroupTimeline2_dockGroup_timeline_edges_node;
}

export interface dockGroupTimeline2_dockGroup_timeline {
  __typename: "DockGroupTimelineConnection";
  pageInfo: dockGroupTimeline2_dockGroup_timeline_pageInfo;
  edges: dockGroupTimeline2_dockGroup_timeline_edges[];
}

export interface dockGroupTimeline2_dockGroup {
  __typename: "DockGroup";
  id: string;
  docksConnection: dockGroupTimeline2_dockGroup_docksConnection | null;
  timeline: dockGroupTimeline2_dockGroup_timeline;
}

export interface dockGroupTimeline2 {
  eventTypes: dockGroupTimeline2_eventTypes | null;
  dockGroup: dockGroupTimeline2_dockGroup | null;
}

export interface dockGroupTimeline2Variables {
  systemId: string;
  id: string;
  cursor: Cursor;
  options?: DockGroupTimelineOptions | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: virtualDockAdministrator
// ====================================================

export interface virtualDockAdministrator_administrator {
  __typename: "Administrator";
  id: string;
  checkInDockGroupId: string | null;
  checkInAt: CORE_IsoDateTime | null;
  checkOutAt: CORE_IsoDateTime | null;
}

export interface virtualDockAdministrator {
  administrator: virtualDockAdministrator_administrator;
}

export interface virtualDockAdministratorVariables {
  administratorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: virtualDocksSubscription
// ====================================================

export interface virtualDocksSubscription_dockAvailability {
  __typename: "Dock";
  id: string;
  availabilityState: DockAvailabilityState | null;
}

export interface virtualDocksSubscription {
  dockAvailability: virtualDocksSubscription_dockAvailability | null;
}

export interface virtualDocksSubscriptionVariables {
  dockGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupVirtualDocks
// ====================================================

export interface dockGroupVirtualDocks_dockGroup_docksConnection_edges_node {
  __typename: "Dock";
  id: string;
  availabilityState: DockAvailabilityState | null;
}

export interface dockGroupVirtualDocks_dockGroup_docksConnection_edges {
  __typename: "DockEdge";
  node: dockGroupVirtualDocks_dockGroup_docksConnection_edges_node;
}

export interface dockGroupVirtualDocks_dockGroup_docksConnection {
  __typename: "DocksConnection";
  edges: dockGroupVirtualDocks_dockGroup_docksConnection_edges[];
}

export interface dockGroupVirtualDocks_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  docksConnection: dockGroupVirtualDocks_dockGroup_docksConnection | null;
}

export interface dockGroupVirtualDocks {
  dockGroup: dockGroupVirtualDocks_dockGroup | null;
}

export interface dockGroupVirtualDocksVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDockGroupExportMutation
// ====================================================

export interface createDockGroupExportMutation_createDockGroupDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_entityTags_tag;
}

export interface createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
}

export interface createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_controller {
  __typename: "Controller";
  state: ControllerState | null;
}

export interface createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup {
  __typename: "DockGroupListItem";
  id: string;
  name: string | null;
  title: string | null;
  state: DockGroupState | null;
  entityTags: createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_entityTags[] | null;
  type: DockGroupType | null;
  availabilityInfo: createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_availabilityInfo;
  adSurfaces: number | null;
  coord: createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_coord | null;
  description: string | null;
  updatedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
  operationsManagesCapacity: boolean | null;
  controller: createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_controller | null;
}

export interface createDockGroupExportMutation_createDockGroupDataExportRequest {
  __typename: "CreateDockGroupDataExportRequestResponse";
  dataExportRequest: createDockGroupExportMutation_createDockGroupDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual dockGroup.
   */
  dockGroup: createDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup | null;
}

export interface createDockGroupExportMutation {
  createDockGroupDataExportRequest: createDockGroupExportMutation_createDockGroupDataExportRequest;
}

export interface createDockGroupExportMutationVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFilteredDockGroupExportMutation
// ====================================================

export interface createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_entityTags_tag;
}

export interface createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
}

export interface createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_controller {
  __typename: "Controller";
  state: ControllerState | null;
}

export interface createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup {
  __typename: "DockGroupListItem";
  id: string;
  name: string | null;
  title: string | null;
  state: DockGroupState | null;
  entityTags: createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_entityTags[] | null;
  type: DockGroupType | null;
  availabilityInfo: createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_availabilityInfo;
  adSurfaces: number | null;
  coord: createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_coord | null;
  description: string | null;
  updatedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
  operationsManagesCapacity: boolean | null;
  controller: createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup_controller | null;
}

export interface createFilteredDockGroupExportMutation_createDockGroupDataExportRequest {
  __typename: "CreateDockGroupDataExportRequestResponse";
  dataExportRequest: createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual dockGroup.
   */
  dockGroup: createFilteredDockGroupExportMutation_createDockGroupDataExportRequest_dockGroup | null;
}

export interface createFilteredDockGroupExportMutation {
  createDockGroupDataExportRequest: createFilteredDockGroupExportMutation_createDockGroupDataExportRequest;
}

export interface createFilteredDockGroupExportMutationVariables {
  systemId: string;
  options?: FilterAndOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupsConnectionQuery
// ====================================================

export interface dockGroupsConnectionQuery_dockGroupsConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface dockGroupsConnectionQuery_dockGroupsConnection_edges_node_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface dockGroupsConnectionQuery_dockGroupsConnection_edges_node_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: dockGroupsConnectionQuery_dockGroupsConnection_edges_node_entityTags_tag;
}

export interface dockGroupsConnectionQuery_dockGroupsConnection_edges_node_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
}

export interface dockGroupsConnectionQuery_dockGroupsConnection_edges_node_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface dockGroupsConnectionQuery_dockGroupsConnection_edges_node_controller {
  __typename: "Controller";
  state: ControllerState | null;
}

export interface dockGroupsConnectionQuery_dockGroupsConnection_edges_node {
  __typename: "DockGroupListItem";
  id: string;
  name: string | null;
  title: string | null;
  state: DockGroupState | null;
  entityTags: dockGroupsConnectionQuery_dockGroupsConnection_edges_node_entityTags[] | null;
  type: DockGroupType | null;
  availabilityInfo: dockGroupsConnectionQuery_dockGroupsConnection_edges_node_availabilityInfo;
  adSurfaces: number | null;
  coord: dockGroupsConnectionQuery_dockGroupsConnection_edges_node_coord | null;
  description: string | null;
  updatedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
  operationsManagesCapacity: boolean | null;
  controller: dockGroupsConnectionQuery_dockGroupsConnection_edges_node_controller | null;
}

export interface dockGroupsConnectionQuery_dockGroupsConnection_edges {
  __typename: "DockGroupEdge";
  cursor: string;
  node: dockGroupsConnectionQuery_dockGroupsConnection_edges_node;
}

export interface dockGroupsConnectionQuery_dockGroupsConnection {
  __typename: "DockGroupsConnection";
  pageInfo: dockGroupsConnectionQuery_dockGroupsConnection_pageInfo;
  edges: dockGroupsConnectionQuery_dockGroupsConnection_edges[];
}

export interface dockGroupsConnectionQuery {
  dockGroupsConnection: dockGroupsConnectionQuery_dockGroupsConnection | null;
}

export interface dockGroupsConnectionQueryVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
  connectionFilters?: DockGroupsConnectionFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupsMapConnection
// ====================================================

export interface dockGroupsMapConnection_dockGroupsConnection_edges_node_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
  availableDocks: number | null;
  availableVehicles: number | null;
}

export interface dockGroupsMapConnection_dockGroupsConnection_edges_node_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface dockGroupsMapConnection_dockGroupsConnection_edges_node {
  __typename: "DockGroupListItem";
  id: string;
  name: string | null;
  title: string | null;
  state: DockGroupState | null;
  type: DockGroupType | null;
  availabilityInfo: dockGroupsMapConnection_dockGroupsConnection_edges_node_availabilityInfo;
  adSurfaces: number | null;
  coord: dockGroupsMapConnection_dockGroupsConnection_edges_node_coord | null;
  description: string | null;
  updatedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
}

export interface dockGroupsMapConnection_dockGroupsConnection_edges {
  __typename: "DockGroupEdge";
  cursor: string;
  node: dockGroupsMapConnection_dockGroupsConnection_edges_node;
}

export interface dockGroupsMapConnection_dockGroupsConnection {
  __typename: "DockGroupsConnection";
  edges: dockGroupsMapConnection_dockGroupsConnection_edges[];
}

export interface dockGroupsMapConnection {
  dockGroupsConnection: dockGroupsMapConnection_dockGroupsConnection | null;
}

export interface dockGroupsMapConnectionVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
  connectionFilters?: DockGroupsConnectionFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onDockGroupUpdate
// ====================================================

export interface onDockGroupUpdate_dockGroup_localeSubTitle {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface onDockGroupUpdate_dockGroup_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
}

export interface onDockGroupUpdate_dockGroup_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface onDockGroupUpdate_dockGroup_lockFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface onDockGroupUpdate_dockGroup_requestFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface onDockGroupUpdate_dockGroup_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface onDockGroupUpdate_dockGroup_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: onDockGroupUpdate_dockGroup_entityTags_tag;
}

export interface onDockGroupUpdate_dockGroup_stationClient {
  __typename: "StationClient";
  id: string;
  state: StationClientState | null;
  description: string | null;
  verificationCode: string | null;
  kioskId: string | null;
  createdAt: CORE_IsoDateTime | null;
  updatedAt: CORE_IsoDateTime | null;
}

export interface onDockGroupUpdate_dockGroup_controller_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface onDockGroupUpdate_dockGroup_controller {
  __typename: "Controller";
  id: string;
  ip: string | null;
  imei: string | null;
  serialNumber: string | null;
  physicalLockId: string | null;
  firmwareVersion: string | null;
  state: ControllerState | null;
  powerState: PowerState | null;
  model: onDockGroupUpdate_dockGroup_controller_model | null;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface onDockGroupUpdate_dockGroup {
  __typename: "DockGroup";
  name: string | null;
  type: DockGroupType | null;
  address: string | null;
  title: string | null;
  /**
   * Localised sub title, using system default language
   */
  subTitle: string | null;
  /**
   * Sub title from Sanity
   */
  localeSubTitle: onDockGroupUpdate_dockGroup_localeSubTitle[] | null;
  state: DockGroupState | null;
  availabilityInfo: onDockGroupUpdate_dockGroup_availabilityInfo;
  elevation: number | null;
  adSurfaces: number | null;
  description: string | null;
  coord: onDockGroupUpdate_dockGroup_coord | null;
  lockFences: onDockGroupUpdate_dockGroup_lockFences[] | null;
  requestFences: onDockGroupUpdate_dockGroup_requestFences[] | null;
  showInPublicOverride: boolean | null;
  id: string;
  systemId: string;
  sanityId: string | null;
  entityTags: onDockGroupUpdate_dockGroup_entityTags[] | null;
  deletedAt: CORE_IsoDateTime | null;
  stationClient: onDockGroupUpdate_dockGroup_stationClient | null;
  operationsManagesCapacity: boolean | null;
  controller: onDockGroupUpdate_dockGroup_controller | null;
  reportedVirtualDockCount: number | null;
  maxVirtualDockCount: number | null;
}

export interface onDockGroupUpdate {
  dockGroup: onDockGroupUpdate_dockGroup | null;
}

export interface onDockGroupUpdateVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onDockUpdate
// ====================================================

export interface onDockUpdate_dock_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
}

export interface onDockUpdate_dock_coords {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface onDockUpdate_dock {
  __typename: "Dock";
  id: string;
  vehicle: onDockUpdate_dock_vehicle | null;
  deletedAt: CORE_IsoDateTime | null;
  number: number | null;
  type: DockType | null;
  state: DockState | null;
  rfid: string | null;
  coords: onDockUpdate_dock_coords | null;
  brokenAt: CORE_IsoDateTime | null;
}

export interface onDockUpdate {
  dock: onDockUpdate_dock | null;
}

export interface onDockUpdateVariables {
  systemId: string;
  dockGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDockExportMutation
// ====================================================

export interface createDockExportMutation_createDockDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createDockExportMutation_createDockDataExportRequest_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  systemId: string;
  type: DockGroupType | null;
}

export interface createDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface createDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface createDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type createDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName = createDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayNamed | createDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayNumbered | createDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayAnonymous;

export interface createDockExportMutation_createDockDataExportRequest_dock_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
}

export interface createDockExportMutation_createDockDataExportRequest_dock_vehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface createDockExportMutation_createDockDataExportRequest_dock_vehicle_controller {
  __typename: "Controller";
  id: string;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  model: createDockExportMutation_createDockDataExportRequest_dock_vehicle_controller_model | null;
}

export interface createDockExportMutation_createDockDataExportRequest_dock_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface createDockExportMutation_createDockDataExportRequest_dock_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: createDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName;
  state: VehicleState | null;
  availabilityState: VehicleAvailabilityState;
  availability: createDockExportMutation_createDockDataExportRequest_dock_vehicle_availability | null;
  batteryCharge: number | null;
  controller: createDockExportMutation_createDockDataExportRequest_dock_vehicle_controller | null;
  model: createDockExportMutation_createDockDataExportRequest_dock_vehicle_model | null;
}

export interface createDockExportMutation_createDockDataExportRequest_dock {
  __typename: "Dock";
  id: string;
  dockGroup: createDockExportMutation_createDockDataExportRequest_dock_dockGroup | null;
  state: DockState | null;
  availabilityState: DockAvailabilityState | null;
  brokenAt: CORE_IsoDateTime | null;
  brokenReason: string | null;
  type: DockType | null;
  rfid: string | null;
  number: number | null;
  vehicle: createDockExportMutation_createDockDataExportRequest_dock_vehicle | null;
}

export interface createDockExportMutation_createDockDataExportRequest {
  __typename: "CreateDockDataExportRequestResponse";
  dataExportRequest: createDockExportMutation_createDockDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual controller.
   */
  dock: createDockExportMutation_createDockDataExportRequest_dock | null;
}

export interface createDockExportMutation {
  createDockDataExportRequest: createDockExportMutation_createDockDataExportRequest;
}

export interface createDockExportMutationVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFilteredDockExportMutation
// ====================================================

export interface createFilteredDockExportMutation_createDockDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  systemId: string;
  type: DockGroupType | null;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName = createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayNamed | createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayNumbered | createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName_VehicleDisplayAnonymous;

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_controller {
  __typename: "Controller";
  id: string;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  model: createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_controller_model | null;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_displayName;
  state: VehicleState | null;
  availabilityState: VehicleAvailabilityState;
  availability: createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_availability | null;
  batteryCharge: number | null;
  controller: createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_controller | null;
  model: createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle_model | null;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest_dock {
  __typename: "Dock";
  id: string;
  dockGroup: createFilteredDockExportMutation_createDockDataExportRequest_dock_dockGroup | null;
  state: DockState | null;
  availabilityState: DockAvailabilityState | null;
  brokenAt: CORE_IsoDateTime | null;
  brokenReason: string | null;
  type: DockType | null;
  rfid: string | null;
  number: number | null;
  vehicle: createFilteredDockExportMutation_createDockDataExportRequest_dock_vehicle | null;
}

export interface createFilteredDockExportMutation_createDockDataExportRequest {
  __typename: "CreateDockDataExportRequestResponse";
  dataExportRequest: createFilteredDockExportMutation_createDockDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual controller.
   */
  dock: createFilteredDockExportMutation_createDockDataExportRequest_dock | null;
}

export interface createFilteredDockExportMutation {
  createDockDataExportRequest: createFilteredDockExportMutation_createDockDataExportRequest;
}

export interface createFilteredDockExportMutationVariables {
  systemId: string;
  options?: FilterAndOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: docksConnectionMeta
// ====================================================

export interface docksConnectionMeta_dockStates_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface docksConnectionMeta_dockStates {
  __typename: "__Type";
  name: string | null;
  enumValues: docksConnectionMeta_dockStates_enumValues[] | null;
}

export interface docksConnectionMeta_dockTypes_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface docksConnectionMeta_dockTypes {
  __typename: "__Type";
  name: string | null;
  enumValues: docksConnectionMeta_dockTypes_enumValues[] | null;
}

export interface docksConnectionMeta {
  dockStates: docksConnectionMeta_dockStates | null;
  dockTypes: docksConnectionMeta_dockTypes | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: docksConnection
// ====================================================

export interface docksConnection_docksConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface docksConnection_docksConnection_edges_node_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  systemId: string;
  type: DockGroupType | null;
}

export interface docksConnection_docksConnection_edges_node_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface docksConnection_docksConnection_edges_node_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface docksConnection_docksConnection_edges_node_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type docksConnection_docksConnection_edges_node_vehicle_displayName = docksConnection_docksConnection_edges_node_vehicle_displayName_VehicleDisplayNamed | docksConnection_docksConnection_edges_node_vehicle_displayName_VehicleDisplayNumbered | docksConnection_docksConnection_edges_node_vehicle_displayName_VehicleDisplayAnonymous;

export interface docksConnection_docksConnection_edges_node_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
}

export interface docksConnection_docksConnection_edges_node_vehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface docksConnection_docksConnection_edges_node_vehicle_controller {
  __typename: "Controller";
  id: string;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  model: docksConnection_docksConnection_edges_node_vehicle_controller_model | null;
}

export interface docksConnection_docksConnection_edges_node_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface docksConnection_docksConnection_edges_node_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: docksConnection_docksConnection_edges_node_vehicle_displayName;
  state: VehicleState | null;
  availabilityState: VehicleAvailabilityState;
  availability: docksConnection_docksConnection_edges_node_vehicle_availability | null;
  batteryCharge: number | null;
  controller: docksConnection_docksConnection_edges_node_vehicle_controller | null;
  model: docksConnection_docksConnection_edges_node_vehicle_model | null;
}

export interface docksConnection_docksConnection_edges_node {
  __typename: "Dock";
  id: string;
  dockGroup: docksConnection_docksConnection_edges_node_dockGroup | null;
  state: DockState | null;
  availabilityState: DockAvailabilityState | null;
  brokenAt: CORE_IsoDateTime | null;
  brokenReason: string | null;
  type: DockType | null;
  rfid: string | null;
  number: number | null;
  vehicle: docksConnection_docksConnection_edges_node_vehicle | null;
}

export interface docksConnection_docksConnection_edges {
  __typename: "DockEdge";
  cursor: string;
  node: docksConnection_docksConnection_edges_node;
}

export interface docksConnection_docksConnection {
  __typename: "DocksConnection";
  pageInfo: docksConnection_docksConnection_pageInfo;
  edges: docksConnection_docksConnection_edges[];
}

export interface docksConnection {
  docksConnection: docksConnection_docksConnection | null;
}

export interface docksConnectionVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paginatedInvoicesQuery
// ====================================================

export interface paginatedInvoicesQuery_paginatedInvoices_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalRowCount: number;
}

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
}

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_paymentConfirmedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
}

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
  product: paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemValueCode_product;
}

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
}

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
  product: paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemTrip_product;
  trip: paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemTrip_trip;
}

export type paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items = paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemAdjustment | paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemValueCode | paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items_InvoiceItemTrip;

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_company {
  __typename: "BusinessCompany";
  id: string;
  name: string;
}

export interface paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness {
  __typename: "InvoiceBusiness";
  id: string;
  systemId: string;
  description: string;
  collectionMethod: CollectionMethod;
  createdAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  total: CORE_Money;
  currency: string;
  vatRate: number;
  totalVat: CORE_Money;
  periodEnd: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  awaitingManualPayment: boolean;
  status: InvoiceStatus;
  paymentConfirmedBy: paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_paymentConfirmedBy | null;
  items: paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_items[] | null;
  company: paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness_company;
}

export type paginatedInvoicesQuery_paginatedInvoices_rows = paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip | paginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness;

export interface paginatedInvoicesQuery_paginatedInvoices {
  __typename: "PaginatedInvoices";
  paginationInfo: paginatedInvoicesQuery_paginatedInvoices_paginationInfo;
  rows: paginatedInvoicesQuery_paginatedInvoices_rows[];
}

export interface paginatedInvoicesQuery {
  paginatedInvoices: paginatedInvoicesQuery_paginatedInvoices;
}

export interface paginatedInvoicesQueryVariables {
  systemId: string;
  pagination?: OffsetPagination | null;
  invoiceFilters?: InvoiceFilters | null;
  order?: InvoiceOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: confirmInvoicePaymentMutation
// ====================================================

export interface confirmInvoicePaymentMutation_confirmInvoicePayment_paymentConfirmedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
}

export interface confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
  product: confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemValueCode_product;
}

export interface confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
}

export interface confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
  product: confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemTrip_product;
  trip: confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemTrip_trip;
}

export type confirmInvoicePaymentMutation_confirmInvoicePayment_items = confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemAdjustment | confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemValueCode | confirmInvoicePaymentMutation_confirmInvoicePayment_items_InvoiceItemTrip;

export interface confirmInvoicePaymentMutation_confirmInvoicePayment_company {
  __typename: "BusinessCompany";
  id: string;
  name: string;
}

export interface confirmInvoicePaymentMutation_confirmInvoicePayment {
  __typename: "InvoiceBusiness";
  id: string;
  systemId: string;
  description: string;
  collectionMethod: CollectionMethod;
  createdAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  total: CORE_Money;
  currency: string;
  vatRate: number;
  totalVat: CORE_Money;
  periodEnd: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  awaitingManualPayment: boolean;
  status: InvoiceStatus;
  paymentConfirmedBy: confirmInvoicePaymentMutation_confirmInvoicePayment_paymentConfirmedBy | null;
  items: confirmInvoicePaymentMutation_confirmInvoicePayment_items[] | null;
  company: confirmInvoicePaymentMutation_confirmInvoicePayment_company;
}

export interface confirmInvoicePaymentMutation {
  confirmInvoicePayment: confirmInvoicePaymentMutation_confirmInvoicePayment;
}

export interface confirmInvoicePaymentMutationVariables {
  systemId: string;
  invoiceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createInvoiceExportMutation
// ====================================================

export interface createInvoiceExportMutation_createInvoiceDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
}

export interface createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip" | "InvoiceItemAdjustment";
}

export interface createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_items_InvoiceItemValueCode_product {
  __typename: "Product";
  orderLine_productNo: string;
  orderLine_productName: string | null;
}

export interface createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amountWithoutVat: CORE_Money;
  product: createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_items_InvoiceItemValueCode_product;
  quantity: CORE_PositiveInt;
  totalAmountWithoutVat: CORE_Money;
}

export type createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_items = createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_items_InvoiceItemTrip | createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_items_InvoiceItemValueCode;

export interface createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_company {
  __typename: "BusinessCompany";
  companyNo: string;
  companyName: string;
  customerNo: CORE_PositiveInt | null;
}

export interface createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness {
  __typename: "InvoiceBusiness";
  orderNo: string;
  system: string;
  orderLine_description: string;
  collectionMethod: CollectionMethod;
  totalWithoutVat: CORE_Money;
  currency: string;
  vatRate: number;
  periodEnd: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  status: InvoiceStatus;
  items: createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_items[] | null;
  company: createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness_company;
}

export type createInvoiceExportMutation_createInvoiceDataExportRequest_invoice = createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip | createInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness;

export interface createInvoiceExportMutation_createInvoiceDataExportRequest {
  __typename: "CreateInvoiceDataExportRequestResponse";
  dataExportRequest: createInvoiceExportMutation_createInvoiceDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual invoice.
   */
  invoice: createInvoiceExportMutation_createInvoiceDataExportRequest_invoice | null;
}

export interface createInvoiceExportMutation {
  createInvoiceDataExportRequest: createInvoiceExportMutation_createInvoiceDataExportRequest;
}

export interface createInvoiceExportMutationVariables {
  systemId: string;
  order?: InvoiceOrder | null;
  filters?: InvoiceFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createUserInvoiceExportMutation
// ====================================================

export interface createUserInvoiceExportMutation_createInvoiceDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness {
  __typename: "InvoiceBusiness";
}

export interface createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode" | "InvoiceItemAdjustment";
}

export interface createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  tripIds: string;
}

export interface createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  trip: createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip;
}

export type createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip_items = createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip_items_InvoiceItemValueCode | createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip_items_InvoiceItemTrip;

export interface createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
  invoiceNumber: string;
  invoiceStatus: InvoiceStatus;
  userId: string;
  invoiceCurrency: string;
  invoiceTotal: CORE_Money;
  invoiceTotalVat: CORE_Money;
  invoiceTotalWithoutVat: CORE_Money;
  vatRate: number;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  invoiceCreatedAt: CORE_IsoDateTime;
  invoicePaidAt: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  items: createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip_items[] | null;
}

export type createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice = createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceBusiness | createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice_InvoiceUserTrip;

export interface createUserInvoiceExportMutation_createInvoiceDataExportRequest {
  __typename: "CreateInvoiceDataExportRequestResponse";
  dataExportRequest: createUserInvoiceExportMutation_createInvoiceDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual invoice.
   */
  invoice: createUserInvoiceExportMutation_createInvoiceDataExportRequest_invoice | null;
}

export interface createUserInvoiceExportMutation {
  createInvoiceDataExportRequest: createUserInvoiceExportMutation_createInvoiceDataExportRequest;
}

export interface createUserInvoiceExportMutationVariables {
  systemId: string;
  order?: InvoiceOrder | null;
  filters?: InvoiceFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onAdminInteraction
// ====================================================

export interface onAdminInteraction_adminInteraction {
  __typename: "Interaction";
  rfid: string | null;
  controllerId: string | null;
  vehicleId: string | null;
  administratorId: string | null;
}

export interface onAdminInteraction {
  adminInteraction: onAdminInteraction_adminInteraction | null;
}

export interface onAdminInteractionVariables {
  administratorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminInteractionController
// ====================================================

export interface adminInteractionController_controller_pairedEntity_DockGroup {
  __typename: "DockGroup";
}

export interface adminInteractionController_controller_pairedEntity_Vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface adminInteractionController_controller_pairedEntity_Vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface adminInteractionController_controller_pairedEntity_Vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type adminInteractionController_controller_pairedEntity_Vehicle_displayName = adminInteractionController_controller_pairedEntity_Vehicle_displayName_VehicleDisplayNamed | adminInteractionController_controller_pairedEntity_Vehicle_displayName_VehicleDisplayNumbered | adminInteractionController_controller_pairedEntity_Vehicle_displayName_VehicleDisplayAnonymous;

export interface adminInteractionController_controller_pairedEntity_Vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface adminInteractionController_controller_pairedEntity_Vehicle_latestMaintenance {
  __typename: "AssetMaintenance";
  id: string;
  completedAt: CORE_IsoDateTime | null;
}

export interface adminInteractionController_controller_pairedEntity_Vehicle {
  __typename: "Vehicle";
  id: string;
  state: VehicleState | null;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: adminInteractionController_controller_pairedEntity_Vehicle_displayName;
  model: adminInteractionController_controller_pairedEntity_Vehicle_model | null;
  latestMaintenance: adminInteractionController_controller_pairedEntity_Vehicle_latestMaintenance | null;
}

export type adminInteractionController_controller_pairedEntity = adminInteractionController_controller_pairedEntity_DockGroup | adminInteractionController_controller_pairedEntity_Vehicle;

export interface adminInteractionController_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface adminInteractionController_controller {
  __typename: "Controller";
  id: string;
  batteryVoltage: number | null;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  physicalLockId: string | null;
  pairedEntity: adminInteractionController_controller_pairedEntity | null;
  model: adminInteractionController_controller_model | null;
}

export interface adminInteractionController {
  controller: adminInteractionController_controller | null;
}

export interface adminInteractionControllerVariables {
  controllerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: createMaintenanceVehicle
// ====================================================

export interface createMaintenanceVehicle_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
}

export interface createMaintenanceVehicle_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface createMaintenanceVehicle_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface createMaintenanceVehicle_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type createMaintenanceVehicle_vehicle_displayName = createMaintenanceVehicle_vehicle_displayName_VehicleDisplayNamed | createMaintenanceVehicle_vehicle_displayName_VehicleDisplayNumbered | createMaintenanceVehicle_vehicle_displayName_VehicleDisplayAnonymous;

export interface createMaintenanceVehicle_vehicle_model {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface createMaintenanceVehicle_vehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
  hardwareBridge: HardwareBridge | null;
}

export interface createMaintenanceVehicle_vehicle_controller {
  __typename: "Controller";
  id: string;
  state: ControllerState | null;
  powerState: PowerState | null;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  latestResponse: CORE_IsoDateTime | null;
  model: createMaintenanceVehicle_vehicle_controller_model | null;
}

export interface createMaintenanceVehicle_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  brokenAt: CORE_IsoDateTime | null;
  availabilityState: VehicleAvailabilityState;
  availability: createMaintenanceVehicle_vehicle_availability | null;
  unavailabilityReason: UnavailabilityReasons[] | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: createMaintenanceVehicle_vehicle_displayName;
  model: createMaintenanceVehicle_vehicle_model | null;
  controller: createMaintenanceVehicle_vehicle_controller | null;
}

export interface createMaintenanceVehicle {
  vehicle: createMaintenanceVehicle_vehicle | null;
}

export interface createMaintenanceVehicleVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetDamageTypesReport
// ====================================================

export interface assetDamageTypesReport_assetDamageTypesConnection_edges_node_localeName {
  __typename: "LocaleString";
  text: string;
  language: LanguageCode;
}

export interface assetDamageTypesReport_assetDamageTypesConnection_edges_node {
  __typename: "AssetDamageType";
  id: string;
  name: string | null;
  localeName: (assetDamageTypesReport_assetDamageTypesConnection_edges_node_localeName | null)[] | null;
  channel: (string | null)[] | null;
  critical: boolean | null;
  tags: (AssetDamageTypeTag | null)[] | null;
}

export interface assetDamageTypesReport_assetDamageTypesConnection_edges {
  __typename: "AssetDamageTypeEdge";
  node: assetDamageTypesReport_assetDamageTypesConnection_edges_node;
}

export interface assetDamageTypesReport_assetDamageTypesConnection {
  __typename: "AssetDamageTypesConnection";
  edges: assetDamageTypesReport_assetDamageTypesConnection_edges[];
}

export interface assetDamageTypesReport {
  assetDamageTypesConnection: assetDamageTypesReport_assetDamageTypesConnection | null;
}

export interface assetDamageTypesReportVariables {
  systemId: string;
  first?: number | null;
  modelId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: maintenanceDamagePage
// ====================================================

export interface maintenanceDamagePage_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface maintenanceDamagePage_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface maintenanceDamagePage_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type maintenanceDamagePage_vehicle_displayName = maintenanceDamagePage_vehicle_displayName_VehicleDisplayNamed | maintenanceDamagePage_vehicle_displayName_VehicleDisplayNumbered | maintenanceDamagePage_vehicle_displayName_VehicleDisplayAnonymous;

export interface maintenanceDamagePage_vehicle_model {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
}

export interface maintenanceDamagePage_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: maintenanceDamagePage_vehicle_displayName;
  model: maintenanceDamagePage_vehicle_model | null;
}

export interface maintenanceDamagePage {
  vehicle: maintenanceDamagePage_vehicle | null;
}

export interface maintenanceDamagePageVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: maintenanceFilters
// ====================================================

export interface maintenanceFilters_vehicleMaintenanceAssetModels {
  __typename: "AssetMaintenanceAssetModel";
  id: string;
  name: string | null;
}

export interface maintenanceFilters_assetDamageTypesConnection_edges_node_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
}

export interface maintenanceFilters_assetDamageTypesConnection_edges_node {
  __typename: "AssetDamageType";
  id: string;
  name: string | null;
  model: maintenanceFilters_assetDamageTypesConnection_edges_node_model | null;
}

export interface maintenanceFilters_assetDamageTypesConnection_edges {
  __typename: "AssetDamageTypeEdge";
  cursor: string;
  node: maintenanceFilters_assetDamageTypesConnection_edges_node;
}

export interface maintenanceFilters_assetDamageTypesConnection {
  __typename: "AssetDamageTypesConnection";
  edges: maintenanceFilters_assetDamageTypesConnection_edges[];
}

export interface maintenanceFilters {
  vehicleMaintenanceAssetModels: maintenanceFilters_vehicleMaintenanceAssetModels[];
  assetDamageTypesConnection: maintenanceFilters_assetDamageTypesConnection | null;
}

export interface maintenanceFiltersVariables {
  systemId: string;
  first?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: maintenancesList
// ====================================================

export interface maintenancesList_assetMaintenances_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface maintenancesList_assetMaintenances_edges_node_asset_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface maintenancesList_assetMaintenances_edges_node_asset_controller {
  __typename: "Controller";
  id: string;
  batteryStatus: BatteryStatus | null;
  batteryVoltage: number | null;
  batteryCharge: number | null;
  model: maintenancesList_assetMaintenances_edges_node_asset_controller_model | null;
}

export interface maintenancesList_assetMaintenances_edges_node_asset_availability_dock_dockGroup_availabilityInfo {
  __typename: "DockGroupAvailability";
  availableVehicles: number | null;
  availableDocks: number | null;
}

export interface maintenancesList_assetMaintenances_edges_node_asset_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
  availabilityInfo: maintenancesList_assetMaintenances_edges_node_asset_availability_dock_dockGroup_availabilityInfo;
}

export interface maintenancesList_assetMaintenances_edges_node_asset_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  dockGroup: maintenancesList_assetMaintenances_edges_node_asset_availability_dock_dockGroup | null;
}

export interface maintenancesList_assetMaintenances_edges_node_asset_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  dock: maintenancesList_assetMaintenances_edges_node_asset_availability_dock | null;
}

export interface maintenancesList_assetMaintenances_edges_node_asset {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  brokenAt: CORE_IsoDateTime | null;
  availabilityState: VehicleAvailabilityState;
  number: string | null;
  controller: maintenancesList_assetMaintenances_edges_node_asset_controller | null;
  availability: maintenancesList_assetMaintenances_edges_node_asset_availability | null;
  unavailabilityReason: UnavailabilityReasons[] | null;
}

export interface maintenancesList_assetMaintenances_edges_node_damages_damageType {
  __typename: "AssetDamageType";
  id: string;
  name: string | null;
  critical: boolean | null;
}

export interface maintenancesList_assetMaintenances_edges_node_damages_reportedBy_Administrator {
  __typename: "Administrator";
  id: string;
  firstName: string | null;
  profilePictureUrl: string | null;
}

export interface maintenancesList_assetMaintenances_edges_node_damages_reportedBy_User {
  __typename: "User";
  id: string;
}

export type maintenancesList_assetMaintenances_edges_node_damages_reportedBy = maintenancesList_assetMaintenances_edges_node_damages_reportedBy_Administrator | maintenancesList_assetMaintenances_edges_node_damages_reportedBy_User;

export interface maintenancesList_assetMaintenances_edges_node_damages {
  __typename: "AssetMaintenanceDamage";
  damageType: maintenancesList_assetMaintenances_edges_node_damages_damageType | null;
  enforceCritical: boolean;
  createdAt: CORE_IsoDateTime | null;
  reportedBy: maintenancesList_assetMaintenances_edges_node_damages_reportedBy | null;
}

export interface maintenancesList_assetMaintenances_edges_node {
  __typename: "AssetMaintenance";
  id: string;
  asset: maintenancesList_assetMaintenances_edges_node_asset;
  assetId: string;
  assetType: AssetType;
  damages: maintenancesList_assetMaintenances_edges_node_damages[] | null;
  updatedAt: CORE_IsoDateTime | null;
  completedAt: CORE_IsoDateTime | null;
}

export interface maintenancesList_assetMaintenances_edges {
  __typename: "AssetMaintenanceEdge";
  cursor: string;
  node: maintenancesList_assetMaintenances_edges_node;
}

export interface maintenancesList_assetMaintenances {
  __typename: "AssetMaintenanceConnection";
  pageInfo: maintenancesList_assetMaintenances_pageInfo;
  edges: maintenancesList_assetMaintenances_edges[];
}

export interface maintenancesList {
  assetMaintenances: maintenancesList_assetMaintenances | null;
}

export interface maintenancesListVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
  maintenanceFilters?: AssetMaintenancesFilters | null;
  includeAvailabilityInfo: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: modelSettingsPage
// ====================================================

export interface modelSettingsPage_assetModelsConnection_edges_node {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
  name: string | null;
  description: string | null;
  hardwareBridge: HardwareBridge | null;
  thirdPartyController: ThirdPartyController | null;
  vehicleCategory: VehicleCategory | null;
}

export interface modelSettingsPage_assetModelsConnection_edges {
  __typename: "AssetModelEdge";
  node: modelSettingsPage_assetModelsConnection_edges_node;
}

export interface modelSettingsPage_assetModelsConnection {
  __typename: "AssetModelsConnection";
  edges: modelSettingsPage_assetModelsConnection_edges[];
}

export interface modelSettingsPage_assetTypes_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface modelSettingsPage_assetTypes {
  __typename: "__Type";
  name: string | null;
  enumValues: modelSettingsPage_assetTypes_enumValues[] | null;
}

export interface modelSettingsPage_hardwareBridges_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface modelSettingsPage_hardwareBridges {
  __typename: "__Type";
  name: string | null;
  enumValues: modelSettingsPage_hardwareBridges_enumValues[] | null;
}

export interface modelSettingsPage_thirdPartyController_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface modelSettingsPage_thirdPartyController {
  __typename: "__Type";
  name: string | null;
  enumValues: modelSettingsPage_thirdPartyController_enumValues[] | null;
}

export interface modelSettingsPage_vehicleCategories_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface modelSettingsPage_vehicleCategories {
  __typename: "__Type";
  name: string | null;
  enumValues: modelSettingsPage_vehicleCategories_enumValues[] | null;
}

export interface modelSettingsPage {
  assetModelsConnection: modelSettingsPage_assetModelsConnection | null;
  assetTypes: modelSettingsPage_assetTypes | null;
  hardwareBridges: modelSettingsPage_hardwareBridges | null;
  thirdPartyController: modelSettingsPage_thirdPartyController | null;
  vehicleCategories: modelSettingsPage_vehicleCategories | null;
}

export interface modelSettingsPageVariables {
  systemId: string;
  cursor?: Cursor | null;
  options?: FilterAndOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetPartsConnection
// ====================================================

export interface assetPartsConnection_assetPartsConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface assetPartsConnection_assetPartsConnection_edges_node_model {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
  name: string | null;
  description: string | null;
}

export interface assetPartsConnection_assetPartsConnection_edges_node {
  __typename: "AssetPart";
  id: string;
  model: assetPartsConnection_assetPartsConnection_edges_node_model | null;
  name: string | null;
  order: number | null;
  updatedAt: CORE_IsoDateTime | null;
}

export interface assetPartsConnection_assetPartsConnection_edges {
  __typename: "AssetPartEdge";
  cursor: string;
  node: assetPartsConnection_assetPartsConnection_edges_node;
}

export interface assetPartsConnection_assetPartsConnection {
  __typename: "AssetPartsConnection";
  pageInfo: assetPartsConnection_assetPartsConnection_pageInfo;
  edges: assetPartsConnection_assetPartsConnection_edges[];
}

export interface assetPartsConnection {
  assetPartsConnection: assetPartsConnection_assetPartsConnection | null;
}

export interface assetPartsConnectionVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetRepairTypes
// ====================================================

export interface assetRepairTypes_assetPartsConnection_edges_node {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface assetRepairTypes_assetPartsConnection_edges {
  __typename: "AssetPartEdge";
  cursor: string;
  node: assetRepairTypes_assetPartsConnection_edges_node;
}

export interface assetRepairTypes_assetPartsConnection {
  __typename: "AssetPartsConnection";
  edges: assetRepairTypes_assetPartsConnection_edges[];
}

export interface assetRepairTypes_assetRepairTypesPaginated_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  totalRowCount: number;
}

export interface assetRepairTypes_assetRepairTypesPaginated_rows_partId {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface assetRepairTypes_assetRepairTypesPaginated_rows_modelId {
  __typename: "AssetModel";
  id: string;
  name: string | null;
}

export interface assetRepairTypes_assetRepairTypesPaginated_rows_defaultReplacedParts {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface assetRepairTypes_assetRepairTypesPaginated_rows {
  __typename: "AssetRepairType";
  id: string;
  partId: assetRepairTypes_assetRepairTypesPaginated_rows_partId | null;
  modelId: assetRepairTypes_assetRepairTypesPaginated_rows_modelId | null;
  name: string | null;
  code: string | null;
  defaultReplacedParts: (assetRepairTypes_assetRepairTypesPaginated_rows_defaultReplacedParts | null)[] | null;
}

export interface assetRepairTypes_assetRepairTypesPaginated {
  __typename: "AssetRepairTypesPaginated";
  paginationInfo: assetRepairTypes_assetRepairTypesPaginated_paginationInfo;
  rows: assetRepairTypes_assetRepairTypesPaginated_rows[];
}

export interface assetRepairTypes {
  assetPartsConnection: assetRepairTypes_assetPartsConnection | null;
  assetRepairTypesPaginated: assetRepairTypes_assetRepairTypesPaginated | null;
}

export interface assetRepairTypesVariables {
  systemId: string;
  modelId: string;
  offset?: number | null;
  limit?: number | null;
  filters?: (Filter | null)[] | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetDamageTypesConnection
// ====================================================

export interface assetDamageTypesConnection_assetRepairTypesPaginated_rows {
  __typename: "AssetRepairType";
  id: string;
  name: string | null;
}

export interface assetDamageTypesConnection_assetRepairTypesPaginated {
  __typename: "AssetRepairTypesPaginated";
  rows: assetDamageTypesConnection_assetRepairTypesPaginated_rows[];
}

export interface assetDamageTypesConnection_assetDamageTypesConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface assetDamageTypesConnection_assetDamageTypesConnection_edges_node_modelId {
  __typename: "AssetModel";
  id: string;
  name: string | null;
}

export interface assetDamageTypesConnection_assetDamageTypesConnection_edges_node_repairTypeId {
  __typename: "AssetRepairType";
  id: string;
  name: string | null;
}

export interface assetDamageTypesConnection_assetDamageTypesConnection_edges_node_localeName {
  __typename: "LocaleString";
  text: string;
  language: LanguageCode;
}

export interface assetDamageTypesConnection_assetDamageTypesConnection_edges_node {
  __typename: "AssetDamageType";
  id: string;
  modelId: assetDamageTypesConnection_assetDamageTypesConnection_edges_node_modelId | null;
  repairTypeId: assetDamageTypesConnection_assetDamageTypesConnection_edges_node_repairTypeId | null;
  name: string | null;
  localeName: (assetDamageTypesConnection_assetDamageTypesConnection_edges_node_localeName | null)[] | null;
  channel: (string | null)[] | null;
  critical: boolean | null;
  tags: (AssetDamageTypeTag | null)[] | null;
}

export interface assetDamageTypesConnection_assetDamageTypesConnection_edges {
  __typename: "AssetDamageTypeEdge";
  cursor: string;
  node: assetDamageTypesConnection_assetDamageTypesConnection_edges_node;
}

export interface assetDamageTypesConnection_assetDamageTypesConnection {
  __typename: "AssetDamageTypesConnection";
  pageInfo: assetDamageTypesConnection_assetDamageTypesConnection_pageInfo;
  edges: assetDamageTypesConnection_assetDamageTypesConnection_edges[];
}

export interface assetDamageTypesConnection {
  assetRepairTypesPaginated: assetDamageTypesConnection_assetRepairTypesPaginated | null;
  assetDamageTypesConnection: assetDamageTypesConnection_assetDamageTypesConnection | null;
}

export interface assetDamageTypesConnectionVariables {
  systemId: string;
  modelId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  filters?: (Filter | null)[] | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetModel
// ====================================================

export interface assetModel_assetModel {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
  name: string | null;
  description: string | null;
  hardwareBridge: HardwareBridge | null;
  maxRangeM: number | null;
  qrCodeFormat: string | null;
  thirdPartyController: ThirdPartyController | null;
  vehicleCategory: VehicleCategory | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
  unresponsiveIntervalMins: number | null;
  communicationTimeoutSec: number | null;
  freeMinutesPerDayMultiplier: number | null;
}

export interface assetModel_hardwareBridges_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface assetModel_hardwareBridges {
  __typename: "__Type";
  name: string | null;
  enumValues: assetModel_hardwareBridges_enumValues[] | null;
}

export interface assetModel_thirdPartyController_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface assetModel_thirdPartyController {
  __typename: "__Type";
  name: string | null;
  enumValues: assetModel_thirdPartyController_enumValues[] | null;
}

export interface assetModel_vehicleCategories_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface assetModel_vehicleCategories {
  __typename: "__Type";
  name: string | null;
  enumValues: assetModel_vehicleCategories_enumValues[] | null;
}

export interface assetModel_assetTypes_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface assetModel_assetTypes {
  __typename: "__Type";
  name: string | null;
  enumValues: assetModel_assetTypes_enumValues[] | null;
}

export interface assetModel {
  assetModel: assetModel_assetModel | null;
  hardwareBridges: assetModel_hardwareBridges | null;
  thirdPartyController: assetModel_thirdPartyController | null;
  vehicleCategories: assetModel_vehicleCategories | null;
  assetTypes: assetModel_assetTypes | null;
}

export interface assetModelVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetModelCreate
// ====================================================

export interface assetModelCreate_assetTypes_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface assetModelCreate_assetTypes {
  __typename: "__Type";
  name: string | null;
  enumValues: assetModelCreate_assetTypes_enumValues[] | null;
}

export interface assetModelCreate_hardwareBridges_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface assetModelCreate_hardwareBridges {
  __typename: "__Type";
  name: string | null;
  enumValues: assetModelCreate_hardwareBridges_enumValues[] | null;
}

export interface assetModelCreate_thirdPartyController_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface assetModelCreate_thirdPartyController {
  __typename: "__Type";
  name: string | null;
  enumValues: assetModelCreate_thirdPartyController_enumValues[] | null;
}

export interface assetModelCreate_vehicleCategories_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface assetModelCreate_vehicleCategories {
  __typename: "__Type";
  name: string | null;
  enumValues: assetModelCreate_vehicleCategories_enumValues[] | null;
}

export interface assetModelCreate {
  assetTypes: assetModelCreate_assetTypes | null;
  hardwareBridges: assetModelCreate_hardwareBridges | null;
  thirdPartyController: assetModelCreate_thirdPartyController | null;
  vehicleCategories: assetModelCreate_vehicleCategories | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAssetModel
// ====================================================

export interface deleteAssetModel_deleteAssetModel {
  __typename: "AssetModel";
  id: string;
  name: string | null;
  type: AssetType;
}

export interface deleteAssetModel {
  deleteAssetModel: deleteAssetModel_deleteAssetModel | null;
}

export interface deleteAssetModelVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetSuggestedRepairs
// ====================================================

export interface assetSuggestedRepairs_suggestedRepairs_part {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface assetSuggestedRepairs_suggestedRepairs_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
}

export interface assetSuggestedRepairs_suggestedRepairs_defaultReplacedParts {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface assetSuggestedRepairs_suggestedRepairs {
  __typename: "AssetRepairType";
  id: string;
  part: assetSuggestedRepairs_suggestedRepairs_part | null;
  model: assetSuggestedRepairs_suggestedRepairs_model | null;
  code: string | null;
  name: string | null;
  defaultReplacedParts: (assetSuggestedRepairs_suggestedRepairs_defaultReplacedParts | null)[] | null;
}

export interface assetSuggestedRepairs {
  suggestedRepairs: assetSuggestedRepairs_suggestedRepairs[] | null;
}

export interface assetSuggestedRepairsVariables {
  reportId: string;
  modelId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: maintenancesAssetRepairTypes
// ====================================================

export interface maintenancesAssetRepairTypes_assetRepairTypesPaginated_rows_part {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface maintenancesAssetRepairTypes_assetRepairTypesPaginated_rows_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
}

export interface maintenancesAssetRepairTypes_assetRepairTypesPaginated_rows_defaultReplacedParts {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface maintenancesAssetRepairTypes_assetRepairTypesPaginated_rows {
  __typename: "AssetRepairType";
  id: string;
  part: maintenancesAssetRepairTypes_assetRepairTypesPaginated_rows_part | null;
  model: maintenancesAssetRepairTypes_assetRepairTypesPaginated_rows_model | null;
  code: string | null;
  name: string | null;
  defaultReplacedParts: (maintenancesAssetRepairTypes_assetRepairTypesPaginated_rows_defaultReplacedParts | null)[] | null;
}

export interface maintenancesAssetRepairTypes_assetRepairTypesPaginated {
  __typename: "AssetRepairTypesPaginated";
  rows: maintenancesAssetRepairTypes_assetRepairTypesPaginated_rows[];
}

export interface maintenancesAssetRepairTypes {
  assetRepairTypesPaginated: maintenancesAssetRepairTypes_assetRepairTypesPaginated | null;
}

export interface maintenancesAssetRepairTypesVariables {
  systemId: string;
  modelIdAsString: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: maintenancesDateList
// ====================================================

export interface maintenancesDateList_assetMaintenances_edges_node {
  __typename: "AssetMaintenance";
  id: string;
  completedAt: CORE_IsoDateTime | null;
}

export interface maintenancesDateList_assetMaintenances_edges {
  __typename: "AssetMaintenanceEdge";
  cursor: string;
  node: maintenancesDateList_assetMaintenances_edges_node;
}

export interface maintenancesDateList_assetMaintenances {
  __typename: "AssetMaintenanceConnection";
  edges: maintenancesDateList_assetMaintenances_edges[];
}

export interface maintenancesDateList {
  assetMaintenances: maintenancesDateList_assetMaintenances | null;
}

export interface maintenancesDateListVariables {
  systemId: string;
  first?: number | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  assetId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetMaintenanceQuery
// ====================================================

export interface assetMaintenanceQuery_assetMaintenance_asset_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
}

export interface assetMaintenanceQuery_assetMaintenance_asset_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface assetMaintenanceQuery_assetMaintenance_asset_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface assetMaintenanceQuery_assetMaintenance_asset_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type assetMaintenanceQuery_assetMaintenance_asset_displayName = assetMaintenanceQuery_assetMaintenance_asset_displayName_VehicleDisplayNamed | assetMaintenanceQuery_assetMaintenance_asset_displayName_VehicleDisplayNumbered | assetMaintenanceQuery_assetMaintenance_asset_displayName_VehicleDisplayAnonymous;

export interface assetMaintenanceQuery_assetMaintenance_asset_model {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
}

export interface assetMaintenanceQuery_assetMaintenance_asset_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
  hardwareBridge: HardwareBridge | null;
}

export interface assetMaintenanceQuery_assetMaintenance_asset_controller {
  __typename: "Controller";
  id: string;
  state: ControllerState | null;
  powerState: PowerState | null;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  latestResponse: CORE_IsoDateTime | null;
  model: assetMaintenanceQuery_assetMaintenance_asset_controller_model | null;
}

export interface assetMaintenanceQuery_assetMaintenance_asset {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  brokenAt: CORE_IsoDateTime | null;
  availabilityState: VehicleAvailabilityState;
  availability: assetMaintenanceQuery_assetMaintenance_asset_availability | null;
  unavailabilityReason: UnavailabilityReasons[] | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: assetMaintenanceQuery_assetMaintenance_asset_displayName;
  model: assetMaintenanceQuery_assetMaintenance_asset_model | null;
  controller: assetMaintenanceQuery_assetMaintenance_asset_controller | null;
}

export interface assetMaintenanceQuery_assetMaintenance_repairedBy {
  __typename: "Administrator";
  id: string;
  firstName: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface assetMaintenanceQuery_assetMaintenance_damages_damageType_localeName {
  __typename: "LocaleString";
  text: string;
}

export interface assetMaintenanceQuery_assetMaintenance_damages_damageType_model {
  __typename: "AssetModel";
  id: string;
}

export interface assetMaintenanceQuery_assetMaintenance_damages_damageType {
  __typename: "AssetDamageType";
  id: string;
  localeName: (assetMaintenanceQuery_assetMaintenance_damages_damageType_localeName | null)[] | null;
  name: string | null;
  critical: boolean | null;
  model: assetMaintenanceQuery_assetMaintenance_damages_damageType_model | null;
}

export interface assetMaintenanceQuery_assetMaintenance_damages_reportedBy_Administrator {
  __typename: "Administrator";
  id: string;
  firstName: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface assetMaintenanceQuery_assetMaintenance_damages_reportedBy_User {
  __typename: "User";
  id: string;
}

export type assetMaintenanceQuery_assetMaintenance_damages_reportedBy = assetMaintenanceQuery_assetMaintenance_damages_reportedBy_Administrator | assetMaintenanceQuery_assetMaintenance_damages_reportedBy_User;

export interface assetMaintenanceQuery_assetMaintenance_damages {
  __typename: "AssetMaintenanceDamage";
  enforceCritical: boolean;
  damageType: assetMaintenanceQuery_assetMaintenance_damages_damageType | null;
  reportedBy: assetMaintenanceQuery_assetMaintenance_damages_reportedBy | null;
  comment: string | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface assetMaintenanceQuery_assetMaintenance_repairs_repairType {
  __typename: "AssetRepairType";
  id: string;
  name: string | null;
  code: string | null;
}

export interface assetMaintenanceQuery_assetMaintenance_repairs_replacedParts {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface assetMaintenanceQuery_assetMaintenance_repairs {
  __typename: "AssetMaintenanceRepair";
  id: string;
  repairType: assetMaintenanceQuery_assetMaintenance_repairs_repairType | null;
  replacedParts: assetMaintenanceQuery_assetMaintenance_repairs_replacedParts[] | null;
}

export interface assetMaintenanceQuery_assetMaintenance {
  __typename: "AssetMaintenance";
  id: string;
  asset: assetMaintenanceQuery_assetMaintenance_asset;
  assetId: string;
  assetType: AssetType;
  comment: string | null;
  repairedBy: assetMaintenanceQuery_assetMaintenance_repairedBy | null;
  damages: assetMaintenanceQuery_assetMaintenance_damages[] | null;
  repairs: assetMaintenanceQuery_assetMaintenance_repairs[] | null;
  startedAt: CORE_IsoDateTime | null;
  completedAt: CORE_IsoDateTime | null;
  updatedAt: CORE_IsoDateTime | null;
}

export interface assetMaintenanceQuery {
  assetMaintenance: assetMaintenanceQuery_assetMaintenance | null;
}

export interface assetMaintenanceQueryVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productQuery
// ====================================================

export interface productQuery_product_localeSlug {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface productQuery_product_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface productQuery_product_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: productQuery_product_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface productQuery_product_localeName {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface productQuery_product_localeDescription {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface productQuery_product_descriptionBeforePurchasable {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface productQuery_product_succeededBy {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface productQuery_product_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface productQuery_product {
  __typename: "Product";
  id: string;
  sanityId: string | null;
  localeSlug: productQuery_product_localeSlug[] | null;
  connectionPriceMatrixes: productQuery_product_connectionPriceMatrixes[];
  systemId: string;
  name: string | null;
  localeName: productQuery_product_localeName[] | null;
  localeDescription: productQuery_product_localeDescription[] | null;
  descriptionBeforePurchasable: (productQuery_product_descriptionBeforePurchasable | null)[] | null;
  freeFloatingDockingFeeAmount: CORE_Money;
  maxVehicleCount: number | null;
  price: CORE_Money;
  vatRate: number;
  enabled: boolean;
  redeemable: boolean;
  giftable: boolean;
  purchasable: boolean;
  campaign: ProductCampaign | null;
  purchaseChannels: (ProductPurchaseChannel | null)[] | null;
  purchasableTo: CORE_IsoDateTime | null;
  purchasableFrom: CORE_IsoDateTime | null;
  giftableTo: CORE_IsoDateTime | null;
  giftableFrom: CORE_IsoDateTime | null;
  redeemableTo: CORE_IsoDateTime | null;
  redeemableFrom: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  renewalTimePadding: CORE_IsoDuration | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  hideFromLists: boolean | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  requiresPaymentCardOnFile: boolean | null;
  useSystemDefaultPriceMatrixes: boolean;
  extendedRentalOptMethod: ProductOptMethod | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  succeededBy: productQuery_product_succeededBy | null;
  partners: productQuery_product_partners[];
  freeMinutesPerDay: number | null;
}

export interface productQuery {
  product: productQuery_product | null;
}

export interface productQueryVariables {
  id: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProductMutation
// ====================================================

export interface updateProductMutation_updateProduct_localeSlug {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface updateProductMutation_updateProduct_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface updateProductMutation_updateProduct_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: updateProductMutation_updateProduct_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface updateProductMutation_updateProduct_localeName {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface updateProductMutation_updateProduct_localeDescription {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface updateProductMutation_updateProduct_descriptionBeforePurchasable {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface updateProductMutation_updateProduct_succeededBy {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface updateProductMutation_updateProduct_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface updateProductMutation_updateProduct {
  __typename: "Product";
  id: string;
  sanityId: string | null;
  localeSlug: updateProductMutation_updateProduct_localeSlug[] | null;
  connectionPriceMatrixes: updateProductMutation_updateProduct_connectionPriceMatrixes[];
  systemId: string;
  name: string | null;
  localeName: updateProductMutation_updateProduct_localeName[] | null;
  localeDescription: updateProductMutation_updateProduct_localeDescription[] | null;
  descriptionBeforePurchasable: (updateProductMutation_updateProduct_descriptionBeforePurchasable | null)[] | null;
  freeFloatingDockingFeeAmount: CORE_Money;
  maxVehicleCount: number | null;
  price: CORE_Money;
  vatRate: number;
  enabled: boolean;
  redeemable: boolean;
  giftable: boolean;
  purchasable: boolean;
  campaign: ProductCampaign | null;
  purchaseChannels: (ProductPurchaseChannel | null)[] | null;
  purchasableTo: CORE_IsoDateTime | null;
  purchasableFrom: CORE_IsoDateTime | null;
  giftableTo: CORE_IsoDateTime | null;
  giftableFrom: CORE_IsoDateTime | null;
  redeemableTo: CORE_IsoDateTime | null;
  redeemableFrom: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  renewalTimePadding: CORE_IsoDuration | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  hideFromLists: boolean | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  requiresPaymentCardOnFile: boolean | null;
  useSystemDefaultPriceMatrixes: boolean;
  extendedRentalOptMethod: ProductOptMethod | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  succeededBy: updateProductMutation_updateProduct_succeededBy | null;
  partners: updateProductMutation_updateProduct_partners[];
  freeMinutesPerDay: number | null;
}

export interface updateProductMutation {
  /**
   * Update a product
   */
  updateProduct: updateProductMutation_updateProduct;
}

export interface updateProductMutationVariables {
  id: string;
  input: ProductInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteProductMutation
// ====================================================

export interface deleteProductMutation_deleteProduct {
  __typename: "Product";
  id: string;
}

export interface deleteProductMutation {
  /**
   * Delete a product by id
   */
  deleteProduct: deleteProductMutation_deleteProduct;
}

export interface deleteProductMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupRebalancingFilters
// ====================================================

export interface dockGroupRebalancingFilters_dockGroupRebalancingState_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface dockGroupRebalancingFilters_dockGroupRebalancingState {
  __typename: "__Type";
  name: string | null;
  enumValues: dockGroupRebalancingFilters_dockGroupRebalancingState_enumValues[] | null;
}

export interface dockGroupRebalancingFilters_dockGroupState_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface dockGroupRebalancingFilters_dockGroupState {
  __typename: "__Type";
  name: string | null;
  enumValues: dockGroupRebalancingFilters_dockGroupState_enumValues[] | null;
}

export interface dockGroupRebalancingFilters {
  dockGroupRebalancingState: dockGroupRebalancingFilters_dockGroupRebalancingState | null;
  dockGroupState: dockGroupRebalancingFilters_dockGroupState | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupsRebalancingListQuery
// ====================================================

export interface dockGroupsRebalancingListQuery_dockGroupsRebalancingList_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface dockGroupsRebalancingListQuery_dockGroupsRebalancingList_controller {
  __typename: "DockGroupRebalancingController";
  state: ControllerState | null;
}

export interface dockGroupsRebalancingListQuery_dockGroupsRebalancingList_availableVehicleCategories {
  __typename: "VehicleCategoryAvailability";
  category: VehicleCategory;
  count: number;
}

export interface dockGroupsRebalancingListQuery_dockGroupsRebalancingList {
  __typename: "DockGroupRebalancing";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
  coord: dockGroupsRebalancingListQuery_dockGroupsRebalancingList_coord | null;
  totalDockCount: number | null;
  brokenDocks: number | null;
  availableVehicles: number | null;
  availableDocks: number | null;
  brokenVehicles: number | null;
  damagedVehicles: number | null;
  virtuallyDockedVehicles: number | null;
  state: DockGroupRebalancingState | null;
  operationsManagesCapacity: boolean | null;
  controller: dockGroupsRebalancingListQuery_dockGroupsRebalancingList_controller | null;
  availableVehicleCategories: dockGroupsRebalancingListQuery_dockGroupsRebalancingList_availableVehicleCategories[] | null;
}

export interface dockGroupsRebalancingListQuery {
  dockGroupsRebalancingList: dockGroupsRebalancingListQuery_dockGroupsRebalancingList[] | null;
}

export interface dockGroupsRebalancingListQueryVariables {
  systemId: string;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  dockGroupsRebalancingListFilters?: DockGroupsRebalancingListFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupRebalancing
// ====================================================

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_availability {
  __typename: "VehicleAvailability";
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type dockGroupRebalancing_dockGroup_brokenVehicles_items_displayName = dockGroupRebalancing_dockGroup_brokenVehicles_items_displayName_VehicleDisplayNamed | dockGroupRebalancing_dockGroup_brokenVehicles_items_displayName_VehicleDisplayNumbered | dockGroupRebalancing_dockGroup_brokenVehicles_items_displayName_VehicleDisplayAnonymous;

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_controller {
  __typename: "Controller";
  id: string;
  batteryCharge: number | null;
  batteryStatus: BatteryStatus | null;
  batteryVoltage: number | null;
  model: dockGroupRebalancing_dockGroup_brokenVehicles_items_controller_model | null;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_latestMaintenance_damages_damageType {
  __typename: "AssetDamageType";
  id: string;
  name: string | null;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_latestMaintenance_damages {
  __typename: "AssetMaintenanceDamage";
  damageType: dockGroupRebalancing_dockGroup_brokenVehicles_items_latestMaintenance_damages_damageType | null;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items_latestMaintenance {
  __typename: "AssetMaintenance";
  id: string;
  assetId: string;
  assetType: AssetType;
  damages: dockGroupRebalancing_dockGroup_brokenVehicles_items_latestMaintenance_damages[] | null;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles_items {
  __typename: "Vehicle";
  id: string;
  state: VehicleState | null;
  name: string | null;
  number: string | null;
  brokenAt: CORE_IsoDateTime | null;
  availabilityState: VehicleAvailabilityState;
  availability: dockGroupRebalancing_dockGroup_brokenVehicles_items_availability | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: dockGroupRebalancing_dockGroup_brokenVehicles_items_displayName;
  model: dockGroupRebalancing_dockGroup_brokenVehicles_items_model | null;
  controller: dockGroupRebalancing_dockGroup_brokenVehicles_items_controller | null;
  latestMaintenance: dockGroupRebalancing_dockGroup_brokenVehicles_items_latestMaintenance | null;
}

export interface dockGroupRebalancing_dockGroup_brokenVehicles {
  __typename: "TotalVehiclesNode";
  items: dockGroupRebalancing_dockGroup_brokenVehicles_items[] | null;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_availability {
  __typename: "VehicleAvailability";
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type dockGroupRebalancing_dockGroup_damagedVehicles_items_displayName = dockGroupRebalancing_dockGroup_damagedVehicles_items_displayName_VehicleDisplayNamed | dockGroupRebalancing_dockGroup_damagedVehicles_items_displayName_VehicleDisplayNumbered | dockGroupRebalancing_dockGroup_damagedVehicles_items_displayName_VehicleDisplayAnonymous;

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_controller {
  __typename: "Controller";
  id: string;
  batteryCharge: number | null;
  batteryStatus: BatteryStatus | null;
  batteryVoltage: number | null;
  model: dockGroupRebalancing_dockGroup_damagedVehicles_items_controller_model | null;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_latestMaintenance_damages_damageType {
  __typename: "AssetDamageType";
  id: string;
  name: string | null;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_latestMaintenance_damages {
  __typename: "AssetMaintenanceDamage";
  damageType: dockGroupRebalancing_dockGroup_damagedVehicles_items_latestMaintenance_damages_damageType | null;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items_latestMaintenance {
  __typename: "AssetMaintenance";
  id: string;
  assetId: string;
  assetType: AssetType;
  damages: dockGroupRebalancing_dockGroup_damagedVehicles_items_latestMaintenance_damages[] | null;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles_items {
  __typename: "Vehicle";
  id: string;
  state: VehicleState | null;
  name: string | null;
  number: string | null;
  brokenAt: CORE_IsoDateTime | null;
  availabilityState: VehicleAvailabilityState;
  availability: dockGroupRebalancing_dockGroup_damagedVehicles_items_availability | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: dockGroupRebalancing_dockGroup_damagedVehicles_items_displayName;
  model: dockGroupRebalancing_dockGroup_damagedVehicles_items_model | null;
  controller: dockGroupRebalancing_dockGroup_damagedVehicles_items_controller | null;
  latestMaintenance: dockGroupRebalancing_dockGroup_damagedVehicles_items_latestMaintenance | null;
}

export interface dockGroupRebalancing_dockGroup_damagedVehicles {
  __typename: "TotalVehiclesNode";
  items: dockGroupRebalancing_dockGroup_damagedVehicles_items[] | null;
}

export interface dockGroupRebalancing_dockGroup {
  __typename: "DockGroup";
  id: string;
  brokenVehicles: dockGroupRebalancing_dockGroup_brokenVehicles | null;
  damagedVehicles: dockGroupRebalancing_dockGroup_damagedVehicles | null;
}

export interface dockGroupRebalancing {
  dockGroup: dockGroupRebalancing_dockGroup | null;
}

export interface dockGroupRebalancingVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: misplacedVehiclesQuery
// ====================================================

export interface misplacedVehiclesQuery_dockGroupsConnection_edges_node_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface misplacedVehiclesQuery_dockGroupsConnection_edges_node_lockFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface misplacedVehiclesQuery_dockGroupsConnection_edges_node_availabilityInfo {
  __typename: "DockGroupAvailability";
  availableVehicles: number | null;
}

export interface misplacedVehiclesQuery_dockGroupsConnection_edges_node {
  __typename: "DockGroupListItem";
  id: string;
  name: string | null;
  title: string | null;
  coord: misplacedVehiclesQuery_dockGroupsConnection_edges_node_coord | null;
  lockFences: misplacedVehiclesQuery_dockGroupsConnection_edges_node_lockFences[] | null;
  availabilityInfo: misplacedVehiclesQuery_dockGroupsConnection_edges_node_availabilityInfo;
}

export interface misplacedVehiclesQuery_dockGroupsConnection_edges {
  __typename: "DockGroupEdge";
  node: misplacedVehiclesQuery_dockGroupsConnection_edges_node;
}

export interface misplacedVehiclesQuery_dockGroupsConnection {
  __typename: "DockGroupsConnection";
  edges: misplacedVehiclesQuery_dockGroupsConnection_edges[];
}

export interface misplacedVehiclesQuery_misplacedVehicles_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface misplacedVehiclesQuery_misplacedVehicles_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface misplacedVehiclesQuery_misplacedVehicles_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type misplacedVehiclesQuery_misplacedVehicles_displayName = misplacedVehiclesQuery_misplacedVehicles_displayName_VehicleDisplayNamed | misplacedVehiclesQuery_misplacedVehicles_displayName_VehicleDisplayNumbered | misplacedVehiclesQuery_misplacedVehicles_displayName_VehicleDisplayAnonymous;

export interface misplacedVehiclesQuery_misplacedVehicles_lastGPSPosition {
  __typename: "PointObject";
  type: GeoJSONType;
  coordinates: CORE_Coordinates;
}

export interface misplacedVehiclesQuery_misplacedVehicles {
  __typename: "MisplacedVehicle";
  id: string;
  name: string | null;
  state: VehicleState | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: misplacedVehiclesQuery_misplacedVehicles_displayName;
  generatedAt: CORE_IsoDateTime | null;
  lastGPSPosition: misplacedVehiclesQuery_misplacedVehicles_lastGPSPosition | null;
}

export interface misplacedVehiclesQuery {
  dockGroupsConnection: misplacedVehiclesQuery_dockGroupsConnection | null;
  misplacedVehicles: misplacedVehiclesQuery_misplacedVehicles[] | null;
}

export interface misplacedVehiclesQueryVariables {
  systemId: string;
  controllerState?: ControllerState | null;
  maxDistanceInMeters?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupsByCoordQuery
// ====================================================

export interface dockGroupsByCoordQuery_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
  name: string | null;
}

export interface dockGroupsByCoordQuery {
  dockGroups: dockGroupsByCoordQuery_dockGroups[] | null;
}

export interface dockGroupsByCoordQueryVariables {
  systemId: string;
  lat: number;
  lng: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupUnlockVehiclesQuery
// ====================================================

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_displayName = dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayNamed | dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayNumbered | dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_displayName_VehicleDisplayAnonymous;

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  type: DockType;
  dockGroup: dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability_dock_dockGroup | null;
}

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  systemId: string | null;
  vehicleId: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  subscription: dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability_subscription | null;
  administrator: dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability_administrator | null;
  dock: dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability_dock | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
}

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle {
  __typename: "Vehicle";
  id: string;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_displayName;
  availability: dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle_availability | null;
  unavailabilityReason: UnavailabilityReasons[] | null;
  availabilityState: VehicleAvailabilityState;
  brokenAt: CORE_IsoDateTime | null;
}

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows {
  __typename: "Dock";
  id: string;
  number: number | null;
  brokenAt: CORE_IsoDateTime | null;
  vehicle: dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows_vehicle | null;
}

export interface dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks {
  __typename: "PaginatedDocks";
  rows: dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks_rows[];
}

export interface dockGroupUnlockVehiclesQuery_dockGroup {
  __typename: "DockGroup";
  id: string;
  paginatedDocks: dockGroupUnlockVehiclesQuery_dockGroup_paginatedDocks;
}

export interface dockGroupUnlockVehiclesQuery {
  dockGroup: dockGroupUnlockVehiclesQuery_dockGroup | null;
}

export interface dockGroupUnlockVehiclesQueryVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ownVehiclesMutation
// ====================================================

export interface ownVehiclesMutation_ownVehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface ownVehiclesMutation_ownVehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  administrator: ownVehiclesMutation_ownVehicles_availability_administrator | null;
}

export interface ownVehiclesMutation_ownVehicles {
  __typename: "Vehicle";
  id: string;
  availabilityState: VehicleAvailabilityState;
  availability: ownVehiclesMutation_ownVehicles_availability | null;
}

export interface ownVehiclesMutation {
  ownVehicles: ownVehiclesMutation_ownVehicles[];
}

export interface ownVehiclesMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: scooterDeploy
// ====================================================

export interface scooterDeploy_operationLocations {
  __typename: "OperationLocation";
  id: string;
  type: OperationLocationType;
  name: string;
  vehicleCount: number;
}

export interface scooterDeploy_dockGroupsConnection_edges_node_availabilityInfo {
  __typename: "DockGroupAvailability";
  availableVehicles: number | null;
}

export interface scooterDeploy_dockGroupsConnection_edges_node {
  __typename: "DockGroupListItem";
  id: string;
  name: string | null;
  title: string | null;
  availabilityInfo: scooterDeploy_dockGroupsConnection_edges_node_availabilityInfo;
}

export interface scooterDeploy_dockGroupsConnection_edges {
  __typename: "DockGroupEdge";
  node: scooterDeploy_dockGroupsConnection_edges_node;
}

export interface scooterDeploy_dockGroupsConnection {
  __typename: "DockGroupsConnection";
  edges: scooterDeploy_dockGroupsConnection_edges[];
}

export interface scooterDeploy {
  operationLocations: scooterDeploy_operationLocations[] | null;
  dockGroupsConnection: scooterDeploy_dockGroupsConnection | null;
}

export interface scooterDeployVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: scooterDeployVehicles
// ====================================================

export interface scooterDeployVehicles_vehiclesPaginated_rows_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface scooterDeployVehicles_vehiclesPaginated_rows_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface scooterDeployVehicles_vehiclesPaginated_rows_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type scooterDeployVehicles_vehiclesPaginated_rows_displayName = scooterDeployVehicles_vehiclesPaginated_rows_displayName_VehicleDisplayNamed | scooterDeployVehicles_vehiclesPaginated_rows_displayName_VehicleDisplayNumbered | scooterDeployVehicles_vehiclesPaginated_rows_displayName_VehicleDisplayAnonymous;

export interface scooterDeployVehicles_vehiclesPaginated_rows_controller {
  __typename: "Controller";
  id: string;
  state: ControllerState | null;
}

export interface scooterDeployVehicles_vehiclesPaginated_rows_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface scooterDeployVehicles_vehiclesPaginated_rows {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: scooterDeployVehicles_vehiclesPaginated_rows_displayName;
  batteryCharge: number | null;
  controller: scooterDeployVehicles_vehiclesPaginated_rows_controller | null;
  model: scooterDeployVehicles_vehiclesPaginated_rows_model | null;
}

export interface scooterDeployVehicles_vehiclesPaginated {
  __typename: "VehiclesPaginated";
  rows: scooterDeployVehicles_vehiclesPaginated_rows[];
}

export interface scooterDeployVehicles {
  vehiclesPaginated: scooterDeployVehicles_vehiclesPaginated | null;
}

export interface scooterDeployVehiclesVariables {
  systemId: string;
  vehiclesConnectionFilters?: VehiclesConnectionFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deployScooterToDockGroup
// ====================================================

export interface deployScooterToDockGroup_dockVehicle {
  __typename: "VehicleAvailability";
  id: string;
}

export interface deployScooterToDockGroup {
  dockVehicle: deployScooterToDockGroup_dockVehicle | null;
}

export interface deployScooterToDockGroupVariables {
  vehicleId: string;
  dockGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: moveScooterToOperationLocation
// ====================================================

export interface moveScooterToOperationLocation_moveVehicleToOperationLocation {
  __typename: "VehicleAvailability";
  id: string;
}

export interface moveScooterToOperationLocation {
  moveVehicleToOperationLocation: moveScooterToOperationLocation_moveVehicleToOperationLocation | null;
}

export interface moveScooterToOperationLocationVariables {
  vehicleId: string;
  operationLocationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deployVehiclesToDockGroup
// ====================================================

export interface deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle_displayName = deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle_displayName_VehicleDisplayNamed | deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle_displayName_VehicleDisplayNumbered | deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle_displayName_VehicleDisplayAnonymous;

export interface deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle_displayName;
}

export interface deployVehiclesToDockGroup_deployVehiclesToDockGroup {
  __typename: "MovedVehicleResponse";
  vehicle: deployVehiclesToDockGroup_deployVehiclesToDockGroup_vehicle;
  status: MovedVehicleStatus;
}

export interface deployVehiclesToDockGroup {
  deployVehiclesToDockGroup: deployVehiclesToDockGroup_deployVehiclesToDockGroup[];
}

export interface deployVehiclesToDockGroupVariables {
  systemId: string;
  vehicleIds: string[];
  dockGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: salesStatisticsByVehicleCategory
// ====================================================

export interface salesStatisticsByVehicleCategory_salesStatisticsByVehicleCategory {
  __typename: "SalesStatisticsByVehicleCategory";
  id: string;
  orderType: OrderType;
  finalPriceGross: number;
  finalPriceNet: number;
  totalTrips: number;
  vehicleCategory: VehicleCategory;
}

export interface salesStatisticsByVehicleCategory {
  salesStatisticsByVehicleCategory: salesStatisticsByVehicleCategory_salesStatisticsByVehicleCategory[] | null;
}

export interface salesStatisticsByVehicleCategoryVariables {
  systemId: string;
  startDate: CORE_IsoDateTime;
  endDate: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: salesStatisticsByProduct
// ====================================================

export interface salesStatisticsByProduct_salesStatisticsByProduct_salesByProduct {
  __typename: "SalesByProduct";
  id: string;
  productId: string;
  productName: string;
  finalPriceGross: number;
  finalPriceNet: number;
  orderType: OrderType | null;
  totalOrders: number;
}

export interface salesStatisticsByProduct_salesStatisticsByProduct {
  __typename: "SalesStatisticsByProduct";
  salesByProduct: (salesStatisticsByProduct_salesStatisticsByProduct_salesByProduct | null)[];
  totalSalesGross: number;
  totalSalesNet: number;
  totalOrders: number;
}

export interface salesStatisticsByProduct {
  salesStatisticsByProduct: salesStatisticsByProduct_salesStatisticsByProduct | null;
}

export interface salesStatisticsByProductVariables {
  systemId: string;
  startDate: CORE_IsoDateTime;
  endDate: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ordersConnection
// ====================================================

export interface ordersConnection_orderStatuses_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface ordersConnection_orderStatuses {
  __typename: "__Type";
  name: string | null;
  enumValues: ordersConnection_orderStatuses_enumValues[] | null;
}

export interface ordersConnection_orderTypes_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface ordersConnection_orderTypes {
  __typename: "__Type";
  name: string | null;
  enumValues: ordersConnection_orderTypes_enumValues[] | null;
}

export interface ordersConnection_ordersConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ordersConnection_ordersConnection_edges_node_discount {
  __typename: "OrderDiscount";
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
}

export interface ordersConnection_ordersConnection_edges_node_orderLines_product {
  __typename: "Product";
  id: string;
  internalName: string | null;
}

export interface ordersConnection_ordersConnection_edges_node_orderLines {
  __typename: "OrderLine";
  product: ordersConnection_ordersConnection_edges_node_orderLines_product | null;
  amount: number;
}

export interface ordersConnection_ordersConnection_edges_node {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  number: string | null;
  amount: CORE_Money;
  currency: string;
  type: OrderType;
  completedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
  discount: ordersConnection_ordersConnection_edges_node_discount | null;
  orderLines: ordersConnection_ordersConnection_edges_node_orderLines[] | null;
  userId: string | null;
  paymentTransactionNumbers: string[];
}

export interface ordersConnection_ordersConnection_edges {
  __typename: "OrderEdge";
  cursor: string;
  node: ordersConnection_ordersConnection_edges_node;
}

export interface ordersConnection_ordersConnection {
  __typename: "OrdersConnection";
  pageInfo: ordersConnection_ordersConnection_pageInfo;
  edges: ordersConnection_ordersConnection_edges[];
}

export interface ordersConnection {
  orderStatuses: ordersConnection_orderStatuses | null;
  orderTypes: ordersConnection_orderTypes | null;
  ordersConnection: ordersConnection_ordersConnection | null;
}

export interface ordersConnectionVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  complexFilters?: (ComplexFilter | null)[] | null;
  filters?: OrdersConnectionFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orderQuery
// ====================================================

export interface orderQuery_order_issuedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface orderQuery_order_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface orderQuery_order_orderLines_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface orderQuery_order_orderLines_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export interface orderQuery_order_orderLines_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type orderQuery_order_orderLines_discount_discountAmount = orderQuery_order_orderLines_discount_discountAmount_AbsoluteDiscountAmount | orderQuery_order_orderLines_discount_discountAmount_RelativeDiscountAmount;

export interface orderQuery_order_orderLines_discount {
  __typename: "OrderLineDiscount";
  /**
   * Defines how many products will be discounted
   */
  quantityDiscounted: CORE_NonNegativeInt;
  /**
   * Represents total amount of discount in absolute values depending on
   * discounted items quantity
   */
  totalDiscountAmount: CORE_Money;
  /**
   * Defines discount amount
   */
  discountAmount: orderQuery_order_orderLines_discount_discountAmount;
}

export interface orderQuery_order_orderLines {
  __typename: "OrderLine";
  product: orderQuery_order_orderLines_product | null;
  amount: number;
  totalAmount: number;
  quantity: number;
  discount: orderQuery_order_orderLines_discount | null;
  type: OrderLineType;
}

export interface orderQuery_order_paymentTransactions_paymentRefunds_refundedByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface orderQuery_order_paymentTransactions_paymentRefunds {
  __typename: "PaymentRefund";
  id: string;
  processedAt: CORE_IsoDateTime;
  state: RefundState;
  amount: CORE_Money;
  failureReason: RefundFailureReason | null;
  refundReason: RefundReason;
  refundedByAdmin: orderQuery_order_paymentTransactions_paymentRefunds_refundedByAdmin | null;
  comment: string | null;
}

export interface orderQuery_order_paymentTransactions_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface orderQuery_order_paymentTransactions_paymentMethod_Card {
  __typename: "Card";
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  brand: CardBrand;
  last4: string;
  expired: boolean;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
}

export type orderQuery_order_paymentTransactions_paymentMethod = orderQuery_order_paymentTransactions_paymentMethod_Blik | orderQuery_order_paymentTransactions_paymentMethod_Card;

export interface orderQuery_order_paymentTransactions {
  __typename: "PaymentTransaction";
  id: string;
  transactionNumber: string | null;
  status: PaymentTransactionStatus | null;
  failureReason: PaymentFailureReason | null;
  provider: PaymentTransactionProvider | null;
  amount: CORE_Money;
  refundable: boolean;
  amountToRefund: CORE_Money;
  createdAt: CORE_IsoDateTime;
  processedAt: CORE_IsoDateTime | null;
  paymentRefunds: orderQuery_order_paymentTransactions_paymentRefunds[] | null;
  paymentMethod: orderQuery_order_paymentTransactions_paymentMethod | null;
}

export interface orderQuery_order_walletTransactions_refundedByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface orderQuery_order_walletTransactions {
  __typename: "WalletTransaction";
  id: string;
  orderId: string;
  createdAt: CORE_IsoDateTime;
  type: WalletTransactionType;
  amount: CORE_Money;
  refundReason: RefundReason | null;
  refundReasonDescription: string | null;
  refundable: boolean;
  refundedByAdmin: orderQuery_order_walletTransactions_refundedByAdmin | null;
}

export interface orderQuery_order_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface orderQuery_order_trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: orderQuery_order_trip_startDock_dockGroup | null;
}

export interface orderQuery_order_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface orderQuery_order_trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: orderQuery_order_trip_endDock_dockGroup | null;
}

export interface orderQuery_order_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: orderQuery_order_trip_startDock | null;
  endDock: orderQuery_order_trip_endDock | null;
}

export interface orderQuery_order_discount_discountCampaign {
  __typename: "DiscountCampaign";
  /**
   * Id
   */
  id: string;
  /**
   * Defines unique discount campaign name
   */
  name: string;
}

export interface orderQuery_order_discount {
  __typename: "OrderDiscount";
  /**
   * The discount campaign for the discount. If null, it have been deleted.
   */
  discountCampaign: orderQuery_order_discount_discountCampaign | null;
  /**
   * Represents total amount of discount in absolute values depending on
   * discounted items quantity
   */
  totalDiscountAmount: CORE_Money;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
}

export interface orderQuery_order {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  amount: CORE_Money;
  /**
   * Available amount to refund
   */
  amountToRefund: CORE_Money | null;
  /**
   * Can the order be refunded
   */
  refundable: boolean;
  currency: string;
  source: ProductPurchaseChannel | null;
  type: OrderType;
  issuedBy: orderQuery_order_issuedBy | null;
  token: string | null;
  completedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
  paymentFailureReason: PaymentFailureReason | null;
  cancellationReason: string | null;
  cancelledByAdmin: orderQuery_order_cancelledByAdmin | null;
  cancelledAt: CORE_IsoDateTime | null;
  /**
   * Can the order be cancelled
   */
  cancellable: boolean;
  orderLines: orderQuery_order_orderLines[] | null;
  userId: string | null;
  customerEmail: string | null;
  paymentTransactions: (orderQuery_order_paymentTransactions | null)[];
  walletTransactions: orderQuery_order_walletTransactions[];
  trip: orderQuery_order_trip | null;
  discount: orderQuery_order_discount | null;
}

export interface orderQuery {
  order: orderQuery_order | null;
}

export interface orderQueryVariables {
  systemId: string;
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orderRefundMetaQuery
// ====================================================

export interface orderRefundMetaQuery_refundReasons_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface orderRefundMetaQuery_refundReasons {
  __typename: "__Type";
  name: string | null;
  enumValues: orderRefundMetaQuery_refundReasons_enumValues[] | null;
}

export interface orderRefundMetaQuery {
  refundReasons: orderRefundMetaQuery_refundReasons | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: refundOrderTransaction
// ====================================================

export interface refundOrderTransaction_refundPaymentTransaction_refundedByAdmin {
  __typename: "Administrator";
  id: string;
}

export interface refundOrderTransaction_refundPaymentTransaction {
  __typename: "PaymentRefund";
  id: string;
  updatedAt: CORE_IsoDateTime;
  createdAt: CORE_IsoDateTime;
  processedAt: CORE_IsoDateTime;
  refundRef: string;
  state: RefundState;
  amount: CORE_Money;
  failureReason: RefundFailureReason | null;
  refundReason: RefundReason;
  refundedBy: string | null;
  refundedByAdmin: refundOrderTransaction_refundPaymentTransaction_refundedByAdmin | null;
  comment: string | null;
  paymentTransactionId: string | null;
}

export interface refundOrderTransaction {
  refundPaymentTransaction: refundOrderTransaction_refundPaymentTransaction;
}

export interface refundOrderTransactionVariables {
  systemId: string;
  transactionId: string;
  amount: CORE_Money;
  reason: RefundReason;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: refundWalletTransaction
// ====================================================

export interface refundWalletTransaction_refundWalletTransaction_order {
  __typename: "Order";
  id: string;
}

export interface refundWalletTransaction_refundWalletTransaction_refundedByAdmin {
  __typename: "Administrator";
  id: string;
}

export interface refundWalletTransaction_refundWalletTransaction {
  __typename: "WalletTransaction";
  id: string;
  orderId: string;
  order: refundWalletTransaction_refundWalletTransaction_order;
  amount: CORE_Money;
  currency: string;
  type: WalletTransactionType;
  refundReason: RefundReason | null;
  refundReasonDescription: string | null;
  createdAt: CORE_IsoDateTime;
  refundedByAdmin: refundWalletTransaction_refundWalletTransaction_refundedByAdmin | null;
}

export interface refundWalletTransaction {
  refundWalletTransaction: refundWalletTransaction_refundWalletTransaction;
}

export interface refundWalletTransactionVariables {
  systemId: string;
  walletTransactionId: string;
  amount: CORE_Money;
  reason: RefundReason;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOrderExportMutation
// ====================================================

export interface createOrderExportMutation_createOrderDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createOrderExportMutation_createOrderDataExportRequest_order_discount {
  __typename: "OrderDiscount";
  /**
   * Price with discount
   */
  orderDiscountedAmount: CORE_Money;
}

export interface createOrderExportMutation_createOrderDataExportRequest_order_orderLines_product {
  __typename: "Product";
  productId: string;
  productName: string | null;
}

export interface createOrderExportMutation_createOrderDataExportRequest_order_orderLines {
  __typename: "OrderLine";
  product: createOrderExportMutation_createOrderDataExportRequest_order_orderLines_product | null;
  productAmount: number;
}

export interface createOrderExportMutation_createOrderDataExportRequest_order {
  __typename: "Order";
  orderId: string;
  orderStatus: OrderStatus;
  orderNumber: string | null;
  orderTotalAmount: CORE_Money;
  currency: string;
  orderType: OrderType;
  orderCompletedAt: CORE_IsoDateTime | null;
  orderCreatedAt: CORE_IsoDateTime;
  discount: createOrderExportMutation_createOrderDataExportRequest_order_discount | null;
  orderLines: createOrderExportMutation_createOrderDataExportRequest_order_orderLines[] | null;
  userId: string | null;
}

export interface createOrderExportMutation_createOrderDataExportRequest {
  __typename: "CreateOrderDataExportRequestResponse";
  dataExportRequest: createOrderExportMutation_createOrderDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual trip.
   */
  order: createOrderExportMutation_createOrderDataExportRequest_order | null;
}

export interface createOrderExportMutation {
  createOrderDataExportRequest: createOrderExportMutation_createOrderDataExportRequest;
}

export interface createOrderExportMutationVariables {
  systemId: string;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  complexFilters?: (ComplexFilter | null)[] | null;
  filters?: OrdersConnectionFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createProductMutation
// ====================================================

export interface createProductMutation_createProduct {
  __typename: "Product";
  id: string;
}

export interface createProductMutation {
  /**
   * Create a product
   */
  createProduct: createProductMutation_createProduct;
}

export interface createProductMutationVariables {
  systemId: string;
  input: ProductInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setProductOrderNumberMutation
// ====================================================

export interface setProductOrderNumberMutation_setProductOrderNumber {
  __typename: "Product";
  id: string;
}

export interface setProductOrderNumberMutation {
  /**
   * Move products in a list.
   * Note, the list of products must be ordered by sortIndex ASC.
   */
  setProductOrderNumber: setProductOrderNumberMutation_setProductOrderNumber;
}

export interface setProductOrderNumberMutationVariables {
  systemId: string;
  sourceIndex: CORE_NonNegativeInt;
  destinationIndex: CORE_NonNegativeInt;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productsSortedQuery
// ====================================================

export interface productsSortedQuery_productsPaginated_rows {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface productsSortedQuery_productsPaginated {
  __typename: "ProductsPaginated";
  rows: productsSortedQuery_productsPaginated_rows[];
}

export interface productsSortedQuery {
  productsPaginated: productsSortedQuery_productsPaginated | null;
}

export interface productsSortedQueryVariables {
  systemId: string;
  orderBy: string;
  orderDirection: OrderDirection;
  filters: (Filter | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPriceMatrix
// ====================================================

export interface createPriceMatrix_createPriceMatrix {
  __typename: "PriceMatrix";
  id: string;
}

export interface createPriceMatrix {
  createPriceMatrix: createPriceMatrix_createPriceMatrix;
}

export interface createPriceMatrixVariables {
  input: PriceMatrixCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tripNotificationsTableMetaQuery
// ====================================================

export interface tripNotificationsTableMetaQuery_tripNotification_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface tripNotificationsTableMetaQuery_tripNotification {
  __typename: "__Type";
  name: string | null;
  enumValues: tripNotificationsTableMetaQuery_tripNotification_enumValues[] | null;
}

export interface tripNotificationsTableMetaQuery {
  tripNotification: tripNotificationsTableMetaQuery_tripNotification | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paginatedPriceMatrixes
// ====================================================

export interface paginatedPriceMatrixes_paginatedPriceMatrixes_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  totalRowCount: number;
}

export interface paginatedPriceMatrixes_paginatedPriceMatrixes_rows_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
}

export interface paginatedPriceMatrixes_paginatedPriceMatrixes_rows {
  __typename: "PriceMatrix";
  id: string;
  title: string;
  matrix: paginatedPriceMatrixes_paginatedPriceMatrixes_rows_matrix[];
  unlockFee: CORE_Money;
}

export interface paginatedPriceMatrixes_paginatedPriceMatrixes {
  __typename: "PriceMatrixesPaginated";
  paginationInfo: paginatedPriceMatrixes_paginatedPriceMatrixes_paginationInfo;
  rows: paginatedPriceMatrixes_paginatedPriceMatrixes_rows[];
}

export interface paginatedPriceMatrixes {
  paginatedPriceMatrixes: paginatedPriceMatrixes_paginatedPriceMatrixes | null;
}

export interface paginatedPriceMatrixesVariables {
  systemId: string;
  offset?: number | null;
  limit?: number | null;
  filters?: (Filter | null)[] | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemConnectionPriceMatrixes
// ====================================================

export interface systemConnectionPriceMatrixes_system_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface systemConnectionPriceMatrixes_system_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: systemConnectionPriceMatrixes_system_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface systemConnectionPriceMatrixes_system {
  __typename: "System";
  id: string;
  connectionPriceMatrixes: systemConnectionPriceMatrixes_system_connectionPriceMatrixes[] | null;
}

export interface systemConnectionPriceMatrixes {
  system: systemConnectionPriceMatrixes_system;
}

export interface systemConnectionPriceMatrixesVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: priceMatrix
// ====================================================

export interface priceMatrix_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
}

export interface priceMatrix_priceMatrix_penalty {
  __typename: "PenaltyItem";
  points: number;
  penaltyAfterMinutes: number;
}

export interface priceMatrix_priceMatrix_notification {
  __typename: "TripNotificationItem";
  minutes: number;
  template: TripNotificationTemplate;
}

export interface priceMatrix_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
  unlockFee: CORE_Money;
  defaultForVehicleCategory: VehicleCategory[] | null;
  matrix: priceMatrix_priceMatrix_matrix[];
  penalty: priceMatrix_priceMatrix_penalty[] | null;
  notification: priceMatrix_priceMatrix_notification[] | null;
}

export interface priceMatrix {
  priceMatrix: priceMatrix_priceMatrix | null;
}

export interface priceMatrixVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePriceMatrix
// ====================================================

export interface updatePriceMatrix_updatePriceMatrix {
  __typename: "PriceMatrix";
  id: string;
}

export interface updatePriceMatrix {
  updatePriceMatrix: updatePriceMatrix_updatePriceMatrix;
}

export interface updatePriceMatrixVariables {
  id: string;
  input: PriceMatrixUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePriceMatrix
// ====================================================

export interface deletePriceMatrix_deletePriceMatrix {
  __typename: "PriceMatrix";
  id: string;
}

export interface deletePriceMatrix {
  deletePriceMatrix: deletePriceMatrix_deletePriceMatrix;
}

export interface deletePriceMatrixVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSystemMutation
// ====================================================

export interface createSystemMutation_createSystem_coords {
  __typename: "Coord";
  lng: number;
  lat: number;
}

export interface createSystemMutation_createSystem_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface createSystemMutation_createSystem_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: createSystemMutation_createSystem_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface createSystemMutation_createSystem_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface createSystemMutation_createSystem {
  __typename: "System";
  name: string;
  description: string;
  state: SystemState;
  coords: createSystemMutation_createSystem_coords[] | null;
  type: SystemType;
  countryName: string;
  countryCode: string;
  cityName: string;
  defaultLanguageCode: LanguageCode;
  languageCodes: LanguageCode[];
  operator: string | null;
  currency: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  timezone: string;
  maxPenaltyPoints: number | null;
  sanityProjectId: string | null;
  sanityDataset: string | null;
  sanityToken: string | null;
  colourKey: SystemColourKey | null;
  publicDomain: string | null;
  workingHoursId: string | null;
  vehicleQuickReturnPeriodSec: number | null;
  vehicleQuickReturnsToBroken: number | null;
  vehicleLostPeriodDays: number | null;
  dispatcherSmsSenderID: string | null;
  iosAppUrl: string | null;
  androidAppUrl: string | null;
  webUrl: string | null;
  sponsor: Sponsor | null;
  settings: CORE_JSON;
  intercomHelpSite: string | null;
  defaultVatRate: number;
  tripAdditionalMinutesPeriod: CORE_NonNegativeInt;
  maxTripAdditionaMinutesPeriodCount: CORE_NonNegativeInt;
  minTripLengthForCoolOffPeriodSec: number | null;
  /**
   * minutes prior to the end of free included period when you can request Additional Free Minutes
   */
  timeToRequestAdditionalMinutes: CORE_NonNegativeInt;
  coolOffPeriodSec: number | null;
  pauseTripEnabled: FeatureToggleValue | null;
  shouldAskForPushPermissions: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  qrCodeScanEnabled: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled: FeatureToggleValue | null;
  vehicleReservationEnabled: FeatureToggleValue | null;
  /**
   * Deep linking prefix used in apps.
   * 
   * Keep the value in sync with the mobile app configurations:
   * Android: Each system_id_deeplinking_prefix should map to systemId
   * https: // github.com/urbaninfrastructure/bikesharing-multicity-android/blob/master/app/build.gradle#L161
   */
  appDeepLinkingPrefix: string | null;
  /**
   * If enabled users who have unpaid trip orders can not take bikes
   */
  suspendDebtorEnabled: boolean;
  /**
   * ClickUp URL to be used in an Iframe for the Feedback form in Admin.
   * Falls back to the old Slack integration if not set
   */
  feedbackFormUrl: string | null;
  /**
   * ClickUp list id used to fetch support tasks.
   */
  clickUpListId: string | null;
  /**
   * If enabled trip charges are consolidated within an invoice
   */
  tripChargeConsolidationEnabled: boolean;
  /**
   * Defines the days amount during which trip charges are consolidated, an invoice payment is triggered once exceeded
   */
  tripChargeConsolidationPeriod: CORE_PositiveInt;
  /**
   * Defines the total charges amount required in order to trigger payment
   */
  tripChargeConsolidationAmount: CORE_Money;
  /**
   * If enabled new app users should pay a fee
   */
  registrationFeeEnabled: boolean;
  /**
   * Defines the amount of the registration fee
   */
  registrationFeeAmount: CORE_Money;
  /**
   * Enforcement of registration agreement withdrawal consent
   */
  registrationAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of subscription agreement withdrawal consent
   */
  subscriptionAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of consent to send data to third parties
   */
  sendDataToThirdPartiesConsent: ConsentEnforcement;
  /**
   * Defines wallet enablement
   */
  walletEnablement: WalletEnablement;
  /**
   * Defines wallet enablement
   */
  walletMinBalance: CORE_Money;
  vehicleReservationCoolOffPeriodSec: CORE_NonNegativeInt;
  vehicleReservationLimitPerDay: CORE_NonNegativeInt;
  freeFloatingEnabled: boolean;
  vehicleReservationQuickCancellationPeriodSec: CORE_NonNegativeInt;
  vehicleReservationPeriodMins: CORE_NonNegativeInt;
  /**
   * If enabled email with trip details is sent to a user
   */
  tripReceiptEmailNotificationEnabled: boolean;
  /**
   * If enabled push notification with trip details is sent to a user
   */
  tripReceiptPushNotificationEnabled: boolean;
  id: string;
  vehicleCategories: VehicleCategory[];
  connectionPriceMatrixes: createSystemMutation_createSystem_connectionPriceMatrixes[] | null;
  partners: createSystemMutation_createSystem_partners[];
}

export interface createSystemMutation {
  createSystem: createSystemMutation_createSystem | null;
}

export interface createSystemMutationVariables {
  input?: SystemCreateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: subscriptionPageQuery
// ====================================================

export interface subscriptionPageQuery_subscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface subscriptionPageQuery_subscription_valueCode {
  __typename: "ValueCode";
  id: string;
  code: string;
}

export interface subscriptionPageQuery_subscription {
  __typename: "ProductSubscription";
  id: string;
  systemId: string;
  userId: string;
  state: ProductSubscriptionState;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  disabledAt: CORE_IsoDateTime | null;
  product: subscriptionPageQuery_subscription_product | null;
  valueCode: subscriptionPageQuery_subscription_valueCode | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
}

export interface subscriptionPageQuery {
  subscription: subscriptionPageQuery_subscription | null;
}

export interface subscriptionPageQueryVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSubscriptionMutation
// ====================================================

export interface createSubscriptionMutation_createSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface createSubscriptionMutation_createSubscription_valueCode {
  __typename: "ValueCode";
  id: string;
  code: string;
}

export interface createSubscriptionMutation_createSubscription {
  __typename: "ProductSubscription";
  id: string;
  systemId: string;
  userId: string;
  state: ProductSubscriptionState;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  disabledAt: CORE_IsoDateTime | null;
  product: createSubscriptionMutation_createSubscription_product | null;
  valueCode: createSubscriptionMutation_createSubscription_valueCode | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
}

export interface createSubscriptionMutation {
  createSubscription: createSubscriptionMutation_createSubscription;
}

export interface createSubscriptionMutationVariables {
  input?: ProductSubscriptionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSubscriptionMutation
// ====================================================

export interface updateSubscriptionMutation_updateSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface updateSubscriptionMutation_updateSubscription_valueCode {
  __typename: "ValueCode";
  id: string;
  code: string;
}

export interface updateSubscriptionMutation_updateSubscription {
  __typename: "ProductSubscription";
  id: string;
  systemId: string;
  userId: string;
  state: ProductSubscriptionState;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  disabledAt: CORE_IsoDateTime | null;
  product: updateSubscriptionMutation_updateSubscription_product | null;
  valueCode: updateSubscriptionMutation_updateSubscription_valueCode | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
}

export interface updateSubscriptionMutation {
  /**
   * Update a subscription
   */
  updateSubscription: updateSubscriptionMutation_updateSubscription;
}

export interface updateSubscriptionMutationVariables {
  id: string;
  validFrom?: CORE_IsoDateTime | null;
  validTo?: CORE_IsoDateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableSubscriptionMutation
// ====================================================

export interface disableSubscriptionMutation_disableSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface disableSubscriptionMutation_disableSubscription_valueCode {
  __typename: "ValueCode";
  id: string;
  code: string;
}

export interface disableSubscriptionMutation_disableSubscription {
  __typename: "ProductSubscription";
  id: string;
  systemId: string;
  userId: string;
  state: ProductSubscriptionState;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  disabledAt: CORE_IsoDateTime | null;
  product: disableSubscriptionMutation_disableSubscription_product | null;
  valueCode: disableSubscriptionMutation_disableSubscription_valueCode | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
}

export interface disableSubscriptionMutation {
  /**
   * Disable a subscription
   */
  disableSubscription: disableSubscriptionMutation_disableSubscription;
}

export interface disableSubscriptionMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSubscriptionMutation
// ====================================================

export interface deleteSubscriptionMutation_deleteSubscription {
  __typename: "ProductSubscription";
  id: string;
}

export interface deleteSubscriptionMutation {
  /**
   * Delete a subscription
   */
  deleteSubscription: deleteSubscriptionMutation_deleteSubscription;
}

export interface deleteSubscriptionMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteProductAutoRenewalMutation
// ====================================================

export interface deleteProductAutoRenewalMutation_deleteProductAutoRenewal {
  __typename: "ProductRenewalPending" | "ProductRenewalScheduled" | "ProductRenewalFailed" | "ProductRenewalDeleted";
  id: string;
}

export interface deleteProductAutoRenewalMutation {
  /**
   * Delete auto renewal of a subscription
   */
  deleteProductAutoRenewal: deleteProductAutoRenewalMutation_deleteProductAutoRenewal | null;
}

export interface deleteProductAutoRenewalMutationVariables {
  productId: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clickUpTasksQuery
// ====================================================

export interface clickUpTasksQuery_clickUpTasks_rows_priority {
  __typename: "CU_Priority";
  priority: string;
  color: string | null;
}

export interface clickUpTasksQuery_clickUpTasks_rows_status {
  __typename: "CU_Status";
  status: string;
  color: string;
}

export interface clickUpTasksQuery_clickUpTasks_rows_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface clickUpTasksQuery_clickUpTasks_rows {
  __typename: "CU_TaskListItem";
  id: string;
  /**
   * Human readable ID
   */
  custom_id: string | null;
  name: string;
  priority: clickUpTasksQuery_clickUpTasks_rows_priority | null;
  status: clickUpTasksQuery_clickUpTasks_rows_status;
  description: string | null;
  date_created: CORE_IsoDateTime;
  date_updated: CORE_IsoDateTime;
  date_closed: CORE_IsoDateTime | null;
  url: string;
  /**
   * Administrator that created the task. Is populated from CustomField named AdministratorID
   */
  administrator: clickUpTasksQuery_clickUpTasks_rows_administrator | null;
}

export interface clickUpTasksQuery_clickUpTasks {
  __typename: "PaginatedClickupTasks";
  page: number;
  perPage: number;
  rows: clickUpTasksQuery_clickUpTasks_rows[];
}

export interface clickUpTasksQuery {
  /**
   * Get ClickUp tasks for the system.
   * The maximum number of tasks returned in this response is 100. When you are paging this request, you should check list limit against the length of each response to determine if you are on the last page.
   */
  clickUpTasks: clickUpTasksQuery_clickUpTasks | null;
}

export interface clickUpTasksQueryVariables {
  systemId: string;
  page: number;
  include_closed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clickUpTaskQuery
// ====================================================

export interface clickUpTaskQuery_clickUpTask_comments_user {
  __typename: "CU_User";
  id: string;
  email: string;
  username: string;
  profilePicture: string | null;
}

export interface clickUpTaskQuery_clickUpTask_comments {
  __typename: "CU_TaskComment";
  id: string;
  comment_text: string;
  date: CORE_IsoDateTime;
  user: clickUpTaskQuery_clickUpTask_comments_user;
}

export interface clickUpTaskQuery_clickUpTask {
  __typename: "CU_TaskItem";
  id: string;
  comments: clickUpTaskQuery_clickUpTask_comments[];
}

export interface clickUpTaskQuery {
  /**
   * Get ClickUp task by ID
   */
  clickUpTask: clickUpTaskQuery_clickUpTask | null;
}

export interface clickUpTaskQueryVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSystemBusinessMutation
// ====================================================

export interface updateSystemBusinessMutation_updateSystem_collectionMethods {
  __typename: "CollectionMethods";
  chargeAutomatically: boolean;
  sendInvoice: boolean;
}

export interface updateSystemBusinessMutation_updateSystem {
  __typename: "System";
  id: string;
  businessUrl: string | null;
  /**
   * Firebase Auth tenant ID used for B2B
   */
  businessFirebaseTenantId: string | null;
  collectionMethods: updateSystemBusinessMutation_updateSystem_collectionMethods;
}

export interface updateSystemBusinessMutation {
  updateSystem: updateSystemBusinessMutation_updateSystem | null;
}

export interface updateSystemBusinessMutationVariables {
  systemId: string;
  input: SystemUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemBusinessQuery
// ====================================================

export interface systemBusinessQuery_system_collectionMethods {
  __typename: "CollectionMethods";
  chargeAutomatically: boolean;
  sendInvoice: boolean;
}

export interface systemBusinessQuery_system {
  __typename: "System";
  id: string;
  businessUrl: string | null;
  /**
   * Firebase Auth tenant ID used for B2B
   */
  businessFirebaseTenantId: string | null;
  collectionMethods: systemBusinessQuery_system_collectionMethods;
}

export interface systemBusinessQuery {
  system: systemBusinessQuery_system;
}

export interface systemBusinessQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemSettings
// ====================================================

export interface systemSettings_system {
  __typename: "System";
  id: string;
  settings: CORE_JSON;
  pauseTripEnabled: FeatureToggleValue | null;
  shouldAskForPushPermissions: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  qrCodeScanEnabled: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled: FeatureToggleValue | null;
  vehicleReservationEnabled: FeatureToggleValue | null;
  /**
   * If enabled email with trip details is sent to a user
   */
  tripReceiptEmailNotificationEnabled: boolean;
  /**
   * If enabled push notification with trip details is sent to a user
   */
  tripReceiptPushNotificationEnabled: boolean;
}

export interface systemSettings_featureToggleValues_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface systemSettings_featureToggleValues {
  __typename: "__Type";
  enumValues: systemSettings_featureToggleValues_enumValues[] | null;
}

export interface systemSettings_availableSettings_inputFields_type_ofType_ofType_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface systemSettings_availableSettings_inputFields_type_ofType_ofType {
  __typename: "__Type";
  name: string | null;
  kind: __TypeKind;
  enumValues: systemSettings_availableSettings_inputFields_type_ofType_ofType_enumValues[] | null;
}

export interface systemSettings_availableSettings_inputFields_type_ofType {
  __typename: "__Type";
  ofType: systemSettings_availableSettings_inputFields_type_ofType_ofType | null;
}

export interface systemSettings_availableSettings_inputFields_type {
  __typename: "__Type";
  kind: __TypeKind;
  name: string | null;
  ofType: systemSettings_availableSettings_inputFields_type_ofType | null;
}

export interface systemSettings_availableSettings_inputFields {
  __typename: "__InputValue";
  name: string;
  type: systemSettings_availableSettings_inputFields_type;
}

export interface systemSettings_availableSettings {
  __typename: "__Type";
  name: string | null;
  inputFields: systemSettings_availableSettings_inputFields[] | null;
}

export interface systemSettings {
  system: systemSettings_system;
  featureToggleValues: systemSettings_featureToggleValues | null;
  availableSettings: systemSettings_availableSettings | null;
}

export interface systemSettingsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSystemMutation
// ====================================================

export interface updateSystemMutation_updateSystem_coords {
  __typename: "Coord";
  lng: number;
  lat: number;
}

export interface updateSystemMutation_updateSystem_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface updateSystemMutation_updateSystem_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: updateSystemMutation_updateSystem_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface updateSystemMutation_updateSystem_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface updateSystemMutation_updateSystem {
  __typename: "System";
  name: string;
  description: string;
  state: SystemState;
  coords: updateSystemMutation_updateSystem_coords[] | null;
  type: SystemType;
  countryName: string;
  countryCode: string;
  cityName: string;
  defaultLanguageCode: LanguageCode;
  languageCodes: LanguageCode[];
  operator: string | null;
  currency: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  timezone: string;
  maxPenaltyPoints: number | null;
  sanityProjectId: string | null;
  sanityDataset: string | null;
  sanityToken: string | null;
  colourKey: SystemColourKey | null;
  publicDomain: string | null;
  workingHoursId: string | null;
  vehicleQuickReturnPeriodSec: number | null;
  vehicleQuickReturnsToBroken: number | null;
  vehicleLostPeriodDays: number | null;
  dispatcherSmsSenderID: string | null;
  iosAppUrl: string | null;
  androidAppUrl: string | null;
  webUrl: string | null;
  sponsor: Sponsor | null;
  settings: CORE_JSON;
  intercomHelpSite: string | null;
  defaultVatRate: number;
  tripAdditionalMinutesPeriod: CORE_NonNegativeInt;
  maxTripAdditionaMinutesPeriodCount: CORE_NonNegativeInt;
  minTripLengthForCoolOffPeriodSec: number | null;
  /**
   * minutes prior to the end of free included period when you can request Additional Free Minutes
   */
  timeToRequestAdditionalMinutes: CORE_NonNegativeInt;
  coolOffPeriodSec: number | null;
  pauseTripEnabled: FeatureToggleValue | null;
  shouldAskForPushPermissions: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  qrCodeScanEnabled: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled: FeatureToggleValue | null;
  vehicleReservationEnabled: FeatureToggleValue | null;
  /**
   * Deep linking prefix used in apps.
   * 
   * Keep the value in sync with the mobile app configurations:
   * Android: Each system_id_deeplinking_prefix should map to systemId
   * https: // github.com/urbaninfrastructure/bikesharing-multicity-android/blob/master/app/build.gradle#L161
   */
  appDeepLinkingPrefix: string | null;
  /**
   * If enabled users who have unpaid trip orders can not take bikes
   */
  suspendDebtorEnabled: boolean;
  /**
   * ClickUp URL to be used in an Iframe for the Feedback form in Admin.
   * Falls back to the old Slack integration if not set
   */
  feedbackFormUrl: string | null;
  /**
   * ClickUp list id used to fetch support tasks.
   */
  clickUpListId: string | null;
  /**
   * If enabled trip charges are consolidated within an invoice
   */
  tripChargeConsolidationEnabled: boolean;
  /**
   * Defines the days amount during which trip charges are consolidated, an invoice payment is triggered once exceeded
   */
  tripChargeConsolidationPeriod: CORE_PositiveInt;
  /**
   * Defines the total charges amount required in order to trigger payment
   */
  tripChargeConsolidationAmount: CORE_Money;
  /**
   * If enabled new app users should pay a fee
   */
  registrationFeeEnabled: boolean;
  /**
   * Defines the amount of the registration fee
   */
  registrationFeeAmount: CORE_Money;
  /**
   * Enforcement of registration agreement withdrawal consent
   */
  registrationAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of subscription agreement withdrawal consent
   */
  subscriptionAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of consent to send data to third parties
   */
  sendDataToThirdPartiesConsent: ConsentEnforcement;
  /**
   * Defines wallet enablement
   */
  walletEnablement: WalletEnablement;
  /**
   * Defines wallet enablement
   */
  walletMinBalance: CORE_Money;
  vehicleReservationCoolOffPeriodSec: CORE_NonNegativeInt;
  vehicleReservationLimitPerDay: CORE_NonNegativeInt;
  freeFloatingEnabled: boolean;
  vehicleReservationQuickCancellationPeriodSec: CORE_NonNegativeInt;
  vehicleReservationPeriodMins: CORE_NonNegativeInt;
  /**
   * If enabled email with trip details is sent to a user
   */
  tripReceiptEmailNotificationEnabled: boolean;
  /**
   * If enabled push notification with trip details is sent to a user
   */
  tripReceiptPushNotificationEnabled: boolean;
  id: string;
  vehicleCategories: VehicleCategory[];
  connectionPriceMatrixes: updateSystemMutation_updateSystem_connectionPriceMatrixes[] | null;
  partners: updateSystemMutation_updateSystem_partners[];
}

export interface updateSystemMutation {
  updateSystem: updateSystemMutation_updateSystem | null;
}

export interface updateSystemMutationVariables {
  id: string;
  input?: SystemUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemWorkingHoursQuery
// ====================================================

export interface systemWorkingHoursQuery_system_workingHours_overrides_localeReason {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface systemWorkingHoursQuery_system_workingHours_overrides {
  __typename: "WorkingHoursOverride";
  id: string;
  state: WorkingHoursOverrideState;
  workingHoursId: string;
  name: string;
  fromDate: CORE_IsoDateTime;
  toDate: CORE_IsoDateTime;
  startTime: CORE_Time | null;
  endTime: CORE_Time | null;
  closed: boolean;
  localeReason: systemWorkingHoursQuery_system_workingHours_overrides_localeReason[] | null;
}

export interface systemWorkingHoursQuery_system_workingHours {
  __typename: "WorkingHours";
  id: string;
  systemId: string;
  name: string | null;
  startTime: CORE_Time;
  endTime: CORE_Time;
  overrides: systemWorkingHoursQuery_system_workingHours_overrides[] | null;
}

export interface systemWorkingHoursQuery_system {
  __typename: "System";
  id: string;
  workingHoursId: string | null;
  workingHours: systemWorkingHoursQuery_system_workingHours | null;
  defaultLanguageCode: LanguageCode;
  languageCodes: LanguageCode[];
}

export interface systemWorkingHoursQuery_seasons {
  __typename: "Season";
  id: string;
  systemId: string;
  startDate: CORE_DateTime;
  endDate: CORE_DateTime | null;
  state: SeasonState;
  startDateOnly: CORE_Date;
  endDateOnly: CORE_Date | null;
}

export interface systemWorkingHoursQuery {
  system: systemWorkingHoursQuery_system;
  seasons: systemWorkingHoursQuery_seasons[];
}

export interface systemWorkingHoursQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemWorkingHoursOverridesExpiredQuery
// ====================================================

export interface systemWorkingHoursOverridesExpiredQuery_system_workingHours_overrides_localeReason {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface systemWorkingHoursOverridesExpiredQuery_system_workingHours_overrides {
  __typename: "WorkingHoursOverride";
  id: string;
  state: WorkingHoursOverrideState;
  workingHoursId: string;
  name: string;
  fromDate: CORE_IsoDateTime;
  toDate: CORE_IsoDateTime;
  startTime: CORE_Time | null;
  endTime: CORE_Time | null;
  closed: boolean;
  localeReason: systemWorkingHoursOverridesExpiredQuery_system_workingHours_overrides_localeReason[] | null;
}

export interface systemWorkingHoursOverridesExpiredQuery_system_workingHours {
  __typename: "WorkingHours";
  id: string;
  systemId: string;
  name: string | null;
  startTime: CORE_Time;
  endTime: CORE_Time;
  overrides: systemWorkingHoursOverridesExpiredQuery_system_workingHours_overrides[] | null;
}

export interface systemWorkingHoursOverridesExpiredQuery_system {
  __typename: "System";
  id: string;
  workingHoursId: string | null;
  workingHours: systemWorkingHoursOverridesExpiredQuery_system_workingHours | null;
}

export interface systemWorkingHoursOverridesExpiredQuery {
  system: systemWorkingHoursOverridesExpiredQuery_system;
}

export interface systemWorkingHoursOverridesExpiredQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createWorkingHoursOverrideMutation
// ====================================================

export interface createWorkingHoursOverrideMutation_createWorkingHoursOverride_localeReason {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface createWorkingHoursOverrideMutation_createWorkingHoursOverride {
  __typename: "WorkingHoursOverride";
  id: string;
  state: WorkingHoursOverrideState;
  workingHoursId: string;
  name: string;
  fromDate: CORE_IsoDateTime;
  toDate: CORE_IsoDateTime;
  startTime: CORE_Time | null;
  endTime: CORE_Time | null;
  closed: boolean;
  localeReason: createWorkingHoursOverrideMutation_createWorkingHoursOverride_localeReason[] | null;
}

export interface createWorkingHoursOverrideMutation {
  createWorkingHoursOverride: createWorkingHoursOverrideMutation_createWorkingHoursOverride | null;
}

export interface createWorkingHoursOverrideMutationVariables {
  input?: WorkingHoursOverrideInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateWorkingHoursOverrideMutation
// ====================================================

export interface updateWorkingHoursOverrideMutation_updateWorkingHoursOverride_localeReason {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface updateWorkingHoursOverrideMutation_updateWorkingHoursOverride {
  __typename: "WorkingHoursOverride";
  id: string;
  state: WorkingHoursOverrideState;
  workingHoursId: string;
  name: string;
  fromDate: CORE_IsoDateTime;
  toDate: CORE_IsoDateTime;
  startTime: CORE_Time | null;
  endTime: CORE_Time | null;
  closed: boolean;
  localeReason: updateWorkingHoursOverrideMutation_updateWorkingHoursOverride_localeReason[] | null;
}

export interface updateWorkingHoursOverrideMutation {
  updateWorkingHoursOverride: updateWorkingHoursOverrideMutation_updateWorkingHoursOverride | null;
}

export interface updateWorkingHoursOverrideMutationVariables {
  id: string;
  input?: WorkingHoursOverrideInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteWorkingHoursOverrideMutation
// ====================================================

export interface deleteWorkingHoursOverrideMutation_deleteWorkingHoursOverride {
  __typename: "WorkingHoursOverride";
  id: string;
}

export interface deleteWorkingHoursOverrideMutation {
  deleteWorkingHoursOverride: deleteWorkingHoursOverrideMutation_deleteWorkingHoursOverride | null;
}

export interface deleteWorkingHoursOverrideMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemStatusBar
// ====================================================

export interface systemStatusBar_system_workingHours_overrides {
  __typename: "WorkingHoursOverride";
  id: string;
  name: string;
  startTime: CORE_Time | null;
  endTime: CORE_Time | null;
  fromDate: CORE_IsoDateTime;
  toDate: CORE_IsoDateTime;
  closed: boolean;
  state: WorkingHoursOverrideState;
}

export interface systemStatusBar_system_workingHours {
  __typename: "WorkingHours";
  id: string;
  systemId: string;
  name: string | null;
  startTime: CORE_Time;
  endTime: CORE_Time;
  overrides: systemStatusBar_system_workingHours_overrides[] | null;
}

export interface systemStatusBar_system_currentSeason {
  __typename: "Season";
  id: string;
  startDate: CORE_DateTime;
  endDate: CORE_DateTime | null;
}

export interface systemStatusBar_system_nextSeason {
  __typename: "Season";
  id: string;
  startDate: CORE_DateTime;
}

export interface systemStatusBar_system {
  __typename: "System";
  id: string;
  name: string;
  state: SystemState;
  inSeason: boolean | null;
  insideOpeningHours: boolean | null;
  closedReason: string | null;
  workingHours: systemStatusBar_system_workingHours | null;
  currentSeason: systemStatusBar_system_currentSeason | null;
  nextSeason: systemStatusBar_system_nextSeason | null;
}

export interface systemStatusBar {
  system: systemStatusBar_system;
}

export interface systemStatusBarVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateWorkingHoursMutation
// ====================================================

export interface updateWorkingHoursMutation_updateWorkingHours {
  __typename: "WorkingHours";
  id: string;
  systemId: string;
  name: string | null;
  startTime: CORE_Time;
  endTime: CORE_Time;
}

export interface updateWorkingHoursMutation {
  updateWorkingHours: updateWorkingHoursMutation_updateWorkingHours | null;
}

export interface updateWorkingHoursMutationVariables {
  id: string;
  input?: WorkingHoursInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSystemWorkingHoursMutation
// ====================================================

export interface createSystemWorkingHoursMutation_createSystemWorkingHours {
  __typename: "WorkingHours";
  id: string;
  systemId: string;
  name: string | null;
  startTime: CORE_Time;
  endTime: CORE_Time;
}

export interface createSystemWorkingHoursMutation {
  createSystemWorkingHours: createSystemWorkingHoursMutation_createSystemWorkingHours;
}

export interface createSystemWorkingHoursMutationVariables {
  systemId: string;
  input: WorkingHoursInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: previewWorkingHoursQuery
// ====================================================

export interface previewWorkingHoursQuery_previewWorkingHours {
  __typename: "PreviewWorkingHours";
  isWithinOpeningHours: boolean;
}

export interface previewWorkingHoursQuery {
  previewWorkingHours: previewWorkingHoursQuery_previewWorkingHours;
}

export interface previewWorkingHoursQueryVariables {
  startTime: CORE_Time;
  endTime: CORE_Time;
  timezone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: operationLocationsForm
// ====================================================

export interface operationLocationsForm_operationLocations_lockFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface operationLocationsForm_operationLocations {
  __typename: "OperationLocation";
  id: string;
  type: OperationLocationType;
  name: string;
  lockFences: operationLocationsForm_operationLocations_lockFences[] | null;
}

export interface operationLocationsForm_operationLocationTypes_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface operationLocationsForm_operationLocationTypes {
  __typename: "__Type";
  name: string | null;
  enumValues: operationLocationsForm_operationLocationTypes_enumValues[] | null;
}

export interface operationLocationsForm {
  operationLocations: operationLocationsForm_operationLocations[] | null;
  operationLocationTypes: operationLocationsForm_operationLocationTypes | null;
}

export interface operationLocationsFormVariables {
  systemId: string;
  options?: QueryOptions | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOperationLocation
// ====================================================

export interface createOperationLocation_createOperationLocation {
  __typename: "OperationLocation";
  id: string;
  name: string;
  type: OperationLocationType;
}

export interface createOperationLocation {
  createOperationLocation: createOperationLocation_createOperationLocation | null;
}

export interface createOperationLocationVariables {
  input?: OperationLocationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateOperationLocation
// ====================================================

export interface updateOperationLocation_updateOperationLocation {
  __typename: "OperationLocation";
  id: string;
  name: string;
  type: OperationLocationType;
}

export interface updateOperationLocation {
  updateOperationLocation: updateOperationLocation_updateOperationLocation | null;
}

export interface updateOperationLocationVariables {
  id: string;
  input?: OperationLocationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOperationLocation
// ====================================================

export interface deleteOperationLocation_deleteOperationLocation {
  __typename: "OperationLocation";
  id: string;
  name: string;
  type: OperationLocationType;
}

export interface deleteOperationLocation {
  deleteOperationLocation: deleteOperationLocation_deleteOperationLocation | null;
}

export interface deleteOperationLocationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemPaymentBackoffSchedulesQuery
// ====================================================

export interface systemPaymentBackoffSchedulesQuery_system_paymentBackoffSchedules {
  __typename: "TripPaymentBackoffSchedule" | "RenewalPaymentBackoffSchedule";
  /**
   * The failure reason for which rule is eligible
   */
  failureReason: PaymentFailureReason;
  /**
   * Max number of attempts. 0 means unlimited
   */
  maxAttempts: CORE_NonNegativeInt;
  /**
   * A payment will be scheduled for retry between min backoff and max backoff duration after.
   */
  minBackoffSeconds: CORE_PositiveInt;
  /**
   * A payment will be scheduled for retry between min backoff and max backoff duration after.
   */
  maxBackoffSeconds: CORE_PositiveInt;
}

export interface systemPaymentBackoffSchedulesQuery_system {
  __typename: "System";
  id: string;
  /**
   * Payment backoff schedule configs
   */
  paymentBackoffSchedules: systemPaymentBackoffSchedulesQuery_system_paymentBackoffSchedules[];
}

export interface systemPaymentBackoffSchedulesQuery {
  system: systemPaymentBackoffSchedulesQuery_system;
}

export interface systemPaymentBackoffSchedulesQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePaymentBackoffSchedulesMutation
// ====================================================

export interface updatePaymentBackoffSchedulesMutation_updateSystem_paymentBackoffSchedules {
  __typename: "TripPaymentBackoffSchedule" | "RenewalPaymentBackoffSchedule";
  /**
   * The failure reason for which rule is eligible
   */
  failureReason: PaymentFailureReason;
  /**
   * Max number of attempts. 0 means unlimited
   */
  maxAttempts: CORE_NonNegativeInt;
  /**
   * A payment will be scheduled for retry between min backoff and max backoff duration after.
   */
  minBackoffSeconds: CORE_PositiveInt;
  /**
   * A payment will be scheduled for retry between min backoff and max backoff duration after.
   */
  maxBackoffSeconds: CORE_PositiveInt;
}

export interface updatePaymentBackoffSchedulesMutation_updateSystem {
  __typename: "System";
  id: string;
  /**
   * Payment backoff schedule configs
   */
  paymentBackoffSchedules: updatePaymentBackoffSchedulesMutation_updateSystem_paymentBackoffSchedules[];
}

export interface updatePaymentBackoffSchedulesMutation {
  updateSystem: updatePaymentBackoffSchedulesMutation_updateSystem | null;
}

export interface updatePaymentBackoffSchedulesMutationVariables {
  systemId: string;
  input?: SystemUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setDefaultPaymentProviderMutation
// ====================================================

export interface setDefaultPaymentProviderMutation_setDefaultPaymentProvider {
  __typename: "System";
  id: string;
  /**
   * Default payment provider
   */
  paymentProvider: PaymentProviderEnum | null;
}

export interface setDefaultPaymentProviderMutation {
  setDefaultPaymentProvider: setDefaultPaymentProviderMutation_setDefaultPaymentProvider;
}

export interface setDefaultPaymentProviderMutationVariables {
  systemId: string;
  paymentProvider: PaymentProviderEnum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateStripeConfigMutation
// ====================================================

export interface updateStripeConfigMutation_updateStripeConfig_StripeConfig {
  __typename: "StripeConfig";
  systemId: string;
  /**
   * Stripe secret API key. The key is obfuscated.
   * https: // stripe.com/docs/keys
   */
  secretKey: string;
  /**
   * Stripe publishable API key
   * https: // stripe.com/docs/keys
   */
  publishableKey: string;
}

export interface updateStripeConfigMutation_updateStripeConfig_PaymentConfigError {
  __typename: "PaymentConfigError";
  message: string;
  description: string;
  errorCode: PaymentConfigErrorCode;
}

export type updateStripeConfigMutation_updateStripeConfig = updateStripeConfigMutation_updateStripeConfig_StripeConfig | updateStripeConfigMutation_updateStripeConfig_PaymentConfigError;

export interface updateStripeConfigMutation {
  updateStripeConfig: updateStripeConfigMutation_updateStripeConfig;
}

export interface updateStripeConfigMutationVariables {
  input: UpdateStripeConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createStripeConfigMutation
// ====================================================

export interface createStripeConfigMutation_createStripeConfig_StripeConfig {
  __typename: "StripeConfig";
  systemId: string;
  /**
   * Stripe secret API key. The key is obfuscated.
   * https: // stripe.com/docs/keys
   */
  secretKey: string;
  /**
   * Stripe publishable API key
   * https: // stripe.com/docs/keys
   */
  publishableKey: string;
}

export interface createStripeConfigMutation_createStripeConfig_PaymentConfigError {
  __typename: "PaymentConfigError";
  message: string;
  description: string;
  errorCode: PaymentConfigErrorCode;
}

export type createStripeConfigMutation_createStripeConfig = createStripeConfigMutation_createStripeConfig_StripeConfig | createStripeConfigMutation_createStripeConfig_PaymentConfigError;

export interface createStripeConfigMutation {
  createStripeConfig: createStripeConfigMutation_createStripeConfig;
}

export interface createStripeConfigMutationVariables {
  input: CreateStripeConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteStripeConfigMutation
// ====================================================

export interface deleteStripeConfigMutation_deleteStripeConfig_ObjectDeleted {
  __typename: "ObjectDeleted";
  id: string;
}

export interface deleteStripeConfigMutation_deleteStripeConfig_PaymentConfigError {
  __typename: "PaymentConfigError";
  message: string;
  description: string;
  errorCode: PaymentConfigErrorCode;
}

export type deleteStripeConfigMutation_deleteStripeConfig = deleteStripeConfigMutation_deleteStripeConfig_ObjectDeleted | deleteStripeConfigMutation_deleteStripeConfig_PaymentConfigError;

export interface deleteStripeConfigMutation {
  deleteStripeConfig: deleteStripeConfigMutation_deleteStripeConfig | null;
}

export interface deleteStripeConfigMutationVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemPaymentProvidersQuery
// ====================================================

export interface systemPaymentProvidersQuery_system_paymentConfigs_stripe {
  __typename: "StripeConfig";
  /**
   * Stripe publishable API key
   * https: // stripe.com/docs/keys
   */
  publishableKey: string;
  /**
   * Stripe secret API key. The key is obfuscated.
   * https: // stripe.com/docs/keys
   */
  secretKey: string;
}

export interface systemPaymentProvidersQuery_system_paymentConfigs_payex {
  __typename: "PayexConfig";
  token: string;
}

export interface systemPaymentProvidersQuery_system_paymentConfigs {
  __typename: "PaymentProviders";
  stripe: systemPaymentProvidersQuery_system_paymentConfigs_stripe | null;
  payex: systemPaymentProvidersQuery_system_paymentConfigs_payex | null;
}

export interface systemPaymentProvidersQuery_system {
  __typename: "System";
  id: string;
  /**
   * Default payment provider
   */
  paymentProvider: PaymentProviderEnum | null;
  paymentConfigs: systemPaymentProvidersQuery_system_paymentConfigs | null;
}

export interface systemPaymentProvidersQuery {
  system: systemPaymentProvidersQuery_system;
}

export interface systemPaymentProvidersQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSeasonMutation
// ====================================================

export interface createSeasonMutation_createSeason {
  __typename: "Season";
  id: string;
  systemId: string;
  startDate: CORE_DateTime;
  endDate: CORE_DateTime | null;
  state: SeasonState;
  startDateOnly: CORE_Date;
  endDateOnly: CORE_Date | null;
}

export interface createSeasonMutation {
  createSeason: createSeasonMutation_createSeason | null;
}

export interface createSeasonMutationVariables {
  input?: SeasonInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSeasonMutation
// ====================================================

export interface updateSeasonMutation_updateSeason {
  __typename: "Season";
  id: string;
  systemId: string;
  startDate: CORE_DateTime;
  endDate: CORE_DateTime | null;
  state: SeasonState;
  startDateOnly: CORE_Date;
  endDateOnly: CORE_Date | null;
}

export interface updateSeasonMutation {
  updateSeason: updateSeasonMutation_updateSeason | null;
}

export interface updateSeasonMutationVariables {
  id: string;
  input?: SeasonInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSeasonMutation
// ====================================================

export interface deleteSeasonMutation_deleteSeason {
  __typename: "Season";
  id: string;
  systemId: string;
  startDate: CORE_DateTime;
  endDate: CORE_DateTime | null;
  state: SeasonState;
  startDateOnly: CORE_Date;
  endDateOnly: CORE_Date | null;
}

export interface deleteSeasonMutation {
  deleteSeason: deleteSeasonMutation_deleteSeason | null;
}

export interface deleteSeasonMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: previewSeasonQuery
// ====================================================

export interface previewSeasonQuery_previewSeason {
  __typename: "SeasonPreview";
  startDate: CORE_DateTime;
  endDate: CORE_DateTime | null;
  state: SeasonState;
}

export interface previewSeasonQuery {
  previewSeason: previewSeasonQuery_previewSeason;
}

export interface previewSeasonQueryVariables {
  input: SeasonInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemSleepTimetable
// ====================================================

export interface systemSleepTimetable_system_sleepTimetable {
  __typename: "SleepTimetable";
  id: string;
  startTime: CORE_Time;
  endTime: CORE_Time;
  name: string | null;
}

export interface systemSleepTimetable_system {
  __typename: "System";
  id: string;
  sleepTimetable: systemSleepTimetable_system_sleepTimetable | null;
  timezone: string;
}

export interface systemSleepTimetable {
  system: systemSleepTimetable_system;
}

export interface systemSleepTimetableVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: twilioVerifyRateLimitsQuery
// ====================================================

export interface twilioVerifyRateLimitsQuery_twilioVerifyRateLimits_TwilioVerifyRateLimitDisabledListItem {
  __typename: "TwilioVerifyRateLimitDisabledListItem";
  internalDescription: string;
  internalUniqueName: TwilioVerifyRateLimitUniqueName;
}

export interface twilioVerifyRateLimitsQuery_twilioVerifyRateLimits_TwilioVerifyRateLimitEnabledListItem {
  __typename: "TwilioVerifyRateLimitEnabledListItem";
  sid: string;
  description: string | null;
  uniqueName: string;
  dateCreated: CORE_IsoDateTime;
  dateUpdated: CORE_IsoDateTime;
}

export type twilioVerifyRateLimitsQuery_twilioVerifyRateLimits = twilioVerifyRateLimitsQuery_twilioVerifyRateLimits_TwilioVerifyRateLimitDisabledListItem | twilioVerifyRateLimitsQuery_twilioVerifyRateLimits_TwilioVerifyRateLimitEnabledListItem;

export interface twilioVerifyRateLimitsQuery {
  /**
   * List all Twilio Verify rate limits for a system.
   * Read more about rate limits: https: // www.twilio.com/docs/verify/api/programmable-rate-limits
   */
  twilioVerifyRateLimits: twilioVerifyRateLimitsQuery_twilioVerifyRateLimits[];
}

export interface twilioVerifyRateLimitsQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRateLimitMutation
// ====================================================

export interface createRateLimitMutation_createTwilioVerifyRateLimit {
  __typename: "TwilioVerifyRateLimit";
  sid: string;
  description: string | null;
  uniqueName: string;
  dateCreated: CORE_IsoDateTime;
  dateUpdated: CORE_IsoDateTime;
}

export interface createRateLimitMutation {
  /**
   * Create a Twilio Verify rate limit
   */
  createTwilioVerifyRateLimit: createRateLimitMutation_createTwilioVerifyRateLimit;
}

export interface createRateLimitMutationVariables {
  systemId: string;
  uniqueName: TwilioVerifyRateLimitUniqueName;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRateLimitMutation
// ====================================================

export interface deleteRateLimitMutation {
  /**
   * Delete a Twilio Verify rate limit
   */
  deleteTwilioVerifyRateLimit: string;
}

export interface deleteRateLimitMutationVariables {
  systemId: string;
  sid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: twilioVerifyRateLimitQuery
// ====================================================

export interface twilioVerifyRateLimitQuery_twilioVerifyRateLimit_buckets {
  __typename: "TwilioVerifyRateLimitBucket";
  sid: string;
  /**
   * Number of seconds that the rate limit will be enforced over.
   */
  interval: number;
  /**
   * Maximum number of requests permitted in during the interval.
   */
  max: number;
  dateCreated: CORE_IsoDateTime;
  dateUpdated: CORE_IsoDateTime;
}

export interface twilioVerifyRateLimitQuery_twilioVerifyRateLimit {
  __typename: "TwilioVerifyRateLimit";
  sid: string;
  buckets: twilioVerifyRateLimitQuery_twilioVerifyRateLimit_buckets[];
}

export interface twilioVerifyRateLimitQuery {
  /**
   * Fetch a Twilio Verify rate limit by sid
   */
  twilioVerifyRateLimit: twilioVerifyRateLimitQuery_twilioVerifyRateLimit | null;
}

export interface twilioVerifyRateLimitQueryVariables {
  systemId: string;
  sid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRateLimitBucketMutation
// ====================================================

export interface createRateLimitBucketMutation_createTwilioVerifyRateLimitBucket {
  __typename: "TwilioVerifyRateLimitBucket";
  sid: string;
  /**
   * Number of seconds that the rate limit will be enforced over.
   */
  interval: number;
  /**
   * Maximum number of requests permitted in during the interval.
   */
  max: number;
  dateCreated: CORE_IsoDateTime;
  dateUpdated: CORE_IsoDateTime;
}

export interface createRateLimitBucketMutation {
  /**
   * Create a Twilio Verify rate limit bucket
   */
  createTwilioVerifyRateLimitBucket: createRateLimitBucketMutation_createTwilioVerifyRateLimitBucket;
}

export interface createRateLimitBucketMutationVariables {
  systemId: string;
  rateLimitSid: string;
  max: number;
  interval: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRateLimitBucketMutation
// ====================================================

export interface updateRateLimitBucketMutation_updateTwilioVerifyRateLimitBucket {
  __typename: "TwilioVerifyRateLimitBucket";
  sid: string;
  /**
   * Number of seconds that the rate limit will be enforced over.
   */
  interval: number;
  /**
   * Maximum number of requests permitted in during the interval.
   */
  max: number;
  dateCreated: CORE_IsoDateTime;
  dateUpdated: CORE_IsoDateTime;
}

export interface updateRateLimitBucketMutation {
  /**
   * Update a Twilio Verify rate limit bucket
   */
  updateTwilioVerifyRateLimitBucket: updateRateLimitBucketMutation_updateTwilioVerifyRateLimitBucket;
}

export interface updateRateLimitBucketMutationVariables {
  systemId: string;
  rateLimitSid: string;
  sid: string;
  max: number;
  interval: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRateLimitBucketMutation
// ====================================================

export interface deleteRateLimitBucketMutation {
  /**
   * Delete a Twilio Verify rate limit bucket.
   */
  deleteTwilioVerifyRateLimitBucket: string;
}

export interface deleteRateLimitBucketMutationVariables {
  systemId: string;
  rateLimitSid: string;
  sid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemAlert
// ====================================================

export interface systemAlert_systemAlert_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface systemAlert_systemAlert_resolvedBy {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface systemAlert_systemAlert_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
  state: DockGroupState | null;
}

export interface systemAlert_systemAlert_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface systemAlert_systemAlert_description {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface systemAlert_systemAlert_slug {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface systemAlert_systemAlert_updates_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface systemAlert_systemAlert_updates_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface systemAlert_systemAlert_updates_description {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface systemAlert_systemAlert_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  administrator: systemAlert_systemAlert_updates_administrator;
  title: systemAlert_systemAlert_updates_title[] | null;
  description: systemAlert_systemAlert_updates_description[] | null;
}

export interface systemAlert_systemAlert {
  __typename: "SystemAlert";
  id: string;
  systemId: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  deletedAt: CORE_IsoDateTime | null;
  sanityId: string;
  isDismissible: boolean | null;
  administrator: systemAlert_systemAlert_administrator;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: systemAlert_systemAlert_resolvedBy | null;
  dockGroups: (systemAlert_systemAlert_dockGroups | null)[] | null;
  systemAlertType: SystemAlertType;
  visibility: SystemAlertVisibility;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  publishedStartTime: CORE_IsoDateTime | null;
  publishedEndTime: CORE_IsoDateTime | null;
  title: systemAlert_systemAlert_title[] | null;
  description: systemAlert_systemAlert_description[] | null;
  slug: systemAlert_systemAlert_slug[] | null;
  updates: (systemAlert_systemAlert_updates | null)[] | null;
}

export interface systemAlert {
  systemAlert: systemAlert_systemAlert | null;
}

export interface systemAlertVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemAlertFilters
// ====================================================

export interface systemAlertFilters_types_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface systemAlertFilters_types {
  __typename: "__Type";
  enumValues: systemAlertFilters_types_enumValues[] | null;
}

export interface systemAlertFilters_timespans_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface systemAlertFilters_timespans {
  __typename: "__Type";
  enumValues: systemAlertFilters_timespans_enumValues[] | null;
}

export interface systemAlertFilters_visibilities_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface systemAlertFilters_visibilities {
  __typename: "__Type";
  enumValues: systemAlertFilters_visibilities_enumValues[] | null;
}

export interface systemAlertFilters {
  types: systemAlertFilters_types | null;
  timespans: systemAlertFilters_timespans | null;
  visibilities: systemAlertFilters_visibilities | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemAlertFormMeta
// ====================================================

export interface systemAlertFormMeta_dockGroupsConnection_edges_node {
  __typename: "DockGroupListItem";
  id: string;
  title: string | null;
}

export interface systemAlertFormMeta_dockGroupsConnection_edges {
  __typename: "DockGroupEdge";
  node: systemAlertFormMeta_dockGroupsConnection_edges_node;
}

export interface systemAlertFormMeta_dockGroupsConnection {
  __typename: "DockGroupsConnection";
  edges: systemAlertFormMeta_dockGroupsConnection_edges[];
}

export interface systemAlertFormMeta_types_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface systemAlertFormMeta_types {
  __typename: "__Type";
  enumValues: systemAlertFormMeta_types_enumValues[] | null;
}

export interface systemAlertFormMeta_visibilities_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface systemAlertFormMeta_visibilities {
  __typename: "__Type";
  enumValues: systemAlertFormMeta_visibilities_enumValues[] | null;
}

export interface systemAlertFormMeta {
  dockGroupsConnection: systemAlertFormMeta_dockGroupsConnection | null;
  types: systemAlertFormMeta_types | null;
  visibilities: systemAlertFormMeta_visibilities | null;
}

export interface systemAlertFormMetaVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemAlertConnection
// ====================================================

export interface systemAlertConnection_systemAlertConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface systemAlertConnection_systemAlertConnection_edges_node_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface systemAlertConnection_systemAlertConnection_edges_node_resolvedBy {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface systemAlertConnection_systemAlertConnection_edges_node_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface systemAlertConnection_systemAlertConnection_edges_node_updates_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface systemAlertConnection_systemAlertConnection_edges_node_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  title: systemAlertConnection_systemAlertConnection_edges_node_updates_title[] | null;
}

export interface systemAlertConnection_systemAlertConnection_edges_node {
  __typename: "SystemAlert";
  id: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  isDismissible: boolean | null;
  administrator: systemAlertConnection_systemAlertConnection_edges_node_administrator;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: systemAlertConnection_systemAlertConnection_edges_node_resolvedBy | null;
  systemAlertType: SystemAlertType;
  visibility: SystemAlertVisibility;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  publishedStartTime: CORE_IsoDateTime | null;
  publishedEndTime: CORE_IsoDateTime | null;
  title: systemAlertConnection_systemAlertConnection_edges_node_title[] | null;
  updates: (systemAlertConnection_systemAlertConnection_edges_node_updates | null)[] | null;
}

export interface systemAlertConnection_systemAlertConnection_edges {
  __typename: "SystemAlertEdge";
  cursor: string;
  node: systemAlertConnection_systemAlertConnection_edges_node;
}

export interface systemAlertConnection_systemAlertConnection {
  __typename: "SystemAlertConnection";
  pageInfo: systemAlertConnection_systemAlertConnection_pageInfo;
  edges: systemAlertConnection_systemAlertConnection_edges[];
}

export interface systemAlertConnection {
  systemAlertConnection: systemAlertConnection_systemAlertConnection | null;
}

export interface systemAlertConnectionVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSystemAlert
// ====================================================

export interface createSystemAlert_createSystemAlert {
  __typename: "SystemAlert";
  id: string;
}

export interface createSystemAlert {
  createSystemAlert: createSystemAlert_createSystemAlert | null;
}

export interface createSystemAlertVariables {
  input: CreateSystemAlertInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSystemAlert
// ====================================================

export interface updateSystemAlert_updateSystemAlert {
  __typename: "SystemAlert";
  id: string;
}

export interface updateSystemAlert {
  updateSystemAlert: updateSystemAlert_updateSystemAlert | null;
}

export interface updateSystemAlertVariables {
  id: string;
  input: UpdateSystemAlertInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSystemAlert
// ====================================================

export interface deleteSystemAlert_deleteSystemAlert {
  __typename: "SystemAlert";
  id: string;
}

export interface deleteSystemAlert {
  deleteSystemAlert: deleteSystemAlert_deleteSystemAlert | null;
}

export interface deleteSystemAlertVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resolveSystemAlert
// ====================================================

export interface resolveSystemAlert_resolveSystemAlert {
  __typename: "SystemAlert";
  id: string;
}

export interface resolveSystemAlert {
  resolveSystemAlert: resolveSystemAlert_resolveSystemAlert | null;
}

export interface resolveSystemAlertVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unresolveSystemAlert
// ====================================================

export interface unresolveSystemAlert_unresolveSystemAlert {
  __typename: "SystemAlert";
  id: string;
}

export interface unresolveSystemAlert {
  unresolveSystemAlert: unresolveSystemAlert_unresolveSystemAlert | null;
}

export interface unresolveSystemAlertVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSystemAlertUpdate
// ====================================================

export interface createSystemAlertUpdate_createSystemAlertUpdate {
  __typename: "SystemAlertUpdate";
  id: string;
}

export interface createSystemAlertUpdate {
  createSystemAlertUpdate: createSystemAlertUpdate_createSystemAlertUpdate | null;
}

export interface createSystemAlertUpdateVariables {
  input: SystemAlertUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSystemAlertUpdate
// ====================================================

export interface updateSystemAlertUpdate_updateSystemAlertUpdate {
  __typename: "SystemAlertUpdate";
  id: string;
}

export interface updateSystemAlertUpdate {
  updateSystemAlertUpdate: updateSystemAlertUpdate_updateSystemAlertUpdate | null;
}

export interface updateSystemAlertUpdateVariables {
  id: string;
  input: SystemAlertUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSystemAlertUpdate
// ====================================================

export interface deleteSystemAlertUpdate_deleteSystemAlertUpdate {
  __typename: "SystemAlertUpdate";
  id: string;
}

export interface deleteSystemAlertUpdate {
  deleteSystemAlertUpdate: deleteSystemAlertUpdate_deleteSystemAlertUpdate | null;
}

export interface deleteSystemAlertUpdateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: administratorView
// ====================================================

export interface administratorView_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface administratorView {
  administrator: administratorView_administrator;
}

export interface administratorViewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rfidAuth
// ====================================================

export interface rfidAuth_rfidAuth_administrator {
  __typename: "Administrator";
  email: string | null;
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface rfidAuth_rfidAuth {
  __typename: "RfidAuth";
  id: string;
  enabled: boolean;
  rfid: string;
  systemId: string;
  action: RfidAuthAction;
  parameters: string[] | null;
  administrator: rfidAuth_rfidAuth_administrator | null;
}

export interface rfidAuth {
  rfidAuth: rfidAuth_rfidAuth | null;
}

export interface rfidAuthVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rfidAuthConnection
// ====================================================

export interface rfidAuthConnection_rfidAuthConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface rfidAuthConnection_rfidAuthConnection_edges_node_administrator {
  __typename: "Administrator";
  email: string | null;
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface rfidAuthConnection_rfidAuthConnection_edges_node {
  __typename: "RfidAuth";
  id: string;
  enabled: boolean;
  rfid: string;
  systemId: string;
  action: RfidAuthAction;
  parameters: string[] | null;
  administrator: rfidAuthConnection_rfidAuthConnection_edges_node_administrator | null;
}

export interface rfidAuthConnection_rfidAuthConnection_edges {
  __typename: "RfidAuthEdge";
  node: rfidAuthConnection_rfidAuthConnection_edges_node;
}

export interface rfidAuthConnection_rfidAuthConnection {
  __typename: "RfidAuthConnection";
  pageInfo: rfidAuthConnection_rfidAuthConnection_pageInfo;
  edges: rfidAuthConnection_rfidAuthConnection_edges[];
}

export interface rfidAuthConnection {
  rfidAuthConnection: rfidAuthConnection_rfidAuthConnection | null;
}

export interface rfidAuthConnectionVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: role
// ====================================================

export interface role_role_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface role_role {
  __typename: "Role";
  id: string;
  name: string;
  description: string | null;
  systemId: string | null;
  acl: role_role_acl[];
}

export interface role {
  role: role_role | null;
}

export interface roleVariables {
  id: string;
  systemId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tripHistoryQuery
// ====================================================

export interface tripHistoryQuery_trip_history_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface tripHistoryQuery_trip_history_vehicleAvailabilityDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface tripHistoryQuery_trip_history_vehicleAvailabilityDock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  type: DockType;
  dockGroup: tripHistoryQuery_trip_history_vehicleAvailabilityDock_dockGroup | null;
}

export interface tripHistoryQuery_trip_history_vehicleAvailabilityLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface tripHistoryQuery_trip_history_vehicleAvailabilityLocation {
  __typename: "VehicleLocation";
  location: tripHistoryQuery_trip_history_vehicleAvailabilityLocation_location;
  address: string | null;
}

export interface tripHistoryQuery_trip_history_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface tripHistoryQuery_trip_history_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface tripHistoryQuery_trip_history_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type tripHistoryQuery_trip_history_vehicle_displayName = tripHistoryQuery_trip_history_vehicle_displayName_VehicleDisplayNamed | tripHistoryQuery_trip_history_vehicle_displayName_VehicleDisplayNumbered | tripHistoryQuery_trip_history_vehicle_displayName_VehicleDisplayAnonymous;

export interface tripHistoryQuery_trip_history_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface tripHistoryQuery_trip_history_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: tripHistoryQuery_trip_history_vehicle_displayName;
  model: tripHistoryQuery_trip_history_vehicle_model | null;
}

export interface tripHistoryQuery_trip_history_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface tripHistoryQuery_trip_history_trip_startDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: tripHistoryQuery_trip_history_trip_startDock_dockGroup | null;
}

export interface tripHistoryQuery_trip_history_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface tripHistoryQuery_trip_history_trip_endDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: tripHistoryQuery_trip_history_trip_endDock_dockGroup | null;
}

export interface tripHistoryQuery_trip_history_trip_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface tripHistoryQuery_trip_history_trip_startLocation {
  __typename: "VehicleLocation";
  location: tripHistoryQuery_trip_history_trip_startLocation_location;
  address: string | null;
}

export interface tripHistoryQuery_trip_history_trip_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface tripHistoryQuery_trip_history_trip_endLocation {
  __typename: "VehicleLocation";
  location: tripHistoryQuery_trip_history_trip_endLocation_location;
  address: string | null;
}

export interface tripHistoryQuery_trip_history_trip_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface tripHistoryQuery_trip_history_trip_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface tripHistoryQuery_trip_history_trip_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: tripHistoryQuery_trip_history_trip_entityTags_tag;
}

export interface tripHistoryQuery_trip_history_trip_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface tripHistoryQuery_trip_history_trip_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface tripHistoryQuery_trip_history_trip_order {
  __typename: "Order";
  id: string;
  amount: CORE_Money;
}

export interface tripHistoryQuery_trip_history_trip {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: tripHistoryQuery_trip_history_trip_startDock | null;
  endDock: tripHistoryQuery_trip_history_trip_endDock | null;
  startLocation: tripHistoryQuery_trip_history_trip_startLocation | null;
  endLocation: tripHistoryQuery_trip_history_trip_endLocation | null;
  subscription: tripHistoryQuery_trip_history_trip_subscription | null;
  entityTags: tripHistoryQuery_trip_history_trip_entityTags[] | null;
  duration: number | null;
  userAgent: tripHistoryQuery_trip_history_trip_userAgent | null;
  cancelledByAdmin: tripHistoryQuery_trip_history_trip_cancelledByAdmin | null;
  order: tripHistoryQuery_trip_history_trip_order | null;
  cancelledReason: string | null;
}

export interface tripHistoryQuery_trip_history {
  __typename: "VehicleAvailability";
  id: string;
  systemId: string | null;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  administrator: tripHistoryQuery_trip_history_administrator | null;
  vehicleAvailabilityDock: tripHistoryQuery_trip_history_vehicleAvailabilityDock | null;
  vehicleAvailabilityLocation: tripHistoryQuery_trip_history_vehicleAvailabilityLocation | null;
  vehicle: tripHistoryQuery_trip_history_vehicle;
  trip: tripHistoryQuery_trip_history_trip | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
}

export interface tripHistoryQuery_trip {
  __typename: "Trip";
  id: string;
  history: tripHistoryQuery_trip_history[] | null;
}

export interface tripHistoryQuery {
  trip: tripHistoryQuery_trip | null;
}

export interface tripHistoryQueryVariables {
  systemId: string;
  id: string;
  skipVehicle: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTripCancellationReason
// ====================================================

export interface updateTripCancellationReason_updateTripCancellationReason {
  __typename: "Trip";
  id: string;
  cancelledReason: string | null;
}

export interface updateTripCancellationReason {
  updateTripCancellationReason: updateTripCancellationReason_updateTripCancellationReason | null;
}

export interface updateTripCancellationReasonVariables {
  tripId: string;
  cancelledReason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tripsConnection
// ====================================================

export interface tripsConnection_tripsConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface tripsConnection_tripsConnection_edges_node_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface tripsConnection_tripsConnection_edges_node_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface tripsConnection_tripsConnection_edges_node_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: tripsConnection_tripsConnection_edges_node_startDock_dockGroup | null;
}

export interface tripsConnection_tripsConnection_edges_node_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface tripsConnection_tripsConnection_edges_node_startLocation {
  __typename: "VehicleLocation";
  location: tripsConnection_tripsConnection_edges_node_startLocation_location;
  address: string | null;
}

export interface tripsConnection_tripsConnection_edges_node_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface tripsConnection_tripsConnection_edges_node_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: tripsConnection_tripsConnection_edges_node_endDock_dockGroup | null;
}

export interface tripsConnection_tripsConnection_edges_node_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface tripsConnection_tripsConnection_edges_node_endLocation {
  __typename: "VehicleLocation";
  location: tripsConnection_tripsConnection_edges_node_endLocation_location;
  address: string | null;
}

export interface tripsConnection_tripsConnection_edges_node_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface tripsConnection_tripsConnection_edges_node_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface tripsConnection_tripsConnection_edges_node_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type tripsConnection_tripsConnection_edges_node_vehicle_displayName = tripsConnection_tripsConnection_edges_node_vehicle_displayName_VehicleDisplayNamed | tripsConnection_tripsConnection_edges_node_vehicle_displayName_VehicleDisplayNumbered | tripsConnection_tripsConnection_edges_node_vehicle_displayName_VehicleDisplayAnonymous;

export interface tripsConnection_tripsConnection_edges_node_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface tripsConnection_tripsConnection_edges_node_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: tripsConnection_tripsConnection_edges_node_vehicle_displayName;
  model: tripsConnection_tripsConnection_edges_node_vehicle_model | null;
}

export interface tripsConnection_tripsConnection_edges_node_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface tripsConnection_tripsConnection_edges_node_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: tripsConnection_tripsConnection_edges_node_entityTags_tag;
}

export interface tripsConnection_tripsConnection_edges_node {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  subscription: tripsConnection_tripsConnection_edges_node_subscription | null;
  startDock: tripsConnection_tripsConnection_edges_node_startDock | null;
  startLocation: tripsConnection_tripsConnection_edges_node_startLocation | null;
  endDock: tripsConnection_tripsConnection_edges_node_endDock | null;
  endLocation: tripsConnection_tripsConnection_edges_node_endLocation | null;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
  vehicle: tripsConnection_tripsConnection_edges_node_vehicle | null;
  /**
   * If false, trip startedAt/endedAt timestamps are not reliable and we should not create an order for such trip
   */
  accurateTimestamps: boolean | null;
  cancelledReason: string | null;
  entityTags: tripsConnection_tripsConnection_edges_node_entityTags[] | null;
}

export interface tripsConnection_tripsConnection_edges {
  __typename: "TripEdge";
  cursor: string;
  node: tripsConnection_tripsConnection_edges_node;
}

export interface tripsConnection_tripsConnection {
  __typename: "TripsConnection";
  pageInfo: tripsConnection_tripsConnection_pageInfo;
  edges: tripsConnection_tripsConnection_edges[];
}

export interface tripsConnection {
  tripsConnection: tripsConnection_tripsConnection | null;
}

export interface tripsConnectionVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  complexFilters?: (ComplexFilter | null)[] | null;
  tripsConnectionFilters?: TripsConnectionFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tripsFilters
// ====================================================

export interface tripsFilters_tripStates_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface tripsFilters_tripStates {
  __typename: "__Type";
  name: string | null;
  enumValues: tripsFilters_tripStates_enumValues[] | null;
}

export interface tripsFilters {
  tripStates: tripsFilters_tripStates | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paginatedTripsQuery
// ====================================================

export interface paginatedTripsQuery_paginatedTrips_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface paginatedTripsQuery_paginatedTrips_rows_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface paginatedTripsQuery_paginatedTrips_rows_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface paginatedTripsQuery_paginatedTrips_rows_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: paginatedTripsQuery_paginatedTrips_rows_startDock_dockGroup | null;
}

export interface paginatedTripsQuery_paginatedTrips_rows_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface paginatedTripsQuery_paginatedTrips_rows_startLocation {
  __typename: "VehicleLocation";
  location: paginatedTripsQuery_paginatedTrips_rows_startLocation_location;
  address: string | null;
}

export interface paginatedTripsQuery_paginatedTrips_rows_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface paginatedTripsQuery_paginatedTrips_rows_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: paginatedTripsQuery_paginatedTrips_rows_endDock_dockGroup | null;
}

export interface paginatedTripsQuery_paginatedTrips_rows_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface paginatedTripsQuery_paginatedTrips_rows_endLocation {
  __typename: "VehicleLocation";
  location: paginatedTripsQuery_paginatedTrips_rows_endLocation_location;
  address: string | null;
}

export interface paginatedTripsQuery_paginatedTrips_rows_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface paginatedTripsQuery_paginatedTrips_rows_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface paginatedTripsQuery_paginatedTrips_rows_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type paginatedTripsQuery_paginatedTrips_rows_vehicle_displayName = paginatedTripsQuery_paginatedTrips_rows_vehicle_displayName_VehicleDisplayNamed | paginatedTripsQuery_paginatedTrips_rows_vehicle_displayName_VehicleDisplayNumbered | paginatedTripsQuery_paginatedTrips_rows_vehicle_displayName_VehicleDisplayAnonymous;

export interface paginatedTripsQuery_paginatedTrips_rows_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface paginatedTripsQuery_paginatedTrips_rows_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: paginatedTripsQuery_paginatedTrips_rows_vehicle_displayName;
  model: paginatedTripsQuery_paginatedTrips_rows_vehicle_model | null;
}

export interface paginatedTripsQuery_paginatedTrips_rows_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface paginatedTripsQuery_paginatedTrips_rows_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: paginatedTripsQuery_paginatedTrips_rows_entityTags_tag;
}

export interface paginatedTripsQuery_paginatedTrips_rows {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  subscription: paginatedTripsQuery_paginatedTrips_rows_subscription | null;
  startDock: paginatedTripsQuery_paginatedTrips_rows_startDock | null;
  startLocation: paginatedTripsQuery_paginatedTrips_rows_startLocation | null;
  endDock: paginatedTripsQuery_paginatedTrips_rows_endDock | null;
  endLocation: paginatedTripsQuery_paginatedTrips_rows_endLocation | null;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
  userRating: CORE_PositiveInt | null;
  vehicle: paginatedTripsQuery_paginatedTrips_rows_vehicle | null;
  /**
   * If false, trip startedAt/endedAt timestamps are not reliable and we should not create an order for such trip
   */
  accurateTimestamps: boolean | null;
  cancelledReason: string | null;
  entityTags: paginatedTripsQuery_paginatedTrips_rows_entityTags[] | null;
}

export interface paginatedTripsQuery_paginatedTrips {
  __typename: "PaginatedTrips";
  paginationInfo: paginatedTripsQuery_paginatedTrips_paginationInfo;
  rows: paginatedTripsQuery_paginatedTrips_rows[];
}

export interface paginatedTripsQuery {
  paginatedTrips: paginatedTripsQuery_paginatedTrips;
}

export interface paginatedTripsQueryVariables {
  systemId: string;
  pagination?: OffsetPagination | null;
  order?: TripOrder | null;
  filters?: TripFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTripExportMutation
// ====================================================

export interface createTripExportMutation_createTripDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_subscription {
  __typename: "ProductSubscription";
  subscriptionId: string;
  userId: string;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_startLocation_location {
  __typename: "Coord";
  startLocationLatitude: number;
  startLocationLongitude: number;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_startLocation {
  __typename: "VehicleLocation";
  location: createTripExportMutation_createTripDataExportRequest_trip_startLocation_location;
  startLocationAddress: string | null;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_startDock_dockGroup {
  __typename: "DockGroup";
  startStationId: string;
  startStationName: string | null;
  startStationTitle: string | null;
  startStationType: DockGroupType | null;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_startDock {
  __typename: "Dock";
  startDockId: string;
  dockGroup: createTripExportMutation_createTripDataExportRequest_trip_startDock_dockGroup | null;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_endLocation_location {
  __typename: "Coord";
  endLocationLatitude: number;
  endLocationLongitude: number;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_endLocation {
  __typename: "VehicleLocation";
  location: createTripExportMutation_createTripDataExportRequest_trip_endLocation_location;
  endLocationAddress: string | null;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_endDock_dockGroup {
  __typename: "DockGroup";
  endStationId: string;
  endStationName: string | null;
  endStationTitle: string | null;
  endStationType: DockGroupType | null;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_endDock {
  __typename: "Dock";
  endDockId: string;
  dockGroup: createTripExportMutation_createTripDataExportRequest_trip_endDock_dockGroup | null;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  vehicleName: string;
  vehicleNumber: string;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  vehicleNumber: string;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  vehicleId: string;
}

export type createTripExportMutation_createTripDataExportRequest_trip_vehicle_displayName = createTripExportMutation_createTripDataExportRequest_trip_vehicle_displayName_VehicleDisplayNamed | createTripExportMutation_createTripDataExportRequest_trip_vehicle_displayName_VehicleDisplayNumbered | createTripExportMutation_createTripDataExportRequest_trip_vehicle_displayName_VehicleDisplayAnonymous;

export interface createTripExportMutation_createTripDataExportRequest_trip_vehicle_model {
  __typename: "AssetModel";
  modelId: string;
  vehicleCategory: VehicleCategory | null;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_vehicle {
  __typename: "Vehicle";
  vehicleId: string;
  vehicleName: string | null;
  vehicleNumber: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: createTripExportMutation_createTripDataExportRequest_trip_vehicle_displayName;
  model: createTripExportMutation_createTripDataExportRequest_trip_vehicle_model | null;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface createTripExportMutation_createTripDataExportRequest_trip_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: createTripExportMutation_createTripDataExportRequest_trip_entityTags_tag;
}

export interface createTripExportMutation_createTripDataExportRequest_trip {
  __typename: "Trip";
  tripId: string;
  tripState: TripState | null;
  subscription: createTripExportMutation_createTripDataExportRequest_trip_subscription | null;
  startLocation: createTripExportMutation_createTripDataExportRequest_trip_startLocation | null;
  startDock: createTripExportMutation_createTripDataExportRequest_trip_startDock | null;
  endLocation: createTripExportMutation_createTripDataExportRequest_trip_endLocation | null;
  endDock: createTripExportMutation_createTripDataExportRequest_trip_endDock | null;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
  vehicle: createTripExportMutation_createTripDataExportRequest_trip_vehicle | null;
  /**
   * If false, trip startedAt/endedAt timestamps are not reliable and we should not create an order for such trip
   */
  accurateTimestamps: boolean | null;
  cancelledReason: string | null;
  entityTags: createTripExportMutation_createTripDataExportRequest_trip_entityTags[] | null;
}

export interface createTripExportMutation_createTripDataExportRequest {
  __typename: "CreateTripDataExportRequestResponse";
  dataExportRequest: createTripExportMutation_createTripDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual trip.
   */
  trip: createTripExportMutation_createTripDataExportRequest_trip | null;
}

export interface createTripExportMutation {
  createTripDataExportRequest: createTripExportMutation_createTripDataExportRequest;
}

export interface createTripExportMutationVariables {
  systemId: string;
  order?: TripOrder | null;
  filters?: TripFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createUserAgreementMutation
// ====================================================

export interface createUserAgreementMutation_createUserAgreement_translations {
  __typename: "UserAgreementTranslation";
  id: string;
  languageCode: LanguageCode;
  content: string;
  fileUrl: string;
}

export interface createUserAgreementMutation_createUserAgreement {
  __typename: "UserAgreement";
  id: string;
  name: string;
  type: UserAgreementType;
  revision: CORE_PositiveInt;
  translations: createUserAgreementMutation_createUserAgreement_translations[];
  isActive: boolean;
  createdAt: CORE_IsoDateTime;
}

export interface createUserAgreementMutation {
  createUserAgreement: createUserAgreementMutation_createUserAgreement;
}

export interface createUserAgreementMutationVariables {
  systemId: string;
  input: UserAgreementCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: agreementsQuery
// ====================================================

export interface agreementsQuery_userAgreements_translations {
  __typename: "UserAgreementTranslation";
  id: string;
  languageCode: LanguageCode;
  content: string;
  fileUrl: string;
  fileName: string;
}

export interface agreementsQuery_userAgreements {
  __typename: "UserAgreement";
  id: string;
  name: string;
  type: UserAgreementType;
  revision: CORE_PositiveInt;
  isActive: boolean;
  createdAt: CORE_IsoDateTime;
  accepts: CORE_NonNegativeInt;
  translations: agreementsQuery_userAgreements_translations[];
}

export interface agreementsQuery {
  userAgreements: agreementsQuery_userAgreements[];
}

export interface agreementsQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: activateAgreementMutation
// ====================================================

export interface activateAgreementMutation_activateUserAgreement {
  __typename: "UserAgreement";
  id: string;
  isActive: boolean;
  type: UserAgreementType;
}

export interface activateAgreementMutation {
  activateUserAgreement: activateAgreementMutation_activateUserAgreement;
}

export interface activateAgreementMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: exportActiveAgreementAccepts
// ====================================================

export interface exportActiveAgreementAccepts_createActiveAgreementAcceptsDataExport {
  __typename: "ActiveAgreementAcceptsDataExportResponse";
  csv: string;
  filename: string;
}

export interface exportActiveAgreementAccepts {
  /**
   * Export users who accepted active agreements for a system
   */
  createActiveAgreementAcceptsDataExport: exportActiveAgreementAccepts_createActiveAgreementAcceptsDataExport;
}

export interface exportActiveAgreementAcceptsVariables {
  systemId: string;
  agreementId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userPaginatedInvoicesQuery
// ====================================================

export interface userPaginatedInvoicesQuery_paginatedInvoices_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalRowCount: number;
}

export interface userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness {
  __typename: "InvoiceBusiness";
}

export interface userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  product: userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemTrip_product;
  trip: userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemTrip_trip;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  administrator: userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator;
  createdAt: CORE_IsoDateTime;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export type userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items = userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemValueCode | userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemTrip | userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items_InvoiceItemAdjustment;

export interface userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
  id: string;
  systemId: string;
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  description: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money;
  vatRate: number;
  awaitingManualPayment: boolean;
  paymentConfirmedById: string | null;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  canTransitToDraft: boolean;
  canTransitToVoid: boolean;
  canTransitToOpen: boolean;
  canTransitToPaid: boolean;
  canTransitToCollectionFailed: boolean;
  items: userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_items[] | null;
  user: userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip_user;
}

export type userPaginatedInvoicesQuery_paginatedInvoices_rows = userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceBusiness | userPaginatedInvoicesQuery_paginatedInvoices_rows_InvoiceUserTrip;

export interface userPaginatedInvoicesQuery_paginatedInvoices {
  __typename: "PaginatedInvoices";
  paginationInfo: userPaginatedInvoicesQuery_paginatedInvoices_paginationInfo;
  rows: userPaginatedInvoicesQuery_paginatedInvoices_rows[];
}

export interface userPaginatedInvoicesQuery {
  paginatedInvoices: userPaginatedInvoicesQuery_paginatedInvoices;
}

export interface userPaginatedInvoicesQueryVariables {
  systemId: string;
  pagination?: OffsetPagination | null;
  invoiceFilters?: InvoiceFilters | null;
  order?: InvoiceOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createInvoiceItemAdjustment
// ====================================================

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amount: CORE_Money;
  vatRate: number;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  vatRate: number;
  product: createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip_product;
  trip: createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip_trip;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
  vatRate: number;
  administrator: createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemAdjustment_administrator;
}

export type createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items = createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemValueCode | createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip | createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemAdjustment;

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness {
  __typename: "InvoiceBusiness";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  items: createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items[] | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  /**
   * If true an invoice must be manually approved as paid
   */
  awaitingManualPayment: boolean;
  paidAt: CORE_IsoDateTime | null;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amount: CORE_Money;
  vatRate: number;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  vatRate: number;
  product: createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip_product;
  trip: createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip_trip;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
  vatRate: number;
  administrator: createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator;
}

export type createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items = createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemValueCode | createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip | createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemAdjustment;

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_user {
  __typename: "User";
  id: string;
}

export interface createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  items: createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items[] | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  /**
   * If true an invoice must be manually approved as paid
   */
  awaitingManualPayment: boolean;
  paidAt: CORE_IsoDateTime | null;
  user: createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_user;
}

export type createInvoiceItemAdjustment_createInvoiceItemAdjustment = createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness | createInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip;

export interface createInvoiceItemAdjustment {
  createInvoiceItemAdjustment: createInvoiceItemAdjustment_createInvoiceItemAdjustment;
}

export interface createInvoiceItemAdjustmentVariables {
  systemId: string;
  invoiceId: string;
  input: CreateInvoiceItemAdjustmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invoiceDetailQuery
// ====================================================

export interface invoiceDetailQuery_invoice_InvoiceBusiness_company {
  __typename: "BusinessCompany";
  id: string;
  name: string;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  product: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemValueCode_product;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_startDock_dockGroup | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_startLocation {
  __typename: "VehicleLocation";
  location: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_startLocation_location;
  address: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_endDock_dockGroup | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_endLocation {
  __typename: "VehicleLocation";
  location: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_endLocation_location;
  address: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
  startDock: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_startDock | null;
  startLocation: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_startLocation | null;
  endDock: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_endDock | null;
  endLocation: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip_endLocation | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  product: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_product;
  trip: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip_trip;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  administrator: invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemAdjustment_administrator;
  createdAt: CORE_IsoDateTime;
  description: string | null;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export type invoiceDetailQuery_invoice_InvoiceBusiness_items = invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemValueCode | invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemTrip | invoiceDetailQuery_invoice_InvoiceBusiness_items_InvoiceItemAdjustment;

export interface invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentRefunds_refundedByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentRefunds {
  __typename: "PaymentRefund";
  id: string;
  processedAt: CORE_IsoDateTime;
  state: RefundState;
  amount: CORE_Money;
  failureReason: RefundFailureReason | null;
  refundReason: RefundReason;
  refundedByAdmin: invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentRefunds_refundedByAdmin | null;
  comment: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentMethod_Card {
  __typename: "Card";
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  brand: CardBrand;
  last4: string;
  expired: boolean;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
}

export type invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentMethod = invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentMethod_Blik | invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentMethod_Card;

export interface invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions {
  __typename: "PaymentTransaction";
  id: string;
  transactionNumber: string | null;
  status: PaymentTransactionStatus | null;
  failureReason: PaymentFailureReason | null;
  provider: PaymentTransactionProvider | null;
  amount: CORE_Money;
  amountToRefund: CORE_Money;
  amountRefunded: CORE_Money;
  createdAt: CORE_IsoDateTime;
  processedAt: CORE_IsoDateTime | null;
  refundable: boolean;
  paymentRefunds: invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentRefunds[] | null;
  paymentMethod: invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions_paymentMethod | null;
}

export interface invoiceDetailQuery_invoice_InvoiceBusiness {
  __typename: "InvoiceBusiness";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  description: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money;
  vatRate: number;
  /**
   * If true an invoice must be manually approved as paid
   */
  awaitingManualPayment: boolean;
  paymentConfirmedById: string | null;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  canTransitToDraft: boolean;
  canTransitToVoid: boolean;
  canTransitToOpen: boolean;
  canTransitToPaid: boolean;
  canTransitToCollectionFailed: boolean;
  company: invoiceDetailQuery_invoice_InvoiceBusiness_company;
  items: invoiceDetailQuery_invoice_InvoiceBusiness_items[] | null;
  paymentTransactions: invoiceDetailQuery_invoice_InvoiceBusiness_paymentTransactions[];
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  product: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemValueCode_product;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_startDock_dockGroup | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_startLocation {
  __typename: "VehicleLocation";
  location: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_startLocation_location;
  address: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_endDock_dockGroup | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_endLocation {
  __typename: "VehicleLocation";
  location: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_endLocation_location;
  address: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
  startDock: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_startDock | null;
  startLocation: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_startLocation | null;
  endDock: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_endDock | null;
  endLocation: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip_endLocation | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  product: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_product;
  trip: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip_trip;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  administrator: invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator;
  createdAt: CORE_IsoDateTime;
  description: string | null;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export type invoiceDetailQuery_invoice_InvoiceUserTrip_items = invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemValueCode | invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemTrip | invoiceDetailQuery_invoice_InvoiceUserTrip_items_InvoiceItemAdjustment;

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentRefunds_refundedByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentRefunds {
  __typename: "PaymentRefund";
  id: string;
  processedAt: CORE_IsoDateTime;
  state: RefundState;
  amount: CORE_Money;
  failureReason: RefundFailureReason | null;
  refundReason: RefundReason;
  refundedByAdmin: invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentRefunds_refundedByAdmin | null;
  comment: string | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentMethod_Card {
  __typename: "Card";
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  brand: CardBrand;
  last4: string;
  expired: boolean;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
}

export type invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentMethod = invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentMethod_Blik | invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentMethod_Card;

export interface invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions {
  __typename: "PaymentTransaction";
  id: string;
  transactionNumber: string | null;
  status: PaymentTransactionStatus | null;
  failureReason: PaymentFailureReason | null;
  provider: PaymentTransactionProvider | null;
  amount: CORE_Money;
  amountToRefund: CORE_Money;
  amountRefunded: CORE_Money;
  createdAt: CORE_IsoDateTime;
  processedAt: CORE_IsoDateTime | null;
  refundable: boolean;
  paymentRefunds: invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentRefunds[] | null;
  paymentMethod: invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions_paymentMethod | null;
}

export interface invoiceDetailQuery_invoice_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  description: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money;
  vatRate: number;
  /**
   * If true an invoice must be manually approved as paid
   */
  awaitingManualPayment: boolean;
  paymentConfirmedById: string | null;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  canTransitToDraft: boolean;
  canTransitToVoid: boolean;
  canTransitToOpen: boolean;
  canTransitToPaid: boolean;
  canTransitToCollectionFailed: boolean;
  items: invoiceDetailQuery_invoice_InvoiceUserTrip_items[] | null;
  paymentTransactions: invoiceDetailQuery_invoice_InvoiceUserTrip_paymentTransactions[];
  userId: string;
}

export type invoiceDetailQuery_invoice = invoiceDetailQuery_invoice_InvoiceBusiness | invoiceDetailQuery_invoice_InvoiceUserTrip;

export interface invoiceDetailQuery {
  invoice: invoiceDetailQuery_invoice | null;
}

export interface invoiceDetailQueryVariables {
  systemId: string;
  invoiceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: refundPaymentTransaction
// ====================================================

export interface refundPaymentTransaction_refundPaymentTransaction_refundedByAdmin {
  __typename: "Administrator";
  id: string;
}

export interface refundPaymentTransaction_refundPaymentTransaction {
  __typename: "PaymentRefund";
  id: string;
  updatedAt: CORE_IsoDateTime;
  createdAt: CORE_IsoDateTime;
  processedAt: CORE_IsoDateTime;
  refundRef: string;
  state: RefundState;
  amount: CORE_Money;
  failureReason: RefundFailureReason | null;
  refundReason: RefundReason;
  refundedBy: string | null;
  refundedByAdmin: refundPaymentTransaction_refundPaymentTransaction_refundedByAdmin | null;
  comment: string | null;
  paymentTransactionId: string | null;
}

export interface refundPaymentTransaction {
  refundPaymentTransaction: refundPaymentTransaction_refundPaymentTransaction;
}

export interface refundPaymentTransactionVariables {
  systemId: string;
  transactionId: string;
  amount: CORE_Money;
  reason: RefundReason;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invoiceRefundReasonQuery
// ====================================================

export interface invoiceRefundReasonQuery_refundReasons_enumValues {
  __typename: "__EnumValue";
  name: string;
}

export interface invoiceRefundReasonQuery_refundReasons {
  __typename: "__Type";
  name: string | null;
  enumValues: invoiceRefundReasonQuery_refundReasons_enumValues[] | null;
}

export interface invoiceRefundReasonQuery {
  refundReasons: invoiceRefundReasonQuery_refundReasons | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateInvoiceStatus
// ====================================================

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemValueCode_product {
  __typename: "Product";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
  totalAmountVat: CORE_Money;
  totalAmountWithoutVat: CORE_Money;
  product: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemValueCode_product;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
  product: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemTrip_product;
  trip: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemTrip_trip;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
  administrator: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemAdjustment_administrator;
}

export type updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items = updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemValueCode | updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemTrip | updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items_InvoiceItemAdjustment;

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentRefunds_refundedByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentRefunds {
  __typename: "PaymentRefund";
  id: string;
  processedAt: CORE_IsoDateTime;
  state: RefundState;
  amount: CORE_Money;
  failureReason: RefundFailureReason | null;
  refundReason: RefundReason;
  refundedByAdmin: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentRefunds_refundedByAdmin | null;
  comment: string | null;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentMethod_Card {
  __typename: "Card";
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  brand: CardBrand;
  last4: string;
  expired: boolean;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
}

export type updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentMethod = updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentMethod_Blik | updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentMethod_Card;

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions {
  __typename: "PaymentTransaction";
  id: string;
  transactionNumber: string | null;
  status: PaymentTransactionStatus | null;
  failureReason: PaymentFailureReason | null;
  provider: PaymentTransactionProvider | null;
  amount: CORE_Money;
  amountToRefund: CORE_Money;
  amountRefunded: CORE_Money;
  createdAt: CORE_IsoDateTime;
  processedAt: CORE_IsoDateTime | null;
  refundable: boolean;
  paymentRefunds: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentRefunds[] | null;
  paymentMethod: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions_paymentMethod | null;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentConfirmedBy {
  __typename: "Administrator";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_company {
  __typename: "BusinessCompany";
  name: string;
  customerNumber: CORE_PositiveInt | null;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness {
  __typename: "InvoiceBusiness";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  items: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_items[] | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money;
  /**
   * If true an invoice must be manually approved as paid
   */
  awaitingManualPayment: boolean;
  paidAt: CORE_IsoDateTime | null;
  paymentConfirmedById: string | null;
  canTransitToDraft: boolean;
  canTransitToVoid: boolean;
  canTransitToOpen: boolean;
  canTransitToPaid: boolean;
  canTransitToCollectionFailed: boolean;
  paymentTransactions: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentTransactions[];
  paymentConfirmedBy: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_paymentConfirmedBy | null;
  company: updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness_company;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemValueCode_product {
  __typename: "Product";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
  totalAmountVat: CORE_Money;
  totalAmountWithoutVat: CORE_Money;
  product: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemValueCode_product;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
  product: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemTrip_product;
  trip: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemTrip_trip;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
  administrator: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator;
}

export type updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items = updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemValueCode | updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemTrip | updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items_InvoiceItemAdjustment;

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentRefunds_refundedByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentRefunds {
  __typename: "PaymentRefund";
  id: string;
  processedAt: CORE_IsoDateTime;
  state: RefundState;
  amount: CORE_Money;
  failureReason: RefundFailureReason | null;
  refundReason: RefundReason;
  refundedByAdmin: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentRefunds_refundedByAdmin | null;
  comment: string | null;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentMethod_Card {
  __typename: "Card";
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  brand: CardBrand;
  last4: string;
  expired: boolean;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
}

export type updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentMethod = updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentMethod_Blik | updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentMethod_Card;

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions {
  __typename: "PaymentTransaction";
  id: string;
  transactionNumber: string | null;
  status: PaymentTransactionStatus | null;
  failureReason: PaymentFailureReason | null;
  provider: PaymentTransactionProvider | null;
  amount: CORE_Money;
  amountToRefund: CORE_Money;
  amountRefunded: CORE_Money;
  createdAt: CORE_IsoDateTime;
  processedAt: CORE_IsoDateTime | null;
  refundable: boolean;
  paymentRefunds: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentRefunds[] | null;
  paymentMethod: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions_paymentMethod | null;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentConfirmedBy {
  __typename: "Administrator";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_user {
  __typename: "User";
  id: string;
}

export interface updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  items: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_items[] | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money;
  /**
   * If true an invoice must be manually approved as paid
   */
  awaitingManualPayment: boolean;
  paidAt: CORE_IsoDateTime | null;
  paymentConfirmedById: string | null;
  canTransitToDraft: boolean;
  canTransitToVoid: boolean;
  canTransitToOpen: boolean;
  canTransitToPaid: boolean;
  canTransitToCollectionFailed: boolean;
  paymentTransactions: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentTransactions[];
  paymentConfirmedBy: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_paymentConfirmedBy | null;
  user: updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip_user;
}

export type updateInvoiceStatus_updateInvoiceStatus = updateInvoiceStatus_updateInvoiceStatus_InvoiceBusiness | updateInvoiceStatus_updateInvoiceStatus_InvoiceUserTrip;

export interface updateInvoiceStatus {
  updateInvoiceStatus: updateInvoiceStatus_updateInvoiceStatus;
}

export interface updateInvoiceStatusVariables {
  systemId: string;
  invoiceId: string;
  status: InvoiceStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userPropertiesQuery
// ====================================================

export interface userPropertiesQuery_user_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface userPropertiesQuery_user_paymentMethod_Card {
  __typename: "Card";
  id: string | null;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  last4: string;
  paymentMethodRef: string;
  paymentProvider: PaymentProviderEnum;
}

export type userPropertiesQuery_user_paymentMethod = userPropertiesQuery_user_paymentMethod_Blik | userPropertiesQuery_user_paymentMethod_Card;

export interface userPropertiesQuery_user_remainingGiveaways {
  __typename: "Giveaway";
  id: string;
}

export interface userPropertiesQuery_user {
  __typename: "User";
  id: string;
  createdAt: CORE_IsoDateTime;
  /**
   * Have the user agreed to receive email? If null, user have not decided.
   */
  agreedToReceiveEmail: boolean | null;
  /**
   * Have the user agreed to receive promotions from external parties? If null, user have not decided.
   */
  agreedToReceiveExternalPromotions: boolean | null;
  agreedToSendDataToThirdParties: boolean | null;
  agreedToTermsOfUseDate: CORE_IsoDateTime | null;
  preferredLocale: string | null;
  lastSeenLocale: string | null;
  paymentMethod: userPropertiesQuery_user_paymentMethod | null;
  isObosMember: boolean | null;
  remainingGiveaways: (userPropertiesQuery_user_remainingGiveaways | null)[] | null;
  maskedPinCode: string | null;
  updatedAt: CORE_IsoDateTime | null;
  rfid: string | null;
  holdDeletion: boolean;
}

export interface userPropertiesQuery {
  user: userPropertiesQuery_user | null;
}

export interface userPropertiesQueryVariables {
  userId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: notificationHistoryQuery
// ====================================================

export interface notificationHistoryQuery_notificationHistory_messages {
  __typename: "NotificationMessage";
  id: string;
  userId: string;
  body: string;
  createdAt: CORE_IsoDateTime;
  receivedAt: CORE_IsoDateTime | null;
  smsFallbackAt: CORE_IsoDateTime | null;
}

export interface notificationHistoryQuery_notificationHistory {
  __typename: "NotificationHistory";
  messages: notificationHistoryQuery_notificationHistory_messages[];
}

export interface notificationHistoryQuery {
  notificationHistory: notificationHistoryQuery_notificationHistory;
}

export interface notificationHistoryQueryVariables {
  userId: string;
  systemId: string;
  limit: CORE_PositiveInt;
  offset: CORE_NonNegativeInt;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userOrdersQuery
// ====================================================

export interface userOrdersQuery_user_orders_issuedBy {
  __typename: "Administrator";
  name: string | null;
}

export interface userOrdersQuery_user_orders_orderLines_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface userOrdersQuery_user_orders_orderLines {
  __typename: "OrderLine";
  product: userOrdersQuery_user_orders_orderLines_product | null;
  amount: number;
}

export interface userOrdersQuery_user_orders_discount {
  __typename: "OrderDiscount";
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
}

export interface userOrdersQuery_user_orders {
  __typename: "Order";
  id: string;
  status: OrderStatus;
  number: string | null;
  amount: CORE_Money;
  currency: string;
  type: OrderType;
  issuedBy: userOrdersQuery_user_orders_issuedBy | null;
  completedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
  orderLines: userOrdersQuery_user_orders_orderLines[] | null;
  paymentTransactionNumbers: string[];
  discount: userOrdersQuery_user_orders_discount | null;
}

export interface userOrdersQuery_user {
  __typename: "User";
  id: string;
  orders: (userOrdersQuery_user_orders | null)[] | null;
}

export interface userOrdersQuery {
  user: userOrdersQuery_user | null;
}

export interface userOrdersQueryVariables {
  userId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userSubscriptionsQuery
// ====================================================

export interface userSubscriptionsQuery_user_subscriptions_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface userSubscriptionsQuery_user_subscriptions {
  __typename: "ProductSubscription";
  id: string;
  state: ProductSubscriptionState;
  product: userSubscriptionsQuery_user_subscriptions_product | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  deletedAt: CORE_IsoDateTime | null;
  disabledAt: CORE_IsoDateTime | null;
}

export interface userSubscriptionsQuery_user {
  __typename: "User";
  id: string;
  subscriptions: (userSubscriptionsQuery_user_subscriptions | null)[] | null;
}

export interface userSubscriptionsQuery {
  user: userSubscriptionsQuery_user | null;
}

export interface userSubscriptionsQueryVariables {
  userId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userProductRenewalsQuery
// ====================================================

export interface userProductRenewalsQuery_user_productRenewals_ProductRenewalPending_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface userProductRenewalsQuery_user_productRenewals_ProductRenewalPending {
  __typename: "ProductRenewalPending";
  id: string;
  product: userProductRenewalsQuery_user_productRenewals_ProductRenewalPending_product;
  createdAt: CORE_IsoDateTime;
}

export interface userProductRenewalsQuery_user_productRenewals_ProductRenewalScheduled_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface userProductRenewalsQuery_user_productRenewals_ProductRenewalScheduled {
  __typename: "ProductRenewalScheduled";
  id: string;
  product: userProductRenewalsQuery_user_productRenewals_ProductRenewalScheduled_product;
  createdAt: CORE_IsoDateTime;
  /**
   * Product renewal is scheduled for renewal at
   */
  scheduledAt: CORE_IsoDateTime;
}

export interface userProductRenewalsQuery_user_productRenewals_ProductRenewalFailed_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface userProductRenewalsQuery_user_productRenewals_ProductRenewalFailed {
  __typename: "ProductRenewalFailed";
  id: string;
  product: userProductRenewalsQuery_user_productRenewals_ProductRenewalFailed_product;
  createdAt: CORE_IsoDateTime;
  failedAt: CORE_IsoDateTime;
}

export interface userProductRenewalsQuery_user_productRenewals_ProductRenewalDeleted_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface userProductRenewalsQuery_user_productRenewals_ProductRenewalDeleted {
  __typename: "ProductRenewalDeleted";
  id: string;
  product: userProductRenewalsQuery_user_productRenewals_ProductRenewalDeleted_product;
  createdAt: CORE_IsoDateTime;
  deletedAt: CORE_IsoDateTime;
}

export type userProductRenewalsQuery_user_productRenewals = userProductRenewalsQuery_user_productRenewals_ProductRenewalPending | userProductRenewalsQuery_user_productRenewals_ProductRenewalScheduled | userProductRenewalsQuery_user_productRenewals_ProductRenewalFailed | userProductRenewalsQuery_user_productRenewals_ProductRenewalDeleted;

export interface userProductRenewalsQuery_user {
  __typename: "User";
  id: string;
  /**
   * Product renewals for the user
   */
  productRenewals: userProductRenewalsQuery_user_productRenewals[] | null;
}

export interface userProductRenewalsQuery {
  user: userProductRenewalsQuery_user | null;
}

export interface userProductRenewalsQueryVariables {
  userId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userPartnerAccountsQuery
// ====================================================

export interface userPartnerAccountsQuery_user_partnerAccounts_OBOSAccount {
  __typename: "OBOSAccount";
}

export interface userPartnerAccountsQuery_user_partnerAccounts_MilanATMCard {
  __typename: "MilanATMCard";
  createdAt: CORE_IsoDateTime;
  isValid: boolean;
  UUID: string;
  expiresAt: CORE_IsoDateTime | null;
  identificationNumber: number;
  partnerId: PartnerId;
  serialNumber: string;
  subscriptionType: MilanATMSubscriptionType | null;
}

export type userPartnerAccountsQuery_user_partnerAccounts = userPartnerAccountsQuery_user_partnerAccounts_OBOSAccount | userPartnerAccountsQuery_user_partnerAccounts_MilanATMCard;

export interface userPartnerAccountsQuery_user {
  __typename: "User";
  id: string;
  partnerAccounts: userPartnerAccountsQuery_user_partnerAccounts[] | null;
}

export interface userPartnerAccountsQuery {
  user: userPartnerAccountsQuery_user | null;
}

export interface userPartnerAccountsQueryVariables {
  userId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userPenaltiesQuery
// ====================================================

export interface userPenaltiesQuery_user_penalties_trip {
  __typename: "Trip";
  id: string;
  cancelledReason: string | null;
}

export interface userPenaltiesQuery_user_penalties_resolvedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface userPenaltiesQuery_user_penalties_givenBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface userPenaltiesQuery_user_penalties {
  __typename: "Penalty";
  id: string;
  comment: string | null;
  trip: userPenaltiesQuery_user_penalties_trip | null;
  value: number;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: userPenaltiesQuery_user_penalties_resolvedBy | null;
  givenBy: userPenaltiesQuery_user_penalties_givenBy | null;
  createdAt: CORE_IsoDateTime;
}

export interface userPenaltiesQuery_user {
  __typename: "User";
  id: string;
  /**
   * Number of active penalty points the user have
   */
  penaltyPoints: number;
  penaltyStatus: PenaltyStatus;
  penalties: userPenaltiesQuery_user_penalties[];
}

export interface userPenaltiesQuery {
  user: userPenaltiesQuery_user | null;
}

export interface userPenaltiesQueryVariables {
  userId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userAgreementsQuery
// ====================================================

export interface userAgreementsQuery_user_agreements_agreement_translations {
  __typename: "UserAgreementTranslation";
  id: string;
  languageCode: LanguageCode;
  content: string;
  fileUrl: string;
  fileName: string;
}

export interface userAgreementsQuery_user_agreements_agreement {
  __typename: "UserAgreement";
  id: string;
  name: string;
  type: UserAgreementType;
  revision: CORE_PositiveInt;
  isActive: boolean;
  createdAt: CORE_IsoDateTime;
  translations: userAgreementsQuery_user_agreements_agreement_translations[];
}

export interface userAgreementsQuery_user_agreements {
  __typename: "UserAgreementAccept";
  id: string;
  userAgreementId: string;
  acceptedAt: CORE_IsoDateTime;
  agreement: userAgreementsQuery_user_agreements_agreement;
}

export interface userAgreementsQuery_user {
  __typename: "User";
  id: string;
  agreements: userAgreementsQuery_user_agreements[];
}

export interface userAgreementsQuery {
  user: userAgreementsQuery_user | null;
}

export interface userAgreementsQueryVariables {
  userId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resolvePenaltyMutation
// ====================================================

export interface resolvePenaltyMutation_resolvePenalty_trip {
  __typename: "Trip";
  id: string;
  cancelledReason: string | null;
}

export interface resolvePenaltyMutation_resolvePenalty_resolvedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface resolvePenaltyMutation_resolvePenalty_givenBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface resolvePenaltyMutation_resolvePenalty {
  __typename: "Penalty";
  id: string;
  comment: string | null;
  trip: resolvePenaltyMutation_resolvePenalty_trip | null;
  value: number;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: resolvePenaltyMutation_resolvePenalty_resolvedBy | null;
  givenBy: resolvePenaltyMutation_resolvePenalty_givenBy | null;
  createdAt: CORE_IsoDateTime;
}

export interface resolvePenaltyMutation {
  resolvePenalty: resolvePenaltyMutation_resolvePenalty | null;
}

export interface resolvePenaltyMutationVariables {
  id: string;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: givePenaltyMutation
// ====================================================

export interface givePenaltyMutation_givePenalty_trip {
  __typename: "Trip";
  id: string;
  cancelledReason: string | null;
}

export interface givePenaltyMutation_givePenalty_resolvedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface givePenaltyMutation_givePenalty_givenBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface givePenaltyMutation_givePenalty {
  __typename: "Penalty";
  id: string;
  comment: string | null;
  trip: givePenaltyMutation_givePenalty_trip | null;
  value: number;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: givePenaltyMutation_givePenalty_resolvedBy | null;
  givenBy: givePenaltyMutation_givePenalty_givenBy | null;
  createdAt: CORE_IsoDateTime;
}

export interface givePenaltyMutation {
  givePenalty: givePenaltyMutation_givePenalty | null;
}

export interface givePenaltyMutationVariables {
  userId: string;
  value: number;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paginatedWalletTransactionsQuery
// ====================================================

export interface paginatedWalletTransactionsQuery_paginatedWalletTransactions_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalRowCount: number;
}

export interface paginatedWalletTransactionsQuery_paginatedWalletTransactions_rows_refundedByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface paginatedWalletTransactionsQuery_paginatedWalletTransactions_rows_order {
  __typename: "Order";
  id: string;
}

export interface paginatedWalletTransactionsQuery_paginatedWalletTransactions_rows {
  __typename: "WalletTransaction";
  id: string;
  createdAt: CORE_IsoDateTime;
  amount: CORE_Money;
  currency: string;
  type: WalletTransactionType;
  refundable: boolean;
  orderId: string;
  refundReason: RefundReason | null;
  refundReasonDescription: string | null;
  refundedByAdmin: paginatedWalletTransactionsQuery_paginatedWalletTransactions_rows_refundedByAdmin | null;
  order: paginatedWalletTransactionsQuery_paginatedWalletTransactions_rows_order;
}

export interface paginatedWalletTransactionsQuery_paginatedWalletTransactions {
  __typename: "PaginatedWalletTransactions";
  paginationInfo: paginatedWalletTransactionsQuery_paginatedWalletTransactions_paginationInfo;
  rows: paginatedWalletTransactionsQuery_paginatedWalletTransactions_rows[];
}

export interface paginatedWalletTransactionsQuery {
  paginatedWalletTransactions: paginatedWalletTransactionsQuery_paginatedWalletTransactions;
}

export interface paginatedWalletTransactionsQueryVariables {
  userId: string;
  pagination?: OffsetPagination | null;
  order?: WalletTransactionSort | null;
  filters?: WalletTransactionFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: legacyUserTimelineQuery
// ====================================================

export interface legacyUserTimelineQuery_userTimeline_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Administrator {
  __typename: "Administrator" | "User";
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_startDock_dockGroup | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_endDock_dockGroup | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_displayName = legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_displayName_VehicleDisplayNamed | legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_displayName_VehicleDisplayNumbered | legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_displayName_VehicleDisplayAnonymous;

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_displayName;
  model: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle_model | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_extendedRental {
  __typename: "ExtendedRental";
  startedAt: CORE_IsoDateTime;
  numberOfPeriods: number;
  totalCostIncurred: number;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_order {
  __typename: "Order";
  id: string;
  type: OrderType;
  amount: CORE_Money;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_entityTags_tag;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_userAgent {
  __typename: "UserAgent";
  original: string | null;
  isAndroid: boolean | null;
  isIOS: boolean | null;
  browser: string | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_startDock | null;
  endDock: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_endDock | null;
  vehicle: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_vehicle | null;
  extendedRental: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_extendedRental | null;
  order: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_order | null;
  entityTags: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_entityTags[] | null;
  userAgent: legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_userAgent | null;
  duration: number | null;
}

export type legacyUserTimelineQuery_userTimeline_edges_node_entity = legacyUserTimelineQuery_userTimeline_edges_node_entity_Administrator | legacyUserTimelineQuery_userTimeline_edges_node_entity_Product | legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip;

export interface legacyUserTimelineQuery_userTimeline_edges_node {
  __typename: "TimelineEvent";
  id: string;
  type: TimelineEventType | null;
  body: string | null;
  entityKind: TimelineEventEntityKind | null;
  entityId: string | null;
  createdAt: CORE_IsoDateTime;
  entity: legacyUserTimelineQuery_userTimeline_edges_node_entity | null;
}

export interface legacyUserTimelineQuery_userTimeline_edges {
  __typename: "TimelineEventEdge";
  cursor: string;
  node: legacyUserTimelineQuery_userTimeline_edges_node;
}

export interface legacyUserTimelineQuery_userTimeline {
  __typename: "UserTimeline";
  pageInfo: legacyUserTimelineQuery_userTimeline_pageInfo;
  edges: legacyUserTimelineQuery_userTimeline_edges[];
}

export interface legacyUserTimelineQuery {
  userTimeline: legacyUserTimelineQuery_userTimeline | null;
}

export interface legacyUserTimelineQueryVariables {
  userId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  complexFilters?: (ComplexFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userInvoicesQuery
// ====================================================

export interface userInvoicesQuery_user_invoices_InvoiceBusiness {
  __typename: "InvoiceBusiness";
}

export interface userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  product: userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemTrip_product;
  trip: userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemTrip_trip;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  administrator: userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator;
  createdAt: CORE_IsoDateTime;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export type userInvoicesQuery_user_invoices_InvoiceUserTrip_items = userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemValueCode | userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemTrip | userInvoicesQuery_user_invoices_InvoiceUserTrip_items_InvoiceItemAdjustment;

export interface userInvoicesQuery_user_invoices_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
  id: string;
  systemId: string;
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  description: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money;
  vatRate: number;
  awaitingManualPayment: boolean;
  paymentConfirmedById: string | null;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  canTransitToDraft: boolean;
  canTransitToVoid: boolean;
  canTransitToOpen: boolean;
  canTransitToPaid: boolean;
  canTransitToCollectionFailed: boolean;
  items: userInvoicesQuery_user_invoices_InvoiceUserTrip_items[] | null;
}

export type userInvoicesQuery_user_invoices = userInvoicesQuery_user_invoices_InvoiceBusiness | userInvoicesQuery_user_invoices_InvoiceUserTrip;

export interface userInvoicesQuery_user {
  __typename: "User";
  id: string;
  invoices: userInvoicesQuery_user_invoices[];
}

export interface userInvoicesQuery {
  user: userInvoicesQuery_user | null;
}

export interface userInvoicesQueryVariables {
  userId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createUserInvoiceItemAdjustment
// ====================================================

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amount: CORE_Money;
  vatRate: number;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  vatRate: number;
  product: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip_product;
  trip: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip_trip;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
  vatRate: number;
  administrator: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemAdjustment_administrator;
}

export type createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items = createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemValueCode | createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemTrip | createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items_InvoiceItemAdjustment;

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness {
  __typename: "InvoiceBusiness";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  items: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness_items[] | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  /**
   * If true an invoice must be manually approved as paid
   */
  awaitingManualPayment: boolean;
  paidAt: CORE_IsoDateTime | null;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amount: CORE_Money;
  vatRate: number;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  vatRate: number;
  product: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip_product;
  trip: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip_trip;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
  vatRate: number;
  administrator: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemAdjustment_administrator;
}

export type createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items = createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemValueCode | createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemTrip | createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items_InvoiceItemAdjustment;

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_user {
  __typename: "User";
  id: string;
}

export interface createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  items: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_items[] | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  /**
   * If true an invoice must be manually approved as paid
   */
  awaitingManualPayment: boolean;
  paidAt: CORE_IsoDateTime | null;
  user: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip_user;
}

export type createUserInvoiceItemAdjustment_createInvoiceItemAdjustment = createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceBusiness | createUserInvoiceItemAdjustment_createInvoiceItemAdjustment_InvoiceUserTrip;

export interface createUserInvoiceItemAdjustment {
  createInvoiceItemAdjustment: createUserInvoiceItemAdjustment_createInvoiceItemAdjustment;
}

export interface createUserInvoiceItemAdjustmentVariables {
  systemId: string;
  invoiceId: string;
  input: CreateInvoiceItemAdjustmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDraftTripInvoice
// ====================================================

export interface createDraftTripInvoice_createDraftTripInvoice_InvoiceBusiness {
  __typename: "InvoiceBusiness";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  paidAt: CORE_IsoDateTime | null;
}

export interface createDraftTripInvoice_createDraftTripInvoice_InvoiceUserTrip_user {
  __typename: "User";
  id: string;
}

export interface createDraftTripInvoice_createDraftTripInvoice_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
  id: string;
  systemId: string;
  /**
   * The status of the invoice
   */
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  paidAt: CORE_IsoDateTime | null;
  user: createDraftTripInvoice_createDraftTripInvoice_InvoiceUserTrip_user;
}

export type createDraftTripInvoice_createDraftTripInvoice = createDraftTripInvoice_createDraftTripInvoice_InvoiceBusiness | createDraftTripInvoice_createDraftTripInvoice_InvoiceUserTrip;

export interface createDraftTripInvoice {
  createDraftTripInvoice: createDraftTripInvoice_createDraftTripInvoice;
}

export interface createDraftTripInvoiceVariables {
  systemId: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userTimelineQuery
// ====================================================

export interface userTimelineQuery_userTimeline_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface userTimelineQuery_userTimeline_edges_node_UserSignedUpEvent_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserSignedUpEvent {
  __typename: "UserSignedUpEvent";
  id: string;
  createdAt: CORE_IsoDateTime;
  userAgent: userTimelineQuery_userTimeline_edges_node_UserSignedUpEvent_userAgent | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserLoggedInEvent_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserLoggedInEvent {
  __typename: "UserLoggedInEvent";
  id: string;
  createdAt: CORE_IsoDateTime;
  loginEventType: UserLoggedInEventType;
  userAgent: userTimelineQuery_userTimeline_edges_node_UserLoggedInEvent_userAgent | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserApiErrorEvent_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserApiErrorEvent {
  __typename: "UserApiErrorEvent";
  id: string;
  createdAt: CORE_IsoDateTime;
  body: string | null;
  userAgent: userTimelineQuery_userTimeline_edges_node_UserApiErrorEvent_userAgent | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicleAvailabilityDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicleAvailabilityDock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  type: DockType;
  dockGroup: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicleAvailabilityDock_dockGroup | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicleAvailabilityLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicleAvailabilityLocation {
  __typename: "VehicleLocation";
  location: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicleAvailabilityLocation_location;
  address: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_displayName = userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayNamed | userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayNumbered | userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayAnonymous;

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_displayName;
  model: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle_model | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_startDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_startDock_dockGroup | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_endDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_endDock_dockGroup | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_startLocation {
  __typename: "VehicleLocation";
  location: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_startLocation_location;
  address: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_endLocation {
  __typename: "VehicleLocation";
  location: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_endLocation_location;
  address: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_entityTags_tag;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_order {
  __typename: "Order";
  id: string;
  amount: CORE_Money;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_startDock | null;
  endDock: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_endDock | null;
  startLocation: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_startLocation | null;
  endLocation: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_endLocation | null;
  subscription: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_subscription | null;
  entityTags: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_entityTags[] | null;
  duration: number | null;
  userAgent: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_userAgent | null;
  cancelledByAdmin: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_cancelledByAdmin | null;
  order: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip_order | null;
  cancelledReason: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_VehicleAvailability {
  __typename: "VehicleAvailability";
  id: string;
  systemId: string | null;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  administrator: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_administrator | null;
  vehicleAvailabilityDock: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicleAvailabilityDock | null;
  vehicleAvailabilityLocation: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicleAvailabilityLocation | null;
  vehicle: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_vehicle;
  trip: userTimelineQuery_userTimeline_edges_node_VehicleAvailability_trip | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserTripCancelledEvent_tripCancelled_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  profilePictureUrl: string | null;
  name: string | null;
  email: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserTripCancelledEvent_tripCancelled {
  __typename: "Trip";
  id: string;
  cancelledByAdmin: userTimelineQuery_userTimeline_edges_node_UserTripCancelledEvent_tripCancelled_cancelledByAdmin | null;
  cancelledReason: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserTripCancelledEvent {
  __typename: "UserTripCancelledEvent";
  id: string;
  tripCancelled: userTimelineQuery_userTimeline_edges_node_UserTripCancelledEvent_tripCancelled;
  createdAt: CORE_IsoDateTime;
}

export interface userTimelineQuery_userTimeline_edges_node_UserProductRenewalEvent_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserProductRenewalEvent_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserProductRenewalEvent {
  __typename: "UserProductRenewalEvent";
  id: string;
  createdAt: CORE_IsoDateTime;
  enabled: boolean;
  product: userTimelineQuery_userTimeline_edges_node_UserProductRenewalEvent_product;
  userAgent: userTimelineQuery_userTimeline_edges_node_UserProductRenewalEvent_userAgent | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserOTPVerifiedEvent_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface userTimelineQuery_userTimeline_edges_node_UserOTPVerifiedEvent {
  __typename: "UserOTPVerifiedEvent";
  id: string;
  createdAt: CORE_IsoDateTime;
  userAgent: userTimelineQuery_userTimeline_edges_node_UserOTPVerifiedEvent_userAgent | null;
}

export type userTimelineQuery_userTimeline_edges_node = userTimelineQuery_userTimeline_edges_node_UserSignedUpEvent | userTimelineQuery_userTimeline_edges_node_UserLoggedInEvent | userTimelineQuery_userTimeline_edges_node_UserApiErrorEvent | userTimelineQuery_userTimeline_edges_node_VehicleAvailability | userTimelineQuery_userTimeline_edges_node_UserTripCancelledEvent | userTimelineQuery_userTimeline_edges_node_UserProductRenewalEvent | userTimelineQuery_userTimeline_edges_node_UserOTPVerifiedEvent;

export interface userTimelineQuery_userTimeline_edges {
  __typename: "UserTimelineConnectionEdge";
  cursor: string;
  node: userTimelineQuery_userTimeline_edges_node;
}

export interface userTimelineQuery_userTimeline {
  __typename: "UserTimelineConnection";
  pageInfo: userTimelineQuery_userTimeline_pageInfo;
  edges: userTimelineQuery_userTimeline_edges[];
}

export interface userTimelineQuery {
  /**
   * A list of events associated with the user
   */
  userTimeline: userTimelineQuery_userTimeline;
}

export interface userTimelineQueryVariables {
  systemId: string;
  userId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  itemTypes?: UserTimelineConnectionItemType[] | null;
  availabilityStates?: AvailabilityState[] | null;
  skipVehicle: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: usersConnection
// ====================================================

export interface usersConnection_usersConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface usersConnection_usersConnection_edges_node_trips_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface usersConnection_usersConnection_edges_node_trips_startLocation {
  __typename: "VehicleLocation";
  location: usersConnection_usersConnection_edges_node_trips_startLocation_location;
  address: string | null;
}

export interface usersConnection_usersConnection_edges_node_trips_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface usersConnection_usersConnection_edges_node_trips_endLocation {
  __typename: "VehicleLocation";
  location: usersConnection_usersConnection_edges_node_trips_endLocation_location;
  address: string | null;
}

export interface usersConnection_usersConnection_edges_node_trips {
  __typename: "Trip";
  id: string;
  startDockId: string | null;
  endDockId: string | null;
  comment: string | null;
  endedAt: CORE_IsoDateTime | null;
  startLocation: usersConnection_usersConnection_edges_node_trips_startLocation | null;
  endLocation: usersConnection_usersConnection_edges_node_trips_endLocation | null;
}

export interface usersConnection_usersConnection_edges_node_subscriptions_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface usersConnection_usersConnection_edges_node_subscriptions {
  __typename: "ProductSubscription";
  product: usersConnection_usersConnection_edges_node_subscriptions_product | null;
  state: ProductSubscriptionState;
}

export interface usersConnection_usersConnection_edges_node {
  __typename: "User";
  id: string;
  name: string | null;
  phoneNumber: string | null;
  email: string | null;
  trips: (usersConnection_usersConnection_edges_node_trips | null)[] | null;
  subscriptions: (usersConnection_usersConnection_edges_node_subscriptions | null)[] | null;
  createdAt: CORE_IsoDateTime;
}

export interface usersConnection_usersConnection_edges {
  __typename: "UserEdge";
  cursor: string;
  node: usersConnection_usersConnection_edges_node;
}

export interface usersConnection_usersConnection {
  __typename: "UserConnection";
  pageInfo: usersConnection_usersConnection_pageInfo;
  edges: usersConnection_usersConnection_edges[];
}

export interface usersConnection {
  usersConnection: usersConnection_usersConnection | null;
}

export interface usersConnectionVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: valueCodesConnection
// ====================================================

export interface valueCodesConnection_valueCodesConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface valueCodesConnection_valueCodesConnection_edges_node_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface valueCodesConnection_valueCodesConnection_edges_node_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface valueCodesConnection_valueCodesConnection_edges_node_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface valueCodesConnection_valueCodesConnection_edges_node {
  __typename: "ValueCode";
  id: string;
  product: valueCodesConnection_valueCodesConnection_edges_node_product | null;
  administrator: valueCodesConnection_valueCodesConnection_edges_node_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: valueCodesConnection_valueCodesConnection_edges_node_subscription | null;
  campaignTag: string | null;
}

export interface valueCodesConnection_valueCodesConnection_edges {
  __typename: "ValueCodeEdge";
  cursor: string;
  node: valueCodesConnection_valueCodesConnection_edges_node;
}

export interface valueCodesConnection_valueCodesConnection {
  __typename: "ValueCodesConnection";
  pageInfo: valueCodesConnection_valueCodesConnection_pageInfo;
  edges: valueCodesConnection_valueCodesConnection_edges[];
}

export interface valueCodesConnection {
  valueCodesConnection: valueCodesConnection_valueCodesConnection | null;
}

export interface valueCodesConnectionVariables {
  systemId: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
  valueCodesConnectionFilters?: ValueCodesConnectionFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paginatedValueCodes
// ====================================================

export interface paginatedValueCodes_paginatedValueCodes_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalRowCount: number;
}

export interface paginatedValueCodes_paginatedValueCodes_rows_ValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface paginatedValueCodes_paginatedValueCodes_rows_ValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface paginatedValueCodes_paginatedValueCodes_rows_ValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface paginatedValueCodes_paginatedValueCodes_rows_ValueCode {
  __typename: "ValueCode";
  id: string;
  product: paginatedValueCodes_paginatedValueCodes_rows_ValueCode_product | null;
  administrator: paginatedValueCodes_paginatedValueCodes_rows_ValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: paginatedValueCodes_paginatedValueCodes_rows_ValueCode_subscription | null;
  campaignTag: string | null;
}

export interface paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode_company {
  __typename: "BusinessCompany";
  id: string;
  name: string;
}

export interface paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode {
  __typename: "BusinessValueCode";
  id: string;
  product: paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode_product | null;
  administrator: paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode_subscription | null;
  campaignTag: string | null;
  company: paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode_company | null;
}

export type paginatedValueCodes_paginatedValueCodes_rows = paginatedValueCodes_paginatedValueCodes_rows_ValueCode | paginatedValueCodes_paginatedValueCodes_rows_BusinessValueCode;

export interface paginatedValueCodes_paginatedValueCodes {
  __typename: "PaginatedValueCodes";
  paginationInfo: paginatedValueCodes_paginatedValueCodes_paginationInfo;
  rows: paginatedValueCodes_paginatedValueCodes_rows[];
}

export interface paginatedValueCodes {
  paginatedValueCodes: paginatedValueCodes_paginatedValueCodes;
}

export interface paginatedValueCodesVariables {
  systemId: string;
  pagination?: OffsetPagination | null;
  order?: ValueCodeOrder | null;
  filters?: ValueCodeFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: valueCodePageQuery
// ====================================================

export interface valueCodePageQuery_valueCode_ValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface valueCodePageQuery_valueCode_ValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface valueCodePageQuery_valueCode_ValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface valueCodePageQuery_valueCode_ValueCode {
  __typename: "ValueCode";
  id: string;
  product: valueCodePageQuery_valueCode_ValueCode_product | null;
  administrator: valueCodePageQuery_valueCode_ValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: valueCodePageQuery_valueCode_ValueCode_subscription | null;
  campaignTag: string | null;
  systemId: string;
}

export interface valueCodePageQuery_valueCode_BusinessValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface valueCodePageQuery_valueCode_BusinessValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface valueCodePageQuery_valueCode_BusinessValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface valueCodePageQuery_valueCode_BusinessValueCode_company {
  __typename: "BusinessCompany";
  id: string;
  name: string;
}

export interface valueCodePageQuery_valueCode_BusinessValueCode {
  __typename: "BusinessValueCode";
  id: string;
  product: valueCodePageQuery_valueCode_BusinessValueCode_product | null;
  administrator: valueCodePageQuery_valueCode_BusinessValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: valueCodePageQuery_valueCode_BusinessValueCode_subscription | null;
  campaignTag: string | null;
  company: valueCodePageQuery_valueCode_BusinessValueCode_company | null;
  systemId: string;
}

export type valueCodePageQuery_valueCode = valueCodePageQuery_valueCode_ValueCode | valueCodePageQuery_valueCode_BusinessValueCode;

export interface valueCodePageQuery {
  valueCode: valueCodePageQuery_valueCode | null;
}

export interface valueCodePageQueryVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateValueCodeMutation
// ====================================================

export interface updateValueCodeMutation_updateValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface updateValueCodeMutation_updateValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface updateValueCodeMutation_updateValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface updateValueCodeMutation_updateValueCode {
  __typename: "ValueCode";
  id: string;
  product: updateValueCodeMutation_updateValueCode_product | null;
  administrator: updateValueCodeMutation_updateValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: updateValueCodeMutation_updateValueCode_subscription | null;
  campaignTag: string | null;
  systemId: string;
}

export interface updateValueCodeMutation {
  updateValueCode: updateValueCodeMutation_updateValueCode | null;
}

export interface updateValueCodeMutationVariables {
  systemId: string;
  valueCodeId: string;
  input: ValueCodeUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: revokeValueCodeMutation
// ====================================================

export interface revokeValueCodeMutation_revokeValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface revokeValueCodeMutation_revokeValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface revokeValueCodeMutation_revokeValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface revokeValueCodeMutation_revokeValueCode {
  __typename: "ValueCode";
  id: string;
  product: revokeValueCodeMutation_revokeValueCode_product | null;
  administrator: revokeValueCodeMutation_revokeValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: revokeValueCodeMutation_revokeValueCode_subscription | null;
  campaignTag: string | null;
  systemId: string;
}

export interface revokeValueCodeMutation {
  revokeValueCode: revokeValueCodeMutation_revokeValueCode | null;
}

export interface revokeValueCodeMutationVariables {
  systemId: string;
  valueCodeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createValueCodeExportMutation
// ====================================================

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_ValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_ValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_ValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_ValueCode {
  __typename: "ValueCode";
  id: string;
  product: createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_ValueCode_product | null;
  administrator: createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_ValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_ValueCode_subscription | null;
  campaignTag: string | null;
}

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode_company {
  __typename: "BusinessCompany";
  id: string;
  name: string;
}

export interface createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode {
  __typename: "BusinessValueCode";
  id: string;
  product: createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode_product | null;
  administrator: createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode_subscription | null;
  campaignTag: string | null;
  company: createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode_company | null;
}

export type createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode = createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_ValueCode | createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode_BusinessValueCode;

export interface createValueCodeExportMutation_createValueCodeDataExportRequest {
  __typename: "CreateValueCodeDataExportRequestResponse";
  dataExportRequest: createValueCodeExportMutation_createValueCodeDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual value code.
   */
  valueCode: createValueCodeExportMutation_createValueCodeDataExportRequest_valueCode | null;
}

export interface createValueCodeExportMutation {
  createValueCodeDataExportRequest: createValueCodeExportMutation_createValueCodeDataExportRequest;
}

export interface createValueCodeExportMutationVariables {
  systemId: string;
  order?: ValueCodeOrder | null;
  filters?: ValueCodeFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paginatedVehiclesQuery
// ====================================================

export interface paginatedVehiclesQuery_paginatedVehicles_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  batteryStatus: BatteryStatus | null;
  batteryVoltage: number | null;
  batteryCharge: number | null;
  lockMechanismState: LockMechanismState | null;
  latestResponse: CORE_IsoDateTime | null;
  model: paginatedVehiclesQuery_paginatedVehicles_rows_controller_model | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: paginatedVehiclesQuery_paginatedVehicles_rows_availability_dock_dockGroup | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_availability_location {
  __typename: "VehicleLocation";
  location: paginatedVehiclesQuery_paginatedVehicles_rows_availability_location_location;
  address: string | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_availability_operationLocation {
  __typename: "OperationLocation";
  id: string;
  name: string;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  updatedAt: CORE_IsoDateTime | null;
  dock: paginatedVehiclesQuery_paginatedVehicles_rows_availability_dock | null;
  location: paginatedVehiclesQuery_paginatedVehicles_rows_availability_location | null;
  administrator: paginatedVehiclesQuery_paginatedVehicles_rows_availability_administrator | null;
  operationLocation: paginatedVehiclesQuery_paginatedVehicles_rows_availability_operationLocation | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: paginatedVehiclesQuery_paginatedVehicles_rows_entityTags_tag;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows_position {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface paginatedVehiclesQuery_paginatedVehicles_rows {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  controller: paginatedVehiclesQuery_paginatedVehicles_rows_controller | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  availability: paginatedVehiclesQuery_paginatedVehicles_rows_availability | null;
  frameNumber: string | null;
  entityTags: paginatedVehiclesQuery_paginatedVehicles_rows_entityTags[] | null;
  model: paginatedVehiclesQuery_paginatedVehicles_rows_model | null;
  position: paginatedVehiclesQuery_paginatedVehicles_rows_position | null;
}

export interface paginatedVehiclesQuery_paginatedVehicles {
  __typename: "PaginatedVehicles";
  paginationInfo: paginatedVehiclesQuery_paginatedVehicles_paginationInfo;
  rows: paginatedVehiclesQuery_paginatedVehicles_rows[];
}

export interface paginatedVehiclesQuery {
  paginatedVehicles: paginatedVehiclesQuery_paginatedVehicles;
}

export interface paginatedVehiclesQueryVariables {
  systemId: string;
  pagination?: OffsetPagination | null;
  order?: VehicleOrder | null;
  filters?: VehicleFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleInventory
// ====================================================

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesInService_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesInService_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesInService_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesInService_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInService {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesInService_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreet {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesOnStreet_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInStation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesInStation_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInOperationLocation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesInOperationLocation_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInStation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailableInStation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesAvailableInStation_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnTrip {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesOnTrip_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesWithOperations {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesWithOperations_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesLost_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesLost_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesLost_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesLost_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesLost {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesLost_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesDecommissioned {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesDecommissioned_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesNeverSeen {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesNeverSeen_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_dock | null;
  location: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_location | null;
  administrator: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability_administrator | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_entityTags_tag;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_controller | null;
  availability: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_availability | null;
  entityTags: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles_entityTags[] | null;
}

export interface vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead_vehicles[] | null;
}

export interface vehicleInventory_vehicleInventory {
  __typename: "VehicleInventory";
  vehiclesInService: vehicleInventory_vehicleInventory_vehiclesInService;
  vehiclesOnStreet: vehicleInventory_vehicleInventory_vehiclesOnStreet;
  vehiclesInStation: vehicleInventory_vehicleInventory_vehiclesInStation;
  vehiclesInOperationLocation: vehicleInventory_vehicleInventory_vehiclesInOperationLocation;
  vehiclesUnavailableInStation: vehicleInventory_vehicleInventory_vehiclesUnavailableInStation;
  vehiclesInFreeFloatingLocation: vehicleInventory_vehicleInventory_vehiclesInFreeFloatingLocation;
  vehiclesAvailableInFreeFloatingLocation: vehicleInventory_vehicleInventory_vehiclesAvailableInFreeFloatingLocation;
  vehiclesUnavailableInFreeFloatingLocation: vehicleInventory_vehicleInventory_vehiclesUnavailableInFreeFloatingLocation;
  vehiclesAvailableInStation: vehicleInventory_vehicleInventory_vehiclesAvailableInStation;
  vehiclesOnTrip: vehicleInventory_vehicleInventory_vehiclesOnTrip;
  vehiclesWithOperations: vehicleInventory_vehicleInventory_vehiclesWithOperations;
  vehiclesAvailabilityUnknown: vehicleInventory_vehicleInventory_vehiclesAvailabilityUnknown;
  vehiclesLost: vehicleInventory_vehicleInventory_vehiclesLost;
  vehiclesDecommissioned: vehicleInventory_vehicleInventory_vehiclesDecommissioned;
  vehiclesNeverSeen: vehicleInventory_vehicleInventory_vehiclesNeverSeen;
  vehiclesOnStreetBatteryDead: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryDead;
  vehiclesOnStreetBatteryLow: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryLow;
  vehiclesOnStreetBatteryOk: vehicleInventory_vehicleInventory_vehiclesOnStreetBatteryOk;
  vehiclesOnStreetControllerBatteryOk: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryOk;
  vehiclesOnStreetControllerBatteryLow: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryLow;
  vehiclesOnStreetControllerBatteryDead: vehicleInventory_vehicleInventory_vehiclesOnStreetControllerBatteryDead;
}

export interface vehicleInventory {
  vehicleInventory: vehicleInventory_vehicleInventory;
}

export interface vehicleInventoryVariables {
  systemId: string;
  vehiclesInService: boolean;
  vehiclesOnStreet: boolean;
  vehiclesInStation: boolean;
  vehiclesInOperationLocation: boolean;
  vehiclesUnavailableInStation: boolean;
  vehiclesInFreeFloatingLocation: boolean;
  vehiclesAvailableInFreeFloatingLocation: boolean;
  vehiclesUnavailableInFreeFloatingLocation: boolean;
  vehiclesAvailableInStation: boolean;
  vehiclesOnTrip: boolean;
  vehiclesWithOperations: boolean;
  vehiclesAvailabilityUnknown: boolean;
  vehiclesLost: boolean;
  vehiclesDecommissioned: boolean;
  vehiclesNeverSeen: boolean;
  vehiclesOnStreetBatteryDead: boolean;
  vehiclesOnStreetBatteryLow: boolean;
  vehiclesOnStreetBatteryOk: boolean;
  vehiclesOnStreetControllerBatteryOk: boolean;
  vehiclesOnStreetControllerBatteryLow: boolean;
  vehiclesOnStreetControllerBatteryDead: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleAnalytics
// ====================================================

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnStreet {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesOnStreet_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesOnTrip {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesOnTrip_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesWithOperations {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesWithOperations_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesLost {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesLost_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesLost_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesDecommissioned {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_dock_dockGroup | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_location_location;
  address: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_dock | null;
  location: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_location | null;
  administrator: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability_administrator | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_entityTags_tag;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_controller | null;
  availability: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_availability | null;
  entityTags: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles_entityTags[] | null;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface vehicleAnalytics_vehicleInventory_vehiclesNeverSeen {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_vehicles[] | null;
  categories: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen_categories[] | null;
}

export interface vehicleAnalytics_vehicleInventory {
  __typename: "VehicleInventory";
  vehiclesOnStreet: vehicleAnalytics_vehicleInventory_vehiclesOnStreet;
  vehiclesAvailableInStation: vehicleAnalytics_vehicleInventory_vehiclesAvailableInStation;
  vehiclesUnavailableInStation: vehicleAnalytics_vehicleInventory_vehiclesUnavailableInStation;
  vehiclesOnTrip: vehicleAnalytics_vehicleInventory_vehiclesOnTrip;
  vehiclesWithOperations: vehicleAnalytics_vehicleInventory_vehiclesWithOperations;
  vehiclesInOperationLocation: vehicleAnalytics_vehicleInventory_vehiclesInOperationLocation;
  vehiclesAvailabilityUnknown: vehicleAnalytics_vehicleInventory_vehiclesAvailabilityUnknown;
  vehiclesLost: vehicleAnalytics_vehicleInventory_vehiclesLost;
  vehiclesDecommissioned: vehicleAnalytics_vehicleInventory_vehiclesDecommissioned;
  vehiclesNeverSeen: vehicleAnalytics_vehicleInventory_vehiclesNeverSeen;
}

export interface vehicleAnalytics_operationLocationsStatistics {
  __typename: "OperationLocationForStatistics";
  id: string;
  name: string;
  vehicleCount: number;
}

export interface vehicleAnalytics_firmwareRollout {
  __typename: "FirmwareRollout";
  version: string | null;
  count: number | null;
}

export interface vehicleAnalytics {
  vehicleInventory: vehicleAnalytics_vehicleInventory;
  operationLocationsStatistics: vehicleAnalytics_operationLocationsStatistics[];
  firmwareRollout: (vehicleAnalytics_firmwareRollout | null)[] | null;
}

export interface vehicleAnalyticsVariables {
  systemId: string;
  withVehicles: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleFormAssetModels
// ====================================================

export interface vehicleFormAssetModels_assetModelsConnection_edges_node {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
  name: string | null;
  description: string | null;
  vehicleCategory: VehicleCategory | null;
  hardwareBridge: HardwareBridge | null;
}

export interface vehicleFormAssetModels_assetModelsConnection_edges {
  __typename: "AssetModelEdge";
  node: vehicleFormAssetModels_assetModelsConnection_edges_node;
}

export interface vehicleFormAssetModels_assetModelsConnection {
  __typename: "AssetModelsConnection";
  edges: vehicleFormAssetModels_assetModelsConnection_edges[];
}

export interface vehicleFormAssetModels {
  assetModelsConnection: vehicleFormAssetModels_assetModelsConnection | null;
}

export interface vehicleFormAssetModelsVariables {
  systemId: string;
  cursor?: Cursor | null;
  options?: FilterAndOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleFiltersQuery
// ====================================================

export interface vehicleFiltersQuery_tags {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleFiltersQuery_assetDamageTypesConnection_edges_node_localeName {
  __typename: "LocaleString";
  text: string;
  language: LanguageCode;
}

export interface vehicleFiltersQuery_assetDamageTypesConnection_edges_node_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
}

export interface vehicleFiltersQuery_assetDamageTypesConnection_edges_node {
  __typename: "AssetDamageType";
  id: string;
  name: string | null;
  localeName: (vehicleFiltersQuery_assetDamageTypesConnection_edges_node_localeName | null)[] | null;
  model: vehicleFiltersQuery_assetDamageTypesConnection_edges_node_model | null;
}

export interface vehicleFiltersQuery_assetDamageTypesConnection_edges {
  __typename: "AssetDamageTypeEdge";
  cursor: string;
  node: vehicleFiltersQuery_assetDamageTypesConnection_edges_node;
}

export interface vehicleFiltersQuery_assetDamageTypesConnection {
  __typename: "AssetDamageTypesConnection";
  edges: vehicleFiltersQuery_assetDamageTypesConnection_edges[];
}

export interface vehicleFiltersQuery {
  tags: vehicleFiltersQuery_tags[];
  assetDamageTypesConnection: vehicleFiltersQuery_assetDamageTypesConnection | null;
}

export interface vehicleFiltersQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unlockAndAssignToMe
// ====================================================

export interface unlockAndAssignToMe_unlockAndAssignToMe {
  __typename: "Vehicle";
  id: string;
}

export interface unlockAndAssignToMe {
  unlockAndAssignToMe: unlockAndAssignToMe_unlockAndAssignToMe;
}

export interface unlockAndAssignToMeVariables {
  vehicleId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: blockVehicles
// ====================================================

export interface blockVehicles {
  /**
   * Make vehicles unavailable for rental. Returns IDs of blocked vehicles
   */
  blockVehicles: string[];
}

export interface blockVehiclesVariables {
  systemId: string;
  vehicleIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unblockVehicles
// ====================================================

export interface unblockVehicles {
  /**
   * Cancel vehicles block. Returns IDs of unblocked vehicles
   */
  unblockVehicles: string[];
}

export interface unblockVehiclesVariables {
  systemId: string;
  vehicleIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listOfVehiclesQuery
// ====================================================

export interface listOfVehiclesQuery_vehiclesPaginated_rows_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface listOfVehiclesQuery_vehiclesPaginated_rows_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface listOfVehiclesQuery_vehiclesPaginated_rows_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type listOfVehiclesQuery_vehiclesPaginated_rows_displayName = listOfVehiclesQuery_vehiclesPaginated_rows_displayName_VehicleDisplayNamed | listOfVehiclesQuery_vehiclesPaginated_rows_displayName_VehicleDisplayNumbered | listOfVehiclesQuery_vehiclesPaginated_rows_displayName_VehicleDisplayAnonymous;

export interface listOfVehiclesQuery_vehiclesPaginated_rows_controller {
  __typename: "Controller";
  id: string;
  serialNumber: string | null;
}

export interface listOfVehiclesQuery_vehiclesPaginated_rows_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
}

export interface listOfVehiclesQuery_vehiclesPaginated_rows_entityTags {
  __typename: "EntityTag";
  tag: listOfVehiclesQuery_vehiclesPaginated_rows_entityTags_tag;
}

export interface listOfVehiclesQuery_vehiclesPaginated_rows {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: listOfVehiclesQuery_vehiclesPaginated_rows_displayName;
  controller: listOfVehiclesQuery_vehiclesPaginated_rows_controller | null;
  entityTags: listOfVehiclesQuery_vehiclesPaginated_rows_entityTags[] | null;
}

export interface listOfVehiclesQuery_vehiclesPaginated {
  __typename: "VehiclesPaginated";
  rows: listOfVehiclesQuery_vehiclesPaginated_rows[];
}

export interface listOfVehiclesQuery {
  vehiclesPaginated: listOfVehiclesQuery_vehiclesPaginated | null;
}

export interface listOfVehiclesQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: moveVehicleToOperationLocation
// ====================================================

export interface moveVehicleToOperationLocation_moveVehicleToOperationLocation_operationLocation {
  __typename: "OperationLocation";
  id: string;
  name: string;
}

export interface moveVehicleToOperationLocation_moveVehicleToOperationLocation {
  __typename: "VehicleAvailability";
  id: string;
  operationLocation: moveVehicleToOperationLocation_moveVehicleToOperationLocation_operationLocation | null;
}

export interface moveVehicleToOperationLocation {
  moveVehicleToOperationLocation: moveVehicleToOperationLocation_moveVehicleToOperationLocation | null;
}

export interface moveVehicleToOperationLocationVariables {
  vehicleId: string;
  operationLocationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ownVehicle
// ====================================================

export interface ownVehicle_ownVehicle {
  __typename: "Vehicle";
  id: string;
}

export interface ownVehicle {
  ownVehicle: ownVehicle_ownVehicle | null;
}

export interface ownVehicleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetVehicleAvailabilityToMissing
// ====================================================

export interface SetVehicleAvailabilityToMissing_setVehicleAvailabilityToMissing {
  __typename: "Vehicle";
  id: string;
}

export interface SetVehicleAvailabilityToMissing {
  setVehicleAvailabilityToMissing: SetVehicleAvailabilityToMissing_setVehicleAvailabilityToMissing | null;
}

export interface SetVehicleAvailabilityToMissingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetVehicleAvailabilityToLost
// ====================================================

export interface SetVehicleAvailabilityToLost_setVehicleAvailabilityToLost {
  __typename: "Vehicle";
  id: string;
}

export interface SetVehicleAvailabilityToLost {
  setVehicleAvailabilityToLost: SetVehicleAvailabilityToLost_setVehicleAvailabilityToLost | null;
}

export interface SetVehicleAvailabilityToLostVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: vehicleLocationNotTrustworthy
// ====================================================

export interface vehicleLocationNotTrustworthy_vehicleLocationNotTrustworthy {
  __typename: "Vehicle";
  id: string;
}

export interface vehicleLocationNotTrustworthy {
  vehicleLocationNotTrustworthy: vehicleLocationNotTrustworthy_vehicleLocationNotTrustworthy | null;
}

export interface vehicleLocationNotTrustworthyVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupsToDeployTo
// ====================================================

export interface dockGroupsToDeployTo_dockGroupsByCoord {
  __typename: "DockGroup";
  id: string;
  title: string | null;
  name: string | null;
}

export interface dockGroupsToDeployTo_dockGroupsConnection_edges_node {
  __typename: "DockGroupListItem";
  id: string;
  title: string | null;
  name: string | null;
}

export interface dockGroupsToDeployTo_dockGroupsConnection_edges {
  __typename: "DockGroupEdge";
  node: dockGroupsToDeployTo_dockGroupsConnection_edges_node;
}

export interface dockGroupsToDeployTo_dockGroupsConnection {
  __typename: "DockGroupsConnection";
  edges: dockGroupsToDeployTo_dockGroupsConnection_edges[];
}

export interface dockGroupsToDeployTo {
  dockGroupsByCoord: dockGroupsToDeployTo_dockGroupsByCoord[] | null;
  dockGroupsConnection: dockGroupsToDeployTo_dockGroupsConnection | null;
}

export interface dockGroupsToDeployToVariables {
  systemId: string;
  lat: number;
  lng: number;
  fetchByCoords: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: dockVehicle
// ====================================================

export interface dockVehicle_dockVehicle {
  __typename: "VehicleAvailability";
  id: string;
}

export interface dockVehicle {
  dockVehicle: dockVehicle_dockVehicle | null;
}

export interface dockVehicleVariables {
  vehicleId: string;
  dockGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehiclePositionQuery
// ====================================================

export interface vehiclePositionQuery_vehicle_positionLog_position {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehiclePositionQuery_vehicle_positionLog {
  __typename: "PositionLogEntry";
  generatedAt: CORE_IsoDateTime;
  speedKMH: number | null;
  accuracy: number | null;
  positionSource: PositionSource;
  position: vehiclePositionQuery_vehicle_positionLog_position;
}

export interface vehiclePositionQuery_vehicle {
  __typename: "Vehicle";
  id: string;
  positionLog: (vehiclePositionQuery_vehicle_positionLog | null)[] | null;
}

export interface vehiclePositionQuery {
  vehicle: vehiclePositionQuery_vehicle | null;
}

export interface vehiclePositionQueryVariables {
  systemId: string;
  vehicleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: decommissionVehicleMutation
// ====================================================

export interface decommissionVehicleMutation_decommissionVehicle {
  __typename: "Vehicle";
  id: string;
}

export interface decommissionVehicleMutation {
  decommissionVehicle: decommissionVehicleMutation_decommissionVehicle;
}

export interface decommissionVehicleMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleBatteryChartQuery
// ====================================================

export interface vehicleBatteryChartQuery_vehicle_batteryLogData {
  __typename: "BatteryLog";
  id: string;
  voltage: number | null;
  percentage: number | null;
  generatedAt: CORE_IsoDateTime | null;
}

export interface vehicleBatteryChartQuery_vehicle {
  __typename: "Vehicle";
  id: string;
  batteryCharge: number | null;
  batteryLogData: (vehicleBatteryChartQuery_vehicle_batteryLogData | null)[] | null;
}

export interface vehicleBatteryChartQuery {
  vehicle: vehicleBatteryChartQuery_vehicle | null;
}

export interface vehicleBatteryChartQueryVariables {
  systemId: string;
  vehicleId: string;
  fromTime?: CORE_IsoDateTime | null;
  toTime?: CORE_IsoDateTime | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleTimelineQuery
// ====================================================

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertUpdated {
  __typename: "SystemAlertUpdated";
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicleAvailabilityDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicleAvailabilityDock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  type: DockType;
  dockGroup: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicleAvailabilityDock_dockGroup | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicleAvailabilityLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicleAvailabilityLocation {
  __typename: "VehicleLocation";
  location: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicleAvailabilityLocation_location;
  address: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_displayName = vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_displayName_VehicleDisplayNamed | vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_displayName_VehicleDisplayNumbered | vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_displayName_VehicleDisplayAnonymous;

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_displayName;
  model: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle_model | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_startDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_startDock_dockGroup | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_endDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_endDock_dockGroup | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_startLocation {
  __typename: "VehicleLocation";
  location: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_startLocation_location;
  address: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_endLocation {
  __typename: "VehicleLocation";
  location: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_endLocation_location;
  address: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_entityTags_tag;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_order {
  __typename: "Order";
  id: string;
  amount: CORE_Money;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_startDock | null;
  endDock: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_endDock | null;
  startLocation: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_startLocation | null;
  endLocation: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_endLocation | null;
  subscription: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_subscription | null;
  entityTags: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_entityTags[] | null;
  duration: number | null;
  userAgent: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_userAgent | null;
  cancelledByAdmin: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_cancelledByAdmin | null;
  order: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip_order | null;
  cancelledReason: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_VehicleAvailability {
  __typename: "VehicleAvailability";
  id: string;
  systemId: string | null;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  administrator: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_administrator | null;
  vehicleAvailabilityDock: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicleAvailabilityDock | null;
  vehicleAvailabilityLocation: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicleAvailabilityLocation | null;
  vehicle: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_vehicle;
  trip: vehicleTimelineQuery_vehicle_timeline_VehicleAvailability_trip | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_MutationLog {
  __typename: "MutationLog";
  id: string;
  createdAt: CORE_IsoDateTime;
  mutations: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertEnded_systemAlert_resolvedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertEnded_systemAlert_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertEnded_systemAlert {
  __typename: "SystemAlert";
  id: string;
  incidentEndTime: CORE_IsoDateTime | null;
  systemAlertType: SystemAlertType;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: vehicleTimelineQuery_vehicle_timeline_SystemAlertEnded_systemAlert_resolvedBy | null;
  title: vehicleTimelineQuery_vehicle_timeline_SystemAlertEnded_systemAlert_title[] | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertEnded {
  __typename: "SystemAlertEnded";
  id: string;
  createdAt: CORE_IsoDateTime;
  systemAlert: vehicleTimelineQuery_vehicle_timeline_SystemAlertEnded_systemAlert;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_description {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_updates_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  title: vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_updates_title[] | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert {
  __typename: "SystemAlert";
  id: string;
  createdAt: CORE_IsoDateTime;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  systemAlertType: SystemAlertType;
  title: vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_title[] | null;
  description: vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_description[] | null;
  administrator: vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_administrator;
  dockGroups: (vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_dockGroups | null)[] | null;
  updates: (vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert_updates | null)[] | null;
}

export interface vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted {
  __typename: "SystemAlertStarted";
  id: string;
  createdAt: CORE_IsoDateTime;
  systemAlert: vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted_systemAlert;
}

export type vehicleTimelineQuery_vehicle_timeline = vehicleTimelineQuery_vehicle_timeline_SystemAlertUpdated | vehicleTimelineQuery_vehicle_timeline_VehicleAvailability | vehicleTimelineQuery_vehicle_timeline_MutationLog | vehicleTimelineQuery_vehicle_timeline_SystemAlertEnded | vehicleTimelineQuery_vehicle_timeline_SystemAlertStarted;

export interface vehicleTimelineQuery_vehicle_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleTimelineQuery_vehicle_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleTimelineQuery_vehicle_entityTags_tag;
}

export interface vehicleTimelineQuery_vehicle {
  __typename: "Vehicle";
  id: string;
  timeline: (vehicleTimelineQuery_vehicle_timeline | null)[] | null;
  entityTags: vehicleTimelineQuery_vehicle_entityTags[] | null;
}

export interface vehicleTimelineQuery {
  vehicle: vehicleTimelineQuery_vehicle | null;
}

export interface vehicleTimelineQueryVariables {
  systemId: string;
  id: string;
  skipVehicle: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleTimelineConnectionQuery
// ====================================================

export interface vehicleTimelineConnectionQuery_vehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
  hardwareBridge: HardwareBridge | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_controller {
  __typename: "Controller";
  id: string;
  ip: string | null;
  imei: string | null;
  serialNumber: string | null;
  physicalLockId: string | null;
  firmwareVersion: string | null;
  state: ControllerState | null;
  powerState: PowerState | null;
  model: vehicleTimelineConnectionQuery_vehicle_controller_model | null;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
}

export interface vehicleTimelineConnectionQuery_vehicle_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  tag: vehicleTimelineConnectionQuery_vehicle_entityTags_tag;
}

export interface vehicleTimelineConnectionQuery_vehicle_availability_operationLocation {
  __typename: "OperationLocation";
  id: string;
  name: string;
  type: OperationLocationType;
}

export interface vehicleTimelineConnectionQuery_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  operationLocation: vehicleTimelineConnectionQuery_vehicle_availability_operationLocation | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_damageType_localeName {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_damageType {
  __typename: "AssetDamageType";
  id: string;
  localeName: (vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_damageType_localeName | null)[] | null;
  name: string | null;
  critical: boolean | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_reportedBy_Administrator {
  __typename: "Administrator";
  id: string;
  firstName: string | null;
  profilePictureUrl: string | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_reportedBy_User {
  __typename: "User";
  id: string;
}

export type vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_reportedBy = vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_reportedBy_Administrator | vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_reportedBy_User;

export interface vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages {
  __typename: "AssetMaintenanceDamage";
  damageTypeId: string | null;
  enforceCritical: boolean;
  damageType: vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_damageType | null;
  reportedBy: vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages_reportedBy | null;
  comment: string | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_latestMaintenance {
  __typename: "AssetMaintenance";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  completedAt: CORE_IsoDateTime | null;
  damages: vehicleTimelineConnectionQuery_vehicle_latestMaintenance_damages[] | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  startCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicleAvailabilityDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicleAvailabilityDock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  type: DockType;
  dockGroup: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicleAvailabilityDock_dockGroup | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicleAvailabilityLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicleAvailabilityLocation {
  __typename: "VehicleLocation";
  location: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicleAvailabilityLocation_location;
  address: string | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_displayName = vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayNamed | vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayNumbered | vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_displayName_VehicleDisplayAnonymous;

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_displayName;
  model: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle_model | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_startDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_startDock_dockGroup | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_endDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_endDock_dockGroup | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_startLocation {
  __typename: "VehicleLocation";
  location: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_startLocation_location;
  address: string | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_endLocation {
  __typename: "VehicleLocation";
  location: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_endLocation_location;
  address: string | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_entityTags_tag;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_order {
  __typename: "Order";
  id: string;
  amount: CORE_Money;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_startDock | null;
  endDock: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_endDock | null;
  startLocation: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_startLocation | null;
  endLocation: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_endLocation | null;
  subscription: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_subscription | null;
  entityTags: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_entityTags[] | null;
  duration: number | null;
  userAgent: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_userAgent | null;
  cancelledByAdmin: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_cancelledByAdmin | null;
  order: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip_order | null;
  cancelledReason: string | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability {
  __typename: "VehicleAvailability";
  id: string;
  systemId: string | null;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  administrator: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_administrator | null;
  vehicleAvailabilityDock: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicleAvailabilityDock | null;
  vehicleAvailabilityLocation: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicleAvailabilityLocation | null;
  vehicle: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_vehicle;
  trip: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability_trip | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_TypedMutationLogNode_mutations {
  __typename: "TypedMutation";
  lhs: CORE_JSON | null;
  rhs: CORE_JSON | null;
  kind: MutationKind | null;
  path: (string | null)[] | null;
  index: string | null;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_TypedMutationLogNode {
  __typename: "TypedMutationLogNode";
  id: string;
  createdAt: CORE_IsoDateTime;
  mutations: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_TypedMutationLogNode_mutations[];
}

export type vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node = vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_VehicleAvailability | vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node_TypedMutationLogNode;

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges {
  __typename: "VehicleTimelineConnectionEdge";
  cursor: string;
  node: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges_node;
}

export interface vehicleTimelineConnectionQuery_vehicle_timelineConnection {
  __typename: "VehicleTimelineConnection";
  pageInfo: vehicleTimelineConnectionQuery_vehicle_timelineConnection_pageInfo;
  edges: vehicleTimelineConnectionQuery_vehicle_timelineConnection_edges[];
}

export interface vehicleTimelineConnectionQuery_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  controller: vehicleTimelineConnectionQuery_vehicle_controller | null;
  entityTags: vehicleTimelineConnectionQuery_vehicle_entityTags[] | null;
  availability: vehicleTimelineConnectionQuery_vehicle_availability | null;
  latestMaintenance: vehicleTimelineConnectionQuery_vehicle_latestMaintenance | null;
  quickReturnCount: number | null;
  timelineConnection: vehicleTimelineConnectionQuery_vehicle_timelineConnection | null;
}

export interface vehicleTimelineConnectionQuery {
  vehicle: vehicleTimelineConnectionQuery_vehicle | null;
}

export interface vehicleTimelineConnectionQueryVariables {
  vehicleId: string;
  systemId: string;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  itemTypes?: VehicleTimelineConnectionItemTypes[] | null;
  skipVehicle: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createVehicleExportMutation
// ====================================================

export interface createVehicleExportMutation_createVehicleDataExportRequest_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle_controller_model {
  __typename: "AssetModel";
  modelId: string;
  modelName: string | null;
  description: string | null;
  type: AssetType;
  vehicleCategory: VehicleCategory | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle_controller {
  __typename: "Controller";
  controllerId: string;
  firmwareVersion: string | null;
  controllerBatteryStatus: BatteryStatus | null;
  controllerBatteryVoltage: number | null;
  controllerBatteryCharge: number | null;
  latestResponse: CORE_IsoDateTime | null;
  model: createVehicleExportMutation_createVehicleDataExportRequest_vehicle_controller_model | null;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability_dock_dockGroup {
  __typename: "DockGroup";
  dockGroupId: string;
  dockGroupTitle: string | null;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability_dock {
  __typename: "VehicleAvailabilityDock";
  dockId: string;
  dockNumber: number | null;
  dockGroup: createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability_dock_dockGroup | null;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability_administrator {
  __typename: "Administrator";
  administratorId: string;
  administratorName: string | null;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability_operationLocation {
  __typename: "OperationLocation";
  operationLocationId: string;
  operationLocationName: string;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability {
  __typename: "VehicleAvailability";
  availabilityState: AvailabilityState | null;
  availabilityReason: AvailabilityStateReason | null;
  availabilityUpdatedAt: CORE_IsoDateTime | null;
  dock: createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability_dock | null;
  administrator: createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability_administrator | null;
  operationLocation: createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability_operationLocation | null;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle_entityTags_tag {
  __typename: "Tag";
  tagId: string;
  tagName: string;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle_entityTags {
  __typename: "EntityTag";
  entityKind: Entity | null;
  entityId: string;
  tag: createVehicleExportMutation_createVehicleDataExportRequest_vehicle_entityTags_tag;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest_vehicle {
  __typename: "Vehicle";
  vehicleId: string;
  vehicleName: string | null;
  vehicleNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  bikeBatteryCharge: number | null;
  controller: createVehicleExportMutation_createVehicleDataExportRequest_vehicle_controller | null;
  availabilityState: VehicleAvailabilityState;
  availability: createVehicleExportMutation_createVehicleDataExportRequest_vehicle_availability | null;
  frameNumber: string | null;
  entityTags: createVehicleExportMutation_createVehicleDataExportRequest_vehicle_entityTags[] | null;
}

export interface createVehicleExportMutation_createVehicleDataExportRequest {
  __typename: "CreateVehicleDataExportRequestResponse";
  dataExportRequest: createVehicleExportMutation_createVehicleDataExportRequest_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual vehicle.
   */
  vehicle: createVehicleExportMutation_createVehicleDataExportRequest_vehicle | null;
}

export interface createVehicleExportMutation {
  createVehicleDataExportRequest: createVehicleExportMutation_createVehicleDataExportRequest;
}

export interface createVehicleExportMutationVariables {
  systemId: string;
  order?: VehicleOrder | null;
  filters?: VehicleFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehiclesMapQuery
// ====================================================

export interface vehiclesMapQuery_paginatedVehicles_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalRowCount: number;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
  hardwareBridge: HardwareBridge | null;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  batteryStatus: BatteryStatus | null;
  batteryVoltage: number | null;
  batteryCharge: number | null;
  latestResponse: CORE_IsoDateTime | null;
  model: vehiclesMapQuery_paginatedVehicles_rows_controller_model | null;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: vehiclesMapQuery_paginatedVehicles_rows_availability_dock_dockGroup | null;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_availability_operationLocation {
  __typename: "OperationLocation";
  id: string;
  name: string;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  updatedAt: CORE_IsoDateTime | null;
  dock: vehiclesMapQuery_paginatedVehicles_rows_availability_dock | null;
  administrator: vehiclesMapQuery_paginatedVehicles_rows_availability_administrator | null;
  operationLocation: vehiclesMapQuery_paginatedVehicles_rows_availability_operationLocation | null;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehiclesMapQuery_paginatedVehicles_rows_entityTags_tag;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
  vehicleCategory: VehicleCategory | null;
}

export interface vehiclesMapQuery_paginatedVehicles_rows_position {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehiclesMapQuery_paginatedVehicles_rows {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  updatedAt: CORE_IsoDateTime | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  controller: vehiclesMapQuery_paginatedVehicles_rows_controller | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  availability: vehiclesMapQuery_paginatedVehicles_rows_availability | null;
  frameNumber: string | null;
  entityTags: vehiclesMapQuery_paginatedVehicles_rows_entityTags[] | null;
  model: vehiclesMapQuery_paginatedVehicles_rows_model | null;
  position: vehiclesMapQuery_paginatedVehicles_rows_position | null;
}

export interface vehiclesMapQuery_paginatedVehicles {
  __typename: "PaginatedVehicles";
  paginationInfo: vehiclesMapQuery_paginatedVehicles_paginationInfo;
  rows: vehiclesMapQuery_paginatedVehicles_rows[];
}

export interface vehiclesMapQuery {
  paginatedVehicles: vehiclesMapQuery_paginatedVehicles;
}

export interface vehiclesMapQueryVariables {
  systemId: string;
  pagination?: OffsetPagination | null;
  order?: VehicleOrder | null;
  filters?: VehicleFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteDockGroup
// ====================================================

export interface deleteDockGroup_deleteDockGroup {
  __typename: "DockGroup";
  id: string;
}

export interface deleteDockGroup {
  deleteDockGroup: deleteDockGroup_deleteDockGroup | null;
}

export interface deleteDockGroupVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDockGroup
// ====================================================

export interface createDockGroup_createDockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface createDockGroup {
  createDockGroup: createDockGroup_createDockGroup | null;
}

export interface createDockGroupVariables {
  input?: DockGroupCreateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDockGroup
// ====================================================

export interface updateDockGroup_updateDockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
}

export interface updateDockGroup {
  updateDockGroup: updateDockGroup_updateDockGroup | null;
}

export interface updateDockGroupVariables {
  id: string;
  input?: DockGroupUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteDock
// ====================================================

export interface deleteDock_deleteDock {
  __typename: "Dock";
  id: string;
}

export interface deleteDock {
  deleteDock: deleteDock_deleteDock | null;
}

export interface deleteDockVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDock
// ====================================================

export interface createDock_createDock {
  __typename: "Dock";
  id: string;
}

export interface createDock {
  createDock: createDock_createDock | null;
}

export interface createDockVariables {
  systemId: string;
  dockGroupId: string;
  input?: DockInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDock
// ====================================================

export interface updateDock_updateDock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface updateDock {
  updateDock: updateDock_updateDock | null;
}

export interface updateDockVariables {
  id: string;
  input?: DockInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addComment
// ====================================================

export interface addComment_addComment {
  __typename: "Comment";
  id: string;
  body: string | null;
}

export interface addComment {
  addComment: addComment_addComment | null;
}

export interface addCommentVariables {
  entityKind: Entity;
  entityId: string;
  body: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addVehicleComment
// ====================================================

export interface addVehicleComment_addVehicleComment {
  __typename: "VehicleComment";
  id: string;
  comment: string;
}

export interface addVehicleComment {
  addVehicleComment: addVehicleComment_addVehicleComment | null;
}

export interface addVehicleCommentVariables {
  vehicleId: string;
  administratorId: string;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addTripComment
// ====================================================

export interface addTripComment_addTripComment {
  __typename: "TripComment";
  id: string;
  comment: string;
}

export interface addTripComment {
  addTripComment: addTripComment_addTripComment | null;
}

export interface addTripCommentVariables {
  tripId: string;
  comment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addDockGroupComment
// ====================================================

export interface addDockGroupComment_addDockGroupComment {
  __typename: "DockGroupComment";
  id: string;
  comment: string;
}

export interface addDockGroupComment {
  addDockGroupComment: addDockGroupComment_addDockGroupComment | null;
}

export interface addDockGroupCommentVariables {
  dockGroupId: string;
  comment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cancelOrder
// ====================================================

export interface cancelOrder_cancelOrder {
  __typename: "Order";
  id: string;
}

export interface cancelOrder {
  cancelOrder: cancelOrder_cancelOrder | null;
}

export interface cancelOrderVariables {
  orderId: string;
  reason: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSystem
// ====================================================

export interface deleteSystem_deleteSystem {
  __typename: "System";
  id: string;
}

export interface deleteSystem {
  deleteSystem: deleteSystem_deleteSystem | null;
}

export interface deleteSystemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRole
// ====================================================

export interface createRole_createRole {
  __typename: "Role";
  id: string;
}

export interface createRole {
  createRole: createRole_createRole | null;
}

export interface createRoleVariables {
  input?: RoleInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRole
// ====================================================

export interface deleteRole_deleteRole {
  __typename: "Role";
  id: string;
}

export interface deleteRole {
  deleteRole: deleteRole_deleteRole | null;
}

export interface deleteRoleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRole
// ====================================================

export interface updateRole_updateRole {
  __typename: "Role";
  id: string;
}

export interface updateRole {
  updateRole: updateRole_updateRole | null;
}

export interface updateRoleVariables {
  id: string;
  input?: RoleInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAdministratorMutation
// ====================================================

export interface updateAdministratorMutation_updateAdministrator_roles_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface updateAdministratorMutation_updateAdministrator_roles {
  __typename: "Role";
  id: string;
  name: string;
  acl: updateAdministratorMutation_updateAdministrator_roles_acl[];
}

export interface updateAdministratorMutation_updateAdministrator_rfidAuth {
  __typename: "RfidAuth";
  id: string;
  enabled: boolean;
  rfid: string;
  systemId: string;
  action: RfidAuthAction;
  parameters: string[] | null;
}

export interface updateAdministratorMutation_updateAdministrator_selectedSystem_coords {
  __typename: "Coord";
  lng: number;
  lat: number;
}

export interface updateAdministratorMutation_updateAdministrator_selectedSystem_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface updateAdministratorMutation_updateAdministrator_selectedSystem_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: updateAdministratorMutation_updateAdministrator_selectedSystem_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface updateAdministratorMutation_updateAdministrator_selectedSystem_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface updateAdministratorMutation_updateAdministrator_selectedSystem {
  __typename: "System";
  name: string;
  description: string;
  state: SystemState;
  coords: updateAdministratorMutation_updateAdministrator_selectedSystem_coords[] | null;
  type: SystemType;
  countryName: string;
  countryCode: string;
  cityName: string;
  defaultLanguageCode: LanguageCode;
  languageCodes: LanguageCode[];
  operator: string | null;
  currency: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  timezone: string;
  maxPenaltyPoints: number | null;
  sanityProjectId: string | null;
  sanityDataset: string | null;
  sanityToken: string | null;
  colourKey: SystemColourKey | null;
  publicDomain: string | null;
  workingHoursId: string | null;
  vehicleQuickReturnPeriodSec: number | null;
  vehicleQuickReturnsToBroken: number | null;
  vehicleLostPeriodDays: number | null;
  dispatcherSmsSenderID: string | null;
  iosAppUrl: string | null;
  androidAppUrl: string | null;
  webUrl: string | null;
  sponsor: Sponsor | null;
  settings: CORE_JSON;
  intercomHelpSite: string | null;
  defaultVatRate: number;
  tripAdditionalMinutesPeriod: CORE_NonNegativeInt;
  maxTripAdditionaMinutesPeriodCount: CORE_NonNegativeInt;
  minTripLengthForCoolOffPeriodSec: number | null;
  /**
   * minutes prior to the end of free included period when you can request Additional Free Minutes
   */
  timeToRequestAdditionalMinutes: CORE_NonNegativeInt;
  coolOffPeriodSec: number | null;
  pauseTripEnabled: FeatureToggleValue | null;
  shouldAskForPushPermissions: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  qrCodeScanEnabled: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled: FeatureToggleValue | null;
  vehicleReservationEnabled: FeatureToggleValue | null;
  /**
   * Deep linking prefix used in apps.
   * 
   * Keep the value in sync with the mobile app configurations:
   * Android: Each system_id_deeplinking_prefix should map to systemId
   * https: // github.com/urbaninfrastructure/bikesharing-multicity-android/blob/master/app/build.gradle#L161
   */
  appDeepLinkingPrefix: string | null;
  /**
   * If enabled users who have unpaid trip orders can not take bikes
   */
  suspendDebtorEnabled: boolean;
  /**
   * ClickUp URL to be used in an Iframe for the Feedback form in Admin.
   * Falls back to the old Slack integration if not set
   */
  feedbackFormUrl: string | null;
  /**
   * ClickUp list id used to fetch support tasks.
   */
  clickUpListId: string | null;
  /**
   * If enabled trip charges are consolidated within an invoice
   */
  tripChargeConsolidationEnabled: boolean;
  /**
   * Defines the days amount during which trip charges are consolidated, an invoice payment is triggered once exceeded
   */
  tripChargeConsolidationPeriod: CORE_PositiveInt;
  /**
   * Defines the total charges amount required in order to trigger payment
   */
  tripChargeConsolidationAmount: CORE_Money;
  /**
   * If enabled new app users should pay a fee
   */
  registrationFeeEnabled: boolean;
  /**
   * Defines the amount of the registration fee
   */
  registrationFeeAmount: CORE_Money;
  /**
   * Enforcement of registration agreement withdrawal consent
   */
  registrationAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of subscription agreement withdrawal consent
   */
  subscriptionAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of consent to send data to third parties
   */
  sendDataToThirdPartiesConsent: ConsentEnforcement;
  /**
   * Defines wallet enablement
   */
  walletEnablement: WalletEnablement;
  /**
   * Defines wallet enablement
   */
  walletMinBalance: CORE_Money;
  vehicleReservationCoolOffPeriodSec: CORE_NonNegativeInt;
  vehicleReservationLimitPerDay: CORE_NonNegativeInt;
  freeFloatingEnabled: boolean;
  vehicleReservationQuickCancellationPeriodSec: CORE_NonNegativeInt;
  vehicleReservationPeriodMins: CORE_NonNegativeInt;
  /**
   * If enabled email with trip details is sent to a user
   */
  tripReceiptEmailNotificationEnabled: boolean;
  /**
   * If enabled push notification with trip details is sent to a user
   */
  tripReceiptPushNotificationEnabled: boolean;
  id: string;
  vehicleCategories: VehicleCategory[];
  connectionPriceMatrixes: updateAdministratorMutation_updateAdministrator_selectedSystem_connectionPriceMatrixes[] | null;
  partners: updateAdministratorMutation_updateAdministrator_selectedSystem_partners[];
}

export interface updateAdministratorMutation_updateAdministrator {
  __typename: "Administrator";
  id: string;
  roles: updateAdministratorMutation_updateAdministrator_roles[];
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
  rfidAuth: (updateAdministratorMutation_updateAdministrator_rfidAuth | null)[] | null;
  settings: CORE_JSON;
  createdAt: CORE_IsoDateTime;
  operationsApiExternalId: string | null;
  selectedSystem: updateAdministratorMutation_updateAdministrator_selectedSystem;
}

export interface updateAdministratorMutation {
  updateAdministrator: updateAdministratorMutation_updateAdministrator | null;
}

export interface updateAdministratorMutationVariables {
  id: string;
  input?: AdministratorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTag
// ====================================================

export interface createTag_createTag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
}

export interface createTag {
  createTag: createTag_createTag | null;
}

export interface createTagVariables {
  input?: TagInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTag
// ====================================================

export interface updateTag_updateTag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
}

export interface updateTag {
  updateTag: updateTag_updateTag | null;
}

export interface updateTagVariables {
  id: string;
  input?: TagInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteTag
// ====================================================

export interface deleteTag_deleteTag {
  __typename: "Tag";
  id: string;
}

export interface deleteTag {
  deleteTag: deleteTag_deleteTag | null;
}

export interface deleteTagVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAssetModel
// ====================================================

export interface updateAssetModel_updateAssetModel {
  __typename: "AssetModel";
  id: string;
}

export interface updateAssetModel {
  updateAssetModel: updateAssetModel_updateAssetModel | null;
}

export interface updateAssetModelVariables {
  id: string;
  input?: AssetModelUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAssetModel
// ====================================================

export interface createAssetModel_createAssetModel {
  __typename: "AssetModel";
  id: string;
}

export interface createAssetModel {
  createAssetModel: createAssetModel_createAssetModel | null;
}

export interface createAssetModelVariables {
  input?: AssetModelCreateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAssetDamageType
// ====================================================

export interface updateAssetDamageType_updateAssetDamageType {
  __typename: "AssetDamageType";
  id: string;
}

export interface updateAssetDamageType {
  updateAssetDamageType: updateAssetDamageType_updateAssetDamageType | null;
}

export interface updateAssetDamageTypeVariables {
  id: string;
  input?: AssetDamageTypeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAssetDamageType
// ====================================================

export interface deleteAssetDamageType_deleteAssetDamageType {
  __typename: "AssetDamageType";
  id: string;
}

export interface deleteAssetDamageType {
  deleteAssetDamageType: deleteAssetDamageType_deleteAssetDamageType | null;
}

export interface deleteAssetDamageTypeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAssetDamageType
// ====================================================

export interface createAssetDamageType_createAssetDamageType {
  __typename: "AssetDamageType";
  id: string;
}

export interface createAssetDamageType {
  createAssetDamageType: createAssetDamageType_createAssetDamageType | null;
}

export interface createAssetDamageTypeVariables {
  input?: AssetDamageTypeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAssetPart
// ====================================================

export interface updateAssetPart_updateAssetPart_model {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
  name: string | null;
  description: string | null;
}

export interface updateAssetPart_updateAssetPart {
  __typename: "AssetPart";
  id: string;
  model: updateAssetPart_updateAssetPart_model | null;
  name: string | null;
  order: number | null;
  updatedAt: CORE_IsoDateTime | null;
}

export interface updateAssetPart {
  updateAssetPart: updateAssetPart_updateAssetPart | null;
}

export interface updateAssetPartVariables {
  id: string;
  input?: AssetPartInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAssetPart
// ====================================================

export interface deleteAssetPart_deleteAssetPart {
  __typename: "AssetPart";
  id: string;
}

export interface deleteAssetPart {
  deleteAssetPart: deleteAssetPart_deleteAssetPart | null;
}

export interface deleteAssetPartVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAssetPart
// ====================================================

export interface createAssetPart_createAssetPart_model {
  __typename: "AssetModel";
  id: string;
  type: AssetType;
  name: string | null;
  description: string | null;
}

export interface createAssetPart_createAssetPart {
  __typename: "AssetPart";
  id: string;
  model: createAssetPart_createAssetPart_model | null;
  name: string | null;
  order: number | null;
  updatedAt: CORE_IsoDateTime | null;
}

export interface createAssetPart {
  createAssetPart: createAssetPart_createAssetPart | null;
}

export interface createAssetPartVariables {
  input?: AssetPartInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAssetRepairType
// ====================================================

export interface updateAssetRepairType_updateAssetRepairType {
  __typename: "AssetRepairType";
  id: string;
}

export interface updateAssetRepairType {
  updateAssetRepairType: updateAssetRepairType_updateAssetRepairType | null;
}

export interface updateAssetRepairTypeVariables {
  id: string;
  input?: AssetRepairTypeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAssetRepairType
// ====================================================

export interface deleteAssetRepairType_deleteAssetRepairType {
  __typename: "AssetRepairType";
  id: string;
}

export interface deleteAssetRepairType {
  deleteAssetRepairType: deleteAssetRepairType_deleteAssetRepairType | null;
}

export interface deleteAssetRepairTypeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAssetRepairType
// ====================================================

export interface createAssetRepairType_createAssetRepairType {
  __typename: "AssetRepairType";
  id: string;
}

export interface createAssetRepairType {
  createAssetRepairType: createAssetRepairType_createAssetRepairType | null;
}

export interface createAssetRepairTypeVariables {
  input?: AssetRepairTypeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAssetMaintenance
// ====================================================

export interface createAssetMaintenance_createAssetMaintenance {
  __typename: "AssetMaintenance";
  id: string;
}

export interface createAssetMaintenance {
  createAssetMaintenance: createAssetMaintenance_createAssetMaintenance | null;
}

export interface createAssetMaintenanceVariables {
  input: AssetMaintenanceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAssetMaintenance
// ====================================================

export interface updateAssetMaintenance_updateAssetMaintenance {
  __typename: "AssetMaintenance";
  id: string;
}

export interface updateAssetMaintenance {
  updateAssetMaintenance: updateAssetMaintenance_updateAssetMaintenance | null;
}

export interface updateAssetMaintenanceVariables {
  id: string;
  input: AssetMaintenanceInput;
  assetRepaired?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: reportAssetDamage
// ====================================================

export interface reportAssetDamage_reportAssetDamage {
  __typename: "AssetMaintenance";
  assetId: string;
}

export interface reportAssetDamage {
  reportAssetDamage: reportAssetDamage_reportAssetDamage | null;
}

export interface reportAssetDamageVariables {
  systemId: string;
  assetId: string;
  assetType: AssetType;
  damages: (AssetMaintenanceDamageInput | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createValueCode
// ====================================================

export interface createValueCode_createValueCode {
  __typename: "ValueCode";
  id: string;
}

export interface createValueCode {
  createValueCode: createValueCode_createValueCode | null;
}

export interface createValueCodeVariables {
  input: ValueCodeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createCampaign
// ====================================================

export interface createCampaign_createCampaign {
  __typename: "ValueCode";
  id: string;
  code: string;
  state: ValueCodeState | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
}

export interface createCampaign {
  createCampaign: (createCampaign_createCampaign | null)[] | null;
}

export interface createCampaignVariables {
  input: CampaignInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createController
// ====================================================

export interface createController_createController {
  __typename: "Controller";
  id: string;
  systemId: string | null;
}

export interface createController {
  createController: createController_createController | null;
}

export interface createControllerVariables {
  input?: ControllerInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateController
// ====================================================

export interface updateController_updateController {
  __typename: "Controller";
  id: string;
  systemId: string | null;
}

export interface updateController {
  updateController: updateController_updateController | null;
}

export interface updateControllerVariables {
  id: string;
  input?: ControllerInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteController
// ====================================================

export interface deleteController_deleteController {
  __typename: "Controller";
  id: string;
}

export interface deleteController {
  deleteController: deleteController_deleteController | null;
}

export interface deleteControllerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unpairControllerFromVehicle
// ====================================================

export interface unpairControllerFromVehicle_unpairControllerFromVehicle {
  __typename: "Controller";
  id: string;
}

export interface unpairControllerFromVehicle {
  unpairControllerFromVehicle: unpairControllerFromVehicle_unpairControllerFromVehicle | null;
}

export interface unpairControllerFromVehicleVariables {
  controllerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createVehicle
// ====================================================

export interface createVehicle_createVehicle {
  __typename: "Vehicle";
  id: string;
  systemId: string | null;
}

export interface createVehicle {
  createVehicle: createVehicle_createVehicle | null;
}

export interface createVehicleVariables {
  input?: VehicleInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateVehicle
// ====================================================

export interface updateVehicle_updateVehicle {
  __typename: "Vehicle";
  id: string;
  systemId: string | null;
}

export interface updateVehicle {
  updateVehicle: updateVehicle_updateVehicle | null;
}

export interface updateVehicleVariables {
  id: string;
  input?: VehicleInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteVehicle
// ====================================================

export interface deleteVehicle_deleteVehicle {
  __typename: "Vehicle";
  id: string;
}

export interface deleteVehicle {
  deleteVehicle: deleteVehicle_deleteVehicle | null;
}

export interface deleteVehicleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendControllerMessage
// ====================================================

export interface sendControllerMessage_sendControllerMessage {
  __typename: "ControllerResponse";
  response: string | null;
}

export interface sendControllerMessage {
  sendControllerMessage: sendControllerMessage_sendControllerMessage | null;
}

export interface sendControllerMessageVariables {
  controllerId: string;
  message: string;
  payload: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: pingController
// ====================================================

export interface pingController_pingController {
  __typename: "ControllerResponse";
  response: string | null;
}

export interface pingController {
  pingController: pingController_pingController | null;
}

export interface pingControllerVariables {
  controllerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: pairElectronWithPhysicalLock
// ====================================================

export interface pairElectronWithPhysicalLock_pairElectronWithPhysicalLock {
  __typename: "Controller";
  id: string;
}

export interface pairElectronWithPhysicalLock {
  pairElectronWithPhysicalLock: pairElectronWithPhysicalLock_pairElectronWithPhysicalLock | null;
}

export interface pairElectronWithPhysicalLockVariables {
  physicalLockId: string;
  imei: string;
  overwrite?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createUser
// ====================================================

export interface createUser_createUser {
  __typename: "User";
  id: string;
  active: boolean | null;
  superpowers: boolean | null;
  systemId: string | null;
  name: string | null;
  gender: string | null;
  birthYear: number | null;
  phoneNumber: string | null;
  email: string | null;
  postalAddress: string | null;
  postalCode: string | null;
  countryName: string | null;
  rfid: string | null;
}

export interface createUser {
  createUser: createUser_createUser | null;
}

export interface createUserVariables {
  input?: UserInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteUser
// ====================================================

export interface deleteUser_deleteUser {
  __typename: "User";
  id: string;
}

export interface deleteUser {
  deleteUser: deleteUser_deleteUser | null;
}

export interface deleteUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUser
// ====================================================

export interface updateUser_updateUser {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface updateUser {
  updateUser: updateUser_updateUser | null;
}

export interface updateUserVariables {
  id: string;
  input?: UserInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: holdDeletionMutation
// ====================================================

export interface holdDeletionMutation_holdDeletion {
  __typename: "User";
  id: string;
}

export interface holdDeletionMutation {
  holdDeletion: holdDeletionMutation_holdDeletion | null;
}

export interface holdDeletionMutationVariables {
  id: string;
  shouldHoldDeletion: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPhoneVerificationRateLimit
// ====================================================

export interface resetPhoneVerificationRateLimit {
  /**
   * Reset phone verification rate limit for a phoneNumber. Returns true if user was ratelimited, false if not
   */
  resetPhoneVerificationRateLimit: boolean | null;
}

export interface resetPhoneVerificationRateLimitVariables {
  systemId: string;
  phoneNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: pairControllerWithVehicleFrameNumber
// ====================================================

export interface pairControllerWithVehicleFrameNumber_pairControllerWithVehicleFrameNumber {
  __typename: "Vehicle";
  id: string;
}

export interface pairControllerWithVehicleFrameNumber {
  pairControllerWithVehicleFrameNumber: pairControllerWithVehicleFrameNumber_pairControllerWithVehicleFrameNumber | null;
}

export interface pairControllerWithVehicleFrameNumberVariables {
  physicalLockId: string;
  frameNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateControllerFirmware
// ====================================================

export interface updateControllerFirmware_updateControllerFirmware {
  __typename: "Controller";
  id: string;
}

export interface updateControllerFirmware_updateController {
  __typename: "Controller";
  id: string;
}

export interface updateControllerFirmware {
  updateControllerFirmware: updateControllerFirmware_updateControllerFirmware | null;
  updateController: updateControllerFirmware_updateController | null;
}

export interface updateControllerFirmwareVariables {
  id: string;
  filename?: string | null;
  systemId: string;
  serialNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRfidAuth
// ====================================================

export interface createRfidAuth_createRfidAuth {
  __typename: "RfidAuth";
  id: string;
}

export interface createRfidAuth {
  createRfidAuth: createRfidAuth_createRfidAuth | null;
}

export interface createRfidAuthVariables {
  input?: RfidAuthInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRfidAuth
// ====================================================

export interface updateRfidAuth_updateRfidAuth {
  __typename: "RfidAuth";
  id: string;
}

export interface updateRfidAuth {
  updateRfidAuth: updateRfidAuth_updateRfidAuth | null;
}

export interface updateRfidAuthVariables {
  id: string;
  input?: RfidAuthInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRfidAuth
// ====================================================

export interface deleteRfidAuth_deleteRfidAuth {
  __typename: "RfidAuth";
  id: string;
}

export interface deleteRfidAuth {
  deleteRfidAuth: deleteRfidAuth_deleteRfidAuth | null;
}

export interface deleteRfidAuthVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: dockGroupCheckIn
// ====================================================

export interface dockGroupCheckIn_dockGroupCheckIn {
  __typename: "DockGroup";
  id: string;
}

export interface dockGroupCheckIn {
  dockGroupCheckIn: dockGroupCheckIn_dockGroupCheckIn | null;
}

export interface dockGroupCheckInVariables {
  dockGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: vehicleRFIDSearchCheckout
// ====================================================

export interface vehicleRFIDSearchCheckout_updateVehicle {
  __typename: "Vehicle";
  id: string;
  systemId: string | null;
}

export interface vehicleRFIDSearchCheckout_ownVehicle {
  __typename: "Vehicle";
  id: string;
}

export interface vehicleRFIDSearchCheckout {
  updateVehicle: vehicleRFIDSearchCheckout_updateVehicle | null;
  ownVehicle: vehicleRFIDSearchCheckout_ownVehicle | null;
}

export interface vehicleRFIDSearchCheckoutVariables {
  id: string;
  input?: VehicleInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ownController
// ====================================================

export interface ownController_ownController {
  __typename: "Controller";
  id: string;
  physicalLockId: string | null;
  systemId: string | null;
  imei: string | null;
  vehicleId: string | null;
}

export interface ownController {
  ownController: ownController_ownController | null;
}

export interface ownControllerVariables {
  systemId: string;
  physicalLockId: string;
  imei: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: globalData
// ====================================================

export interface globalData_administrator_roles_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface globalData_administrator_roles {
  __typename: "Role";
  id: string;
  name: string;
  acl: globalData_administrator_roles_acl[];
}

export interface globalData_administrator_rfidAuth {
  __typename: "RfidAuth";
  id: string;
  enabled: boolean;
  rfid: string;
  systemId: string;
  action: RfidAuthAction;
  parameters: string[] | null;
}

export interface globalData_administrator_selectedSystem_coords {
  __typename: "Coord";
  lng: number;
  lat: number;
}

export interface globalData_administrator_selectedSystem_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface globalData_administrator_selectedSystem_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: globalData_administrator_selectedSystem_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface globalData_administrator_selectedSystem_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface globalData_administrator_selectedSystem {
  __typename: "System";
  name: string;
  description: string;
  state: SystemState;
  coords: globalData_administrator_selectedSystem_coords[] | null;
  type: SystemType;
  countryName: string;
  countryCode: string;
  cityName: string;
  defaultLanguageCode: LanguageCode;
  languageCodes: LanguageCode[];
  operator: string | null;
  currency: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  timezone: string;
  maxPenaltyPoints: number | null;
  sanityProjectId: string | null;
  sanityDataset: string | null;
  sanityToken: string | null;
  colourKey: SystemColourKey | null;
  publicDomain: string | null;
  workingHoursId: string | null;
  vehicleQuickReturnPeriodSec: number | null;
  vehicleQuickReturnsToBroken: number | null;
  vehicleLostPeriodDays: number | null;
  dispatcherSmsSenderID: string | null;
  iosAppUrl: string | null;
  androidAppUrl: string | null;
  webUrl: string | null;
  sponsor: Sponsor | null;
  settings: CORE_JSON;
  intercomHelpSite: string | null;
  defaultVatRate: number;
  tripAdditionalMinutesPeriod: CORE_NonNegativeInt;
  maxTripAdditionaMinutesPeriodCount: CORE_NonNegativeInt;
  minTripLengthForCoolOffPeriodSec: number | null;
  /**
   * minutes prior to the end of free included period when you can request Additional Free Minutes
   */
  timeToRequestAdditionalMinutes: CORE_NonNegativeInt;
  coolOffPeriodSec: number | null;
  pauseTripEnabled: FeatureToggleValue | null;
  shouldAskForPushPermissions: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  qrCodeScanEnabled: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled: FeatureToggleValue | null;
  vehicleReservationEnabled: FeatureToggleValue | null;
  /**
   * Deep linking prefix used in apps.
   * 
   * Keep the value in sync with the mobile app configurations:
   * Android: Each system_id_deeplinking_prefix should map to systemId
   * https: // github.com/urbaninfrastructure/bikesharing-multicity-android/blob/master/app/build.gradle#L161
   */
  appDeepLinkingPrefix: string | null;
  /**
   * If enabled users who have unpaid trip orders can not take bikes
   */
  suspendDebtorEnabled: boolean;
  /**
   * ClickUp URL to be used in an Iframe for the Feedback form in Admin.
   * Falls back to the old Slack integration if not set
   */
  feedbackFormUrl: string | null;
  /**
   * ClickUp list id used to fetch support tasks.
   */
  clickUpListId: string | null;
  /**
   * If enabled trip charges are consolidated within an invoice
   */
  tripChargeConsolidationEnabled: boolean;
  /**
   * Defines the days amount during which trip charges are consolidated, an invoice payment is triggered once exceeded
   */
  tripChargeConsolidationPeriod: CORE_PositiveInt;
  /**
   * Defines the total charges amount required in order to trigger payment
   */
  tripChargeConsolidationAmount: CORE_Money;
  /**
   * If enabled new app users should pay a fee
   */
  registrationFeeEnabled: boolean;
  /**
   * Defines the amount of the registration fee
   */
  registrationFeeAmount: CORE_Money;
  /**
   * Enforcement of registration agreement withdrawal consent
   */
  registrationAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of subscription agreement withdrawal consent
   */
  subscriptionAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of consent to send data to third parties
   */
  sendDataToThirdPartiesConsent: ConsentEnforcement;
  /**
   * Defines wallet enablement
   */
  walletEnablement: WalletEnablement;
  /**
   * Defines wallet enablement
   */
  walletMinBalance: CORE_Money;
  vehicleReservationCoolOffPeriodSec: CORE_NonNegativeInt;
  vehicleReservationLimitPerDay: CORE_NonNegativeInt;
  freeFloatingEnabled: boolean;
  vehicleReservationQuickCancellationPeriodSec: CORE_NonNegativeInt;
  vehicleReservationPeriodMins: CORE_NonNegativeInt;
  /**
   * If enabled email with trip details is sent to a user
   */
  tripReceiptEmailNotificationEnabled: boolean;
  /**
   * If enabled push notification with trip details is sent to a user
   */
  tripReceiptPushNotificationEnabled: boolean;
  id: string;
  vehicleCategories: VehicleCategory[];
  connectionPriceMatrixes: globalData_administrator_selectedSystem_connectionPriceMatrixes[] | null;
  partners: globalData_administrator_selectedSystem_partners[];
}

export interface globalData_administrator {
  __typename: "Administrator";
  id: string;
  roles: globalData_administrator_roles[];
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
  rfidAuth: (globalData_administrator_rfidAuth | null)[] | null;
  settings: CORE_JSON;
  createdAt: CORE_IsoDateTime;
  operationsApiExternalId: string | null;
  selectedSystem: globalData_administrator_selectedSystem;
}

export interface globalData_systems {
  __typename: "System";
  id: string;
  name: string;
  type: SystemType;
}

export interface globalData {
  administrator: globalData_administrator;
  systems: globalData_systems[];
}

export interface globalDataVariables {
  administratorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroup
// ====================================================

export interface dockGroup_dockGroup_localeSubTitle {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface dockGroup_dockGroup_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
}

export interface dockGroup_dockGroup_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface dockGroup_dockGroup_lockFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface dockGroup_dockGroup_requestFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface dockGroup_dockGroup_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface dockGroup_dockGroup_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: dockGroup_dockGroup_entityTags_tag;
}

export interface dockGroup_dockGroup_stationClient {
  __typename: "StationClient";
  id: string;
  state: StationClientState | null;
  description: string | null;
  verificationCode: string | null;
  kioskId: string | null;
  createdAt: CORE_IsoDateTime | null;
  updatedAt: CORE_IsoDateTime | null;
}

export interface dockGroup_dockGroup_controller_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface dockGroup_dockGroup_controller {
  __typename: "Controller";
  id: string;
  ip: string | null;
  imei: string | null;
  serialNumber: string | null;
  physicalLockId: string | null;
  firmwareVersion: string | null;
  state: ControllerState | null;
  powerState: PowerState | null;
  model: dockGroup_dockGroup_controller_model | null;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface dockGroup_dockGroup {
  __typename: "DockGroup";
  name: string | null;
  type: DockGroupType | null;
  address: string | null;
  title: string | null;
  /**
   * Localised sub title, using system default language
   */
  subTitle: string | null;
  /**
   * Sub title from Sanity
   */
  localeSubTitle: dockGroup_dockGroup_localeSubTitle[] | null;
  state: DockGroupState | null;
  availabilityInfo: dockGroup_dockGroup_availabilityInfo;
  elevation: number | null;
  adSurfaces: number | null;
  description: string | null;
  coord: dockGroup_dockGroup_coord | null;
  lockFences: dockGroup_dockGroup_lockFences[] | null;
  requestFences: dockGroup_dockGroup_requestFences[] | null;
  showInPublicOverride: boolean | null;
  id: string;
  systemId: string;
  sanityId: string | null;
  entityTags: dockGroup_dockGroup_entityTags[] | null;
  deletedAt: CORE_IsoDateTime | null;
  stationClient: dockGroup_dockGroup_stationClient | null;
  operationsManagesCapacity: boolean | null;
  controller: dockGroup_dockGroup_controller | null;
  reportedVirtualDockCount: number | null;
  maxVirtualDockCount: number | null;
}

export interface dockGroup {
  dockGroup: dockGroup_dockGroup | null;
}

export interface dockGroupVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupTimeline
// ====================================================

export interface dockGroupTimeline_dockGroup_mutationLog {
  __typename: "MutationLog";
  createdAt: CORE_IsoDateTime;
  mutations: string | null;
}

export interface dockGroupTimeline_dockGroup {
  __typename: "DockGroup";
  id: string;
  mutationLog: (dockGroupTimeline_dockGroup_mutationLog | null)[] | null;
}

export interface dockGroupTimeline {
  dockGroup: dockGroupTimeline_dockGroup | null;
}

export interface dockGroupTimelineVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupComments
// ====================================================

export interface dockGroupComments_dockGroup_adminComments_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface dockGroupComments_dockGroup_adminComments {
  __typename: "DockGroupComment";
  id: string;
  administrator: dockGroupComments_dockGroup_adminComments_administrator;
  comment: string;
  createdAt: CORE_IsoDateTime;
}

export interface dockGroupComments_dockGroup {
  __typename: "DockGroup";
  id: string;
  adminComments: dockGroupComments_dockGroup_adminComments[];
}

export interface dockGroupComments {
  dockGroup: dockGroupComments_dockGroup | null;
}

export interface dockGroupCommentsVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupNameIsAvailable
// ====================================================

export interface dockGroupNameIsAvailable {
  dockGroupNameIsAvailable: boolean | null;
}

export interface dockGroupNameIsAvailableVariables {
  systemId: string;
  name?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleNameIsAvailable
// ====================================================

export interface vehicleNameIsAvailable_vehicleNameIsAvailable {
  __typename: "Vehicle";
  id: string;
}

export interface vehicleNameIsAvailable {
  vehicleNameIsAvailable: vehicleNameIsAvailable_vehicleNameIsAvailable | null;
}

export interface vehicleNameIsAvailableVariables {
  systemId: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleNumberIsAvailable
// ====================================================

export interface vehicleNumberIsAvailable_vehicleNumberIsAvailable {
  __typename: "Vehicle";
  id: string;
}

export interface vehicleNumberIsAvailable {
  vehicleNumberIsAvailable: vehicleNumberIsAvailable_vehicleNumberIsAvailable | null;
}

export interface vehicleNumberIsAvailableVariables {
  systemId: string;
  number: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discountCampaignCodeIsAvailable
// ====================================================

export interface discountCampaignCodeIsAvailable {
  discountCampaignCodeIsAvailable: boolean | null;
}

export interface discountCampaignCodeIsAvailableVariables {
  systemId: string;
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discountCampaignNameIsAvailable
// ====================================================

export interface discountCampaignNameIsAvailable {
  discountCampaignNameIsAvailable: boolean | null;
}

export interface discountCampaignNameIsAvailableVariables {
  systemId: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: products
// ====================================================

export interface products_products {
  __typename: "Product";
  id: string;
  name: string | null;
  enabled: boolean;
  deletedAt: CORE_IsoDateTime | null;
  purchaseChannels: (ProductPurchaseChannel | null)[] | null;
  availableForValueCode: boolean;
}

export interface products {
  products: products_products[] | null;
}

export interface productsVariables {
  page?: number | null;
  perPage?: number | null;
  orderBy?: string | null;
  showDeleted?: boolean | null;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productsPaginated
// ====================================================

export interface productsPaginated_productsPaginated_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalRowCount: number;
}

export interface productsPaginated_productsPaginated_rows_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface productsPaginated_productsPaginated_rows {
  __typename: "Product";
  id: string;
  name: string | null;
  enabled: boolean;
  deletedAt: CORE_IsoDateTime | null;
  purchaseChannels: (ProductPurchaseChannel | null)[] | null;
  partners: productsPaginated_productsPaginated_rows_partners[];
}

export interface productsPaginated_productsPaginated {
  __typename: "ProductsPaginated";
  paginationInfo: productsPaginated_productsPaginated_paginationInfo;
  rows: productsPaginated_productsPaginated_rows[];
}

export interface productsPaginated {
  productsPaginated: productsPaginated_productsPaginated | null;
}

export interface productsPaginatedVariables {
  systemId: string;
  offset?: number | null;
  limit?: number | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: administratorsConnection
// ====================================================

export interface administratorsConnection_administratorsConnection_pageInfo {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface administratorsConnection_administratorsConnection_edges_node_rfidAuth {
  __typename: "RfidAuth";
  id: string;
  rfid: string;
  action: RfidAuthAction;
  parameters: string[] | null;
}

export interface administratorsConnection_administratorsConnection_edges_node_roles_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface administratorsConnection_administratorsConnection_edges_node_roles {
  __typename: "Role";
  id: string;
  name: string;
  acl: administratorsConnection_administratorsConnection_edges_node_roles_acl[];
}

export interface administratorsConnection_administratorsConnection_edges_node {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
  rfidAuth: (administratorsConnection_administratorsConnection_edges_node_rfidAuth | null)[] | null;
  roles: administratorsConnection_administratorsConnection_edges_node_roles[];
}

export interface administratorsConnection_administratorsConnection_edges {
  __typename: "AdministratorEdge";
  node: administratorsConnection_administratorsConnection_edges_node;
}

export interface administratorsConnection_administratorsConnection {
  __typename: "AdministratorsConnection";
  pageInfo: administratorsConnection_administratorsConnection_pageInfo;
  edges: administratorsConnection_administratorsConnection_edges[];
}

export interface administratorsConnection {
  administratorsConnection: administratorsConnection_administratorsConnection | null;
}

export interface administratorsConnectionVariables {
  systemId?: string | null;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
  showInvited?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: administrator
// ====================================================

export interface administrator_administrator_roles_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface administrator_administrator_roles {
  __typename: "Role";
  id: string;
  name: string;
  acl: administrator_administrator_roles_acl[];
}

export interface administrator_administrator_rfidAuth {
  __typename: "RfidAuth";
  id: string;
  enabled: boolean;
  rfid: string;
  systemId: string;
  action: RfidAuthAction;
  parameters: string[] | null;
}

export interface administrator_administrator {
  __typename: "Administrator";
  id: string;
  roles: administrator_administrator_roles[];
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
  rfidAuth: (administrator_administrator_rfidAuth | null)[] | null;
  settings: CORE_JSON;
  createdAt: CORE_IsoDateTime;
  operationsApiExternalId: string | null;
}

export interface administrator_roles_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface administrator_roles {
  __typename: "Role";
  name: string;
  systemId: string | null;
  description: string | null;
  acl: administrator_roles_acl[];
  id: string;
}

export interface administrator {
  administrator: administrator_administrator;
  roles: (administrator_roles | null)[] | null;
}

export interface administratorVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: user
// ====================================================

export interface user_user_trips {
  __typename: "Trip";
  id: string;
}

export interface user_user_currentSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface user_user_currentSubscription {
  __typename: "ProductSubscription";
  id: string;
  state: ProductSubscriptionState;
  product: user_user_currentSubscription_product | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  deletedAt: CORE_IsoDateTime | null;
}

export interface user_user_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface user_user_paymentMethod_Card {
  __typename: "Card";
  id: string | null;
  expired: boolean;
}

export type user_user_paymentMethod = user_user_paymentMethod_Blik | user_user_paymentMethod_Card;

export interface user_user_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface user_user_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: user_user_entityTags_tag;
}

export interface user_user_partnerAccounts_OBOSAccount {
  __typename: "OBOSAccount";
}

export interface user_user_partnerAccounts_MilanATMCard {
  __typename: "MilanATMCard";
  UUID: string;
}

export type user_user_partnerAccounts = user_user_partnerAccounts_OBOSAccount | user_user_partnerAccounts_MilanATMCard;

export interface user_user_wallet {
  __typename: "UserWallet";
  enabled: boolean;
  balance: CORE_Money;
  currency: string;
}

export interface user_user_currentReservation_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
}

export interface user_user_currentReservation {
  __typename: "VehicleReservation";
  id: string;
  vehicle: user_user_currentReservation_vehicle | null;
}

export interface user_user_agreements_agreement {
  __typename: "UserAgreement";
  id: string;
  name: string;
  revision: CORE_PositiveInt;
  createdAt: CORE_IsoDateTime;
}

export interface user_user_agreements {
  __typename: "UserAgreementAccept";
  id: string;
  userAgreementId: string;
  acceptedAt: CORE_IsoDateTime;
  agreement: user_user_agreements_agreement;
}

export interface user_user {
  __typename: "User";
  id: string;
  systemId: string | null;
  superpowers: boolean | null;
  gender: string | null;
  name: string | null;
  birthYear: number | null;
  phoneNumber: string | null;
  email: string | null;
  postalAddress: string | null;
  postalCode: string | null;
  countryName: string | null;
  intercomId: string | null;
  active: boolean | null;
  rfid: string | null;
  maskedPinCode: string | null;
  phoneVerificationRateLimited: boolean | null;
  trips: (user_user_trips | null)[] | null;
  currentSubscription: user_user_currentSubscription | null;
  createdAt: CORE_IsoDateTime;
  paymentMethod: user_user_paymentMethod | null;
  entityTags: user_user_entityTags[] | null;
  partnerAccounts: user_user_partnerAccounts[] | null;
  wallet: user_user_wallet | null;
  currentReservation: user_user_currentReservation | null;
  agreements: user_user_agreements[];
}

export interface user {
  user: user_user | null;
}

export interface userVariables {
  userId: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systems
// ====================================================

export interface systems_systems {
  __typename: "System";
  id: string;
  name: string;
  state: SystemState;
}

export interface systems {
  systems: systems_systems[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: system
// ====================================================

export interface system_system_coords {
  __typename: "Coord";
  lng: number;
  lat: number;
}

export interface system_system_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface system_system_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: system_system_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface system_system_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface system_system {
  __typename: "System";
  id: string;
  name: string;
  description: string;
  state: SystemState;
  coords: system_system_coords[] | null;
  type: SystemType;
  countryName: string;
  countryCode: string;
  cityName: string;
  defaultLanguageCode: LanguageCode;
  languageCodes: LanguageCode[];
  operator: string | null;
  currency: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  timezone: string;
  maxPenaltyPoints: number | null;
  sanityProjectId: string | null;
  sanityDataset: string | null;
  sanityToken: string | null;
  colourKey: SystemColourKey | null;
  publicDomain: string | null;
  workingHoursId: string | null;
  vehicleQuickReturnPeriodSec: number | null;
  vehicleQuickReturnsToBroken: number | null;
  vehicleLostPeriodDays: number | null;
  dispatcherSmsSenderID: string | null;
  iosAppUrl: string | null;
  androidAppUrl: string | null;
  webUrl: string | null;
  sponsor: Sponsor | null;
  settings: CORE_JSON;
  intercomHelpSite: string | null;
  defaultVatRate: number;
  tripAdditionalMinutesPeriod: CORE_NonNegativeInt;
  maxTripAdditionaMinutesPeriodCount: CORE_NonNegativeInt;
  minTripLengthForCoolOffPeriodSec: number | null;
  /**
   * minutes prior to the end of free included period when you can request Additional Free Minutes
   */
  timeToRequestAdditionalMinutes: CORE_NonNegativeInt;
  coolOffPeriodSec: number | null;
  pauseTripEnabled: FeatureToggleValue | null;
  shouldAskForPushPermissions: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  qrCodeScanEnabled: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled: FeatureToggleValue | null;
  vehicleReservationEnabled: FeatureToggleValue | null;
  /**
   * Deep linking prefix used in apps.
   * 
   * Keep the value in sync with the mobile app configurations:
   * Android: Each system_id_deeplinking_prefix should map to systemId
   * https: // github.com/urbaninfrastructure/bikesharing-multicity-android/blob/master/app/build.gradle#L161
   */
  appDeepLinkingPrefix: string | null;
  /**
   * If enabled users who have unpaid trip orders can not take bikes
   */
  suspendDebtorEnabled: boolean;
  /**
   * ClickUp URL to be used in an Iframe for the Feedback form in Admin.
   * Falls back to the old Slack integration if not set
   */
  feedbackFormUrl: string | null;
  /**
   * ClickUp list id used to fetch support tasks.
   */
  clickUpListId: string | null;
  /**
   * If enabled trip charges are consolidated within an invoice
   */
  tripChargeConsolidationEnabled: boolean;
  /**
   * Defines the days amount during which trip charges are consolidated, an invoice payment is triggered once exceeded
   */
  tripChargeConsolidationPeriod: CORE_PositiveInt;
  /**
   * Defines the total charges amount required in order to trigger payment
   */
  tripChargeConsolidationAmount: CORE_Money;
  /**
   * If enabled new app users should pay a fee
   */
  registrationFeeEnabled: boolean;
  /**
   * Defines the amount of the registration fee
   */
  registrationFeeAmount: CORE_Money;
  /**
   * Enforcement of registration agreement withdrawal consent
   */
  registrationAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of subscription agreement withdrawal consent
   */
  subscriptionAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of consent to send data to third parties
   */
  sendDataToThirdPartiesConsent: ConsentEnforcement;
  /**
   * Defines wallet enablement
   */
  walletEnablement: WalletEnablement;
  /**
   * Defines wallet enablement
   */
  walletMinBalance: CORE_Money;
  vehicleReservationCoolOffPeriodSec: CORE_NonNegativeInt;
  vehicleReservationLimitPerDay: CORE_NonNegativeInt;
  freeFloatingEnabled: boolean;
  vehicleReservationQuickCancellationPeriodSec: CORE_NonNegativeInt;
  vehicleReservationPeriodMins: CORE_NonNegativeInt;
  /**
   * If enabled email with trip details is sent to a user
   */
  tripReceiptEmailNotificationEnabled: boolean;
  /**
   * If enabled push notification with trip details is sent to a user
   */
  tripReceiptPushNotificationEnabled: boolean;
  vehicleCategories: VehicleCategory[];
  connectionPriceMatrixes: system_system_connectionPriceMatrixes[] | null;
  partners: system_system_partners[];
}

export interface system {
  system: system_system;
}

export interface systemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: roles
// ====================================================

export interface roles_roles_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface roles_roles {
  __typename: "Role";
  name: string;
  systemId: string | null;
  description: string | null;
  acl: roles_roles_acl[];
  id: string;
}

export interface roles {
  roles: (roles_roles | null)[] | null;
}

export interface rolesVariables {
  systemId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicle
// ====================================================

export interface vehicle_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface vehicle_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface vehicle_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type vehicle_vehicle_displayName = vehicle_vehicle_displayName_VehicleDisplayNamed | vehicle_vehicle_displayName_VehicleDisplayNumbered | vehicle_vehicle_displayName_VehicleDisplayAnonymous;

export interface vehicle_vehicle_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
  type: AssetType;
  vehicleCategory: VehicleCategory | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface vehicle_vehicle_availability_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface vehicle_vehicle_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicle_vehicle_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicle_vehicle_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  type: DockType;
  dockGroup: vehicle_vehicle_availability_dock_dockGroup | null;
}

export interface vehicle_vehicle_availability_trip_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicle_vehicle_availability_trip {
  __typename: "VehicleAvailabilityTrip";
  id: string | null;
  cancelledByAdmin: vehicle_vehicle_availability_trip_cancelledByAdmin | null;
}

export interface vehicle_vehicle_availability_operationLocation {
  __typename: "OperationLocation";
  id: string;
  name: string;
  type: OperationLocationType;
}

export interface vehicle_vehicle_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicle_vehicle_availability_location {
  __typename: "VehicleLocation";
  location: vehicle_vehicle_availability_location_location;
  address: string | null;
}

export interface vehicle_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  systemId: string | null;
  vehicleId: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  subscription: vehicle_vehicle_availability_subscription | null;
  administrator: vehicle_vehicle_availability_administrator | null;
  dock: vehicle_vehicle_availability_dock | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  trip: vehicle_vehicle_availability_trip | null;
  operationLocation: vehicle_vehicle_availability_operationLocation | null;
  location: vehicle_vehicle_availability_location | null;
}

export interface vehicle_vehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
  hardwareBridge: HardwareBridge | null;
}

export interface vehicle_vehicle_controller {
  __typename: "Controller";
  id: string;
  ip: string | null;
  imei: string | null;
  serialNumber: string | null;
  physicalLockId: string | null;
  firmwareVersion: string | null;
  state: ControllerState | null;
  powerState: PowerState | null;
  model: vehicle_vehicle_controller_model | null;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface vehicle_vehicle_latestMaintenance_damages_damageType_localeName {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface vehicle_vehicle_latestMaintenance_damages_damageType {
  __typename: "AssetDamageType";
  id: string;
  localeName: (vehicle_vehicle_latestMaintenance_damages_damageType_localeName | null)[] | null;
  name: string | null;
  critical: boolean | null;
}

export interface vehicle_vehicle_latestMaintenance_damages_reportedBy_Administrator {
  __typename: "Administrator";
  id: string;
  firstName: string | null;
  profilePictureUrl: string | null;
}

export interface vehicle_vehicle_latestMaintenance_damages_reportedBy_User {
  __typename: "User";
  id: string;
}

export type vehicle_vehicle_latestMaintenance_damages_reportedBy = vehicle_vehicle_latestMaintenance_damages_reportedBy_Administrator | vehicle_vehicle_latestMaintenance_damages_reportedBy_User;

export interface vehicle_vehicle_latestMaintenance_damages {
  __typename: "AssetMaintenanceDamage";
  damageTypeId: string | null;
  enforceCritical: boolean;
  damageType: vehicle_vehicle_latestMaintenance_damages_damageType | null;
  reportedBy: vehicle_vehicle_latestMaintenance_damages_reportedBy | null;
  comment: string | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface vehicle_vehicle_latestMaintenance {
  __typename: "AssetMaintenance";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  completedAt: CORE_IsoDateTime | null;
  damages: vehicle_vehicle_latestMaintenance_damages[] | null;
}

export interface vehicle_vehicle_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface vehicle_vehicle_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: vehicle_vehicle_entityTags_tag;
}

export interface vehicle_vehicle_position {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface vehicle_vehicle_reservation_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
}

export interface vehicle_vehicle_reservation_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface vehicle_vehicle_reservation_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface vehicle_vehicle_reservation {
  __typename: "VehicleReservation";
  id: string;
  vehicle: vehicle_vehicle_reservation_vehicle | null;
  subscription: vehicle_vehicle_reservation_subscription | null;
  dockGroup: vehicle_vehicle_reservation_dockGroup | null;
  completedAt: CORE_IsoDateTime | null;
  cancelledAt: CORE_IsoDateTime | null;
  expiresAt: CORE_IsoDateTime;
  createdAt: CORE_IsoDateTime;
}

export interface vehicle_vehicle {
  __typename: "Vehicle";
  id: string;
  systemId: string | null;
  name: string | null;
  number: string | null;
  brokenAt: CORE_IsoDateTime | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: vehicle_vehicle_displayName;
  frameNumber: string | null;
  modelId: string | null;
  model: vehicle_vehicle_model | null;
  priority: number | null;
  availability: vehicle_vehicle_availability | null;
  unavailabilityReason: UnavailabilityReasons[] | null;
  availabilityState: VehicleAvailabilityState;
  batteryVoltage: number | null;
  batteryCharge: number | null;
  controller: vehicle_vehicle_controller | null;
  quickReturnCount: number | null;
  latestMaintenance: vehicle_vehicle_latestMaintenance | null;
  entityTags: vehicle_vehicle_entityTags[] | null;
  rfid: string | null;
  qrCode: string | null;
  position: vehicle_vehicle_position | null;
  reservation: vehicle_vehicle_reservation | null;
}

export interface vehicle {
  vehicle: vehicle_vehicle | null;
}

export interface vehicleVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleByQrCode
// ====================================================

export interface vehicleByQrCode_vehicleByQrCode {
  __typename: "Vehicle";
  id: string;
}

export interface vehicleByQrCode {
  vehicleByQrCode: vehicleByQrCode_vehicleByQrCode | null;
}

export interface vehicleByQrCodeVariables {
  qrCode: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleWithController
// ====================================================

export interface vehicleWithController_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface vehicleWithController_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface vehicleWithController_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type vehicleWithController_vehicle_displayName = vehicleWithController_vehicle_displayName_VehicleDisplayNamed | vehicleWithController_vehicle_displayName_VehicleDisplayNumbered | vehicleWithController_vehicle_displayName_VehicleDisplayAnonymous;

export interface vehicleWithController_vehicle_controller {
  __typename: "Controller";
  id: string;
  serialNumber: string | null;
}

export interface vehicleWithController_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: vehicleWithController_vehicle_displayName;
  controller: vehicleWithController_vehicle_controller | null;
}

export interface vehicleWithController {
  vehicle: vehicleWithController_vehicle | null;
}

export interface vehicleWithControllerVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vehicleComments
// ====================================================

export interface vehicleComments_vehicle_comments_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface vehicleComments_vehicle_comments {
  __typename: "VehicleComment";
  id: string;
  administrator: vehicleComments_vehicle_comments_administrator;
  comment: string;
  createdAt: CORE_IsoDateTime;
}

export interface vehicleComments_vehicle {
  __typename: "Vehicle";
  id: string;
  comments: vehicleComments_vehicle_comments[];
}

export interface vehicleComments {
  vehicle: vehicleComments_vehicle | null;
}

export interface vehicleCommentsVariables {
  systemId: string;
  vehicleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tripComments
// ====================================================

export interface tripComments_trip_adminComments_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface tripComments_trip_adminComments {
  __typename: "TripComment";
  id: string;
  administrator: tripComments_trip_adminComments_administrator;
  comment: string;
  createdAt: CORE_IsoDateTime;
}

export interface tripComments_trip {
  __typename: "Trip";
  id: string;
  adminComments: tripComments_trip_adminComments[];
}

export interface tripComments {
  trip: tripComments_trip | null;
}

export interface tripCommentsVariables {
  systemId: string;
  tripId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: operationLocations
// ====================================================

export interface operationLocations_operationLocations {
  __typename: "OperationLocation";
  id: string;
  name: string;
  type: OperationLocationType;
}

export interface operationLocations {
  operationLocations: operationLocations_operationLocations[] | null;
}

export interface operationLocationsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: trips
// ====================================================

export interface trips_trips_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface trips_trips_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface trips_trips_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: trips_trips_startDock_dockGroup | null;
}

export interface trips_trips_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface trips_trips_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: trips_trips_endDock_dockGroup | null;
}

export interface trips_trips_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
}

export interface trips_trips {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  subscription: trips_trips_subscription | null;
  startDock: trips_trips_startDock | null;
  endDock: trips_trips_endDock | null;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  vehicle: trips_trips_vehicle | null;
}

export interface trips {
  trips: (trips_trips | null)[] | null;
}

export interface tripsVariables {
  page?: number | null;
  perPage?: number | null;
  orderBy?: string | null;
  order?: string | null;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: trip
// ====================================================

export interface trip_trip_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface trip_trip_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: trip_trip_entityTags_tag;
}

export interface trip_trip_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface trip_trip_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface trip_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface trip_trip_startDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  dockGroup: trip_trip_startDock_dockGroup | null;
}

export interface trip_trip_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface trip_trip_startLocation {
  __typename: "VehicleLocation";
  location: trip_trip_startLocation_location;
  address: string | null;
}

export interface trip_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface trip_trip_endDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  dockGroup: trip_trip_endDock_dockGroup | null;
}

export interface trip_trip_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface trip_trip_endLocation {
  __typename: "VehicleLocation";
  location: trip_trip_endLocation_location;
  address: string | null;
}

export interface trip_trip_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface trip_trip_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface trip_trip_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type trip_trip_vehicle_displayName = trip_trip_vehicle_displayName_VehicleDisplayNamed | trip_trip_vehicle_displayName_VehicleDisplayNumbered | trip_trip_vehicle_displayName_VehicleDisplayAnonymous;

export interface trip_trip_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface trip_trip_vehicle_controller {
  __typename: "Controller";
  id: string;
}

export interface trip_trip_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: trip_trip_vehicle_displayName;
  model: trip_trip_vehicle_model | null;
  controller: trip_trip_vehicle_controller | null;
}

export interface trip_trip_route {
  __typename: "LineStringObject";
  coordinates: CORE_Coordinates;
}

export interface trip_trip_userAgent {
  __typename: "UserAgent";
  original: string | null;
  isAndroid: boolean | null;
  isIOS: boolean | null;
  browser: string | null;
}

export interface trip_trip_additionalMinutes_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface trip_trip_additionalMinutes {
  __typename: "TripAdditionalMinutes";
  dockGroup: trip_trip_additionalMinutes_dockGroup;
  value: number;
  createdAt: CORE_IsoDateTime;
}

export interface trip_trip_order {
  __typename: "Order";
  id: string;
  amount: CORE_Money;
}

export interface trip_trip_paymentDocument_InvoiceUserTrip_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode" | "InvoiceItemAdjustment";
  amount: CORE_Money;
}

export interface trip_trip_paymentDocument_InvoiceUserTrip_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface trip_trip_paymentDocument_InvoiceUserTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  amount: CORE_Money;
  trip: trip_trip_paymentDocument_InvoiceUserTrip_items_InvoiceItemTrip_trip;
}

export type trip_trip_paymentDocument_InvoiceUserTrip_items = trip_trip_paymentDocument_InvoiceUserTrip_items_InvoiceItemValueCode | trip_trip_paymentDocument_InvoiceUserTrip_items_InvoiceItemTrip;

export interface trip_trip_paymentDocument_InvoiceUserTrip {
  __typename: "InvoiceUserTrip";
  id: string;
  currency: string;
  total: CORE_Money;
  items: trip_trip_paymentDocument_InvoiceUserTrip_items[] | null;
}

export interface trip_trip_paymentDocument_Order {
  __typename: "Order";
  id: string;
  currency: string;
  amount: CORE_Money;
}

export type trip_trip_paymentDocument = trip_trip_paymentDocument_InvoiceUserTrip | trip_trip_paymentDocument_Order;

export interface trip_trip {
  __typename: "Trip";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
  state: TripState | null;
  entityTags: trip_trip_entityTags[] | null;
  cancelledByAdmin: trip_trip_cancelledByAdmin | null;
  cancelledReason: string | null;
  subscription: trip_trip_subscription | null;
  startDock: trip_trip_startDock | null;
  startLocation: trip_trip_startLocation | null;
  endDock: trip_trip_endDock | null;
  endLocation: trip_trip_endLocation | null;
  userRating: CORE_PositiveInt | null;
  vehicle: trip_trip_vehicle | null;
  route: trip_trip_route | null;
  userAgent: trip_trip_userAgent | null;
  additionalMinutes: trip_trip_additionalMinutes[];
  order: trip_trip_order | null;
  paymentDocument: trip_trip_paymentDocument | null;
  /**
   * If false, trip startedAt/endedAt timestamps are not reliable and we should not create an order for such trip
   */
  accurateTimestamps: boolean | null;
}

export interface trip {
  trip: trip_trip | null;
}

export interface tripVariables {
  systemId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: controllerEventLogs
// ====================================================

export interface controllerEventLogs_controllerEventLogs {
  __typename: "ControllerEventLog";
  id: string;
  topic: string | null;
  payload: string | null;
  controllerId: string | null;
  generatedAt: CORE_IsoDateTime | null;
  publishedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  cellularNetworkLatency: number | null;
  pubSubLatency: number | null;
  direction: ControllerEventLogDirection | null;
  bridgeId: string | null;
}

export interface controllerEventLogs {
  controllerEventLogs: (controllerEventLogs_controllerEventLogs | null)[] | null;
}

export interface controllerEventLogsVariables {
  controllerId: string;
  systemId: string;
  topic?: string | null;
  topics?: string[] | null;
  page?: number | null;
  perPage?: number | null;
  orderBy?: string | null;
  order?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: gmapsElevation
// ====================================================

export interface gmapsElevation {
  gmapsElevation: number | null;
}

export interface gmapsElevationVariables {
  lat?: string | null;
  lng?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: campaigns
// ====================================================

export interface campaigns_campaigns {
  __typename: "CampaignsData";
  campaignTag: string;
  productName: string;
  countTotal: string;
  countUsed: string;
  createdAt: CORE_IsoDateTime;
}

export interface campaigns {
  campaigns: (campaigns_campaigns | null)[];
}

export interface campaignsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: campaignValueCodes
// ====================================================

export interface campaignValueCodes_campaignValueCodes_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface campaignValueCodes_campaignValueCodes {
  __typename: "ValueCode";
  id: string;
  code: string;
  state: ValueCodeState | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  product: campaignValueCodes_campaignValueCodes_product | null;
}

export interface campaignValueCodes {
  campaignValueCodes: (campaignValueCodes_campaignValueCodes | null)[];
}

export interface campaignValueCodesVariables {
  systemId: string;
  campaignTag?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: docks
// ====================================================

export interface docks_docks_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  systemId: string;
  type: DockGroupType | null;
}

export interface docks_docks_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
}

export interface docks_docks {
  __typename: "Dock";
  id: string;
  dockGroup: docks_docks_dockGroup | null;
  state: DockState | null;
  rfid: string | null;
  number: number | null;
  vehicle: docks_docks_vehicle | null;
}

export interface docks {
  docks: (docks_docks | null)[] | null;
}

export interface docksVariables {
  systemId?: string | null;
  page?: number | null;
  perPage?: number | null;
  orderBy?: string | null;
  order?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: controller
// ====================================================

export interface controller_controller_pairedEntity_Vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface controller_controller_pairedEntity_Vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface controller_controller_pairedEntity_Vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type controller_controller_pairedEntity_Vehicle_displayName = controller_controller_pairedEntity_Vehicle_displayName_VehicleDisplayNamed | controller_controller_pairedEntity_Vehicle_displayName_VehicleDisplayNumbered | controller_controller_pairedEntity_Vehicle_displayName_VehicleDisplayAnonymous;

export interface controller_controller_pairedEntity_Vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface controller_controller_pairedEntity_Vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: controller_controller_pairedEntity_Vehicle_displayName;
  model: controller_controller_pairedEntity_Vehicle_model | null;
}

export interface controller_controller_pairedEntity_DockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
  name: string | null;
}

export type controller_controller_pairedEntity = controller_controller_pairedEntity_Vehicle | controller_controller_pairedEntity_DockGroup;

export interface controller_controller_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
  type: AssetType;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
  hardwareBridge: HardwareBridge | null;
}

export interface controller_controller {
  __typename: "Controller";
  systemId: string | null;
  serialNumber: string | null;
  ip: string | null;
  imei: string | null;
  physicalLockId: string | null;
  desiredFirmwareVersion: string | null;
  modelId: string | null;
  id: string;
  powerState: PowerState | null;
  state: ControllerState | null;
  firmwareVersion: string | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  pairedEntity: controller_controller_pairedEntity | null;
  iccid: string | null;
  model: controller_controller_model | null;
  latestResponse: CORE_IsoDateTime | null;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
}

export interface controller {
  controller: controller_controller | null;
}

export interface controllerVariables {
  id: string;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: activeSystemClosureAlerts
// ====================================================

export interface activeSystemClosureAlerts_activeSystemAlerts {
  __typename: "SystemAlert";
  id: string;
}

export interface activeSystemClosureAlerts {
  activeSystemAlerts: activeSystemClosureAlerts_activeSystemAlerts[] | null;
}

export interface activeSystemClosureAlertsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onControllerEventLogUpdate
// ====================================================

export interface onControllerEventLogUpdate_controllerEventLog {
  __typename: "ControllerEventLog";
  id: string;
  topic: string | null;
  payload: string | null;
  controllerId: string | null;
  generatedAt: CORE_IsoDateTime | null;
  publishedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  cellularNetworkLatency: number | null;
  pubSubLatency: number | null;
  direction: ControllerEventLogDirection | null;
  bridgeId: string | null;
}

export interface onControllerEventLogUpdate {
  controllerEventLog: onControllerEventLogUpdate_controllerEventLog | null;
}

export interface onControllerEventLogUpdateVariables {
  id?: string | null;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onVehicleAvailabilityUpdate
// ====================================================

export interface onVehicleAvailabilityUpdate_vehicleAvailability_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface onVehicleAvailabilityUpdate_vehicleAvailability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface onVehicleAvailabilityUpdate_vehicleAvailability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface onVehicleAvailabilityUpdate_vehicleAvailability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  type: DockType;
  dockGroup: onVehicleAvailabilityUpdate_vehicleAvailability_dock_dockGroup | null;
}

export interface onVehicleAvailabilityUpdate_vehicleAvailability {
  __typename: "VehicleAvailability";
  id: string;
  systemId: string | null;
  vehicleId: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  subscription: onVehicleAvailabilityUpdate_vehicleAvailability_subscription | null;
  administrator: onVehicleAvailabilityUpdate_vehicleAvailability_administrator | null;
  dock: onVehicleAvailabilityUpdate_vehicleAvailability_dock | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
}

export interface onVehicleAvailabilityUpdate {
  vehicleAvailability: onVehicleAvailabilityUpdate_vehicleAvailability | null;
}

export interface onVehicleAvailabilityUpdateVariables {
  id?: string | null;
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onActiveSystemClosureAlerts
// ====================================================

export interface onActiveSystemClosureAlerts_activeSystemClosureAlerts {
  __typename: "SystemAlert";
  id: string;
}

export interface onActiveSystemClosureAlerts {
  activeSystemClosureAlerts: onActiveSystemClosureAlerts_activeSystemClosureAlerts[];
}

export interface onActiveSystemClosureAlertsVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BusinessCompanyFragment
// ====================================================

export interface BusinessCompanyFragment_approvals {
  __typename: "BusinessCompanyApprovals";
  sendInvoice: boolean;
}

export interface BusinessCompanyFragment {
  __typename: "BusinessCompany";
  id: string;
  systemId: string;
  name: string;
  organizationNumber: string;
  customerNumber: CORE_PositiveInt | null;
  address: string;
  zipCode: string;
  city: string;
  receiptEmail: string;
  collectionMethod: CollectionMethod;
  approvals: BusinessCompanyFragment_approvals;
  tripChargeConsolidationEnabled: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListControllerFragment
// ====================================================

export interface ListControllerFragment_pairedEntity_DockGroup {
  __typename: "DockGroup";
}

export interface ListControllerFragment_pairedEntity_Vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
}

export type ListControllerFragment_pairedEntity = ListControllerFragment_pairedEntity_DockGroup | ListControllerFragment_pairedEntity_Vehicle;

export interface ListControllerFragment {
  __typename: "Controller";
  id: string;
  vehicleId: string | null;
  systemId: string | null;
  state: ControllerState | null;
  powerState: PowerState | null;
  firmwareVersion: string | null;
  lockMechanismState: LockMechanismState | null;
  pairedEntity: ListControllerFragment_pairedEntity | null;
  serialNumber: string | null;
  ip: string | null;
  imei: string | null;
  physicalLockId: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DockMutationEventFragment
// ====================================================

export interface DockMutationEventFragment_dock {
  __typename: "Dock";
  number: number | null;
  id: string;
}

export interface DockMutationEventFragment_diffs {
  __typename: "MutationDiff";
  field: string | null;
  lhs: CORE_JSON | null;
  rhs: CORE_JSON | null;
}

export interface DockMutationEventFragment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface DockMutationEventFragment {
  __typename: "DockMutationEvent";
  id: string;
  dock: DockMutationEventFragment_dock;
  diffs: DockMutationEventFragment_diffs[] | null;
  administrator: DockMutationEventFragment_administrator | null;
  createdAt: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DockGroupMutationEventFragment
// ====================================================

export interface DockGroupMutationEventFragment_dockGroup {
  __typename: "DockGroup";
  name: string | null;
  id: string;
}

export interface DockGroupMutationEventFragment_diffs {
  __typename: "MutationDiff";
  lhs: CORE_JSON | null;
  rhs: CORE_JSON | null;
  field: string | null;
}

export interface DockGroupMutationEventFragment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface DockGroupMutationEventFragment {
  __typename: "DockGroupMutationEvent";
  id: string;
  dockGroup: DockGroupMutationEventFragment_dockGroup;
  diffs: DockGroupMutationEventFragment_diffs[] | null;
  administrator: DockGroupMutationEventFragment_administrator | null;
  createdAt: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TripEventFragment
// ====================================================

export interface TripEventFragment_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface TripEventFragment_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface TripEventFragment_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type TripEventFragment_vehicle_displayName = TripEventFragment_vehicle_displayName_VehicleDisplayNamed | TripEventFragment_vehicle_displayName_VehicleDisplayNumbered | TripEventFragment_vehicle_displayName_VehicleDisplayAnonymous;

export interface TripEventFragment_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface TripEventFragment_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: TripEventFragment_vehicle_displayName;
  model: TripEventFragment_vehicle_model | null;
}

export interface TripEventFragment_dock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface TripEventFragment_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
}

export interface TripEventFragment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface TripEventFragment_user {
  __typename: "User";
  id: string;
}

export interface TripEventFragment {
  __typename: "TripEvent";
  id: string;
  eventType: DockGroupTimelineEventType;
  vehicle: TripEventFragment_vehicle;
  dock: TripEventFragment_dock;
  trip: TripEventFragment_trip | null;
  administrator: TripEventFragment_administrator | null;
  user: TripEventFragment_user | null;
  createdAt: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VehicleDockingEventFragment
// ====================================================

export interface VehicleDockingEventFragment_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface VehicleDockingEventFragment_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface VehicleDockingEventFragment_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type VehicleDockingEventFragment_vehicle_displayName = VehicleDockingEventFragment_vehicle_displayName_VehicleDisplayNamed | VehicleDockingEventFragment_vehicle_displayName_VehicleDisplayNumbered | VehicleDockingEventFragment_vehicle_displayName_VehicleDisplayAnonymous;

export interface VehicleDockingEventFragment_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface VehicleDockingEventFragment_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: VehicleDockingEventFragment_vehicle_displayName;
  model: VehicleDockingEventFragment_vehicle_model | null;
}

export interface VehicleDockingEventFragment_dock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface VehicleDockingEventFragment_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
}

export interface VehicleDockingEventFragment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface VehicleDockingEventFragment_user {
  __typename: "User";
  id: string;
}

export interface VehicleDockingEventFragment {
  __typename: "VehicleDockingEvent";
  id: string;
  eventType: DockGroupTimelineEventType;
  vehicle: VehicleDockingEventFragment_vehicle;
  dock: VehicleDockingEventFragment_dock;
  trip: VehicleDockingEventFragment_trip | null;
  administrator: VehicleDockingEventFragment_administrator | null;
  user: VehicleDockingEventFragment_user | null;
  createdAt: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DockGroupCreationEventFragment
// ====================================================

export interface DockGroupCreationEventFragment_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
}

export interface DockGroupCreationEventFragment {
  __typename: "DockGroupCreationEvent";
  id: string;
  dockGroup: DockGroupCreationEventFragment_dockGroup;
  createdAt: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DockCreationEventFragment
// ====================================================

export interface DockCreationEventFragment_dock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface DockCreationEventFragment {
  __typename: "DockCreationEvent";
  id: string;
  dock: DockCreationEventFragment_dock;
  createdAt: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DockGroupTimelineNodeFragment
// ====================================================

export interface DockGroupTimelineNodeFragment_SystemAlertUpdated {
  __typename: "SystemAlertUpdated";
}

export interface DockGroupTimelineNodeFragment_DockMutationEvent_dock {
  __typename: "Dock";
  number: number | null;
  id: string;
}

export interface DockGroupTimelineNodeFragment_DockMutationEvent_diffs {
  __typename: "MutationDiff";
  field: string | null;
  lhs: CORE_JSON | null;
  rhs: CORE_JSON | null;
}

export interface DockGroupTimelineNodeFragment_DockMutationEvent_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface DockGroupTimelineNodeFragment_DockMutationEvent {
  __typename: "DockMutationEvent";
  id: string;
  dock: DockGroupTimelineNodeFragment_DockMutationEvent_dock;
  diffs: DockGroupTimelineNodeFragment_DockMutationEvent_diffs[] | null;
  administrator: DockGroupTimelineNodeFragment_DockMutationEvent_administrator | null;
  createdAt: CORE_IsoDateTime;
}

export interface DockGroupTimelineNodeFragment_DockGroupMutationEvent_dockGroup {
  __typename: "DockGroup";
  name: string | null;
  id: string;
}

export interface DockGroupTimelineNodeFragment_DockGroupMutationEvent_diffs {
  __typename: "MutationDiff";
  lhs: CORE_JSON | null;
  rhs: CORE_JSON | null;
  field: string | null;
}

export interface DockGroupTimelineNodeFragment_DockGroupMutationEvent_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface DockGroupTimelineNodeFragment_DockGroupMutationEvent {
  __typename: "DockGroupMutationEvent";
  id: string;
  dockGroup: DockGroupTimelineNodeFragment_DockGroupMutationEvent_dockGroup;
  diffs: DockGroupTimelineNodeFragment_DockGroupMutationEvent_diffs[] | null;
  administrator: DockGroupTimelineNodeFragment_DockGroupMutationEvent_administrator | null;
  createdAt: CORE_IsoDateTime;
}

export interface DockGroupTimelineNodeFragment_TripEvent_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface DockGroupTimelineNodeFragment_TripEvent_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface DockGroupTimelineNodeFragment_TripEvent_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type DockGroupTimelineNodeFragment_TripEvent_vehicle_displayName = DockGroupTimelineNodeFragment_TripEvent_vehicle_displayName_VehicleDisplayNamed | DockGroupTimelineNodeFragment_TripEvent_vehicle_displayName_VehicleDisplayNumbered | DockGroupTimelineNodeFragment_TripEvent_vehicle_displayName_VehicleDisplayAnonymous;

export interface DockGroupTimelineNodeFragment_TripEvent_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface DockGroupTimelineNodeFragment_TripEvent_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: DockGroupTimelineNodeFragment_TripEvent_vehicle_displayName;
  model: DockGroupTimelineNodeFragment_TripEvent_vehicle_model | null;
}

export interface DockGroupTimelineNodeFragment_TripEvent_dock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface DockGroupTimelineNodeFragment_TripEvent_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
}

export interface DockGroupTimelineNodeFragment_TripEvent_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface DockGroupTimelineNodeFragment_TripEvent_user {
  __typename: "User";
  id: string;
}

export interface DockGroupTimelineNodeFragment_TripEvent {
  __typename: "TripEvent";
  id: string;
  eventType: DockGroupTimelineEventType;
  vehicle: DockGroupTimelineNodeFragment_TripEvent_vehicle;
  dock: DockGroupTimelineNodeFragment_TripEvent_dock;
  trip: DockGroupTimelineNodeFragment_TripEvent_trip | null;
  administrator: DockGroupTimelineNodeFragment_TripEvent_administrator | null;
  user: DockGroupTimelineNodeFragment_TripEvent_user | null;
  createdAt: CORE_IsoDateTime;
}

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_displayName = DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_displayName_VehicleDisplayNamed | DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_displayName_VehicleDisplayNumbered | DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_displayName_VehicleDisplayAnonymous;

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_displayName;
  model: DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle_model | null;
}

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent_dock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
}

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent_user {
  __typename: "User";
  id: string;
}

export interface DockGroupTimelineNodeFragment_VehicleDockingEvent {
  __typename: "VehicleDockingEvent";
  id: string;
  eventType: DockGroupTimelineEventType;
  vehicle: DockGroupTimelineNodeFragment_VehicleDockingEvent_vehicle;
  dock: DockGroupTimelineNodeFragment_VehicleDockingEvent_dock;
  trip: DockGroupTimelineNodeFragment_VehicleDockingEvent_trip | null;
  administrator: DockGroupTimelineNodeFragment_VehicleDockingEvent_administrator | null;
  user: DockGroupTimelineNodeFragment_VehicleDockingEvent_user | null;
  createdAt: CORE_IsoDateTime;
}

export interface DockGroupTimelineNodeFragment_DockGroupCreationEvent_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
}

export interface DockGroupTimelineNodeFragment_DockGroupCreationEvent {
  __typename: "DockGroupCreationEvent";
  id: string;
  dockGroup: DockGroupTimelineNodeFragment_DockGroupCreationEvent_dockGroup;
  createdAt: CORE_IsoDateTime;
}

export interface DockGroupTimelineNodeFragment_DockCreationEvent_dock {
  __typename: "Dock";
  id: string;
  number: number | null;
}

export interface DockGroupTimelineNodeFragment_DockCreationEvent {
  __typename: "DockCreationEvent";
  id: string;
  dock: DockGroupTimelineNodeFragment_DockCreationEvent_dock;
  createdAt: CORE_IsoDateTime;
}

export interface DockGroupTimelineNodeFragment_SystemAlertEnded_systemAlert_resolvedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface DockGroupTimelineNodeFragment_SystemAlertEnded_systemAlert_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface DockGroupTimelineNodeFragment_SystemAlertEnded_systemAlert {
  __typename: "SystemAlert";
  id: string;
  incidentEndTime: CORE_IsoDateTime | null;
  systemAlertType: SystemAlertType;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: DockGroupTimelineNodeFragment_SystemAlertEnded_systemAlert_resolvedBy | null;
  title: DockGroupTimelineNodeFragment_SystemAlertEnded_systemAlert_title[] | null;
}

export interface DockGroupTimelineNodeFragment_SystemAlertEnded {
  __typename: "SystemAlertEnded";
  id: string;
  createdAt: CORE_IsoDateTime;
  systemAlert: DockGroupTimelineNodeFragment_SystemAlertEnded_systemAlert;
}

export interface DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_description {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_updates_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  title: DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_updates_title[] | null;
}

export interface DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert {
  __typename: "SystemAlert";
  id: string;
  createdAt: CORE_IsoDateTime;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  systemAlertType: SystemAlertType;
  title: DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_title[] | null;
  description: DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_description[] | null;
  administrator: DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_administrator;
  dockGroups: (DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_dockGroups | null)[] | null;
  updates: (DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert_updates | null)[] | null;
}

export interface DockGroupTimelineNodeFragment_SystemAlertStarted {
  __typename: "SystemAlertStarted";
  id: string;
  createdAt: CORE_IsoDateTime;
  systemAlert: DockGroupTimelineNodeFragment_SystemAlertStarted_systemAlert;
}

export type DockGroupTimelineNodeFragment = DockGroupTimelineNodeFragment_SystemAlertUpdated | DockGroupTimelineNodeFragment_DockMutationEvent | DockGroupTimelineNodeFragment_DockGroupMutationEvent | DockGroupTimelineNodeFragment_TripEvent | DockGroupTimelineNodeFragment_VehicleDockingEvent | DockGroupTimelineNodeFragment_DockGroupCreationEvent | DockGroupTimelineNodeFragment_DockCreationEvent | DockGroupTimelineNodeFragment_SystemAlertEnded | DockGroupTimelineNodeFragment_SystemAlertStarted;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CreateDockGroupDataExportRequestResponseFragment
// ====================================================

export interface CreateDockGroupDataExportRequestResponseFragment_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface CreateDockGroupDataExportRequestResponseFragment_dockGroup_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface CreateDockGroupDataExportRequestResponseFragment_dockGroup_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: CreateDockGroupDataExportRequestResponseFragment_dockGroup_entityTags_tag;
}

export interface CreateDockGroupDataExportRequestResponseFragment_dockGroup_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
}

export interface CreateDockGroupDataExportRequestResponseFragment_dockGroup_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface CreateDockGroupDataExportRequestResponseFragment_dockGroup_controller {
  __typename: "Controller";
  state: ControllerState | null;
}

export interface CreateDockGroupDataExportRequestResponseFragment_dockGroup {
  __typename: "DockGroupListItem";
  id: string;
  name: string | null;
  title: string | null;
  state: DockGroupState | null;
  entityTags: CreateDockGroupDataExportRequestResponseFragment_dockGroup_entityTags[] | null;
  type: DockGroupType | null;
  availabilityInfo: CreateDockGroupDataExportRequestResponseFragment_dockGroup_availabilityInfo;
  adSurfaces: number | null;
  coord: CreateDockGroupDataExportRequestResponseFragment_dockGroup_coord | null;
  description: string | null;
  updatedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
  operationsManagesCapacity: boolean | null;
  controller: CreateDockGroupDataExportRequestResponseFragment_dockGroup_controller | null;
}

export interface CreateDockGroupDataExportRequestResponseFragment {
  __typename: "CreateDockGroupDataExportRequestResponse";
  dataExportRequest: CreateDockGroupDataExportRequestResponseFragment_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual dockGroup.
   */
  dockGroup: CreateDockGroupDataExportRequestResponseFragment_dockGroup | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListDockGroupFragment
// ====================================================

export interface ListDockGroupFragment_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface ListDockGroupFragment_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: ListDockGroupFragment_entityTags_tag;
}

export interface ListDockGroupFragment_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
}

export interface ListDockGroupFragment_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface ListDockGroupFragment_controller {
  __typename: "Controller";
  state: ControllerState | null;
}

export interface ListDockGroupFragment {
  __typename: "DockGroupListItem";
  id: string;
  name: string | null;
  title: string | null;
  state: DockGroupState | null;
  entityTags: ListDockGroupFragment_entityTags[] | null;
  type: DockGroupType | null;
  availabilityInfo: ListDockGroupFragment_availabilityInfo;
  adSurfaces: number | null;
  coord: ListDockGroupFragment_coord | null;
  description: string | null;
  updatedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
  operationsManagesCapacity: boolean | null;
  controller: ListDockGroupFragment_controller | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CreateDockDataExportRequestResponseFragment
// ====================================================

export interface CreateDockDataExportRequestResponseFragment_dataExportRequest {
  __typename: "DataExportRequestCompleted" | "DataExportRequestPending" | "DataExportRequestRemoved" | "DataExportRequestError";
  id: string;
}

export interface CreateDockDataExportRequestResponseFragment_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  systemId: string;
  type: DockGroupType | null;
}

export interface CreateDockDataExportRequestResponseFragment_dock_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface CreateDockDataExportRequestResponseFragment_dock_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface CreateDockDataExportRequestResponseFragment_dock_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type CreateDockDataExportRequestResponseFragment_dock_vehicle_displayName = CreateDockDataExportRequestResponseFragment_dock_vehicle_displayName_VehicleDisplayNamed | CreateDockDataExportRequestResponseFragment_dock_vehicle_displayName_VehicleDisplayNumbered | CreateDockDataExportRequestResponseFragment_dock_vehicle_displayName_VehicleDisplayAnonymous;

export interface CreateDockDataExportRequestResponseFragment_dock_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
}

export interface CreateDockDataExportRequestResponseFragment_dock_vehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface CreateDockDataExportRequestResponseFragment_dock_vehicle_controller {
  __typename: "Controller";
  id: string;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  model: CreateDockDataExportRequestResponseFragment_dock_vehicle_controller_model | null;
}

export interface CreateDockDataExportRequestResponseFragment_dock_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface CreateDockDataExportRequestResponseFragment_dock_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: CreateDockDataExportRequestResponseFragment_dock_vehicle_displayName;
  state: VehicleState | null;
  availabilityState: VehicleAvailabilityState;
  availability: CreateDockDataExportRequestResponseFragment_dock_vehicle_availability | null;
  batteryCharge: number | null;
  controller: CreateDockDataExportRequestResponseFragment_dock_vehicle_controller | null;
  model: CreateDockDataExportRequestResponseFragment_dock_vehicle_model | null;
}

export interface CreateDockDataExportRequestResponseFragment_dock {
  __typename: "Dock";
  id: string;
  dockGroup: CreateDockDataExportRequestResponseFragment_dock_dockGroup | null;
  state: DockState | null;
  availabilityState: DockAvailabilityState | null;
  brokenAt: CORE_IsoDateTime | null;
  brokenReason: string | null;
  type: DockType | null;
  rfid: string | null;
  number: number | null;
  vehicle: CreateDockDataExportRequestResponseFragment_dock_vehicle | null;
}

export interface CreateDockDataExportRequestResponseFragment {
  __typename: "CreateDockDataExportRequestResponse";
  dataExportRequest: CreateDockDataExportRequestResponseFragment_dataExportRequest;
  /**
   * Provide the fields to build the data export from here.
   * Note: this does not return the actual controller.
   */
  dock: CreateDockDataExportRequestResponseFragment_dock | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListDockFragment
// ====================================================

export interface ListDockFragment_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  systemId: string;
  type: DockGroupType | null;
}

export interface ListDockFragment_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface ListDockFragment_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface ListDockFragment_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type ListDockFragment_vehicle_displayName = ListDockFragment_vehicle_displayName_VehicleDisplayNamed | ListDockFragment_vehicle_displayName_VehicleDisplayNumbered | ListDockFragment_vehicle_displayName_VehicleDisplayAnonymous;

export interface ListDockFragment_vehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
}

export interface ListDockFragment_vehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface ListDockFragment_vehicle_controller {
  __typename: "Controller";
  id: string;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  model: ListDockFragment_vehicle_controller_model | null;
}

export interface ListDockFragment_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface ListDockFragment_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: ListDockFragment_vehicle_displayName;
  state: VehicleState | null;
  availabilityState: VehicleAvailabilityState;
  availability: ListDockFragment_vehicle_availability | null;
  batteryCharge: number | null;
  controller: ListDockFragment_vehicle_controller | null;
  model: ListDockFragment_vehicle_model | null;
}

export interface ListDockFragment {
  __typename: "Dock";
  id: string;
  dockGroup: ListDockFragment_dockGroup | null;
  state: DockState | null;
  availabilityState: DockAvailabilityState | null;
  brokenAt: CORE_IsoDateTime | null;
  brokenReason: string | null;
  type: DockType | null;
  rfid: string | null;
  number: number | null;
  vehicle: ListDockFragment_vehicle | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaginatedInvoiceFragment
// ====================================================

export interface PaginatedInvoiceFragment_paymentConfirmedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface PaginatedInvoiceFragment_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
}

export interface PaginatedInvoiceFragment_items_InvoiceItemValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface PaginatedInvoiceFragment_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  id: string;
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
  product: PaginatedInvoiceFragment_items_InvoiceItemValueCode_product;
}

export interface PaginatedInvoiceFragment_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface PaginatedInvoiceFragment_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
}

export interface PaginatedInvoiceFragment_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  quantity: CORE_PositiveInt;
  totalAmount: CORE_Money;
  product: PaginatedInvoiceFragment_items_InvoiceItemTrip_product;
  trip: PaginatedInvoiceFragment_items_InvoiceItemTrip_trip;
}

export type PaginatedInvoiceFragment_items = PaginatedInvoiceFragment_items_InvoiceItemAdjustment | PaginatedInvoiceFragment_items_InvoiceItemValueCode | PaginatedInvoiceFragment_items_InvoiceItemTrip;

export interface PaginatedInvoiceFragment_company {
  __typename: "BusinessCompany";
  id: string;
  name: string;
}

export interface PaginatedInvoiceFragment {
  __typename: "InvoiceBusiness";
  id: string;
  systemId: string;
  description: string;
  collectionMethod: CollectionMethod;
  createdAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  total: CORE_Money;
  currency: string;
  vatRate: number;
  totalVat: CORE_Money;
  periodEnd: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  awaitingManualPayment: boolean;
  status: InvoiceStatus;
  paymentConfirmedBy: PaginatedInvoiceFragment_paymentConfirmedBy | null;
  items: PaginatedInvoiceFragment_items[] | null;
  company: PaginatedInvoiceFragment_company;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReportDamageAssetRepairType
// ====================================================

export interface ReportDamageAssetRepairType_part {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface ReportDamageAssetRepairType_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
}

export interface ReportDamageAssetRepairType_defaultReplacedParts {
  __typename: "AssetPart";
  id: string;
  name: string | null;
}

export interface ReportDamageAssetRepairType {
  __typename: "AssetRepairType";
  id: string;
  part: ReportDamageAssetRepairType_part | null;
  model: ReportDamageAssetRepairType_model | null;
  code: string | null;
  name: string | null;
  defaultReplacedParts: (ReportDamageAssetRepairType_defaultReplacedParts | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductFragment
// ====================================================

export interface ProductFragment_localeSlug {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface ProductFragment_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface ProductFragment_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: ProductFragment_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface ProductFragment_localeName {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface ProductFragment_localeDescription {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface ProductFragment_descriptionBeforePurchasable {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface ProductFragment_succeededBy {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface ProductFragment_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface ProductFragment {
  __typename: "Product";
  id: string;
  sanityId: string | null;
  localeSlug: ProductFragment_localeSlug[] | null;
  connectionPriceMatrixes: ProductFragment_connectionPriceMatrixes[];
  systemId: string;
  name: string | null;
  localeName: ProductFragment_localeName[] | null;
  localeDescription: ProductFragment_localeDescription[] | null;
  descriptionBeforePurchasable: (ProductFragment_descriptionBeforePurchasable | null)[] | null;
  freeFloatingDockingFeeAmount: CORE_Money;
  maxVehicleCount: number | null;
  price: CORE_Money;
  vatRate: number;
  enabled: boolean;
  redeemable: boolean;
  giftable: boolean;
  purchasable: boolean;
  campaign: ProductCampaign | null;
  purchaseChannels: (ProductPurchaseChannel | null)[] | null;
  purchasableTo: CORE_IsoDateTime | null;
  purchasableFrom: CORE_IsoDateTime | null;
  giftableTo: CORE_IsoDateTime | null;
  giftableFrom: CORE_IsoDateTime | null;
  redeemableTo: CORE_IsoDateTime | null;
  redeemableFrom: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  renewalTimePadding: CORE_IsoDuration | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  hideFromLists: boolean | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  requiresPaymentCardOnFile: boolean | null;
  useSystemDefaultPriceMatrixes: boolean;
  extendedRentalOptMethod: ProductOptMethod | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  succeededBy: ProductFragment_succeededBy | null;
  partners: ProductFragment_partners[];
  freeMinutesPerDay: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DockGroupRebalancingItem
// ====================================================

export interface DockGroupRebalancingItem_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface DockGroupRebalancingItem_controller {
  __typename: "DockGroupRebalancingController";
  state: ControllerState | null;
}

export interface DockGroupRebalancingItem_availableVehicleCategories {
  __typename: "VehicleCategoryAvailability";
  category: VehicleCategory;
  count: number;
}

export interface DockGroupRebalancingItem {
  __typename: "DockGroupRebalancing";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
  coord: DockGroupRebalancingItem_coord | null;
  totalDockCount: number | null;
  brokenDocks: number | null;
  availableVehicles: number | null;
  availableDocks: number | null;
  brokenVehicles: number | null;
  damagedVehicles: number | null;
  virtuallyDockedVehicles: number | null;
  state: DockGroupRebalancingState | null;
  operationsManagesCapacity: boolean | null;
  controller: DockGroupRebalancingItem_controller | null;
  availableVehicleCategories: DockGroupRebalancingItem_availableVehicleCategories[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BrokenOrDamagedVehicle
// ====================================================

export interface BrokenOrDamagedVehicle_availability {
  __typename: "VehicleAvailability";
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
}

export interface BrokenOrDamagedVehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface BrokenOrDamagedVehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface BrokenOrDamagedVehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type BrokenOrDamagedVehicle_displayName = BrokenOrDamagedVehicle_displayName_VehicleDisplayNamed | BrokenOrDamagedVehicle_displayName_VehicleDisplayNumbered | BrokenOrDamagedVehicle_displayName_VehicleDisplayAnonymous;

export interface BrokenOrDamagedVehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface BrokenOrDamagedVehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface BrokenOrDamagedVehicle_controller {
  __typename: "Controller";
  id: string;
  batteryCharge: number | null;
  batteryStatus: BatteryStatus | null;
  batteryVoltage: number | null;
  model: BrokenOrDamagedVehicle_controller_model | null;
}

export interface BrokenOrDamagedVehicle_latestMaintenance_damages_damageType {
  __typename: "AssetDamageType";
  id: string;
  name: string | null;
}

export interface BrokenOrDamagedVehicle_latestMaintenance_damages {
  __typename: "AssetMaintenanceDamage";
  damageType: BrokenOrDamagedVehicle_latestMaintenance_damages_damageType | null;
}

export interface BrokenOrDamagedVehicle_latestMaintenance {
  __typename: "AssetMaintenance";
  id: string;
  assetId: string;
  assetType: AssetType;
  damages: BrokenOrDamagedVehicle_latestMaintenance_damages[] | null;
}

export interface BrokenOrDamagedVehicle {
  __typename: "Vehicle";
  id: string;
  state: VehicleState | null;
  name: string | null;
  number: string | null;
  brokenAt: CORE_IsoDateTime | null;
  availabilityState: VehicleAvailabilityState;
  availability: BrokenOrDamagedVehicle_availability | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: BrokenOrDamagedVehicle_displayName;
  model: BrokenOrDamagedVehicle_model | null;
  controller: BrokenOrDamagedVehicle_controller | null;
  latestMaintenance: BrokenOrDamagedVehicle_latestMaintenance | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductSubscriptionFragment
// ====================================================

export interface ProductSubscriptionFragment_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface ProductSubscriptionFragment_valueCode {
  __typename: "ValueCode";
  id: string;
  code: string;
}

export interface ProductSubscriptionFragment {
  __typename: "ProductSubscription";
  id: string;
  systemId: string;
  userId: string;
  state: ProductSubscriptionState;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  disabledAt: CORE_IsoDateTime | null;
  product: ProductSubscriptionFragment_product | null;
  valueCode: ProductSubscriptionFragment_valueCode | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Season
// ====================================================

export interface Season {
  __typename: "Season";
  id: string;
  systemId: string;
  startDate: CORE_DateTime;
  endDate: CORE_DateTime | null;
  state: SeasonState;
  startDateOnly: CORE_Date;
  endDateOnly: CORE_Date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkingHoursMutable
// ====================================================

export interface WorkingHoursMutable {
  __typename: "WorkingHours";
  systemId: string;
  name: string | null;
  startTime: CORE_Time;
  endTime: CORE_Time;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkingHoursOverrideMutable
// ====================================================

export interface WorkingHoursOverrideMutable_localeReason {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface WorkingHoursOverrideMutable {
  __typename: "WorkingHoursOverride";
  workingHoursId: string;
  name: string;
  fromDate: CORE_IsoDateTime;
  toDate: CORE_IsoDateTime;
  startTime: CORE_Time | null;
  endTime: CORE_Time | null;
  closed: boolean;
  localeReason: WorkingHoursOverrideMutable_localeReason[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkingHoursOverride
// ====================================================

export interface WorkingHoursOverride_localeReason {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface WorkingHoursOverride {
  __typename: "WorkingHoursOverride";
  id: string;
  state: WorkingHoursOverrideState;
  workingHoursId: string;
  name: string;
  fromDate: CORE_IsoDateTime;
  toDate: CORE_IsoDateTime;
  startTime: CORE_Time | null;
  endTime: CORE_Time | null;
  closed: boolean;
  localeReason: WorkingHoursOverride_localeReason[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkingHours
// ====================================================

export interface WorkingHours {
  __typename: "WorkingHours";
  id: string;
  systemId: string;
  name: string | null;
  startTime: CORE_Time;
  endTime: CORE_Time;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TwilioVerifyRateLimitBucketFragment
// ====================================================

export interface TwilioVerifyRateLimitBucketFragment {
  __typename: "TwilioVerifyRateLimitBucket";
  sid: string;
  /**
   * Number of seconds that the rate limit will be enforced over.
   */
  interval: number;
  /**
   * Maximum number of requests permitted in during the interval.
   */
  max: number;
  dateCreated: CORE_IsoDateTime;
  dateUpdated: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RfidAuth
// ====================================================

export interface RfidAuth_administrator {
  __typename: "Administrator";
  email: string | null;
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface RfidAuth {
  __typename: "RfidAuth";
  id: string;
  enabled: boolean;
  rfid: string;
  systemId: string;
  action: RfidAuthAction;
  parameters: string[] | null;
  administrator: RfidAuth_administrator | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaginatedInvoiceUserTripFragment
// ====================================================

export interface PaginatedInvoiceUserTripFragment_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface PaginatedInvoiceUserTripFragment_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface PaginatedInvoiceUserTripFragment_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface PaginatedInvoiceUserTripFragment_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  product: PaginatedInvoiceUserTripFragment_items_InvoiceItemTrip_product;
  trip: PaginatedInvoiceUserTripFragment_items_InvoiceItemTrip_trip;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface PaginatedInvoiceUserTripFragment_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface PaginatedInvoiceUserTripFragment_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  administrator: PaginatedInvoiceUserTripFragment_items_InvoiceItemAdjustment_administrator;
  createdAt: CORE_IsoDateTime;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export type PaginatedInvoiceUserTripFragment_items = PaginatedInvoiceUserTripFragment_items_InvoiceItemValueCode | PaginatedInvoiceUserTripFragment_items_InvoiceItemTrip | PaginatedInvoiceUserTripFragment_items_InvoiceItemAdjustment;

export interface PaginatedInvoiceUserTripFragment_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface PaginatedInvoiceUserTripFragment {
  __typename: "InvoiceUserTrip";
  id: string;
  systemId: string;
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  description: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money;
  vatRate: number;
  awaitingManualPayment: boolean;
  paymentConfirmedById: string | null;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  canTransitToDraft: boolean;
  canTransitToVoid: boolean;
  canTransitToOpen: boolean;
  canTransitToPaid: boolean;
  canTransitToCollectionFailed: boolean;
  items: PaginatedInvoiceUserTripFragment_items[] | null;
  user: PaginatedInvoiceUserTripFragment_user;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PenaltyListFragment
// ====================================================

export interface PenaltyListFragment_trip {
  __typename: "Trip";
  id: string;
  cancelledReason: string | null;
}

export interface PenaltyListFragment_resolvedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface PenaltyListFragment_givenBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface PenaltyListFragment {
  __typename: "Penalty";
  id: string;
  comment: string | null;
  trip: PenaltyListFragment_trip | null;
  value: number;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: PenaltyListFragment_resolvedBy | null;
  givenBy: PenaltyListFragment_givenBy | null;
  createdAt: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimelineEventNodeFragment
// ====================================================

export interface TimelineEventNodeFragment_entity_Administrator {
  __typename: "Administrator" | "User";
}

export interface TimelineEventNodeFragment_entity_Product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface TimelineEventNodeFragment_entity_Trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface TimelineEventNodeFragment_entity_Trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: TimelineEventNodeFragment_entity_Trip_startDock_dockGroup | null;
}

export interface TimelineEventNodeFragment_entity_Trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface TimelineEventNodeFragment_entity_Trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: TimelineEventNodeFragment_entity_Trip_endDock_dockGroup | null;
}

export interface TimelineEventNodeFragment_entity_Trip_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface TimelineEventNodeFragment_entity_Trip_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface TimelineEventNodeFragment_entity_Trip_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type TimelineEventNodeFragment_entity_Trip_vehicle_displayName = TimelineEventNodeFragment_entity_Trip_vehicle_displayName_VehicleDisplayNamed | TimelineEventNodeFragment_entity_Trip_vehicle_displayName_VehicleDisplayNumbered | TimelineEventNodeFragment_entity_Trip_vehicle_displayName_VehicleDisplayAnonymous;

export interface TimelineEventNodeFragment_entity_Trip_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface TimelineEventNodeFragment_entity_Trip_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: TimelineEventNodeFragment_entity_Trip_vehicle_displayName;
  model: TimelineEventNodeFragment_entity_Trip_vehicle_model | null;
}

export interface TimelineEventNodeFragment_entity_Trip_extendedRental {
  __typename: "ExtendedRental";
  startedAt: CORE_IsoDateTime;
  numberOfPeriods: number;
  totalCostIncurred: number;
}

export interface TimelineEventNodeFragment_entity_Trip_order {
  __typename: "Order";
  id: string;
  type: OrderType;
  amount: CORE_Money;
}

export interface TimelineEventNodeFragment_entity_Trip_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface TimelineEventNodeFragment_entity_Trip_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: TimelineEventNodeFragment_entity_Trip_entityTags_tag;
}

export interface TimelineEventNodeFragment_entity_Trip_userAgent {
  __typename: "UserAgent";
  original: string | null;
  isAndroid: boolean | null;
  isIOS: boolean | null;
  browser: string | null;
}

export interface TimelineEventNodeFragment_entity_Trip {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: TimelineEventNodeFragment_entity_Trip_startDock | null;
  endDock: TimelineEventNodeFragment_entity_Trip_endDock | null;
  vehicle: TimelineEventNodeFragment_entity_Trip_vehicle | null;
  extendedRental: TimelineEventNodeFragment_entity_Trip_extendedRental | null;
  order: TimelineEventNodeFragment_entity_Trip_order | null;
  entityTags: TimelineEventNodeFragment_entity_Trip_entityTags[] | null;
  userAgent: TimelineEventNodeFragment_entity_Trip_userAgent | null;
  duration: number | null;
}

export type TimelineEventNodeFragment_entity = TimelineEventNodeFragment_entity_Administrator | TimelineEventNodeFragment_entity_Product | TimelineEventNodeFragment_entity_Trip;

export interface TimelineEventNodeFragment {
  __typename: "TimelineEvent";
  id: string;
  type: TimelineEventType | null;
  body: string | null;
  entityKind: TimelineEventEntityKind | null;
  entityId: string | null;
  createdAt: CORE_IsoDateTime;
  entity: TimelineEventNodeFragment_entity | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InvoiceUserTripFragment
// ====================================================

export interface InvoiceUserTripFragment_items_InvoiceItemValueCode {
  __typename: "InvoiceItemValueCode";
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface InvoiceUserTripFragment_items_InvoiceItemTrip_product {
  __typename: "Product";
  id: string;
}

export interface InvoiceUserTripFragment_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
}

export interface InvoiceUserTripFragment_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  product: InvoiceUserTripFragment_items_InvoiceItemTrip_product;
  trip: InvoiceUserTripFragment_items_InvoiceItemTrip_trip;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export interface InvoiceUserTripFragment_items_InvoiceItemAdjustment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface InvoiceUserTripFragment_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  administrator: InvoiceUserTripFragment_items_InvoiceItemAdjustment_administrator;
  createdAt: CORE_IsoDateTime;
  amount: CORE_Money;
  amountVat: CORE_Money;
  amountWithoutVat: CORE_Money;
  vatRate: number;
}

export type InvoiceUserTripFragment_items = InvoiceUserTripFragment_items_InvoiceItemValueCode | InvoiceUserTripFragment_items_InvoiceItemTrip | InvoiceUserTripFragment_items_InvoiceItemAdjustment;

export interface InvoiceUserTripFragment {
  __typename: "InvoiceUserTrip";
  id: string;
  systemId: string;
  status: InvoiceStatus;
  collectionMethod: CollectionMethod;
  currency: string;
  description: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  total: CORE_Money;
  totalVat: CORE_Money;
  totalWithoutVat: CORE_Money;
  vatRate: number;
  awaitingManualPayment: boolean;
  paymentConfirmedById: string | null;
  /**
   * Indicates ability to adjust invoice total amount
   */
  adjustable: boolean;
  canTransitToDraft: boolean;
  canTransitToVoid: boolean;
  canTransitToOpen: boolean;
  canTransitToPaid: boolean;
  canTransitToCollectionFailed: boolean;
  items: InvoiceUserTripFragment_items[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimelineUserAgent
// ====================================================

export interface TimelineUserAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ValueCodeFragment
// ====================================================

export interface ValueCodeFragment_ValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface ValueCodeFragment_ValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface ValueCodeFragment_ValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface ValueCodeFragment_ValueCode {
  __typename: "ValueCode";
  id: string;
  product: ValueCodeFragment_ValueCode_product | null;
  administrator: ValueCodeFragment_ValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: ValueCodeFragment_ValueCode_subscription | null;
  campaignTag: string | null;
}

export interface ValueCodeFragment_BusinessValueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface ValueCodeFragment_BusinessValueCode_administrator {
  __typename: "Administrator";
  id: string;
  email: string | null;
  name: string | null;
  profilePictureUrl: string | null;
}

export interface ValueCodeFragment_BusinessValueCode_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface ValueCodeFragment_BusinessValueCode_company {
  __typename: "BusinessCompany";
  id: string;
  name: string;
}

export interface ValueCodeFragment_BusinessValueCode {
  __typename: "BusinessValueCode";
  id: string;
  product: ValueCodeFragment_BusinessValueCode_product | null;
  administrator: ValueCodeFragment_BusinessValueCode_administrator | null;
  code: string;
  state: ValueCodeState | null;
  receiverEmail: string | null;
  redeemedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  validUntil: CORE_IsoDateTime | null;
  subscription: ValueCodeFragment_BusinessValueCode_subscription | null;
  campaignTag: string | null;
  company: ValueCodeFragment_BusinessValueCode_company | null;
}

export type ValueCodeFragment = ValueCodeFragment_ValueCode | ValueCodeFragment_BusinessValueCode;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SimpleListVehicle
// ====================================================

export interface SimpleListVehicle_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface SimpleListVehicle_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface SimpleListVehicle_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: SimpleListVehicle_availability_dock_dockGroup | null;
}

export interface SimpleListVehicle_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface SimpleListVehicle_availability_location {
  __typename: "VehicleLocation";
  location: SimpleListVehicle_availability_location_location;
  address: string | null;
}

export interface SimpleListVehicle_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface SimpleListVehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: SimpleListVehicle_availability_dock | null;
  location: SimpleListVehicle_availability_location | null;
  administrator: SimpleListVehicle_availability_administrator | null;
}

export interface SimpleListVehicle_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface SimpleListVehicle_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: SimpleListVehicle_entityTags_tag;
}

export interface SimpleListVehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: SimpleListVehicle_controller | null;
  availability: SimpleListVehicle_availability | null;
  entityTags: SimpleListVehicle_entityTags[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListVehicle
// ====================================================

export interface ListVehicle_controller_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface ListVehicle_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  batteryStatus: BatteryStatus | null;
  batteryVoltage: number | null;
  batteryCharge: number | null;
  lockMechanismState: LockMechanismState | null;
  latestResponse: CORE_IsoDateTime | null;
  model: ListVehicle_controller_model | null;
}

export interface ListVehicle_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface ListVehicle_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: ListVehicle_availability_dock_dockGroup | null;
}

export interface ListVehicle_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface ListVehicle_availability_location {
  __typename: "VehicleLocation";
  location: ListVehicle_availability_location_location;
  address: string | null;
}

export interface ListVehicle_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface ListVehicle_availability_operationLocation {
  __typename: "OperationLocation";
  id: string;
  name: string;
}

export interface ListVehicle_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  updatedAt: CORE_IsoDateTime | null;
  dock: ListVehicle_availability_dock | null;
  location: ListVehicle_availability_location | null;
  administrator: ListVehicle_availability_administrator | null;
  operationLocation: ListVehicle_availability_operationLocation | null;
}

export interface ListVehicle_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface ListVehicle_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: ListVehicle_entityTags_tag;
}

export interface ListVehicle_model {
  __typename: "AssetModel";
  id: string;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface ListVehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  controller: ListVehicle_controller | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  availability: ListVehicle_availability | null;
  frameNumber: string | null;
  entityTags: ListVehicle_entityTags[] | null;
  model: ListVehicle_model | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AnalyticsNode
// ====================================================

export interface AnalyticsNode_vehicles_controller {
  __typename: "Controller";
  id: string;
  firmwareVersion: string | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface AnalyticsNode_vehicles_availability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface AnalyticsNode_vehicles_availability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  dockGroup: AnalyticsNode_vehicles_availability_dock_dockGroup | null;
}

export interface AnalyticsNode_vehicles_availability_location_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface AnalyticsNode_vehicles_availability_location {
  __typename: "VehicleLocation";
  location: AnalyticsNode_vehicles_availability_location_location;
  address: string | null;
}

export interface AnalyticsNode_vehicles_availability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface AnalyticsNode_vehicles_availability {
  __typename: "VehicleAvailability";
  id: string;
  availabilityState: AvailabilityState | null;
  updatedAt: CORE_IsoDateTime | null;
  reason: AvailabilityStateReason | null;
  dock: AnalyticsNode_vehicles_availability_dock | null;
  location: AnalyticsNode_vehicles_availability_location | null;
  administrator: AnalyticsNode_vehicles_availability_administrator | null;
}

export interface AnalyticsNode_vehicles_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface AnalyticsNode_vehicles_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: AnalyticsNode_vehicles_entityTags_tag;
}

export interface AnalyticsNode_vehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  brokenAt: CORE_IsoDateTime | null;
  batteryCharge: number | null;
  availabilityState: VehicleAvailabilityState;
  unavailabilityReason: UnavailabilityReasons[] | null;
  controller: AnalyticsNode_vehicles_controller | null;
  availability: AnalyticsNode_vehicles_availability | null;
  entityTags: AnalyticsNode_vehicles_entityTags[] | null;
}

export interface AnalyticsNode_categories {
  __typename: "VehicleCategoryInventoryNode";
  category: VehicleCategory;
  count: number;
}

export interface AnalyticsNode {
  __typename: "VehicleInventoryNode";
  count: number;
  vehicles: AnalyticsNode_vehicles[] | null;
  categories: AnalyticsNode_categories[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VehicleTimelineVehicleAvailabilityFragment
// ====================================================

export interface VehicleTimelineVehicleAvailabilityFragment_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_vehicleAvailabilityDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_vehicleAvailabilityDock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  type: DockType;
  dockGroup: VehicleTimelineVehicleAvailabilityFragment_vehicleAvailabilityDock_dockGroup | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_vehicleAvailabilityLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface VehicleTimelineVehicleAvailabilityFragment_vehicleAvailabilityLocation {
  __typename: "VehicleLocation";
  location: VehicleTimelineVehicleAvailabilityFragment_vehicleAvailabilityLocation_location;
  address: string | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_vehicle_displayName_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface VehicleTimelineVehicleAvailabilityFragment_vehicle_displayName_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface VehicleTimelineVehicleAvailabilityFragment_vehicle_displayName_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type VehicleTimelineVehicleAvailabilityFragment_vehicle_displayName = VehicleTimelineVehicleAvailabilityFragment_vehicle_displayName_VehicleDisplayNamed | VehicleTimelineVehicleAvailabilityFragment_vehicle_displayName_VehicleDisplayNumbered | VehicleTimelineVehicleAvailabilityFragment_vehicle_displayName_VehicleDisplayAnonymous;

export interface VehicleTimelineVehicleAvailabilityFragment_vehicle_model {
  __typename: "AssetModel";
  id: string;
  vehicleCategory: VehicleCategory | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  /**
   * Used when displaying and linking to the vehicle in the UI
   */
  displayName: VehicleTimelineVehicleAvailabilityFragment_vehicle_displayName;
  model: VehicleTimelineVehicleAvailabilityFragment_vehicle_model | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_startDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: VehicleTimelineVehicleAvailabilityFragment_trip_startDock_dockGroup | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_endDock {
  __typename: "Dock";
  id: string;
  number: number | null;
  type: DockType | null;
  dockGroup: VehicleTimelineVehicleAvailabilityFragment_trip_endDock_dockGroup | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_startLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_startLocation {
  __typename: "VehicleLocation";
  location: VehicleTimelineVehicleAvailabilityFragment_trip_startLocation_location;
  address: string | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_endLocation_location {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_endLocation {
  __typename: "VehicleLocation";
  location: VehicleTimelineVehicleAvailabilityFragment_trip_endLocation_location;
  address: string | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: VehicleTimelineVehicleAvailabilityFragment_trip_entityTags_tag;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_userAgent {
  __typename: "UserAgent";
  isIOS: boolean | null;
  isAndroid: boolean | null;
  appName: string | null;
  browser: string | null;
  original: string | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_cancelledByAdmin {
  __typename: "Administrator";
  id: string;
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip_order {
  __typename: "Order";
  id: string;
  amount: CORE_Money;
}

export interface VehicleTimelineVehicleAvailabilityFragment_trip {
  __typename: "Trip";
  id: string;
  state: TripState | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: VehicleTimelineVehicleAvailabilityFragment_trip_startDock | null;
  endDock: VehicleTimelineVehicleAvailabilityFragment_trip_endDock | null;
  startLocation: VehicleTimelineVehicleAvailabilityFragment_trip_startLocation | null;
  endLocation: VehicleTimelineVehicleAvailabilityFragment_trip_endLocation | null;
  subscription: VehicleTimelineVehicleAvailabilityFragment_trip_subscription | null;
  entityTags: VehicleTimelineVehicleAvailabilityFragment_trip_entityTags[] | null;
  duration: number | null;
  userAgent: VehicleTimelineVehicleAvailabilityFragment_trip_userAgent | null;
  cancelledByAdmin: VehicleTimelineVehicleAvailabilityFragment_trip_cancelledByAdmin | null;
  order: VehicleTimelineVehicleAvailabilityFragment_trip_order | null;
  cancelledReason: string | null;
}

export interface VehicleTimelineVehicleAvailabilityFragment {
  __typename: "VehicleAvailability";
  id: string;
  systemId: string | null;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  administrator: VehicleTimelineVehicleAvailabilityFragment_administrator | null;
  vehicleAvailabilityDock: VehicleTimelineVehicleAvailabilityFragment_vehicleAvailabilityDock | null;
  vehicleAvailabilityLocation: VehicleTimelineVehicleAvailabilityFragment_vehicleAvailabilityLocation | null;
  vehicle: VehicleTimelineVehicleAvailabilityFragment_vehicle;
  trip: VehicleTimelineVehicleAvailabilityFragment_trip | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Tag
// ====================================================

export interface Tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EntityTag
// ====================================================

export interface EntityTag_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface EntityTag {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: EntityTag_tag;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EntityTagMutable
// ====================================================

export interface EntityTagMutable {
  __typename: "Tag";
  name: string;
  colour: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DockMutable
// ====================================================

export interface DockMutable_coords {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface DockMutable {
  __typename: "Dock";
  number: number | null;
  type: DockType | null;
  state: DockState | null;
  rfid: string | null;
  coords: DockMutable_coords | null;
  brokenAt: CORE_IsoDateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Dock
// ====================================================

export interface Dock_vehicle {
  __typename: "Vehicle";
  id: string;
  name: string | null;
}

export interface Dock_coords {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface Dock {
  __typename: "Dock";
  id: string;
  vehicle: Dock_vehicle | null;
  deletedAt: CORE_IsoDateTime | null;
  number: number | null;
  type: DockType | null;
  state: DockState | null;
  rfid: string | null;
  coords: Dock_coords | null;
  brokenAt: CORE_IsoDateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DockGroupMutable
// ====================================================

export interface DockGroupMutable_localeSubTitle {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface DockGroupMutable_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
}

export interface DockGroupMutable_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface DockGroupMutable_lockFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface DockGroupMutable_requestFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface DockGroupMutable {
  __typename: "DockGroup";
  name: string | null;
  type: DockGroupType | null;
  address: string | null;
  title: string | null;
  /**
   * Localised sub title, using system default language
   */
  subTitle: string | null;
  /**
   * Sub title from Sanity
   */
  localeSubTitle: DockGroupMutable_localeSubTitle[] | null;
  state: DockGroupState | null;
  availabilityInfo: DockGroupMutable_availabilityInfo;
  elevation: number | null;
  adSurfaces: number | null;
  description: string | null;
  coord: DockGroupMutable_coord | null;
  lockFences: DockGroupMutable_lockFences[] | null;
  requestFences: DockGroupMutable_requestFences[] | null;
  showInPublicOverride: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MapDockGroup
// ====================================================

export interface MapDockGroup_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
  availableDocks: number | null;
  availableVehicles: number | null;
}

export interface MapDockGroup_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface MapDockGroup {
  __typename: "DockGroupListItem";
  id: string;
  name: string | null;
  title: string | null;
  state: DockGroupState | null;
  type: DockGroupType | null;
  availabilityInfo: MapDockGroup_availabilityInfo;
  adSurfaces: number | null;
  coord: MapDockGroup_coord | null;
  description: string | null;
  updatedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DockGroup
// ====================================================

export interface DockGroup_localeSubTitle {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface DockGroup_availabilityInfo {
  __typename: "DockGroupAvailability";
  physicalDocks: number | null;
  virtualDocks: number | null;
  totalNumberOfDocks: number | null;
}

export interface DockGroup_coord {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface DockGroup_lockFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface DockGroup_requestFences {
  __typename: "Coord";
  lat: number;
  lng: number;
}

export interface DockGroup_entityTags_tag {
  __typename: "Tag";
  id: string;
  name: string;
  colour: string | null;
  systemTag: boolean | null;
}

export interface DockGroup_entityTags {
  __typename: "EntityTag";
  id: string;
  entityKind: Entity | null;
  entityId: string;
  createdAt: CORE_IsoDateTime;
  tag: DockGroup_entityTags_tag;
}

export interface DockGroup_stationClient {
  __typename: "StationClient";
  id: string;
  state: StationClientState | null;
  description: string | null;
  verificationCode: string | null;
  kioskId: string | null;
  createdAt: CORE_IsoDateTime | null;
  updatedAt: CORE_IsoDateTime | null;
}

export interface DockGroup_controller_model {
  __typename: "AssetModel";
  id: string;
  name: string | null;
  emptyBatteryChargeThreshold: number | null;
  lowBatteryChargeThreshold: number | null;
}

export interface DockGroup_controller {
  __typename: "Controller";
  id: string;
  ip: string | null;
  imei: string | null;
  serialNumber: string | null;
  physicalLockId: string | null;
  firmwareVersion: string | null;
  state: ControllerState | null;
  powerState: PowerState | null;
  model: DockGroup_controller_model | null;
  batteryStatus: BatteryStatus | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  latestResponse: CORE_IsoDateTime | null;
}

export interface DockGroup {
  __typename: "DockGroup";
  name: string | null;
  type: DockGroupType | null;
  address: string | null;
  title: string | null;
  /**
   * Localised sub title, using system default language
   */
  subTitle: string | null;
  /**
   * Sub title from Sanity
   */
  localeSubTitle: DockGroup_localeSubTitle[] | null;
  state: DockGroupState | null;
  availabilityInfo: DockGroup_availabilityInfo;
  elevation: number | null;
  adSurfaces: number | null;
  description: string | null;
  coord: DockGroup_coord | null;
  lockFences: DockGroup_lockFences[] | null;
  requestFences: DockGroup_requestFences[] | null;
  showInPublicOverride: boolean | null;
  id: string;
  systemId: string;
  sanityId: string | null;
  entityTags: DockGroup_entityTags[] | null;
  deletedAt: CORE_IsoDateTime | null;
  stationClient: DockGroup_stationClient | null;
  operationsManagesCapacity: boolean | null;
  controller: DockGroup_controller | null;
  reportedVirtualDockCount: number | null;
  maxVirtualDockCount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SystemMutable
// ====================================================

export interface SystemMutable_coords {
  __typename: "Coord";
  lng: number;
  lat: number;
}

export interface SystemMutable {
  __typename: "System";
  name: string;
  description: string;
  state: SystemState;
  coords: SystemMutable_coords[] | null;
  type: SystemType;
  countryName: string;
  countryCode: string;
  cityName: string;
  defaultLanguageCode: LanguageCode;
  languageCodes: LanguageCode[];
  operator: string | null;
  currency: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  timezone: string;
  maxPenaltyPoints: number | null;
  sanityProjectId: string | null;
  sanityDataset: string | null;
  sanityToken: string | null;
  colourKey: SystemColourKey | null;
  publicDomain: string | null;
  workingHoursId: string | null;
  vehicleQuickReturnPeriodSec: number | null;
  vehicleQuickReturnsToBroken: number | null;
  vehicleLostPeriodDays: number | null;
  dispatcherSmsSenderID: string | null;
  iosAppUrl: string | null;
  androidAppUrl: string | null;
  webUrl: string | null;
  sponsor: Sponsor | null;
  settings: CORE_JSON;
  intercomHelpSite: string | null;
  defaultVatRate: number;
  tripAdditionalMinutesPeriod: CORE_NonNegativeInt;
  maxTripAdditionaMinutesPeriodCount: CORE_NonNegativeInt;
  minTripLengthForCoolOffPeriodSec: number | null;
  /**
   * minutes prior to the end of free included period when you can request Additional Free Minutes
   */
  timeToRequestAdditionalMinutes: CORE_NonNegativeInt;
  coolOffPeriodSec: number | null;
  pauseTripEnabled: FeatureToggleValue | null;
  shouldAskForPushPermissions: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  qrCodeScanEnabled: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled: FeatureToggleValue | null;
  vehicleReservationEnabled: FeatureToggleValue | null;
  /**
   * Deep linking prefix used in apps.
   * 
   * Keep the value in sync with the mobile app configurations:
   * Android: Each system_id_deeplinking_prefix should map to systemId
   * https: // github.com/urbaninfrastructure/bikesharing-multicity-android/blob/master/app/build.gradle#L161
   */
  appDeepLinkingPrefix: string | null;
  /**
   * If enabled users who have unpaid trip orders can not take bikes
   */
  suspendDebtorEnabled: boolean;
  /**
   * ClickUp URL to be used in an Iframe for the Feedback form in Admin.
   * Falls back to the old Slack integration if not set
   */
  feedbackFormUrl: string | null;
  /**
   * ClickUp list id used to fetch support tasks.
   */
  clickUpListId: string | null;
  /**
   * If enabled trip charges are consolidated within an invoice
   */
  tripChargeConsolidationEnabled: boolean;
  /**
   * Defines the days amount during which trip charges are consolidated, an invoice payment is triggered once exceeded
   */
  tripChargeConsolidationPeriod: CORE_PositiveInt;
  /**
   * Defines the total charges amount required in order to trigger payment
   */
  tripChargeConsolidationAmount: CORE_Money;
  /**
   * If enabled new app users should pay a fee
   */
  registrationFeeEnabled: boolean;
  /**
   * Defines the amount of the registration fee
   */
  registrationFeeAmount: CORE_Money;
  /**
   * Enforcement of registration agreement withdrawal consent
   */
  registrationAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of subscription agreement withdrawal consent
   */
  subscriptionAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of consent to send data to third parties
   */
  sendDataToThirdPartiesConsent: ConsentEnforcement;
  /**
   * Defines wallet enablement
   */
  walletEnablement: WalletEnablement;
  /**
   * Defines wallet enablement
   */
  walletMinBalance: CORE_Money;
  vehicleReservationCoolOffPeriodSec: CORE_NonNegativeInt;
  vehicleReservationLimitPerDay: CORE_NonNegativeInt;
  freeFloatingEnabled: boolean;
  vehicleReservationQuickCancellationPeriodSec: CORE_NonNegativeInt;
  vehicleReservationPeriodMins: CORE_NonNegativeInt;
  /**
   * If enabled email with trip details is sent to a user
   */
  tripReceiptEmailNotificationEnabled: boolean;
  /**
   * If enabled push notification with trip details is sent to a user
   */
  tripReceiptPushNotificationEnabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: System
// ====================================================

export interface System_coords {
  __typename: "Coord";
  lng: number;
  lat: number;
}

export interface System_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  id: string;
  title: string;
}

export interface System_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  type: ConnectionPriceMatrixType;
  priceMatrix: System_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory;
}

export interface System_partners {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface System {
  __typename: "System";
  name: string;
  description: string;
  state: SystemState;
  coords: System_coords[] | null;
  type: SystemType;
  countryName: string;
  countryCode: string;
  cityName: string;
  defaultLanguageCode: LanguageCode;
  languageCodes: LanguageCode[];
  operator: string | null;
  currency: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  timezone: string;
  maxPenaltyPoints: number | null;
  sanityProjectId: string | null;
  sanityDataset: string | null;
  sanityToken: string | null;
  colourKey: SystemColourKey | null;
  publicDomain: string | null;
  workingHoursId: string | null;
  vehicleQuickReturnPeriodSec: number | null;
  vehicleQuickReturnsToBroken: number | null;
  vehicleLostPeriodDays: number | null;
  dispatcherSmsSenderID: string | null;
  iosAppUrl: string | null;
  androidAppUrl: string | null;
  webUrl: string | null;
  sponsor: Sponsor | null;
  settings: CORE_JSON;
  intercomHelpSite: string | null;
  defaultVatRate: number;
  tripAdditionalMinutesPeriod: CORE_NonNegativeInt;
  maxTripAdditionaMinutesPeriodCount: CORE_NonNegativeInt;
  minTripLengthForCoolOffPeriodSec: number | null;
  /**
   * minutes prior to the end of free included period when you can request Additional Free Minutes
   */
  timeToRequestAdditionalMinutes: CORE_NonNegativeInt;
  coolOffPeriodSec: number | null;
  pauseTripEnabled: FeatureToggleValue | null;
  shouldAskForPushPermissions: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled: FeatureToggleValue | null;
  qrCodeScanEnabled: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled: FeatureToggleValue | null;
  vehicleReservationEnabled: FeatureToggleValue | null;
  /**
   * Deep linking prefix used in apps.
   * 
   * Keep the value in sync with the mobile app configurations:
   * Android: Each system_id_deeplinking_prefix should map to systemId
   * https: // github.com/urbaninfrastructure/bikesharing-multicity-android/blob/master/app/build.gradle#L161
   */
  appDeepLinkingPrefix: string | null;
  /**
   * If enabled users who have unpaid trip orders can not take bikes
   */
  suspendDebtorEnabled: boolean;
  /**
   * ClickUp URL to be used in an Iframe for the Feedback form in Admin.
   * Falls back to the old Slack integration if not set
   */
  feedbackFormUrl: string | null;
  /**
   * ClickUp list id used to fetch support tasks.
   */
  clickUpListId: string | null;
  /**
   * If enabled trip charges are consolidated within an invoice
   */
  tripChargeConsolidationEnabled: boolean;
  /**
   * Defines the days amount during which trip charges are consolidated, an invoice payment is triggered once exceeded
   */
  tripChargeConsolidationPeriod: CORE_PositiveInt;
  /**
   * Defines the total charges amount required in order to trigger payment
   */
  tripChargeConsolidationAmount: CORE_Money;
  /**
   * If enabled new app users should pay a fee
   */
  registrationFeeEnabled: boolean;
  /**
   * Defines the amount of the registration fee
   */
  registrationFeeAmount: CORE_Money;
  /**
   * Enforcement of registration agreement withdrawal consent
   */
  registrationAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of subscription agreement withdrawal consent
   */
  subscriptionAgreementWithdrawalConsent: ConsentEnforcement;
  /**
   * Enforcement of consent to send data to third parties
   */
  sendDataToThirdPartiesConsent: ConsentEnforcement;
  /**
   * Defines wallet enablement
   */
  walletEnablement: WalletEnablement;
  /**
   * Defines wallet enablement
   */
  walletMinBalance: CORE_Money;
  vehicleReservationCoolOffPeriodSec: CORE_NonNegativeInt;
  vehicleReservationLimitPerDay: CORE_NonNegativeInt;
  freeFloatingEnabled: boolean;
  vehicleReservationQuickCancellationPeriodSec: CORE_NonNegativeInt;
  vehicleReservationPeriodMins: CORE_NonNegativeInt;
  /**
   * If enabled email with trip details is sent to a user
   */
  tripReceiptEmailNotificationEnabled: boolean;
  /**
   * If enabled push notification with trip details is sent to a user
   */
  tripReceiptPushNotificationEnabled: boolean;
  id: string;
  vehicleCategories: VehicleCategory[];
  connectionPriceMatrixes: System_connectionPriceMatrixes[] | null;
  partners: System_partners[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RoleMutable
// ====================================================

export interface RoleMutable_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface RoleMutable {
  __typename: "Role";
  name: string;
  systemId: string | null;
  description: string | null;
  acl: RoleMutable_acl[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Role
// ====================================================

export interface Role_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface Role {
  __typename: "Role";
  name: string;
  systemId: string | null;
  description: string | null;
  acl: Role_acl[];
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Administrator
// ====================================================

export interface Administrator_roles_acl {
  __typename: "Acl";
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface Administrator_roles {
  __typename: "Role";
  id: string;
  name: string;
  acl: Administrator_roles_acl[];
}

export interface Administrator_rfidAuth {
  __typename: "RfidAuth";
  id: string;
  enabled: boolean;
  rfid: string;
  systemId: string;
  action: RfidAuthAction;
  parameters: string[] | null;
}

export interface Administrator {
  __typename: "Administrator";
  id: string;
  roles: Administrator_roles[];
  name: string | null;
  profilePictureUrl: string | null;
  email: string | null;
  rfidAuth: (Administrator_rfidAuth | null)[] | null;
  settings: CORE_JSON;
  createdAt: CORE_IsoDateTime;
  operationsApiExternalId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ControllerEventLog
// ====================================================

export interface ControllerEventLog {
  __typename: "ControllerEventLog";
  id: string;
  topic: string | null;
  payload: string | null;
  controllerId: string | null;
  generatedAt: CORE_IsoDateTime | null;
  publishedAt: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
  cellularNetworkLatency: number | null;
  pubSubLatency: number | null;
  direction: ControllerEventLogDirection | null;
  bridgeId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VehicleAvailability
// ====================================================

export interface VehicleAvailability_subscription {
  __typename: "ProductSubscription";
  id: string;
  userId: string;
}

export interface VehicleAvailability_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
}

export interface VehicleAvailability_dock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  type: DockGroupType | null;
}

export interface VehicleAvailability_dock {
  __typename: "VehicleAvailabilityDock";
  id: string;
  number: number | null;
  type: DockType;
  dockGroup: VehicleAvailability_dock_dockGroup | null;
}

export interface VehicleAvailability {
  __typename: "VehicleAvailability";
  id: string;
  systemId: string | null;
  vehicleId: string;
  availabilityState: AvailabilityState | null;
  reason: AvailabilityStateReason | null;
  subscription: VehicleAvailability_subscription | null;
  administrator: VehicleAvailability_administrator | null;
  dock: VehicleAvailability_dock | null;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ControllerMutable
// ====================================================

export interface ControllerMutable {
  __typename: "Controller";
  systemId: string | null;
  serialNumber: string | null;
  ip: string | null;
  imei: string | null;
  physicalLockId: string | null;
  desiredFirmwareVersion: string | null;
  modelId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VehicleMutable
// ====================================================

export interface VehicleMutable {
  __typename: "Vehicle";
  systemId: string | null;
  modelId: string | null;
  name: string | null;
  number: string | null;
  frameNumber: string | null;
  state: VehicleState | null;
  priority: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserMutable
// ====================================================

export interface UserMutable {
  __typename: "User";
  systemId: string | null;
  superpowers: boolean | null;
  gender: string | null;
  name: string | null;
  birthYear: number | null;
  phoneNumber: string | null;
  email: string | null;
  postalAddress: string | null;
  postalCode: string | null;
  countryName: string | null;
  intercomId: string | null;
  active: boolean | null;
  rfid: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GH_ReleaseList
// ====================================================

export interface GH_ReleaseList_tag {
  __typename: "GH_Tag";
  name: string;
}

export interface GH_ReleaseList {
  __typename: "GH_Release";
  id: string;
  name: string | null;
  description: string | null;
  url: string | null;
  tag: GH_ReleaseList_tag | null;
  publishedAt: CORE_IsoDateTime | null;
  isDraft: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimelineSystemAlertEndedFragment
// ====================================================

export interface TimelineSystemAlertEndedFragment_systemAlert_resolvedBy {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface TimelineSystemAlertEndedFragment_systemAlert_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface TimelineSystemAlertEndedFragment_systemAlert {
  __typename: "SystemAlert";
  id: string;
  incidentEndTime: CORE_IsoDateTime | null;
  systemAlertType: SystemAlertType;
  resolvedAt: CORE_IsoDateTime | null;
  resolvedBy: TimelineSystemAlertEndedFragment_systemAlert_resolvedBy | null;
  title: TimelineSystemAlertEndedFragment_systemAlert_title[] | null;
}

export interface TimelineSystemAlertEndedFragment {
  __typename: "SystemAlertEnded";
  id: string;
  createdAt: CORE_IsoDateTime;
  systemAlert: TimelineSystemAlertEndedFragment_systemAlert;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimelineSystemAlertStartedFragment
// ====================================================

export interface TimelineSystemAlertStartedFragment_systemAlert_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface TimelineSystemAlertStartedFragment_systemAlert_description {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface TimelineSystemAlertStartedFragment_systemAlert_administrator {
  __typename: "Administrator";
  id: string;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
}

export interface TimelineSystemAlertStartedFragment_systemAlert_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface TimelineSystemAlertStartedFragment_systemAlert_updates_title {
  __typename: "LocaleString";
  language: LanguageCode;
  text: string;
}

export interface TimelineSystemAlertStartedFragment_systemAlert_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  title: TimelineSystemAlertStartedFragment_systemAlert_updates_title[] | null;
}

export interface TimelineSystemAlertStartedFragment_systemAlert {
  __typename: "SystemAlert";
  id: string;
  createdAt: CORE_IsoDateTime;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  systemAlertType: SystemAlertType;
  title: TimelineSystemAlertStartedFragment_systemAlert_title[] | null;
  description: TimelineSystemAlertStartedFragment_systemAlert_description[] | null;
  administrator: TimelineSystemAlertStartedFragment_systemAlert_administrator;
  dockGroups: (TimelineSystemAlertStartedFragment_systemAlert_dockGroups | null)[] | null;
  updates: (TimelineSystemAlertStartedFragment_systemAlert_updates | null)[] | null;
}

export interface TimelineSystemAlertStartedFragment {
  __typename: "SystemAlertStarted";
  id: string;
  createdAt: CORE_IsoDateTime;
  systemAlert: TimelineSystemAlertStartedFragment_systemAlert;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VehicleDisplayNameFragment
// ====================================================

export interface VehicleDisplayNameFragment_VehicleDisplayNamed {
  __typename: "VehicleDisplayNamed";
  name: string;
  number: string;
}

export interface VehicleDisplayNameFragment_VehicleDisplayNumbered {
  __typename: "VehicleDisplayNumbered";
  number: string;
}

export interface VehicleDisplayNameFragment_VehicleDisplayAnonymous {
  __typename: "VehicleDisplayAnonymous";
  id: string;
}

export type VehicleDisplayNameFragment = VehicleDisplayNameFragment_VehicleDisplayNamed | VehicleDisplayNameFragment_VehicleDisplayNumbered | VehicleDisplayNameFragment_VehicleDisplayAnonymous;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AclAction {
  any = "any",
  create = "create",
  delete = "delete",
  read = "read",
  update = "update",
}

export enum AclEntity {
  administrator = "administrator",
  any = "any",
  area = "area",
  assembly = "assembly",
  asset = "asset",
  assetMaintenance = "assetMaintenance",
  business = "business",
  businessWithPII = "businessWithPII",
  comment = "comment",
  controller = "controller",
  controllerEventLog = "controllerEventLog",
  dataExportRequest = "dataExportRequest",
  discountCampaign = "discountCampaign",
  dock = "dock",
  dockGroup = "dockGroup",
  entityTag = "entityTag",
  fakeTrip = "fakeTrip",
  globalControllerSearch = "globalControllerSearch",
  incident = "incident",
  invoice = "invoice",
  operationLocation = "operationLocation",
  operatorPrivs = "operatorPrivs",
  order = "order",
  paymentConfig = "paymentConfig",
  paymentRefund = "paymentRefund",
  penalty = "penalty",
  priceMatrix = "priceMatrix",
  product = "product",
  pubSubEvent = "pubSubEvent",
  rebalancing = "rebalancing",
  rfidAuth = "rfidAuth",
  role = "role",
  season = "season",
  smartStationManagerAdCampaign = "smartStationManagerAdCampaign",
  smartStationManagerAll = "smartStationManagerAll",
  smartStationManagerConfigurationCustomDisplay = "smartStationManagerConfigurationCustomDisplay",
  smartStationManagerConfigurationSetting = "smartStationManagerConfigurationSetting",
  smartStationManagerConfigurationSoftwareUpdate = "smartStationManagerConfigurationSoftwareUpdate",
  smartStationManagerControllerLog = "smartStationManagerControllerLog",
  smartStationManagerDebug = "smartStationManagerDebug",
  smartStationManagerHostingUtil = "smartStationManagerHostingUtil",
  subscription = "subscription",
  support = "support",
  system = "system",
  systemAlert = "systemAlert",
  tag = "tag",
  trip = "trip",
  user = "user",
  userAgreement = "userAgreement",
  userWithPII = "userWithPII",
  valueCode = "valueCode",
  vehicle = "vehicle",
  vehicleAvailability = "vehicleAvailability",
  vehicleCategory = "vehicleCategory",
  vehicleComment = "vehicleComment",
  vehicleControllerAssembly = "vehicleControllerAssembly",
  workingHours = "workingHours",
  workingHoursOverride = "workingHoursOverride",
}

export enum AssemblyPageTab {
  controllerAssembly = "controllerAssembly",
  globalControllerSearch = "globalControllerSearch",
  moveController = "moveController",
  vehicleControllerAssembly = "vehicleControllerAssembly",
  vehicleRfidSearch = "vehicleRfidSearch",
}

export enum AssetDamageTypeTag {
  continental_battery_error = "continental_battery_error",
  continental_generic_error = "continental_generic_error",
  continental_mcu_error = "continental_mcu_error",
  continental_motor_error = "continental_motor_error",
  decommissioned = "decommissioned",
  lost = "lost",
  reported_by_rfid_scan = "reported_by_rfid_scan",
  too_many_quick_returns = "too_many_quick_returns",
  unauthorized_trip = "unauthorized_trip",
  unresponsive = "unresponsive",
  unresponsive_sleeping = "unresponsive_sleeping",
}

export enum AssetMaintenanceState {
  completed = "completed",
  notCompleted = "notCompleted",
}

export enum AssetType {
  bike = "bike",
  bike_controller = "bike_controller",
  bike_dock = "bike_dock",
  bike_dock_plate = "bike_dock_plate",
  ebike = "ebike",
  headless_bike = "headless_bike",
  scooter = "scooter",
  scooter_controller = "scooter_controller",
  station_column = "station_column",
  station_controller = "station_controller",
}

export enum AvailabilityState {
  awaiting_bolt_lock_armed_to_end = "awaiting_bolt_lock_armed_to_end",
  awaiting_bolt_lock_armed_to_pause = "awaiting_bolt_lock_armed_to_pause",
  awaiting_bolt_lock_armed_to_virtual_dock = "awaiting_bolt_lock_armed_to_virtual_dock",
  awaiting_bolt_lock_to_end = "awaiting_bolt_lock_to_end",
  awaiting_bolt_lock_to_end_cancelling = "awaiting_bolt_lock_to_end_cancelling",
  awaiting_bolt_lock_to_end_jammed = "awaiting_bolt_lock_to_end_jammed",
  awaiting_bolt_lock_to_pause = "awaiting_bolt_lock_to_pause",
  awaiting_bolt_lock_to_pause_cancelling = "awaiting_bolt_lock_to_pause_cancelling",
  awaiting_bolt_lock_to_pause_jammed = "awaiting_bolt_lock_to_pause_jammed",
  awaiting_bolt_lock_to_virtual_dock = "awaiting_bolt_lock_to_virtual_dock",
  awaiting_geoposition = "awaiting_geoposition",
  awaiting_geoposition_administrator = "awaiting_geoposition_administrator",
  awaiting_interaction_confirmation = "awaiting_interaction_confirmation",
  awaiting_iot_unlock_interaction = "awaiting_iot_unlock_interaction",
  awaiting_lock_to_end = "awaiting_lock_to_end",
  awaiting_lock_to_pause = "awaiting_lock_to_pause",
  awaiting_unlock_interaction = "awaiting_unlock_interaction",
  awaiting_vehicle_unlock = "awaiting_vehicle_unlock",
  awaiting_vehicle_unlock_jammed = "awaiting_vehicle_unlock_jammed",
  awaiting_vehicle_unlock_on_resume = "awaiting_vehicle_unlock_on_resume",
  awaiting_vehicle_unlock_on_resume_jammed = "awaiting_vehicle_unlock_on_resume_jammed",
  awaiting_wake_event = "awaiting_wake_event",
  awaiting_wake_event_on_resume = "awaiting_wake_event_on_resume",
  cancelling = "cancelling",
  decommissioned = "decommissioned",
  docked = "docked",
  docking_jammed = "docking_jammed",
  docking_jammed_administrator = "docking_jammed_administrator",
  docking_jammed_operation_location = "docking_jammed_operation_location",
  docking_jammed_unknown = "docking_jammed_unknown",
  in_operation_location = "in_operation_location",
  in_progress = "in_progress",
  lost = "lost",
  on_hold = "on_hold",
  removed_jammed = "removed_jammed",
  unknown = "unknown",
  with_administrator = "with_administrator",
}

export enum AvailabilityStateReason {
  by_administrator = "by_administrator",
  dock_taken = "dock_taken",
  lost_by_dock = "lost_by_dock",
  lost_from_trip = "lost_from_trip",
  missing_dock_rfid = "missing_dock_rfid",
  trip_cancelled = "trip_cancelled",
  unauthorized_trip = "unauthorized_trip",
  unknown_dock_rfid = "unknown_dock_rfid",
  unknown_operations_rfid = "unknown_operations_rfid",
}

export enum BatteryStatus {
  dead = "dead",
  needs_replacement = "needs_replacement",
  ok = "ok",
}

export enum CardBrand {
  american_express = "american_express",
  amex = "amex",
  diners = "diners",
  discover = "discover",
  jcb = "jcb",
  maestro = "maestro",
  mastercard = "mastercard",
  unionpay = "unionpay",
  unknown = "unknown",
  visa = "visa",
}

export enum CollectionMethod {
  charge_automatically = "charge_automatically",
  send_invoice = "send_invoice",
}

export enum ComplexFilterType {
  AND = "AND",
  IN = "IN",
  NOT = "NOT",
  OR = "OR",
}

export enum ConnectionPriceMatrixType {
  reservation = "reservation",
  trip = "trip",
}

export enum ConsentEnforcement {
  hidden = "hidden",
  optional = "optional",
  required = "required",
}

export enum ConsentOptMethod {
  disabled = "disabled",
  forced = "forced",
  in = "in",
  out = "out",
}

export enum ControllerEventLogDirection {
  in = "in",
  out = "out",
}

export enum ControllerState {
  online = "online",
  unresponsive = "unresponsive",
  updating_firmware = "updating_firmware",
}

export enum DashboardWidgets {
  controllerCount = "controllerCount",
  dockGroupRebalancing = "dockGroupRebalancing",
  dockGroupStatus = "dockGroupStatus",
  missingAndLost = "missingAndLost",
  operationLocations = "operationLocations",
  systemAlerts = "systemAlerts",
  tripsActive = "tripsActive",
  tripsThisYear = "tripsThisYear",
  userCount = "userCount",
  vehicleBatteries = "vehicleBatteries",
  vehiclesStatus = "vehiclesStatus",
}

export enum DataExportEntityKind {
  dock = "dock",
  dock_group = "dock_group",
  hardware_controller = "hardware_controller",
  invoice = "invoice",
  order = "order",
  trip = "trip",
  value_code = "value_code",
  vehicle = "vehicle",
}

export enum DiscountCampaignType {
  auto = "auto",
  manual = "manual",
}

export enum DiscountType {
  absolute = "absolute",
  relative = "relative",
}

export enum DockAvailabilityState {
  available = "available",
  unavailable = "unavailable",
  vehicle_available = "vehicle_available",
}

export enum DockCommand {
  arm = "arm",
  free = "free",
  open = "open",
  reset = "reset",
}

export enum DockGroupCommand {
  keepalive = "keepalive",
  restart = "restart",
  restartbus = "restartbus",
}

export enum DockGroupDocksListColumn {
  availabilityState = "availabilityState",
  brokenAt = "brokenAt",
  number = "number",
  rfid = "rfid",
  state = "state",
  vehicle = "vehicle",
  vehicle_batteryCharge = "vehicle_batteryCharge",
}

export enum DockGroupPageTab {
  advanced = "advanced",
  comments = "comments",
  docks = "docks",
  events = "events",
  logs = "logs",
  map = "map",
  properties = "properties",
  stationClient = "stationClient",
}

export enum DockGroupRebalancingState {
  brokenBikes = "brokenBikes",
  damagedBikes = "damagedBikes",
  empty = "empty",
  full = "full",
  unbalanced = "unbalanced",
}

export enum DockGroupState {
  active = "active",
  decommissioned = "decommissioned",
  maintenance = "maintenance",
  not_in_service = "not_in_service",
  planning = "planning",
  testing = "testing",
}

export enum DockGroupTimelineEventType {
  dock_created = "dock_created",
  dock_group_created = "dock_group_created",
  dock_group_mutation = "dock_group_mutation",
  dock_mutation = "dock_mutation",
  system_alert_ended = "system_alert_ended",
  system_alert_started = "system_alert_started",
  system_alert_updated = "system_alert_updated",
  system_closure_alert_ended = "system_closure_alert_ended",
  system_closure_alert_started = "system_closure_alert_started",
  trip_cancelled = "trip_cancelled",
  trip_completed = "trip_completed",
  trip_started = "trip_started",
  trip_starting = "trip_starting",
  vehicle_docking_jammed = "vehicle_docking_jammed",
}

export enum DockGroupType {
  hybrid = "hybrid",
  physical = "physical",
  virtual = "virtual",
}

export enum DockGroupsListColumn {
  adSurfaces = "adSurfaces",
  controllerState = "controllerState",
  description = "description",
  entityTags = "entityTags",
  name = "name",
  state = "state",
  title = "title",
  totalDockCount = "totalDockCount",
  type = "type",
  updatedAt = "updatedAt",
}

export enum DockState {
  active = "active",
  maintenance = "maintenance",
}

export enum DockType {
  physical = "physical",
  virtual = "virtual",
}

export enum DocksListColumn {
  availabilityState = "availabilityState",
  brokenAt = "brokenAt",
  dockGroup = "dockGroup",
  number = "number",
  rfid = "rfid",
  state = "state",
  type = "type",
  vehicleState = "vehicleState",
}

export enum Entity {
  Dock = "Dock",
  DockGroup = "DockGroup",
  Incident = "Incident",
  Product = "Product",
  System = "System",
  Trip = "Trip",
  User = "User",
  Vehicle = "Vehicle",
}

export enum FeatureToggleValue {
  disabled = "disabled",
  enabled = "enabled",
  enabled_for_superpowers = "enabled_for_superpowers",
}

export enum FilterOperator {
  contains = "contains",
  equal = "equal",
  greaterThan = "greaterThan",
  isSet = "isSet",
  lowerThan = "lowerThan",
  notEqual = "notEqual",
  startsWith = "startsWith",
}

export enum GeoJSONType {
  LineString = "LineString",
  Point = "Point",
}

export enum HardwareBridge {
  axa = "axa",
  linka = "linka",
  niu = "niu",
  omni = "omni",
  omni_lock = "omni_lock",
  pashley = "pashley",
  segway = "segway",
  segway_api = "segway_api",
  sharebike = "sharebike",
  smoove = "smoove",
}

export enum HardwareControllerOrderField {
  firmwareVersion = "firmwareVersion",
  id = "id",
  ip = "ip",
  latestResponse = "latestResponse",
  lockMechanismState = "lockMechanismState",
  physicalLockId = "physicalLockId",
  serialNumber = "serialNumber",
  state = "state",
}

export enum InvoiceOrderField {
  createdAt = "createdAt",
  id = "id",
}

export enum InvoiceStatus {
  collection_failed = "collection_failed",
  draft = "draft",
  open = "open",
  paid = "paid",
  uncollectible = "uncollectible",
  void = "void",
}

export enum InvoiceType {
  business = "business",
  trip = "trip",
  value_code = "value_code",
}

export enum LanguageCode {
  de = "de",
  en = "en",
  es = "es",
  fi = "fi",
  fr = "fr",
  it = "it",
  nb = "nb",
  pl = "pl",
  sv = "sv",
  uk = "uk",
}

export enum LockMechanismState {
  jammed = "jammed",
  locked = "locked",
  unlocked = "unlocked",
}

export enum LockVisual {
  ring_lock = "ring_lock",
  segway_bike = "segway_bike",
}

export enum MainMenuItem {
  areas = "areas",
  assembly = "assembly",
  business = "business",
  controllers = "controllers",
  dashboard = "dashboard",
  discounts = "discounts",
  dockGroups = "dockGroups",
  docks = "docks",
  incidents = "incidents",
  maintenance = "maintenance",
  rebalancing = "rebalancing",
  sales = "sales",
  support = "support",
  system = "system",
  systemAlerts = "systemAlerts",
  team = "team",
  trips = "trips",
  users = "users",
  valueCodes = "valueCodes",
  vehicles = "vehicles",
}

export enum MilanATMSubscriptionType {
  annual_adult = "annual_adult",
  annual_young = "annual_young",
  monthly = "monthly",
}

export enum MovedVehicleStatus {
  DOCK_VEHICLE_ERROR = "DOCK_VEHICLE_ERROR",
  OWN_VEHICLE_ERROR = "OWN_VEHICLE_ERROR",
  SUCCESS = "SUCCESS",
}

export enum MutationKind {
  A = "A",
  D = "D",
  E = "E",
  N = "N",
}

export enum OperationLocationType {
  storage = "storage",
  workshop = "workshop",
}

export enum OrderDirection {
  asc = "asc",
  desc = "desc",
}

export enum OrderLineType {
  business_registration_fee = "business_registration_fee",
  business_regular_charge = "business_regular_charge",
  extended_rental = "extended_rental",
  free_floating_docking_fee = "free_floating_docking_fee",
  free_minutes_per_day = "free_minutes_per_day",
  illegal_docking_fee = "illegal_docking_fee",
  pay2go = "pay2go",
  registration_fee = "registration_fee",
  reservation = "reservation",
  subscription = "subscription",
  trip = "trip",
  value_code = "value_code",
  verification = "verification",
  wallet_deposit = "wallet_deposit",
}

export enum OrderStatus {
  cancelled = "cancelled",
  completed = "completed",
  created = "created",
  paid = "paid",
  partially_refunded = "partially_refunded",
  payment_failed = "payment_failed",
  payment_postponed = "payment_postponed",
  pending_payment = "pending_payment",
  pending_refund = "pending_refund",
  refunded = "refunded",
}

export enum OrderType {
  business_registration_fee = "business_registration_fee",
  business_regular_charge = "business_regular_charge",
  extended_rental = "extended_rental",
  pay2go = "pay2go",
  registration_fee = "registration_fee",
  reservation = "reservation",
  subscription = "subscription",
  trip = "trip",
  value_code = "value_code",
  verification = "verification",
  wallet_deposit = "wallet_deposit",
}

export enum PartnerId {
  milan_atm = "milan_atm",
  obos = "obos",
}

/**
 * Order type for which back off rule is configured
 */
export enum PaymentBackoffOrderType {
  renewal = "renewal",
  trip = "trip",
}

export enum PaymentConfigErrorCode {
  ERR_GENERIC = "ERR_GENERIC",
  ERR_NOT_FOUND = "ERR_NOT_FOUND",
  ERR_VALIDATION_FAILED = "ERR_VALIDATION_FAILED",
}

export enum PaymentFailureReason {
  CARD_BLACKLISTED = "CARD_BLACKLISTED",
  CARD_EXPIRED = "CARD_EXPIRED",
  FRAUDULENT = "FRAUDULENT",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  INVALID_AMOUNT = "INVALID_AMOUNT",
  NO_PAYMENT_METHOD = "NO_PAYMENT_METHOD",
  PROCESSING_ERROR = "PROCESSING_ERROR",
  UNKNOWN = "UNKNOWN",
}

export enum PaymentProviderEnum {
  payex = "payex",
  payex_old = "payex_old",
  stripe = "stripe",
}

export enum PaymentTransactionProvider {
  payex = "payex",
  payex_old = "payex_old",
  stripe = "stripe",
}

export enum PaymentTransactionStatus {
  cancelled = "cancelled",
  completed = "completed",
  created = "created",
  failed = "failed",
  partially_refunded = "partially_refunded",
  pending_authentication = "pending_authentication",
  refunded = "refunded",
  scheduled = "scheduled",
}

export enum PenaltyStatus {
  blocked = "blocked",
  none = "none",
  severe_warning = "severe_warning",
  warning = "warning",
}

export enum PositionSource {
  controller_gps = "controller_gps",
  dock = "dock",
  ebike_tracker_gps = "ebike_tracker_gps",
  enduser_device = "enduser_device",
  manual = "manual",
  virtual_station = "virtual_station",
}

export enum PowerState {
  low_battery_sleep = "low_battery_sleep",
  off = "off",
  on = "on",
  sleep = "sleep",
  snooze = "snooze",
}

export enum ProductCampaign {
  masterpass = "masterpass",
}

export enum ProductDurationMethod {
  absolute = "absolute",
  from_purchase = "from_purchase",
  relative = "relative",
}

export enum ProductOptMethod {
  disabled = "disabled",
  forced = "forced",
  in = "in",
  out = "out",
}

export enum ProductPurchaseChannel {
  app = "app",
  business = "business",
  unknown = "unknown",
  web = "web",
}

export enum ProductSubscriptionState {
  active = "active",
  disabled = "disabled",
  inactive = "inactive",
  pending = "pending",
}

export enum RebalancingListColumn {
  availabilityChart = "availabilityChart",
  availableDocks = "availableDocks",
  availableVehicles = "availableVehicles",
  brokenDocks = "brokenDocks",
  brokenVehicles = "brokenVehicles",
  controllerState = "controllerState",
  damagedVehicles = "damagedVehicles",
  dockGroupTitle = "dockGroupTitle",
  state = "state",
  totalDockCount = "totalDockCount",
  virtuallyDockedVehicles = "virtuallyDockedVehicles",
}

export enum RebalancingPageTab {
  availability = "availability",
  list = "list",
  map = "map",
  misplacedVehicles = "misplacedVehicles",
  unlockVehicles = "unlockVehicles",
  vehicleDeploy = "vehicleDeploy",
}

export enum RefundFailureReason {
  CARD_EXPIRED = "CARD_EXPIRED",
  FRAUDULENT = "FRAUDULENT",
  UNKNOWN = "UNKNOWN",
}

export enum RefundReason {
  DUPLICATE = "DUPLICATE",
  FRAUDULENT = "FRAUDULENT",
  OTHER = "OTHER",
  REQUESTED_BY_CUSTOMER = "REQUESTED_BY_CUSTOMER",
}

export enum RefundState {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum RentalMeterType {
  running = "running",
  standard = "standard",
}

export enum RfidAuthAction {
  assign_to_operation_location = "assign_to_operation_location",
  default = "default",
  end_maintenance = "end_maintenance",
  start_maintenance = "start_maintenance",
  unlock_and_apply_critical_damage = "unlock_and_apply_critical_damage",
  unlock_and_assign_to_operation_location = "unlock_and_assign_to_operation_location",
  unlock_assign_to_operation_location_and_apply_critical_damage = "unlock_assign_to_operation_location_and_apply_critical_damage",
  unlock_battery = "unlock_battery",
  unlock_vehicle = "unlock_vehicle",
}

export enum SeasonState {
  active = "active",
  ended = "ended",
  upcoming = "upcoming",
}

export enum Sponsor {
  atb = "atb",
  foodora = "foodora",
  just_eat = "just_eat",
  obos = "obos",
}

export enum StationClientState {
  maintenance = "maintenance",
  offline = "offline",
  online = "online",
  planning = "planning",
}

export enum SystemAlertType {
  other = "other",
  station_closure = "station_closure",
  station_move = "station_move",
  system_closure = "system_closure",
}

export enum SystemAlertVisibility {
  internal = "internal",
  public_all = "public_all",
  public_web = "public_web",
}

export enum SystemColourKey {
  bikeMi = "bikeMi",
  black = "black",
  blue01 = "blue01",
  blue02 = "blue02",
  edinburghCityBike = "edinburghCityBike",
  green01 = "green01",
  green02 = "green02",
  green03 = "green03",
  green04 = "green04",
  orange01 = "orange01",
  pink01 = "pink01",
  pink02 = "pink02",
  purple01 = "purple01",
  purple02 = "purple02",
  purple03 = "purple03",
  red01 = "red01",
  red02 = "red02",
  red03 = "red03",
  red04 = "red04",
  rose01 = "rose01",
  veronaBike = "veronaBike",
}

export enum SystemState {
  closed = "closed",
  open = "open",
  planning = "planning",
}

export enum SystemType {
  bikesharing = "bikesharing",
  busshelters = "busshelters",
  carsharing = "carsharing",
  scootersharing = "scootersharing",
}

export enum ThirdPartyController {
  axa_lock = "axa_lock",
  continental = "continental",
}

export enum TimelineEventEntityKind {
  Administrator = "Administrator",
  Product = "Product",
  Trip = "Trip",
  User = "User",
}

export enum TimelineEventType {
  admin_note = "admin_note",
  api_error = "api_error",
  app_login = "app_login",
  code_verified = "code_verified",
  pii_admin_query = "pii_admin_query",
  pii_admin_search = "pii_admin_search",
  pii_update = "pii_update",
  product_renewal_disabled = "product_renewal_disabled",
  product_renewal_enabled = "product_renewal_enabled",
  signup = "signup",
  trip_cancelled = "trip_cancelled",
  trip_completed = "trip_completed",
  trip_paused = "trip_paused",
  trip_resumed = "trip_resumed",
  trip_started = "trip_started",
  unlock_failure = "unlock_failure",
  unlock_success = "unlock_success",
  web_login = "web_login",
}

export enum TripNotificationTemplate {
  endOfFreePeriod = "endOfFreePeriod",
  onTripPenalty = "onTripPenalty",
}

export enum TripOrderField {
  cancelledReason = "cancelledReason",
  endedAt = "endedAt",
  id = "id",
  startedAt = "startedAt",
  state = "state",
}

export enum TripState {
  cancelled = "cancelled",
  completed = "completed",
  in_progress = "in_progress",
  on_hold = "on_hold",
  starting = "starting",
}

export enum TwilioVerifyRateLimitUniqueName {
  end_user_ip_address = "end_user_ip_address",
  phone_number = "phone_number",
  phone_number_country_code = "phone_number_country_code",
  phone_number_first_four = "phone_number_first_four",
  phone_number_first_four_and_user_agent = "phone_number_first_four_and_user_agent",
  phone_number_foreign_country_code = "phone_number_foreign_country_code",
  user_agent = "user_agent",
}

export enum UnavailabilityReasons {
  dockGroup_state = "dockGroup_state",
  dock_brokenAt = "dock_brokenAt",
  dock_state = "dock_state",
  vehicle_batteryCharge = "vehicle_batteryCharge",
  vehicle_blockedAt = "vehicle_blockedAt",
  vehicle_brokenAt = "vehicle_brokenAt",
  vehicle_controller = "vehicle_controller",
  vehicle_controller_batteryCharge = "vehicle_controller_batteryCharge",
  vehicle_controller_batteryVoltage = "vehicle_controller_batteryVoltage",
  vehicle_controller_ip = "vehicle_controller_ip",
  vehicle_controller_model = "vehicle_controller_model",
  vehicle_controller_powerState = "vehicle_controller_powerState",
  vehicle_controller_serialNumber = "vehicle_controller_serialNumber",
  vehicle_controller_state = "vehicle_controller_state",
  vehicle_model = "vehicle_model",
  vehicle_reserved = "vehicle_reserved",
}

export enum UserAgreementType {
  privacy_policy = "privacy_policy",
  terms_of_use = "terms_of_use",
}

export enum UserLoggedInEventType {
  app_login = "app_login",
  web_login = "web_login",
}

export enum UserRequiredFields {
  email = "email",
}

export enum UserRfidAssignMethod {
  scan_qr = "scan_qr",
  set_rfid = "set_rfid",
}

export enum UserTimelineConnectionItemType {
  admin_note = "admin_note",
  api_error = "api_error",
  app_login = "app_login",
  code_verified = "code_verified",
  pii_admin_query = "pii_admin_query",
  pii_admin_search = "pii_admin_search",
  pii_update = "pii_update",
  product_renewal_disabled = "product_renewal_disabled",
  product_renewal_enabled = "product_renewal_enabled",
  signup = "signup",
  trip_cancelled = "trip_cancelled",
  unlock_failure = "unlock_failure",
  web_login = "web_login",
}

export enum ValueCodeOrderField {
  campaignTag = "campaignTag",
  company = "company",
  createdAt = "createdAt",
  validUntil = "validUntil",
}

export enum ValueCodeState {
  redeemed = "redeemed",
  revoked = "revoked",
  unused = "unused",
}

export enum ValueCodeType {
  business = "business",
  regular = "regular",
}

export enum VehicleAnalyticsWidgets {
  availableBikesOnTheStreet = "availableBikesOnTheStreet",
  bikesDecommissioned = "bikesDecommissioned",
  bikesInOperationLocation = "bikesInOperationLocation",
  bikesLost = "bikesLost",
  bikesMissing = "bikesMissing",
  bikesNeverSeen = "bikesNeverSeen",
  bikesOnTheStreet = "bikesOnTheStreet",
  bikesOnTrip = "bikesOnTrip",
  bikesWithRebalancing = "bikesWithRebalancing",
  firmwareRollout = "firmwareRollout",
  unavailableBikesOnTheStreet = "unavailableBikesOnTheStreet",
}

export enum VehicleAvailabilityState {
  available = "available",
  decommissioned = "decommissioned",
  in_operation_location = "in_operation_location",
  lost = "lost",
  never_seen = "never_seen",
  on_trip = "on_trip",
  unavailable = "unavailable",
  unknown = "unknown",
  with_administrator = "with_administrator",
}

export enum VehicleCategory {
  bike = "bike",
  ebike = "ebike",
  ebike_with_childseat = "ebike_with_childseat",
  scooter = "scooter",
}

export enum VehicleOrderField {
  availabilityState = "availabilityState",
  availability_dock_dockGroup_title = "availability_dock_dockGroup_title",
  availability_operationLocation = "availability_operationLocation",
  batteryCharge = "batteryCharge",
  brokenAt = "brokenAt",
  controller_batteryCharge = "controller_batteryCharge",
  controller_latestResponse = "controller_latestResponse",
  controller_lockMechanismState = "controller_lockMechanismState",
  dockGroup = "dockGroup",
  firmwareVersion = "firmwareVersion",
  frameNumber = "frameNumber",
  lastEvent = "lastEvent",
  latestResponse = "latestResponse",
  name = "name",
  number = "number",
  updatedAt = "updatedAt",
}

export enum VehicleState {
  active = "active",
  broken = "broken",
  decommissioned = "decommissioned",
}

export enum VehicleTimelineConnectionItemTypes {
  mutation_log = "mutation_log",
  vehicle_availability_history = "vehicle_availability_history",
}

export enum VehiclesAvailabilityFilter {
  awaiting_bolt_lock_armed_to_end = "awaiting_bolt_lock_armed_to_end",
  awaiting_bolt_lock_armed_to_pause = "awaiting_bolt_lock_armed_to_pause",
  awaiting_bolt_lock_armed_to_virtual_dock = "awaiting_bolt_lock_armed_to_virtual_dock",
  awaiting_bolt_lock_to_end = "awaiting_bolt_lock_to_end",
  awaiting_bolt_lock_to_end_cancelling = "awaiting_bolt_lock_to_end_cancelling",
  awaiting_bolt_lock_to_end_jammed = "awaiting_bolt_lock_to_end_jammed",
  awaiting_bolt_lock_to_pause = "awaiting_bolt_lock_to_pause",
  awaiting_bolt_lock_to_pause_cancelling = "awaiting_bolt_lock_to_pause_cancelling",
  awaiting_bolt_lock_to_pause_jammed = "awaiting_bolt_lock_to_pause_jammed",
  awaiting_bolt_lock_to_virtual_dock = "awaiting_bolt_lock_to_virtual_dock",
  awaiting_geoposition = "awaiting_geoposition",
  awaiting_geoposition_administrator = "awaiting_geoposition_administrator",
  awaiting_interaction_confirmation = "awaiting_interaction_confirmation",
  awaiting_iot_unlock_interaction = "awaiting_iot_unlock_interaction",
  awaiting_lock_to_end = "awaiting_lock_to_end",
  awaiting_lock_to_pause = "awaiting_lock_to_pause",
  awaiting_unlock_interaction = "awaiting_unlock_interaction",
  awaiting_vehicle_unlock = "awaiting_vehicle_unlock",
  awaiting_vehicle_unlock_jammed = "awaiting_vehicle_unlock_jammed",
  awaiting_vehicle_unlock_on_resume = "awaiting_vehicle_unlock_on_resume",
  awaiting_vehicle_unlock_on_resume_jammed = "awaiting_vehicle_unlock_on_resume_jammed",
  awaiting_wake_event = "awaiting_wake_event",
  awaiting_wake_event_on_resume = "awaiting_wake_event_on_resume",
  cancelling = "cancelling",
  decommissioned = "decommissioned",
  docked = "docked",
  docking_jammed = "docking_jammed",
  docking_jammed_administrator = "docking_jammed_administrator",
  docking_jammed_operation_location = "docking_jammed_operation_location",
  docking_jammed_unknown = "docking_jammed_unknown",
  in_operation_location = "in_operation_location",
  in_progress = "in_progress",
  lost = "lost",
  on_hold = "on_hold",
  removed_jammed = "removed_jammed",
  unknown = "unknown",
  with_administrator = "with_administrator",
}

export enum VehiclesInventoryListColumns {
  availability_administrator_name = "availability_administrator_name",
  availability_availabilityState = "availability_availabilityState",
  availability_dock_dockGroup_title = "availability_dock_dockGroup_title",
  batteryCharge = "batteryCharge",
  entityTags = "entityTags",
  name = "name",
  state = "state",
}

export enum VehiclesListColumns {
  availabilityState = "availabilityState",
  availability_availabilityState = "availability_availabilityState",
  availability_dock_dockGroup_title = "availability_dock_dockGroup_title",
  availability_operationLocation = "availability_operationLocation",
  batteryCharge = "batteryCharge",
  brokenAt = "brokenAt",
  controller_batteryCharge = "controller_batteryCharge",
  controller_latestResponse = "controller_latestResponse",
  controller_lockMechanismState = "controller_lockMechanismState",
  dockGroup = "dockGroup",
  entityTags = "entityTags",
  firmwareVersion = "firmwareVersion",
  frameNumber = "frameNumber",
  lastEvent = "lastEvent",
  name = "name",
  number = "number",
  state = "state",
}

export enum VehiclesPageTab {
  analytics = "analytics",
  list = "list",
  map = "map",
}

export enum WalletEnablement {
  disabled = "disabled",
  forced = "forced",
  opt_in = "opt_in",
}

export enum WalletTransactionSortField {
  createdAt = "createdAt",
}

export enum WalletTransactionType {
  credit = "credit",
  credit_refund = "credit_refund",
  debit = "debit",
  debit_refund = "debit_refund",
}

export enum WorkingHoursOverrideState {
  active = "active",
  expired = "expired",
  upcoming = "upcoming",
}

/**
 * An enum describing what kind of type a given `__Type` is.
 */
export enum __TypeKind {
  ENUM = "ENUM",
  INPUT_OBJECT = "INPUT_OBJECT",
  INTERFACE = "INTERFACE",
  LIST = "LIST",
  NON_NULL = "NON_NULL",
  OBJECT = "OBJECT",
  SCALAR = "SCALAR",
  UNION = "UNION",
}

export interface AclInput {
  systemId: string;
  entity: AclEntity;
  action: AclAction;
}

export interface AdministratorInput {
  roleIds?: string[] | null;
  settings?: AdministratorSettingsInput | null;
  selectedSystemId?: string | null;
  operationsApiExternalId?: string | null;
}

export interface AdministratorSettingsInput {
  showRelativeDates?: boolean | null;
  vehiclesConnectionFields?: string[] | null;
  developerMode?: boolean | null;
  collapseMainMenu?: boolean | null;
  systemAlertConnectionFilters?: string[] | null;
  tripsConnectionFilters?: string[] | null;
  rfidAuthConnectionFilters?: string[] | null;
  productsPaginatedFilters?: string[] | null;
  valueCodesConnectionFilters?: string[] | null;
  administratorsConnectionFilter?: string[] | null;
  docksConnectionFilters?: string[] | null;
  dockGroupsConnectionFilters?: string[] | null;
  controllersConnectionFilters?: string[] | null;
  discountCampaignsPaginatedFilters?: string[] | null;
  areasConnectionFilters?: string[] | null;
}

export interface AssetDamageTypeInput {
  modelId: string;
  repairTypeId?: string | null;
  sanityId?: string | null;
  localeName?: (InputLocaleString | null)[] | null;
  channel?: (string | null)[] | null;
  tags?: (string | null)[] | null;
  critical?: boolean | null;
}

export interface AssetMaintenanceDamageInput {
  damageTypeId?: string | null;
  administratorId?: string | null;
  userId?: string | null;
  enforceCritical?: boolean | null;
  comment?: string | null;
}

export interface AssetMaintenanceInput {
  systemId: string;
  assetId: string;
  assetType: AssetType;
  comment?: string | null;
  repairedById?: string | null;
  repairs?: AssetMaintenanceRepairInput[] | null;
  startedAt?: CORE_IsoDateTime | null;
  completedAt?: CORE_IsoDateTime | null;
}

export interface AssetMaintenanceRepairInput {
  repairTypeId: string;
  replacedParts?: (string | null)[] | null;
}

export interface AssetMaintenancesFilters {
  damageTypeIds?: string[] | null;
  modelIds?: string[] | null;
  assetIds?: string[] | null;
  assetName?: string | null;
  assetState?: VehicleState | null;
  maintenanceState?: AssetMaintenanceState | null;
}

export interface AssetModelCreateInput {
  type: AssetType;
  systemId: string;
  name?: string | null;
  description?: string | null;
  hardwareBridge?: HardwareBridge | null;
  thirdPartyController?: ThirdPartyController | null;
  lockVisual?: LockVisual | null;
  vehicleCategory?: VehicleCategory | null;
  emptyBatteryChargeThreshold?: number | null;
  lowBatteryChargeThreshold?: number | null;
  createQuickReturnDamageType?: boolean | null;
  createUnresponsiveDamageType?: boolean | null;
  createUnauthorizedTripDamageType?: boolean | null;
  createLostDamageType?: boolean | null;
  unresponsiveIntervalMins?: number | null;
  communicationTimeoutSec?: number | null;
  qrCodeFormat?: string | null;
  freeMinutesPerDayMultiplier?: number | null;
  maxRangeM?: number | null;
}

export interface AssetModelUpdateInput {
  name?: string | null;
  description?: string | null;
  hardwareBridge?: HardwareBridge | null;
  thirdPartyController?: ThirdPartyController | null;
  lockVisual?: LockVisual | null;
  vehicleCategory?: VehicleCategory | null;
  emptyBatteryChargeThreshold?: number | null;
  lowBatteryChargeThreshold?: number | null;
  unresponsiveIntervalMins?: number | null;
  communicationTimeoutSec?: number | null;
  qrCodeFormat?: string | null;
  freeMinutesPerDayMultiplier?: number | null;
  maxRangeM?: number | null;
}

export interface AssetPartInput {
  modelId: string;
  name?: string | null;
  order: number;
  parentPartId?: string | null;
}

export interface AssetRepairTypeInput {
  partId?: string | null;
  defaultReplacedParts?: (string | null)[] | null;
  modelId: string;
  code?: string | null;
  name?: string | null;
}

export interface BusinessCompanyApprovalsInput {
  sendInvoice: boolean;
}

export interface BusinessCompanyInput {
  name?: string | null;
  organizationNumber?: string | null;
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  receiptEmail?: string | null;
  collectionMethod?: CollectionMethod | null;
  active?: boolean | null;
  approvals?: BusinessCompanyApprovalsInput | null;
  customerNumber?: CORE_PositiveInt | null;
  tripChargeConsolidationEnabled?: boolean | null;
}

export interface CampaignInput {
  systemId: string;
  productId: string;
  campaignTag: string;
  validUntil: CORE_IsoDateTime;
  quantity: number;
}

export interface CollectionMethodsInput {
  chargeAutomatically?: boolean | null;
  sendInvoice?: boolean | null;
}

export interface CompanyAdministratorInput {
  name?: string | null;
  email?: CORE_EmailAddress | null;
}

export interface ComplexFilter {
  type: ComplexFilterType;
  filters?: (Filter | null)[] | null;
  group?: GroupFilter | null;
}

export interface ConnectionPriceMatrixInput {
  type: ConnectionPriceMatrixType;
  vehicleCategory: VehicleCategory;
  priceMatrixId: string;
}

export interface ControllerInput {
  systemId: string;
  modelId?: string | null;
  serialNumber: string;
  ip?: string | null;
  imei?: string | null;
  physicalLockId?: string | null;
  desiredFirmwareVersion?: string | null;
}

export interface CoordInput {
  lat: number;
  lng: number;
}

export interface CreateInvoiceItemAdjustmentInput {
  amount: CORE_Money;
  vatRate?: number | null;
  description?: string | null;
}

export interface CreateStripeConfigInput {
  systemId: string;
  token?: string | null;
  secretKey?: string | null;
  publishableKey: string;
  endpointSecret?: string | null;
}

export interface CreateSystemAlertInput {
  createdAt?: CORE_IsoDateTime | null;
  systemId: string;
  administratorId: string;
  sanityId?: string | null;
  dockGroupIds?: string[] | null;
  resolvedAt?: CORE_IsoDateTime | null;
  resolvedBy?: string | null;
  systemAlertType: SystemAlertType;
  visibility: SystemAlertVisibility;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime?: CORE_IsoDateTime | null;
  publishedStartTime?: CORE_IsoDateTime | null;
  publishedEndTime?: CORE_IsoDateTime | null;
  title?: InputLocaleString[] | null;
  description?: InputLocaleString[] | null;
  slug?: InputLocaleString[] | null;
  isDismissible?: boolean | null;
}

export interface Cursor {
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
}

export interface DiscountCampaignCreateInput {
  name: string;
  localizationData?: DiscountCampaignLocalizationDataInput | null;
  type: DiscountCampaignType;
  limit?: CORE_NonNegativeInt | null;
  active?: boolean | null;
  discount: DiscountInput;
  applicableOncePerUser?: boolean | null;
  applicableForSubscription?: boolean | null;
  applicableForValueCode?: boolean | null;
  applicableForRegistrationFee?: boolean | null;
  applicableForAllProducts?: boolean | null;
  code?: string | null;
  validFrom: CORE_IsoDateTime;
  validTo?: CORE_IsoDateTime | null;
  productIds?: CORE_PositiveInt[] | null;
}

export interface DiscountCampaignLocalizationDataInput {
  name?: InputLocaleString[] | null;
}

export interface DiscountCampaignUpdateInput {
  name?: string | null;
  localizationData?: DiscountCampaignLocalizationDataInput | null;
  type?: DiscountCampaignType | null;
  limit?: CORE_NonNegativeInt | null;
  active?: boolean | null;
  discount?: DiscountInput | null;
  applicableOncePerUser?: boolean | null;
  applicableForSubscription?: boolean | null;
  applicableForValueCode?: boolean | null;
  applicableForRegistrationFee?: boolean | null;
  applicableForAllProducts?: boolean | null;
  code?: string | null;
  validFrom?: CORE_IsoDateTime | null;
  validTo?: CORE_IsoDateTime | null;
  productIds?: CORE_PositiveInt[] | null;
}

export interface DiscountInput {
  type: DiscountType;
  amount: CORE_PositiveInt;
}

export interface DockGroupCreateInput {
  systemId: string;
  sanityId?: string | null;
  type?: string | null;
  name?: string | null;
  address?: string | null;
  title?: string | null;
  subTitle?: string | null;
  localeSubTitle?: (InputLocaleString | null)[] | null;
  state?: DockGroupState | null;
  description?: string | null;
  tagIds?: (string | null)[] | null;
  physicalDockCount?: number | null;
  virtualDockCount?: number | null;
  coord?: CoordInput | null;
  adSurfaces?: number | null;
  elevation?: number | null;
  lockFences?: CoordInput[] | null;
  requestFences?: CoordInput[] | null;
  workingHoursId?: string | null;
  showInPublicOverride?: boolean | null;
  operationsManagesCapacity?: boolean | null;
  reportedVirtualDockCount?: number | null;
  maxVirtualDockCount?: number | null;
}

export interface DockGroupTimelineOptions {
  eventTypes?: (DockGroupTimelineEventType | null)[] | null;
  docks?: (string | null)[] | null;
  toDate?: CORE_IsoDateTime | null;
  fromDate?: CORE_IsoDateTime | null;
}

export interface DockGroupUpdateInput {
  sanityId?: string | null;
  type?: string | null;
  name?: string | null;
  address?: string | null;
  title?: string | null;
  subTitle?: string | null;
  localeSubTitle?: (InputLocaleString | null)[] | null;
  state?: DockGroupState | null;
  description?: string | null;
  tagIds?: (string | null)[] | null;
  physicalDockCount?: number | null;
  virtualDockCount?: number | null;
  coord?: CoordInput | null;
  adSurfaces?: number | null;
  elevation?: number | null;
  lockFences?: CoordInput[] | null;
  requestFences?: CoordInput[] | null;
  workingHoursId?: string | null;
  showInPublicOverride?: boolean | null;
  operationsManagesCapacity?: boolean | null;
  reportedVirtualDockCount?: number | null;
  maxVirtualDockCount?: number | null;
}

export interface DockGroupsConnectionFilters {
  tags?: string[] | null;
  technicalStates?: ControllerState[] | null;
  technicalState?: ControllerState | null;
}

export interface DockGroupsRebalancingListFilters {
  name?: string | null;
  title?: string | null;
  rebalancingState?: DockGroupRebalancingState[] | null;
  state?: DockGroupState[] | null;
}

export interface DockInput {
  type?: string | null;
  name?: string | null;
  number?: number | null;
  state?: string | null;
  coords?: CoordInput | null;
  elevation?: number | null;
  rfid?: string | null;
  brokenAt?: CORE_IsoDateTime | null;
  brokenReason?: string | null;
}

export interface EntityTagInput {
  tagId: string;
  entityKind: Entity;
  entityId: string;
}

export interface Filter {
  operator?: FilterOperator | null;
  attribute: string;
  filter?: string | null;
}

export interface FilterAndOrder {
  orderBy?: string | null;
  orderDirection?: OrderDirection | null;
  filters?: (Filter | null)[] | null;
  complexFilters?: (ComplexFilter | null)[] | null;
}

export interface GroupFilter {
  attribute: string;
  items: string[];
}

export interface HardwareControllerFilters {
  physicalLockId?: string | null;
  imei?: string | null;
  firmwareVersion?: string | null;
  serialNumber?: string | null;
  lockMechanismState?: LockMechanismState[] | null;
}

export interface HardwareControllerOrder {
  orderBy?: HardwareControllerOrderField | null;
  orderDirection?: OrderDirection | null;
}

export interface InputLocaleString {
  language: LanguageCode;
  text: string;
}

export interface InvoiceFilters {
  after?: CORE_IsoDateTime | null;
  before?: CORE_IsoDateTime | null;
  statuses?: InvoiceStatus[] | null;
  collectionMethods?: CollectionMethod[] | null;
  types?: InvoiceType[] | null;
  userId?: string | null;
  id?: string | null;
}

export interface InvoiceOrder {
  orderBy?: InvoiceOrderField | null;
  orderDirection?: OrderDirection | null;
}

export interface OffsetPagination {
  limit?: number | null;
  offset?: number | null;
}

export interface OperationLocationInput {
  systemId?: string | null;
  type?: OperationLocationType | null;
  name?: string | null;
  lockFences?: CoordInput[] | null;
}

export interface OrdersConnectionFilters {
  transactionNumber?: string | null;
}

/**
 * Input to create backoff rule
 */
export interface PaymentBackoffScheduleInput {
  orderType: PaymentBackoffOrderType;
  failureReason: PaymentFailureReason;
  maxAttempts: CORE_NonNegativeInt;
  minBackoffSeconds: CORE_PositiveInt;
  maxBackoffSeconds: CORE_PositiveInt;
}

export interface PenaltyItemInput {
  points: number;
  penaltyAfterMinutes: number;
}

export interface PriceMatrixCreateInput {
  matrix: PriceMatrixItemInput[];
  penalty?: PenaltyItemInput[] | null;
  notification?: TripNotificationItemInput[] | null;
  systemId: string;
  lastStepInfinite?: boolean | null;
  title: string;
  unlockFee: CORE_Money;
  defaultForVehicleCategory?: PriceMatrixVehicleCategoryInput[] | null;
}

export interface PriceMatrixItemInput {
  price: CORE_Money;
  quantity: number;
  minutes: number;
}

export interface PriceMatrixUpdateInput {
  matrix: PriceMatrixItemInput[];
  penalty?: PenaltyItemInput[] | null;
  notification?: TripNotificationItemInput[] | null;
  title: string;
  lastStepInfinite?: boolean | null;
  unlockFee: CORE_Money;
  defaultForVehicleCategory?: PriceMatrixVehicleCategoryInput[] | null;
}

export interface PriceMatrixVehicleCategoryInput {
  category: VehicleCategory;
  type: ConnectionPriceMatrixType;
}

export interface ProductInput {
  systemId?: string | null;
  sanityId?: string | null;
  name?: string | null;
  localeName?: InputLocaleString[] | null;
  localeDescription?: InputLocaleString[] | null;
  enabled?: boolean | null;
  maxVehicleCount?: number | null;
  price?: CORE_Money | null;
  freeFloatingDockingFeeAmount?: CORE_Money | null;
  vatRate?: number | null;
  redeemable?: boolean | null;
  giftable?: boolean | null;
  purchasable?: boolean | null;
  campaign?: ProductCampaign | null;
  extendedRentalOptMethod?: ProductOptMethod | null;
  tripStartCharge?: CORE_Money | null;
  rentalMeterType?: RentalMeterType | null;
  renewOptMethod?: ProductOptMethod | null;
  renewalTimePadding?: CORE_IsoDuration | null;
  purchaseChannels?: (ProductPurchaseChannel | null)[] | null;
  purchasableFrom?: CORE_IsoDateTime | null;
  purchasableTo?: CORE_IsoDateTime | null;
  descriptionBeforePurchasable?: (InputLocaleString | null)[] | null;
  redeemableFrom?: CORE_IsoDateTime | null;
  redeemableTo?: CORE_IsoDateTime | null;
  giftableFrom?: CORE_IsoDateTime | null;
  giftableTo?: CORE_IsoDateTime | null;
  durationMethod?: ProductDurationMethod | null;
  durationMins?: number | null;
  succeededById?: string | null;
  extendedRentalPeriodCharge?: CORE_Money | null;
  hideFromLists?: boolean | null;
  requiresPaymentCardOnFile?: boolean | null;
  validFrom?: CORE_IsoDateTime | null;
  validTo?: CORE_IsoDateTime | null;
  useSystemDefaultPriceMatrixes?: boolean | null;
  connectionPriceMatrixes?: ConnectionPriceMatrixInput[] | null;
  partnerIds?: PartnerId[] | null;
  freeMinutesPerDay?: number | null;
}

export interface ProductSubscriptionInput {
  systemId: string;
  productId: string;
  userId: string;
  state?: ProductSubscriptionState | null;
  validFrom?: CORE_IsoDateTime | null;
  validTo?: CORE_IsoDateTime | null;
}

export interface QueryOptions {
  page?: number | null;
  perPage?: number | null;
  order?: string | null;
  orderBy?: string | null;
  showDeleted?: boolean | null;
}

export interface RfidAuthInput {
  enabled?: boolean | null;
  rfid?: string | null;
  systemId?: string | null;
  administratorId?: string | null;
  action?: RfidAuthAction | null;
  parameters?: string[] | null;
}

export interface RoleInput {
  name: string;
  systemId?: string | null;
  description?: string | null;
  acl?: AclInput[] | null;
}

export interface SeasonInput {
  systemId: string;
  startDate: CORE_DateTime;
  endDate?: CORE_DateTime | null;
}

export interface SystemAlertUpdateInput {
  systemAlertId: string;
  administratorId: string;
  sanityId?: string | null;
  title?: InputLocaleString[] | null;
  description?: (InputLocaleString | null)[] | null;
}

export interface SystemCreateInput {
  id?: string | null;
  name: string;
  description: string;
  type: SystemType;
  state: SystemState;
  coords?: CoordInput[] | null;
  countryName?: string | null;
  countryCode?: string | null;
  cityName: string;
  defaultLanguageCode: LanguageCode;
  languageCodes?: (LanguageCode | null)[] | null;
  defaultVatRate?: number | null;
  publicDomain?: string | null;
  businessUrl?: string | null;
  operator: string;
  contactNumber: string;
  contactEmail: string;
  timezone: string;
  url?: string | null;
  maxPenaltyPoints?: number | null;
  sanityProjectId?: string | null;
  sanityBusinessProjectId?: string | null;
  sanityDataset?: string | null;
  sanityToken?: string | null;
  currency: string;
  standardRentalMins?: number | null;
  standardRentalGraceMins?: number | null;
  extendedRentalGraceMins?: number | null;
  extendedRentalPeriods?: number | null;
  extendedRentalPeriodMins?: number | null;
  colourKey?: SystemColourKey | null;
  iosAppUrl?: string | null;
  androidAppUrl?: string | null;
  webUrl?: string | null;
  workingHoursId?: string | null;
  vehicleQuickReturnPeriodSec?: number | null;
  vehicleQuickReturnsToBroken?: number | null;
  firmwareFilenamePostfix?: string | null;
  dispatcherSmsSenderID?: string | null;
  sponsor?: Sponsor | null;
  GBFS?: boolean | null;
  publicTripHistory?: boolean | null;
  pauseTripEnabled?: FeatureToggleValue | null;
  shouldAskForPushPermissions?: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled?: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled?: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled?: FeatureToggleValue | null;
  qrCodeScanEnabled?: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled?: FeatureToggleValue | null;
  vehicleReservationEnabled?: FeatureToggleValue | null;
  showSystemBoundaryEnabled?: FeatureToggleValue | null;
  intercomHelpSite?: string | null;
  settings?: SystemSettingsInput | null;
  expirationTripSmsAlertEnabled?: boolean | null;
  lateDeliverySmsAlertEnabled?: boolean | null;
  minTripLengthForCoolOffPeriodSec?: number | null;
  coolOffPeriodSec?: number | null;
  vehicleLostPeriodDays?: number | null;
  maxTripAdditionaMinutesPeriodCount?: number | null;
  tripAdditionalMinutesPeriod?: number | null;
  timeToRequestAdditionalMinutes?: CORE_NonNegativeInt | null;
  partnerIds?: PartnerId[] | null;
  businessFirebaseTenantId?: string | null;
  paymentBackoffSchedules?: PaymentBackoffScheduleInput[] | null;
  suspendDebtorEnabled?: boolean | null;
  appDeepLinkingPrefix?: string | null;
  feedbackFormUrl?: string | null;
  clickUpListId?: string | null;
  collectionMethods?: CollectionMethodsInput | null;
  smartStationAsyncUnlock?: boolean | null;
  agreedToReceiveEmailConsentOptMethod?: ConsentOptMethod | null;
  agreedToReceiveExternalPromotionsConsentOptMethod?: ConsentOptMethod | null;
  tripChargeConsolidationEnabled?: boolean | null;
  tripChargeConsolidationPeriod?: CORE_PositiveInt | null;
  tripChargeConsolidationAmount?: CORE_Money | null;
  tripChargeConsolidationSubscriptionExpiredEnabled?: boolean | null;
  tripReceiptEmailNotificationEnabled?: boolean | null;
  tripReceiptPushNotificationEnabled?: boolean | null;
  rfidAssignMethod?: UserRfidAssignMethod[] | null;
  registrationEnabled?: boolean | null;
  registrationFeeEnabled?: boolean | null;
  registrationFeeAmount?: CORE_Money | null;
  freeFloatingEnabled?: boolean | null;
  walletEnablement?: WalletEnablement | null;
  walletMinBalance?: CORE_Money | null;
  registrationAgreementWithdrawalConsent?: ConsentEnforcement | null;
  subscriptionAgreementWithdrawalConsent?: ConsentEnforcement | null;
  sendDataToThirdPartiesConsent?: ConsentEnforcement | null;
  vehicleReservationCoolOffPeriodSec?: CORE_NonNegativeInt | null;
  vehicleReservationLimitPerDay?: CORE_NonNegativeInt | null;
  vehicleReservationQuickCancellationPeriodSec?: CORE_NonNegativeInt | null;
  vehicleReservationPeriodMins?: CORE_NonNegativeInt | null;
}

export interface SystemSettingsInput {
  assemblyPageTabs?: AssemblyPageTab[] | null;
  rebalancingPageTabs?: RebalancingPageTab[] | null;
  vehiclesPageTabs?: VehiclesPageTab[] | null;
  dashboardWidgets?: DashboardWidgets[] | null;
  mainMenuItems?: MainMenuItem[] | null;
  dockGroupsListColumns?: DockGroupsListColumn[] | null;
  dockGroupPageTabs?: DockGroupPageTab[] | null;
  docksListColumns?: DocksListColumn[] | null;
  dockGroupDocksListColumns?: DockGroupDocksListColumn[] | null;
  rebalancingListColumns?: RebalancingListColumn[] | null;
  vehiclesInventoryListColumns?: VehiclesInventoryListColumns[] | null;
  vehiclesListColumns?: VehiclesListColumns[] | null;
  vehiclesAvailabilityFilter?: VehiclesAvailabilityFilter[] | null;
  vehicleAnalyticsWidgets?: VehicleAnalyticsWidgets[] | null;
  userRequiredFields?: UserRequiredFields[] | null;
  enableVehicleRFIDSearch?: boolean | null;
  enableQrScanButton?: boolean | null;
  enableAppIntegrationPage?: boolean | null;
  enableDockCommands?: boolean | null;
  enableDockGroupCommands?: boolean | null;
  enableAdminIntercomApp?: boolean | null;
  disableAdminFeedbackForm?: boolean | null;
  adminIntercomAppId?: string | null;
  showAgreedToSendDataToThirdPartiesCheckbox?: boolean | null;
  tripReceiptEmailNotificationEnabled?: boolean | null;
  tripReceiptPushNotificationEnabled?: boolean | null;
}

export interface SystemUpdateInput {
  name?: string | null;
  description?: string | null;
  type?: SystemType | null;
  state?: SystemState | null;
  coords?: CoordInput[] | null;
  countryName?: string | null;
  countryCode?: string | null;
  cityName?: string | null;
  defaultLanguageCode?: LanguageCode | null;
  languageCodes?: (LanguageCode | null)[] | null;
  publicDomain?: string | null;
  defaultVatRate?: number | null;
  businessUrl?: string | null;
  operator?: string | null;
  contactNumber?: string | null;
  contactEmail?: string | null;
  timezone?: string | null;
  url?: string | null;
  maxPenaltyPoints?: number | null;
  sanityProjectId?: string | null;
  sanityBusinessProjectId?: string | null;
  sanityDataset?: string | null;
  sanityToken?: string | null;
  currency?: string | null;
  standardRentalMins?: number | null;
  standardRentalGraceMins?: number | null;
  extendedRentalGraceMins?: number | null;
  extendedRentalPeriods?: number | null;
  extendedRentalPeriodMins?: number | null;
  colourKey?: SystemColourKey | null;
  iosAppUrl?: string | null;
  androidAppUrl?: string | null;
  webUrl?: string | null;
  workingHoursId?: string | null;
  vehicleQuickReturnPeriodSec?: number | null;
  vehicleQuickReturnsToBroken?: number | null;
  firmwareFilenamePostfix?: string | null;
  dispatcherSmsSenderID?: string | null;
  sponsor?: Sponsor | null;
  GBFS?: boolean | null;
  publicTripHistory?: boolean | null;
  pauseTripEnabled?: FeatureToggleValue | null;
  shouldAskForPushPermissions?: FeatureToggleValue | null;
  physicalDockGroupRequestVehicleEnabled?: FeatureToggleValue | null;
  virtualDockGroupRequestVehicleEnabled?: FeatureToggleValue | null;
  hybridDockGroupRequestVehicleEnabled?: FeatureToggleValue | null;
  qrCodeScanEnabled?: FeatureToggleValue | null;
  standaloneQrCodeScanEnabled?: FeatureToggleValue | null;
  vehicleReservationEnabled?: FeatureToggleValue | null;
  showSystemBoundaryEnabled?: FeatureToggleValue | null;
  intercomHelpSite?: string | null;
  settings?: SystemSettingsInput | null;
  expirationTripSmsAlertEnabled?: boolean | null;
  lateDeliverySmsAlertEnabled?: boolean | null;
  minTripLengthForCoolOffPeriodSec?: number | null;
  coolOffPeriodSec?: number | null;
  connectionPriceMatrixes?: ConnectionPriceMatrixInput[] | null;
  vehicleLostPeriodDays?: number | null;
  maxTripAdditionaMinutesPeriodCount?: number | null;
  tripAdditionalMinutesPeriod?: number | null;
  timeToRequestAdditionalMinutes?: CORE_NonNegativeInt | null;
  partnerIds?: PartnerId[] | null;
  businessFirebaseTenantId?: string | null;
  paymentBackoffSchedules?: PaymentBackoffScheduleInput[] | null;
  suspendDebtorEnabled?: boolean | null;
  appDeepLinkingPrefix?: string | null;
  feedbackFormUrl?: string | null;
  clickUpListId?: string | null;
  collectionMethods?: CollectionMethodsInput | null;
  agreedToReceiveEmailConsentOptMethod?: ConsentOptMethod | null;
  agreedToReceiveExternalPromotionsConsentOptMethod?: ConsentOptMethod | null;
  tripChargeConsolidationEnabled?: boolean | null;
  tripChargeConsolidationPeriod?: CORE_PositiveInt | null;
  tripChargeConsolidationAmount?: CORE_Money | null;
  tripChargeConsolidationSubscriptionExpiredEnabled?: boolean | null;
  tripReceiptEmailNotificationEnabled?: boolean | null;
  tripReceiptPushNotificationEnabled?: boolean | null;
  assignRfidWithQrEnabled?: boolean | null;
  registrationEnabled?: boolean | null;
  registrationFeeEnabled?: boolean | null;
  registrationFeeAmount?: CORE_Money | null;
  rfidAssignMethod?: UserRfidAssignMethod[] | null;
  freeFloatingEnabled?: boolean | null;
  walletEnablement?: WalletEnablement | null;
  walletMinBalance?: CORE_Money | null;
  registrationAgreementWithdrawalConsent?: ConsentEnforcement | null;
  subscriptionAgreementWithdrawalConsent?: ConsentEnforcement | null;
  sendDataToThirdPartiesConsent?: ConsentEnforcement | null;
  vehicleReservationCoolOffPeriodSec?: CORE_NonNegativeInt | null;
  vehicleReservationLimitPerDay?: CORE_NonNegativeInt | null;
  vehicleReservationQuickCancellationPeriodSec?: CORE_NonNegativeInt | null;
  vehicleReservationPeriodMins?: CORE_NonNegativeInt | null;
}

export interface TagInput {
  systemId: string;
  entityKind: Entity;
  name: string;
  description?: string | null;
  colour?: string | null;
}

export interface TripFilters {
  states?: TripState[] | null;
  id?: string | null;
  userId?: string | null;
  from?: CORE_IsoDateTime | null;
  to?: CORE_IsoDateTime | null;
  vehicleId?: string | null;
  subscriptionId?: string | null;
  tags?: string[] | null;
  vehicleCategories?: VehicleCategory[] | null;
}

export interface TripNotificationItemInput {
  minutes: number;
  template: TripNotificationTemplate;
}

export interface TripOrder {
  orderBy?: TripOrderField | null;
  orderDirection?: OrderDirection | null;
}

export interface TripsConnectionFilters {
  userId?: string | null;
  tags?: string[] | null;
  vehicleCategory?: VehicleCategory[] | null;
}

export interface UpdateStripeConfigInput {
  systemId: string;
  token?: string | null;
  secretKey?: string | null;
  publishableKey?: string | null;
  endpointSecret?: string | null;
}

export interface UpdateSystemAlertInput {
  systemId?: string | null;
  administratorId?: string | null;
  sanityId?: string | null;
  dockGroupIds?: string[] | null;
  systemAlertType?: SystemAlertType | null;
  visibility?: SystemAlertVisibility | null;
  incidentStartTime?: CORE_IsoDateTime | null;
  incidentEndTime?: CORE_IsoDateTime | null;
  publishedStartTime?: CORE_IsoDateTime | null;
  publishedEndTime?: CORE_IsoDateTime | null;
  title?: InputLocaleString[] | null;
  description?: InputLocaleString[] | null;
  slug?: InputLocaleString[] | null;
  isDismissible?: boolean | null;
}

export interface UserAgreementCreateInput {
  type: UserAgreementType;
  name: string;
  translations: UserAgreementTranslationCreateInput[];
}

export interface UserAgreementTranslationCreateInput {
  languageCode: LanguageCode;
  file: CORE_Upload;
  content: string;
  fileName: string;
}

export interface UserInput {
  systemId?: string | null;
  active?: boolean | null;
  superpowers?: boolean | null;
  enabledExtendedRentalTrips?: boolean | null;
  gender?: string | null;
  name?: string | null;
  birthYear?: number | null;
  testMode?: boolean | null;
  phoneNumber?: string | null;
  email?: CORE_EmailAddress | null;
  postalAddress?: string | null;
  postalCode?: string | null;
  countryName?: string | null;
  preferredLocale?: string | null;
  lastSeenLocale?: string | null;
  intercomId?: string | null;
  agreedToTermsOfUseDate?: CORE_IsoDateTime | null;
  agreedToReceiveEmail?: boolean | null;
  agreedToReceiveExternalPromotions?: boolean | null;
  agreedToProfiling?: boolean | null;
  agreedToExtendedUsageTracking?: boolean | null;
  agreedToExtendedTechTracking?: boolean | null;
  pinCode?: string | null;
  rfid?: string | null;
}

export interface ValueCodeFilters {
  companyName?: string | null;
  code?: string | null;
  receiverEmail?: string | null;
  campaignTag?: string | null;
  types: ValueCodeType[];
  createdFrom?: CORE_IsoDateTime | null;
  createdTo?: CORE_IsoDateTime | null;
  redeemedFrom?: CORE_IsoDateTime | null;
  redeemedTo?: CORE_IsoDateTime | null;
}

export interface ValueCodeInput {
  systemId: string;
  receiverEmail: string;
  productId: string;
  validUntil?: CORE_IsoDateTime | null;
  locale?: string | null;
}

export interface ValueCodeOrder {
  orderBy?: ValueCodeOrderField | null;
  orderDirection?: OrderDirection | null;
}

export interface ValueCodeUpdateInput {
  validUntil?: CORE_IsoDateTime | null;
}

export interface ValueCodesConnectionFilters {
  codesWithCampaign?: boolean | null;
  businessCodes?: boolean | null;
}

export interface VehicleFilters {
  name?: string | null;
  number?: string | null;
  rfid?: string | null;
  frameNumber?: string | null;
  availabilityStateReasons?: AvailabilityStateReason[] | null;
  availabilityStates?: AvailabilityState[] | null;
  broken?: boolean | null;
  controllerState?: ControllerState[] | null;
  controllerPowerState?: PowerState[] | null;
  entityTags?: (string | null)[] | null;
  entityTagsExcluded?: string[] | null;
  operationLocations?: string[] | null;
  dockGroups?: string[] | null;
  vehicleCategory?: VehicleCategory[] | null;
  damageType?: string[] | null;
  batteryChargeGreaterThan?: number | null;
  batteryChargeLessThan?: number | null;
  freeFloating?: boolean | null;
  lastEventFrom?: CORE_IsoDateTime | null;
  lastEventTo?: CORE_IsoDateTime | null;
  brokenAtTo?: CORE_IsoDateTime | null;
  brokenAtFrom?: CORE_IsoDateTime | null;
  controllerLockMechanismState?: LockMechanismState[] | null;
}

export interface VehicleInput {
  systemId: string;
  modelId?: string | null;
  name?: string | null;
  number?: string | null;
  qrCode?: string | null;
  tagIds?: (string | null)[] | null;
  frameNumber?: string | null;
  state?: VehicleState | null;
  priority?: number | null;
  rfid?: string | null;
}

export interface VehicleOrder {
  orderBy?: VehicleOrderField | null;
  orderDirection?: OrderDirection | null;
}

export interface VehiclesConnectionFilters {
  availabilityStateReason?: AvailabilityStateReason[] | null;
  availabilityState?: AvailabilityState[] | null;
  controllerState?: ControllerState[] | null;
  controllerPowerState?: PowerState[] | null;
  tags?: string[] | null;
  operationLocations?: string[] | null;
  dockGroups?: string[] | null;
  vehicleCategory?: VehicleCategory[] | null;
  damageType?: string[] | null;
}

export interface WalletTransactionFilters {
  types?: WalletTransactionType[] | null;
}

export interface WalletTransactionSort {
  orderBy?: WalletTransactionSortField | null;
  orderDirection?: OrderDirection | null;
}

export interface WorkingHoursInput {
  systemId: string;
  name?: string | null;
  startTime: CORE_Time;
  endTime: CORE_Time;
}

export interface WorkingHoursOverrideInput {
  workingHoursId: string;
  name: string;
  fromDate: CORE_IsoDateTime;
  toDate: CORE_IsoDateTime;
  startTime?: CORE_Time | null;
  endTime?: CORE_Time | null;
  closed?: boolean | null;
  localeReason?: InputLocaleString[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
