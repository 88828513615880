import React from "react";
import { Field } from "formik";
import {
  Label,
  Textarea,
  Select,
  Input,
  Box,
  BoxProps,
  Text
} from "@urbaninfrastructure/react-ui-kit";
import { capitalize } from "lodash";
import CurrencyInput from "./CurrencyInput";

type Props = BoxProps & {
  id?: string;
  children: React.ReactNode;
  error?: React.ReactNode;
};

export function FormGroup({ children, id, error, mb = 5, ...props }: Props) {
  if (!children) {
    return null;
  }
  if (React.isValidElement(children) && React.Children.count(children) < 1) {
    return children;
  }
  // modify children Label and Input with id and htmlFor
  const newChildren = React.Children.map(children, child => {
    if (!child) {
      return child;
    }
    if (!React.isValidElement(child)) {
      return child;
    }
    const isInput = [Input, Select, Textarea, CurrencyInput].includes(
      // @ts-ignore
      child.type
    );
    const isLabel = child.type === Label;

    // if formik field, assume its some kind of input field
    const isFormikField = child.type === Field;

    if (isInput || isLabel || isFormikField) {
      const newProps: { error: boolean; id?: string; htmlFor?: string } = {
        ...(child.props as Record<string, unknown>),
        error: !!error
      };

      if (id) {
        if (isInput || isFormikField) {
          newProps.id = id;
        } else if (isLabel) {
          newProps.htmlFor = id;
        }
      }

      return React.cloneElement(child, newProps);
    }

    return child;
  });
  return (
    <Box color={error ? ("state.error" as any) : undefined} mb={mb} {...props}>
      {newChildren}
      {error && (
        <Text mt={1} fontSize={1}>
          {typeof error === "string" ? capitalize(error) : error}
        </Text>
      )}
    </Box>
  );
}
