import { defineMessages } from "react-intl";

const routeMessages = defineMessages({
  "/[systemId]": {
    id: "routes.dashboard",
    defaultMessage: "Dashboard"
  },
  "/[systemId]/users": {
    id: "routes.users",
    defaultMessage: "Users"
  },
  "/[systemId]/users/[userId]/update": {
    id: "routes.users.update",
    defaultMessage: "Update user"
  },
  "/[systemId]/users/create": {
    id: "routes.users.create",
    defaultMessage: "Create user"
  },
  "/[systemId]/users/[userId]": {
    id: "routes.users.user",
    defaultMessage: "User"
  },
  "/[systemId]/users/[userId]/[subscriptionId]": {
    id: "routes.users.subscription",
    defaultMessage: "Subscription"
  },
  "/[systemId]/users/[userId]/create-subscription": {
    id: "routes.users.create-subscription",
    defaultMessage: "Create subscription"
  },
  "/[systemId]/trips": {
    id: "routes.trips",
    defaultMessage: "Trips"
  },
  "/[systemId]/trips/[tripId]": {
    id: "routes.trip",
    defaultMessage: "Trip"
  },
  "/[systemId]/vehicles": {
    id: "routes.vehicles",
    defaultMessage: "{vehicleNounPluralUpperFirst}"
  },
  "/[systemId]/vehicles/map": {
    id: "routes.vehicles.map",
    defaultMessage: "Map"
  },
  "/[systemId]/vehicles/analytics": {
    id: "routes.vehicles.analytics",
    defaultMessage: "Analytics"
  },
  "/[systemId]/vehicles/create": {
    id: "routes.vehicle.create",
    defaultMessage: "Create {vehicleNoun}"
  },
  "/[systemId]/vehicles/[vehicleId]": {
    id: "routes.vehicle",
    defaultMessage: "{vehicleNounUpperFirst}"
  },
  "/[systemId]/vehicles/[vehicleId]/update": {
    id: "routes.vehicles.update",
    defaultMessage: "Update {vehicleNoun}"
  },
  "/[systemId]/vehicles/[vehicleId]/reservation-details": {
    id: "routes.vehicles.reservationDetails",
    defaultMessage: "Reservation"
  },
  "/[systemId]/controllers": {
    id: "routes.controllers",
    defaultMessage: "Controllers"
  },
  "/[systemId]/controllers/[controllerId]": {
    id: "routes.controllers.controller",
    defaultMessage: "Controller"
  },
  "/[systemId]/controllers/create": {
    id: "routes.controllers.create",
    defaultMessage: "Create controller"
  },
  "/[systemId]/controllers/[controllerId]/update": {
    id: "routes.controllers.update",
    defaultMessage: "Update controller"
  },
  "/[systemId]/stations": {
    id: "routes.stations",
    defaultMessage: "Stations"
  },
  "/[systemId]/stations/[stationId]": {
    id: "routes.stations.station",
    defaultMessage: "Station"
  },
  "/[systemId]/stations/[stationId]/update": {
    id: "routes.stations.station.update",
    defaultMessage: "Update"
  },
  "/[systemId]/stations/create": {
    id: "routes.stations.create",
    defaultMessage: "Create"
  },
  "/[systemId]/sales/invoices": {
    id: "routes.sales.invoices",
    defaultMessage: "Invoices"
  },
  "/[systemId]/sales/invoices/[invoiceId]": {
    id: "routes.sales.invoices.invoice",
    defaultMessage: "Invoice"
  },
  "/[systemId]/sales": {
    id: "routes.sales",
    defaultMessage: "Sales"
  },
  "/[systemId]/sales/analytics": {
    id: "routes.sales.analytics",
    defaultMessage: "Analytics"
  },
  "/[systemId]/sales/[orderId]": {
    id: "routes.sales.orders.order",
    defaultMessage: "Order"
  },
  "/[systemId]/sales/products": {
    id: "routes.products",
    defaultMessage: "Products"
  },
  "/[systemId]/sales/products/create": {
    id: "routes.sales.products.create",
    defaultMessage: "Create product"
  },
  "/[systemId]/sales/products/[productId]": {
    id: "routes.sales.products.product",
    defaultMessage: "Product"
  },
  "/[systemId]/sales/products/[productId]/edit": {
    id: "routes.sales.products.product.edit",
    defaultMessage: "Edit product"
  },
  "/[systemId]/sales/trip-charges": {
    id: "routes.sales.tripCharges",
    defaultMessage: "Trip charges"
  },
  "/[systemId]/sales/trip-charges/create": {
    id: "routes.sales.tripCharges.create",
    defaultMessage: "Create charge"
  },
  "/[systemId]/sales/trip-charges/[tripChargeId]": {
    id: "routes.sales.tripCharges.tripCharge",
    defaultMessage: "Trip charge"
  },
  "/[systemId]/value-codes": {
    id: "routes.valueCodes",
    defaultMessage: "Value codes"
  },
  "/[systemId]/value-codes/[valueCodeId]": {
    id: "routes.valueCodes.valueCode",
    defaultMessage: "Value code"
  },
  "/[systemId]/value-codes/create": {
    id: "routes.valueCodes.create",
    defaultMessage: "Create"
  },
  "/[systemId]/docks": {
    id: "routes.docks",
    defaultMessage: "Docks"
  },
  "/[systemId]/assembly": {
    id: "routes.assembly",
    defaultMessage: "Assembly"
  },
  "/[systemId]/incidents": {
    id: "routes.incidents",
    defaultMessage: "Incidents"
  },
  "/[systemId]/maintenance": {
    id: "routes.maintenance",
    defaultMessage: "Maintenance"
  },
  "/[systemId]/maintenance/[maintenanceId]": {
    id: "routes.maintenance.report",
    defaultMessage: "Report"
  },
  "/[systemId]/maintenance/create/[assetId]": {
    id: "routes.maintenance.create",
    defaultMessage: "Create"
  },
  "/[systemId]/maintenance/damage/[assetId]": {
    id: "routes.maintenance.damage",
    defaultMessage: "Damage"
  },
  "/[systemId]/maintenance/models": {
    id: "routes.maintenance.models",
    defaultMessage: "Model settings"
  },
  "/[systemId]/maintenance/models/[modelId]": {
    id: "routes.maintenance.models.model",
    defaultMessage: "Model"
  },
  "/[systemId]/maintenance/models/create": {
    id: "routes.maintenance.model.create",
    defaultMessage: "Create model"
  },
  "/releases": {
    id: "routes.releases",
    defaultMessage: "Releases"
  },
  "/settings/systems": {
    id: "routes.settings.systems",
    defaultMessage: "Systems"
  },
  "/settings/systems/create": {
    id: "routes.settings.systems.create",
    defaultMessage: "Create system"
  },
  "/[systemId]/system": {
    id: "routes.system",
    defaultMessage: "System"
  },
  "/[systemId]/system/update": {
    id: "routes.system.update",
    defaultMessage: "System setup"
  },
  "/[systemId]/system/tags": {
    id: "routes.system.tags",
    defaultMessage: "Tags"
  },
  "/[systemId]/system/operation-locations": {
    id: "routes.system.operationLocations",
    defaultMessage: "Operation locations"
  },
  "/[systemId]/system/sleep-timetable": {
    id: "routes.system.sleepTimetable",
    defaultMessage: "Sleep timetable"
  },
  "/[systemId]/system/configurations": {
    id: "routes.system.configurations",
    defaultMessage: "Configurations"
  },
  "/[systemId]/system/business": {
    id: "routes.system.business",
    defaultMessage: "Business"
  },
  "/[systemId]/system/payment": {
    id: "routes.system.payment",
    defaultMessage: "Payment"
  },
  "/[systemId]/system/twilio": {
    id: "routes.system.twilio",
    defaultMessage: "Twilio"
  },
  "/[systemId]/system/user-agreements": {
    id: "routes.system.userAgreements",
    defaultMessage: "User agreements"
  },
  "/[systemId]/rebalancing": {
    id: "routes.rebalancing",
    defaultMessage: "Rebalancing"
  },
  "/[systemId]/rebalancing/map": {
    id: "routes.rebalancing.map",
    defaultMessage: "Map"
  },
  "/[systemId]/rebalancing/misplacedVehicles": {
    id: "routes.rebalancing.misplacedVehicles",
    defaultMessage: "Misplaced vehicles"
  },
  "/[systemId]/rebalancing/systemAvailability": {
    id: "routes.rebalancing.systemAvailability",
    defaultMessage: "System availability"
  },
  "/[systemId]/rebalancing/vehicleRFIDSearch": {
    id: "routes.rebalancing.vehicleRFIDSearch",
    defaultMessage: "Vehicle RFID search"
  },
  "/[systemId]/rebalancing/vehicleDeploy": {
    id: "routes.rebalancing.vehicleDeploy",
    defaultMessage: "Vehicle deploy"
  },
  "/[systemId]/rebalancing/unlock-vehicles": {
    id: "routes.rebalancing.unlock-vehicles",
    defaultMessage: "Unlock vehicles"
  },
  "/[systemId]/business": {
    id: "routes.business",
    defaultMessage: "Business"
  },
  "/[systemId]/business/[companyId]": {
    id: "routes.business.companies.company",
    defaultMessage: "Company"
  },
  "/[systemId]/business/[companyId]/edit": {
    id: "routes.business.edit",
    defaultMessage: "Edit"
  },
  "/[systemId]/business/[companyId]/[companyAdministratorId]": {
    id: "routes.business.company-administrators",
    defaultMessage: "Company administrator"
  },
  "/[systemId]/business/config": {
    id: "routes.business.config",
    defaultMessage: "Settings"
  },
  "/[systemId]/business/invoices": {
    id: "routes.business.invoices",
    defaultMessage: "Invoices"
  },
  "/[systemId]/business/valueCodes": {
    id: "routes.business.valueCodes",
    defaultMessage: "Value codes"
  },
  "/[systemId]/system-alerts": {
    id: "routes.systemAlerts",
    defaultMessage: "System alerts"
  },
  "/[systemId]/system-alerts/create": {
    id: "routes.systemAlerts.create",
    defaultMessage: "New system alert"
  },
  "/[systemId]/system-alerts/[systemAlertId]": {
    id: "routes.systemAlerts/view",
    defaultMessage: "System alert"
  },
  "/[systemId]/system-alerts/[systemAlertId]/edit": {
    id: "routes.systemAlerts/edit",
    defaultMessage: "Update system alert"
  },
  "/[systemId]/team": {
    id: "routes.team",
    defaultMessage: "Team"
  },
  "/[systemId]/team/[teamMemberId]": {
    id: "routes.team/team-member",
    defaultMessage: "Team member"
  },
  "/[systemId]/team/roles": {
    id: "routes.team/roles",
    defaultMessage: "Roles"
  },
  "/[systemId]/team/roles/create": {
    id: "routes.team/roles/create",
    defaultMessage: "Create role"
  },
  "/[systemId]/team/roles/[roleId]": {
    id: "routes.team/roles/role",
    defaultMessage: "Role"
  },
  "/[systemId]/team/rfid-cards": {
    id: "routes.team/rfid-cards",
    defaultMessage: "RFID cards"
  },
  "/[systemId]/discounts": {
    id: "routes.discounts",
    defaultMessage: "Discounts"
  },
  "/[systemId]/discounts/create": {
    id: "routes.discounts/create",
    defaultMessage: "Create discount campaign"
  },
  "/[systemId]/discounts/[discountId]": {
    id: "routes.discounts/discount",
    defaultMessage: "Discount campaign"
  },
  "/[systemId]/exports": {
    id: "routes.export",
    defaultMessage: "Your data exports"
  },
  "/[systemId]/support": {
    id: "routes.support",
    defaultMessage: "Support"
  }
});

export default routeMessages;
